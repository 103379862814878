import { useState, useEffect } from "react";
function useDebounce(value, deley) {
    const [debounce, setDebounce] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounce(value);
        }, deley);
        return () => {
            clearTimeout(handler);
        };
    }, [value, deley]);
    return debounce;
}

export default useDebounce;

import { Input, Pagination } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CashsTable from "../../components/Table/CashsTable";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import CompanySearch from "../../components/CompanySearch/CompanySearch"
import useDebounce from "../../Hook/useDebounce"
import { Context } from "../../Context/Context";
import Loading1 from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";

function CashsList() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate();
  const [loading, setLaoding] = useState(false);
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [changeStatus, setChangeStatus] = useState(false)
  // Basic
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalElement, setTotalElement] = React.useState(0);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  // Search Start
  const searchRef = useRef();
  const [searchVal, setSearchVal] = useState(window.localStorage.getItem("cashSearch") || "");
  const [letClear, setLetClear] = useState(JSON.parse(window.localStorage.getItem("cashSearchClear")) || false);
  const handleSearch = (evt) => {
    setPageNum(1);
    setSearchVal(evt.target.value);
    setLaoding(true);
    if (evt.target.value === "") {
      setLetClear(false);
    } else {
      setLetClear(true);
    }
  };
  const searchTextWaiting = useDebounce(searchVal, 500)
  // Search end

  // Sam Modul Start
  const samModulSearchRef = useRef()
  const [samModulSearch, setSamModulSearch] = useState(window.localStorage.getItem("cashSamModulSearch") || "")
  const [letClearSamModul, setLetClearSamModul] = useState(JSON.parse(window.localStorage.getItem("cashSearchClearSamModul")) || false);
  const searchSamModulWaiting = useDebounce(samModulSearch, 500)

  const samModulChange = (evt) => {
    setPageNum(1);
    setSamModulSearch(evt.target.value);
    setLaoding(true);
    if (evt.target.value === "") {
      setLetClearSamModul(false);
    } else {
      setLetClearSamModul(true);
    }
  }
  // Sam Modul end

  // Sam Modul Start
  const deviceIdSearchRef = useRef()
  const [deviceIdSearch, setDeviceIdSearch] = useState(window.localStorage.getItem("cashDeviceIdSearch") || "")
  const searchDeviceIdWaiting = useDebounce(deviceIdSearch, 500)
  const [letClearDeviceId, setLetClearDeviceId] = useState(JSON.parse(window.localStorage.getItem("cashSearchClearDeviceId")) || false);

  const deviceIdChange = (evt) => {
    setPageNum(1);
    setDeviceIdSearch(evt.target.value);
    setLaoding(true);
    if (evt.target.value === "") {
      setLetClearDeviceId(false);
    } else {
      setLetClearDeviceId(true);
    }
  }
  // Sam Modul end

  // Partiner search start
  const [companyName, setCompanyName] = useState(window.localStorage.getItem("cashCompName") || "")
  const [companyId, setCompanyId] = useState(JSON.parse(window.localStorage.getItem("cashCampanyId")) || role == "company" ? roleId : 0)
  // Partiner search end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end

  // POST API start
  const [cashsList, setCashsList] = useState([]);
  useEffect(() => {
    axios
      .post(
        `${env}cabinet/v2/checkout/getFilter`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            title: searchTextWaiting,
            deviceModule: searchSamModulWaiting,
            deviceNumber: searchDeviceIdWaiting,
            companyId: companyId,
            partnerId: role == "partner" ? roleId : 0
          }
        }
      )
      .then((res) => {
        setLaoding(false);
        setCashsList(res.data.listOfItems);
        setTotalElement(res.data.totalElements);
      })
      .catch((err) => {
        setLaoding(false);
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, searchTextWaiting, companyId, changeStatus, searchSamModulWaiting, searchDeviceIdWaiting]);
  // POST API end

  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/checkout/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })

  }

  window.localStorage.setItem("cashSearch", searchVal)
  window.localStorage.setItem("cashSamModulSearch", samModulSearch)
  window.localStorage.setItem("cashDeviceIdSearch", deviceIdSearch)
  window.localStorage.setItem("cashSearchClear", JSON.stringify(letClear))
  window.localStorage.setItem("cashSearchClearSamModul", JSON.stringify(letClearSamModul))
  window.localStorage.setItem("cashSearchClearDeviceId", JSON.stringify(letClearDeviceId))
  window.localStorage.setItem("cashCampanyId", JSON.stringify(companyId) ? JSON.stringify(companyId) : 0)
  window.localStorage.setItem("cashCompName", companyName)

  return (
    <div className="bg-white">
      <div className="px-5 flex items-center justify-between">
        <div>
          <h2 className="font-bold text-[16px] sm:text-[25px]">{lang ? "Все кассы" : "Barcha kassalar"}</h2>
          <span className="text-slate-500 text-[14px] sm:text-[16px]">{totalElement} {lang ? "кассы" : "kassalar"}</span>
        </div>
        {role != "company" &&
          <AddBtn onClick={() => navigate("add")} title={lang ? "Добавить кассы" : "Kassa qo'shish"} />
        }
      </div>
      {/* Inputs start */}
      <div className="p-5 flex items-center relative flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-10">
        <label className="relative w-full lg:w-[25%]">
          <span className="inline-block mb-1 pl-1 text-[14px] sm:text-[16px] text-slate-500">{lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}</span>
          <Input allowClear className="w-[100%]" size="large" value={searchVal} ref={searchRef} onChange={handleSearch} placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} />
        </label>
        <label className=" w-full lg:w-[25%]">
          <span className="inline-block text-[14px] sm:text-[16px] mb-1 pl-1 text-slate-500">{lang ? "Поиск компании" : "Kompaniya qidiring"}</span>
          <CompanySearch isDisalbed={role == "company" ? true : false} setPageNum={setPageNum} value={role == "company" ? companyId : companyId === 0 ? null : companyName} setCompanyName={setCompanyName} width={'100%'} letLoading={true} setLoading={setLaoding} setCompanyId={setCompanyId} />
        </label>
        <label className="relative w-full lg:w-[25%]">
          <span className="inline-block text-[14px] sm:text-[16px] mb-1 pl-1 text-slate-500">{lang ? "Войдите Сам Модуль" : "Sam modul kiriting"}</span>
          <Input size="large" className="w-[100%]" value={samModulSearch} ref={samModulSearchRef} onChange={samModulChange} placeholder={lang ? "Войдите Сам Модуль" : "Sam modul kiriting"} />
        </label>
        <label className="relative w-full lg:w-[25%]">
          <span className="inline-block text-[14px] sm:text-[16px] mb-1 pl-1 text-slate-500">{lang ? "Введите номер устройства" : "Aparat nomer kiriting"}</span>
          <Input className="w-[100%]" size="large" allowClear value={deviceIdSearch} ref={deviceIdSearchRef} onChange={deviceIdChange} placeholder={lang ? "Введите номер устройства" : "Aparat nomer kiriting"} />
        </label>
      </div>
      <div className="px-5 hidden lg:block">
        <CashsTable changeStatus={changeStatus} setChangeStatus={setChangeStatus} loading={loading} cashsList={cashsList} />
      </div>
      {/* Mobile start */}
      <ul className="lg:hidden px-5">
        {loading ?
          <div className="flex justify-center">
            <img
              className="flex mx-auto items-start justify-center"
              src={Loading1}
              width={70}
              height={70}
              alt="Loading Icon"
            />
          </div>
          :
          cashsList.length > 0 && cashsList.map(item => (
            <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] text-end font-semibold">ID</span>
                <span className="w-[50%]">: {item.id}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ИМЯ" : "NOMI"}</span>
                <span className="w-[50%]">{item.title}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">
                  {lang ? "ОРГАНИЗАЦИЯ, ФИЛИАЛ" : "TASHKILOT"},
                  <br />
                  {lang ? " ФИЛИАЛ" : "FILIAL"}
                </span>
                <div className="w-[50%]">
                  <span>TN : {item.companyName}</span>,
                  <br />
                  <span>FN : {item.branchName}</span>
                </div>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ИНН" : "INN"}</span>
                <span className="w-[50%]">{item.companyInn}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "САМ МОДУЛЬ" : "SAM MODUL"}</span>
                <span className="w-[50%]">{item.deviceModule}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "№ УСТРОЙСТВА" : "APPARAT NOMER"}</span>
                <span className="w-[50%]">{item.deviceNumber}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ВРЕМЯ СОЗДАНИЯ" : "YARATILGAN VAQT"}</span>
                <span className="w-[50%]">{item.createdAt.split("T")[0]} - {item.createdAt.split("T")[1].split(".")[0]}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "СТАТУСЬ" : "HOLAT"}</span>
                <div
                  id={item.id}
                  onClick={clickedActive}
                  className="flex w-[50%] cursor-pointer py-1 items-center"
                >
                  <ul
                    id={item.id}
                    className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                      } rounded-[50px]`}
                  >
                    <li
                      id={item.id}
                      className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                        } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                    ></li>
                  </ul>
                </div>
              </div>
              <div className="flex items-center justify-between py-1 px-3">
                <span className="w-[50%] font-semibold">{lang ? "Подробно" : "Batafsil"}</span>
                <div className="w-[50%]">
                  <button
                    onClick={(evt) => navigate(`${item.id}`)}
                    id={item.id}
                    className="flex justify-center items-center rounded-[50%] w-[20px] h-[20px] bg-[#BFDDFC]"
                  >
                    <img className="w-[15px] h-[15px]" id={item.id} src={Edit} width={'100%'} height={"100%"} alt="Edit" />
                  </button>
                </div>
              </div>

            </li>
          ))
        }
      </ul>
      {/* Mobile end */}
      <div className="hidden sm:flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || cashsList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
      <div className="flex sm:hidden justify-center py-5">
        <Pagination
          size="small"
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || cashsList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default CashsList;

import React, { useEffect } from 'react'
import { Select } from 'antd';
import axios from 'axios';
import { useState } from 'react';
function BranchSearch({ setDistricId, regionId, width, value, setDistrictName, lang }) {
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));

  const onChange = (value, name) => {
    setDistricId(value);
    setDistrictName(name?.label)
  };
  const [districList, setDistricList] = useState([])


  useEffect(() => {
    if (regionId) {
      axios
        .get(`${env}cabinet/v2/district?regionId=${regionId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDistricList([...res.data.map(item => ({ value: item.id, label: item.title }))]);
        })
    }
  }, [regionId])
  return (
    <Select
      value={value}
      style={{ 'inlineSize': `${width}` }}
      showSearch
      allowClear
      size="large"
      placeholder={lang ? "Выберите район" : "Tuman tanlang"}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={districList}
    />
  )
}

export default BranchSearch
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom"
import UzImg from "../../Assets/Images/uzb.png"
import RuImg from "../../Assets/Images/ru.png"
import BackIcon from "../../Assets/Images/real-back.png"
import { Dropdown, Modal } from "antd";
import { Context } from "../../Context/Context";
function Header() {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const { lang, setLang } = useContext(Context)
  const [showModal, setShowModal] = useState(false);
  const [openLogOut, setOpenLogOut] = useState(false)
  const loginName = JSON.parse(window.localStorage.getItem("userLogName"));
  const handleDelToken = () => {
    navigate('/')
    window.localStorage.removeItem("token");
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  };
  const items = [
    {
      key: '1',
      label: (
        <button onClick={() => setLang(!lang)}>
          {lang ? <img src={UzImg} width={40} height={40} alt="Uz" /> : <img src={RuImg} width={40} height={40} alt="Uz" />}
        </button>
      ),
    }
  ];

  window.localStorage.setItem("lang", JSON.stringify(lang))
  return (
    <header className="bg-white py-[19px] px-[30px]">
      <div className="flex items-center justify-between w-full">
        <button onClick={() => {
          navigate(-1)
        }}>
          <img src={BackIcon} width={30} height={30} alt="Back" />
        </button>
        <div className="flex space-x-2 sm:space-x-6">
          <Dropdown
            menu={{
              items,
            }}
            placement="bottom"
            arrow={{
              pointAtCenter: true,
            }}
          >
            <button className="hover:bg-[#D7F9EF] duration-200 px-3 rounded-[4px]">
              {lang ? <img src={RuImg} width={40} height={40} alt="Uz" /> : <img src={UzImg} width={40} height={40} alt="Uz" />}

            </button>
          </Dropdown>
          <div className="text-right">
            <h2 className="capitalize font-semibold">{loginName?.firstName} {loginName?.lastName}</h2>
            <p className="text-slate-400 text-[14px]">
              {loginName?.role === 1 ? "Administrator" : ""}
              {loginName?.role === 4 ? "Xamkor" : ""}
              {loginName?.role === 5 ? "Kompaniya" : ""}
            </p>
          </div>
          <button
            onClick={() => setOpen(true)}
            className="h-[50px] w-[50px] relative  bg-[#D7F9EF] rounded-md flex items-center justify-center "
          >
            <span className="uppercase font-semibold sm:text-[22px] text-[#0BB783]">{loginName?.firstName?.split("")[0]}{loginName?.lastName?.split("")[0]}</span>
          </button>
        </div>
      </div>
      <Modal
        centered
        open={open}
        onOk={handleDelToken}
        onCancel={() => setOpen(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Выход" : "Tizimdan chiqish"}</h2>
        <p className="text-[20px]">{lang ? "Если вы выйдете из системы, все данные будут удалены!" : "Agar tizimdan chiqilsa barcha ma'lumotlar o'chib ketadi !"}</p>
      </Modal>
    </header >
  );
}

export default Header;

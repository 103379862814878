import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import { AddBtn, UpdateBtn } from '../../components/NewBtn/NewBtn';
import { Modal } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import Edit1 from "../../Assets/Images/dots.svg"


function ResearchMore() {
  // Get All users Start
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [changeStatus, setChangeStatus] = useState(false)
  const { id } = useParams()

  // Company if true start
  const [companyName, setCompanyName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  // Company if true end

  // change status start
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/poll/change-status?id=${Number(id)}`,
        {
          params: {
            id: Number(id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })
  }
  // change status end

  // Get By Id start
  const [pollMore, setPollMore] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setPollMore(res.data);
        if (res.data.companyId) {
          axios
            .get(`${env}cabinet/v2/company/${res.data.companyId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setCompanyName(res.data.title);
            })
        }
        if (res.data.telegramId) {
          axios
            .get(`${env}cabinet/v2/contact/select`, {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            })
            .then((res1) => {
              setPhoneNumber(res1.data.find(item => item.telegramId === res.data.telegramId)?.phoneNumber);
            })
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, changeStatus]);
  // Get By Id end

  // Delete Poll start
  const [deleteModal, setDeleteModal] = useState(false)
  const pollDelete = () => {
    axios
      .post(
        `${env}cabinet/v2/poll/delete?id=${Number(id)}`,
        {
          params: {
            id: Number(id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(res => {
        setDeleteModal(false)
        toast.success(lang ? "Сохранено" : "Saqlandi")
        setTimeout(() => {
          navigate(-1)
        }, 800)
      })
  }
  // Delete Poll end
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <h2 className="font-bold text-[25px] ">{lang ? "Опрос" : "Sorovnoma"} : {lang ? pollMore?.titleRu : pollMore?.titleUz}</h2>
        <div className='flex items-end space-x-3'>
          <div
            onClick={clickedActive}
            className="flex cursor-pointer justify-center py-1 items-center"
          >
            {lang ? "Активный" : "Faol"}
            <ul
              className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${pollMore?.isActive ? "bg-blue-500 " : "bg-slate-300"
                } rounded-[50px]`}
            >
              <li
                className={`absolute duration-200 ${pollMore?.isActive ? "translate-x-4" : ""
                  } inset-0.5 w-[45%] rounded-[50%] bg-white`}
              ></li>
            </ul>
          </div>
          <button onClick={() => setDeleteModal(true)} className='bg-red-500 p-1 rounded-md'>
            <svg onClick={() => setDeleteModal(true)} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#fff" className="bi bi-trash3" viewBox="0 0 16 16">
              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
            </svg>
          </button>
          <UpdateBtn onClick={() => navigate(`/research/edit/${id}`)} title={lang ? "Редактирование" : 'Tahrirlash'} />
        </div>
      </div>
      <div className='p-3'>
        <ul className='p-2 flex flex-col space-y-2 border-[1.5px] border-slate-300 w-[50%] rounded-md'>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Имя Уз" : "Nomi Uz"}</span>
            <p className='text-[18px]'>{pollMore?.titleUz}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Имя Ру" : "Nomi Ru"}</span>
            <p className='text-[18px]'>{pollMore?.titleRu}</p>
          </li>
          {pollMore?.telegramId ?
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Номер телефона" : "Telefon nomer"}</span>
              <p className='text-[18px]'>{phoneNumber}</p>
            </li>
            :
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Название компании" : "Kompaniya nomi"}</span>
              <p className='text-[18px]'>{companyName}</p>
            </li>
          }

          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "СРОК" : "MUDDATI"}</span>
            <p className='text-[18px]'>{pollMore?.expirationDate}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Дата создания" : "Yaratilgan vaqt"}</span>
            <p className='text-[18px]'>{pollMore?.createdAt?.split("T")[0]} - {pollMore?.createdAt?.split("T")[1]?.split(".")[0]}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Дата изменения" : "O'zgartirilgan vaqt"}</span>
            <p className='text-[18px]'>{pollMore?.updatedAt?.split("T")[0]} - {pollMore?.updatedAt?.split("T")[1]?.split(".")[0]}</p>
          </li>
        </ul>
      </div>
      <div className='p-3'>
        <div className='flex justify-end py-1'>
          <AddBtn onClick={() => navigate("question/add")} title={lang ? "Добавить вопрос" : "Savol qo'shish"} />
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-slate-400">
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">ID</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Вопрос Уз" : "Savol Uz"}</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Вопрос в Ру" : "Savol Ru"}</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Время создания" : "Yaratilgan vaqt"} </th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Тип ответа" : "Javob turi"} </th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Последовательность" : "Tartibi"} </th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5 px-1">{lang ? "ПОДРОБНО" : "BARAFSIL"}</th>
            </tr>
          </thead>
          <tbody>
            {pollMore?.questions?.length > 0 ?
              pollMore?.questions.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.questionUz}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.questionRu}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.createdAt?.split("T")[0]} - {item?.createdAt?.split("T")[1]?.split(".")[0]}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.answerType == 0 && "Javobsiz"}{item.answerType == 1 && "Matn"}{item.answerType == 2 && "Knopka"}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.ordinalNumber == 0 ? lang ? item.prevAnswerRu : item.prevAnswerUz : item?.ordinalNumber}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">
                    <button
                      onClick={() => navigate(`question/more/${item.id}`)}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC] hover:bg-[#a6d3ff]"
                    >
                      <img id={item.id} src={Edit1} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))
              :
              <tr>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <Modal
        centered
        open={deleteModal}
        onOk={pollDelete}
        onCancel={() => setDeleteModal(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Удалить опрос" : "So'rovnomani o'chirish"}</h2>
      </Modal>
    </div>
  )
}

export default ResearchMore
import { Radio } from "antd";
import axios from "axios";
import React, { useState, useRef } from "react";
import { toast, Toaster } from "react-hot-toast";
import Buttons from "../Buttons/Buttons";
import { Modal } from "../Modal/Modal";
import Aye from "../../Assets/Images/open-aye.svg"
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function VersionListTable({ refResh, setRefResh, versionList }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const navigate = useNavigate()
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [editDevValue, setEditDevValue] = useState({});
  const [showModal, setShowModal] = useState(false);
  const clickedActive = (evt) => {
    const clickedObj = versionList.find((item) => item.id === Number(evt.target.id));
    axios
      .put(
        `${env}cabinet/v2/versions/${Number(evt.target.id)}?importance=${clickedObj.important ? 0 : 1
        }`,
        {
          params: {
            importance: clickedObj.important ? 0 : 1,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefResh(!refResh);
      })
      .catch((err) => {
        toast.error("Xatolik bor !");
      });
  };
  // Version List

  // Radio buttons changes start
  const [value, setValue] = useState(editDevValue?.important);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  // Radio buttons changes end
  // Edit Click start
  const addInputCount = useRef();
  const aboutVersionRef = useRef();
  const handleEditIconCLick = (evt) => {
    setEditDevValue(versionList.find((item) => item.id === Number(evt.target.id)));
    setShowModal(true);
  };
  // Edit Click end
  // Edit Form Submit form start
  const editSubmitForm = (evt) => {
    evt.preventDefault();
    const data = {
      id: editDevValue.id,
      title: aboutVersionRef.current.value,
      versionNumber: addInputCount.current.value,
      important: value,
      status: true
    };
    axios
      .put(`${env}cabinet/v2/version/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success("Saqlandi");
        setRefResh(!refResh);
        setShowModal(false);
      })
      .catch((err) => {
        toast.error("Xatolik bor");
      });
  };
  // Edit Form Submit form end
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className=" font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">ID</th>
            <th className=" font-normal uppercase text-[13px] xl:text-[15px] text-center w-[12%] p-2.5">{lang ? "Номер версии" : "Versiya raqami"}</th>
            <th className=" font-normal uppercase text-[13px] xl:text-[15px] text-center  p-2.5">{lang ? "Объяснение" : "Izoh"}</th>
            <th className=" font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Обязательство" : "Majburiylik"}</th>
            <th className=" font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5 w-[8%]">{lang ? "Статус" : "Holat"}</th>
            <th className=" font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Редактирование" : "Tahrirlash"}</th>
          </tr>
        </thead>
        <tbody>
          {versionList.length > 0 &&
            versionList.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA]">
                <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item.versionNumber}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center text-ellipsis whitespace-nowrap overflow-hidden max-w-[150px] xl:max-w-[400px]  p-2.5">{item.title}</td>
                <td className="border-t-[1px] relative border-slate-300 text-center p-2.5">
                  <div
                    id={item.id}
                    onClick={clickedActive}
                    className="flex cursor-pointer  justify-center items-center"
                  >
                    <ul
                      id={item.id}
                      className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.important ? "bg-blue-500 " : "bg-slate-300"
                        } rounded-[50px]`}
                    >
                      <li
                        id={item.id}
                        className={`absolute duration-200 ${item.important ? "translate-x-4" : ""
                          } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                      ></li>
                    </ul>
                  </div>
                </td>
                <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">
                  {item.status ? "Active " : "Active emas"}
                </td>
                <td className="border-t-[1px] relative flex items-center justify-center border-slate-300 text-center ">
                  <div className={`absolute flex items-center  top-0`}>
                    <button id={item.id} onClick={(evt) => navigate(`more/${evt.target.id}`)} className="p-[10px] hover:bg-[#6bc5f1] rounded-tl-md rounded-bl-md cursor-pointer">
                      <img id={item.id} src={Aye} width={25} height={25} alt="More" />
                    </button>
                    <button id={item.id} onClick={handleEditIconCLick} className="p-[10px] hover:bg-[#6bc5f1] rounded-tr-md rounded-br-md cursor-pointer">
                      <svg id={item.id} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="blue" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal className={`w-[70%] overflow-y-auto max-h-[85%]`} isVisible={showModal} onClose={() => setShowModal(false)}>
        <h2 className="text-center font-semibold ">{lang ? "Версия Править" : "Versiya Tahrirlash"}</h2>
        <form onSubmit={editSubmitForm}>
          <label className="flex flex-col">
            {lang ? "Номер версии" : "Versiya raqami"}
            <input
              defaultValue={editDevValue?.versionNumber}
              ref={addInputCount}
              type={"number"}
              className="outline-none border-2 py-2  text-center w-[30%]"
            />
          </label>
          <label className="flex flex-col mt-5">
            {lang ? "О Верси..." : "Versihaqida ..."}
            <textarea
              rows={10}
              defaultValue={editDevValue?.title}
              ref={aboutVersionRef}
              className="outline-none border-2 rounded-md p-2"
              placeholder={lang ? "информация" : "malumot"}
            ></textarea>
          </label>
          <div className="flex items-end justify-between">
            <label className="flex flex-col mt-5">
              {lang ? "Уровень важности:" : "Muhimlik darajasi :"}
              <Radio.Group
                onChange={onChange}
                className="mt-3"
                name="radiogroup"
                defaultValue={editDevValue?.important ? true : false}
              >
                <Radio className="p-2 rounded-md border-2 border-slate-200 font-semibold" value={true}>
                  {lang ? "Важный" : "Muhim"}
                </Radio>
                <Radio
                  className="p-2 rounded-md border-2 border-slate-200 font-semibold"
                  value={false}
                >
                  {lang ? "Не важно" : "Muhim emas"}
                </Radio>
              </Radio.Group>
            </label>
            <Buttons
              extraSty={"h-[45px] w-[250px]"}
              btnType={"submit"}
              bg={"blue"}
              title={lang ? "Сохранять" : "Saqlash"}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default VersionListTable;

import React from 'react'

function Checkbox({ children, setOpen, open, handleClick }) {
  return (
    <button onClick={handleClick} className='flex items-center font-semibold space-x-2'>
      <span className='flex items-center justify-center w-[18px] relative h-[18px] rounded-[50%] border-[1px] border-slate-400'>
        {open ?
          <span className='absolute w-[12px] h-[12px] rounded-[50%] bg-blue-500'></span>
          : ""}
      </span>
      <span>{children}</span>
    </button>
  )
}

export default Checkbox
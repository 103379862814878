import axios from 'axios';
import React from 'react'
import { Input, Modal } from 'antd';
import { Toaster, toast } from 'react-hot-toast';
import { useState } from 'react';
import { AddBtn } from "../../../../components/NewBtn/NewBtn"
import { useContext } from 'react';
import { Context } from '../../../../Context/Context';
import UpdateIcon from "../../../../Assets/Images/edit.svg"

function Contact({ id, contactList, changeStatus, setChangeStatus }) {
  const { lang, role } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const clickedActive = (evt) => {
    if (role != "company") {
      axios
        .put(
          `${env}cabinet/v2/contact/change-status?id=${Number(evt.target.id)}`,
          {
            params: {
              id: Number(evt.target.id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setChangeStatus(!changeStatus)
        })
    }
    else {
      toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murojaat qiling")
    }
  }


  // Add modal start
  const [openEdit, setOpenEdit] = useState(false);
  // Phone number start
  const [phoneNumber, setPhoneNumber] = useState("+998")
  const phoneChange = (evt) => {
    setPhoneNumber(evt.target.value)
  }
  // Phone number end
  const addUser = (evt) => {
    const data = {
      name: name,
      phoneNumber: phoneNumber,
      companyId: id,
      isAdmin: false
    }
    if (phoneNumber.length == 13 && name != null) {
      axios
        .post(`${env}cabinet/v2/contact/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          toast.success("Qo;shildi !")
          setOpenEdit(false)
          setChangeStatus(!changeStatus)

        }).catch(err => {
          if (err.response.status === 406) {
            toast.error(lang ? "Этот номер имеется в базе данных" : "Bu raqam bazada mavjud !")
          }
        })
    }
    else {
      toast.error(lang ? "Введите полный номер телефона" : "To'liq telefon raqam kiriting")
    }

  }
  // Add modal end

  // update modal start
  const [name, setName] = useState(null)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [clickUpdateId, setClickUpdateId] = useState(null)
  const clickUpdateBtn = (evt) => {
    setOpenEditModal(true)
    setClickUpdateId(evt.target.id)
    axios
      .get(
        `${env}cabinet/v2/contact/${Number(evt.target.id)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setName(res.data.name)
        setPhoneNumber(res.data.phoneNumber)
      })
  }
  const updateUser = () => {
    const data = {
      id: clickUpdateId - 0,
      name: name,
      phoneNumber: phoneNumber,
      companyId: id - 0,
      isAdmin: false
    }
    if (name && phoneNumber) {
      axios
        .post(`${env}cabinet/v2/contact/edit`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: data
        })
        .then((res) => {
          toast.success(lang ? "Сохранено" : "Saqlandi ")
          setOpenEditModal(false)
          setChangeStatus(!changeStatus)
        })
        .catch((err) => {
          if (err.response.status === 406) {
            toast.error(lang ? "Этот номер имеется в базе данных" : "Bu raqam bazada mavjud !")
          }
          else {
            toast.error(lang ? "Информация не сохранена" : "Ma'lumot saqlanmadi !")
          }
        });
    }
    else {
      toast.error(lang ? "Введите имя или номер телефона" : "Ism yoki telefon raqam kiriting ")
    }
  }
  // update modal end

  // Delete modal start
  const [open, setOpen] = useState(false);
  const [delId, setDelId] = useState(0)
  const clickedDelBtn = (evt) => {
    setOpen(true)
    setDelId(evt.target.id)
  }

  const deleteUser = () => {
    axios
      .post(
        `${env}cabinet/v2/contact/delete?id=${Number(delId)}`,
        {
          params: {
            id: Number(delId),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
        toast.success("O'chirildi !")
        setOpen(false)
      })
  }
  // Delete modal end

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='flex items-center justify-center sm:justify-end px-10 mt-2'>
        {role != "company" ?
          <AddBtn onClick={() => {
            setOpenEdit(true)
            setName(null)
            setPhoneNumber("+998")
          }} title={"Qo'shish"} />
          : ""}
      </div>
      <div className='hidden lg:block'>
        <table className="w-full mt-5">
          <thead>
            <tr className="text-slate-400 text-center">
              <th className=" py-2.5 font-normal text-[17px] ">ID</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Имя" : "Ismi"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Номер телефона" : "Telefon raqam"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Позиция" : "Lavozim"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Телеграмма ID" : "Telegram ID"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Время создания" : "Yaratilgan vaqt"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Статус" : "Holat"}</th>
              {role != "company" ?
                <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Процессе" : "Amal"}</th>
                : ""}
            </tr>
          </thead>
          <tbody>
            {contactList.length > 0 &&
              contactList.map((item, index) => (
                <tr className="odd:bg-[#F7F7F7]" key={index}>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{index + 1}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item.name ? item.name : "-"}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item.phoneNumber}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item.isAdmin ? "Administrator" : "Foydalanuvchi"}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item.telegramId}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">  {item?.createdAt?.split("T")[0]}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.isActive ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.isActive ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td className='font-normal py-3 flex items-center justify-center space-x-3'>
                    {role != "company" ?
                      <>
                        <button
                          onClick={clickUpdateBtn}
                          id={item.id}
                          className="bg-blue-500 flex items-center justify-center hover:opacity-70 rounded-lg h-[35px] w-[35px] "
                        >
                          <img id={item.id} src={UpdateIcon} width={25} height={25} alt="Edit" />
                        </button>
                        {role != "partner" ?
                          <button onClick={clickedDelBtn} id={item.id} className='w-[35px] h-[35px] flex items-center justify-center text-slate-600 bg-red-300 hover:bg-red-800 hover:text-white rounded-[10px]'>
                            <svg id={item.id} width="25px" height="25px" viewBox="0 -0.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                              <g id="Page-1" stroke="currentColor" strokeWidth="0.01" fill="currentColor" fillRule="evenodd">
                                <g id="Dribbble-Light-Preview" transform="translate(-179.000000, -360.000000)" fill="currentColor">
                                  <g id="icons" transform="translate(56.000000, 160.000000)">
                                    <path id={item.id} d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z">
                                    </path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </button> : ""}
                      </>
                      : ""}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ul className="lg:hidden">
        {contactList.length > 0 && contactList.map((item, index) => (
          <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] text-end font-semibold">ID</span>
              <span className="w-[50%]">: {index + 1}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Имя" : "Ismi"}</span>
              <span className="w-[50%]">{item.name ? item.name : "-"}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Позиция" : "Lavozim"}</span>
              <span className="w-[50%]">{item.isAdmin ? "Administrator" : "Foydalanuvchi"}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Телеграмма ID" : "Telegram ID"}</span>
              <span className="w-[50%]">{item.telegramId ? item.telegramId : "-0"}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
              <span className="w-[50%]">{item?.createdAt?.split("T")[0]}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Процессе" : "Amal"}</span>
              <div className="w-[50%] flex space-x-2">
                <button
                  onClick={clickUpdateBtn}
                  id={item.id}
                  className="bg-blue-500 flex items-center justify-center hover:opacity-70 rounded-lg w-[30px] h-[30px]"
                >
                  <img id={item.id} src={UpdateIcon} width={'75%'} height={"75%"} alt="Edit" />
                </button>
                <button onClick={clickedDelBtn} id={item.id} className='w-[30px] h-[30px] flex items-center justify-center text-slate-600 bg-red-300 hover:bg-red-800 hover:text-white rounded-[10px]'>
                  <svg id={item.id} width="70%" height="70%" viewBox="0 -0.5 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                    <g id="Page-1" stroke="currentColor" strokeWidth="0.01" fill="currentColor" fillRule="evenodd">
                      <g id="Dribbble-Light-Preview" transform="translate(-179.000000, -360.000000)" fill="currentColor">
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                          <path id={item.id} d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z">
                          </path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </li>
        ))
        }
      </ul>
      <Modal
        title="Ma'lumotni ochirish !"
        centered
        open={open}
        onOk={deleteUser}
        onCancel={() => setOpen(false)}
        width={500}
      >
      </Modal>
      <Modal
        title={lang ? "Добавьте номер телефона" : "Telefon raqam qo'shish"}
        centered
        open={openEdit}
        onOk={addUser}
        onCancel={() => setOpenEdit(false)}
        width={800}
      >
        <div>
          <div className='flex flex-col sm:flex-row items-center justify-between mb-5'>
            <label className='flex flex-col mb-3 sm:mb-0 w-full sm:w-[48%]'>
              <span className='text-slate-500 text-[16px] inline-block mb-1 pl-1'>{lang ? "Введите имя" : "Ism kiriting"}</span>
              <Input value={name} className='w-full' onChange={(evt) => setName(evt.target.value)} size='large' allowClear placeholder={lang ? "Введите имя" : "Ism kiriting"} />
            </label>
            <label className='flex flex-col w-full sm:w-[48%]'>
              <span className='text-slate-500 text-[16px] inline-block mb-1 pl-1'>{lang ? "Введите номер телефона" : "Telefon raqam kiriting"}</span>
              <Input maxLength={13} allowClear value={phoneNumber} onChange={phoneChange} className='w-full' placeholder='Telefon raqam' size='large' />
            </label>
          </div>
        </div>
      </Modal>
      <Modal
        title={lang ? "Добавьте номер телефона" : "Telefon raqam qo'shish"}
        centered
        open={openEditModal}
        onOk={updateUser}
        onCancel={() => setOpenEditModal(false)}
        width={800}
      >
        <div>
          <div className='flex flex-col sm:flex-row items-center justify-between mb-5'>
            <label className='flex flex-col mb-3 sm:mb-0 w-full sm:w-[48%]'>
              <span className='text-slate-500 text-[16px] inline-block mb-1 pl-1'>{lang ? "Введите имя" : "Ism kiriting"}</span>
              <Input value={name} className='w-full' onChange={(evt) => setName(evt.target.value)} size='large' allowClear placeholder={lang ? "Введите имя" : "Ism kiriting"} />
            </label>
            <label className='flex flex-col w-full sm:w-[48%]'>
              <span className='text-slate-500 text-[16px] inline-block mb-1 pl-1'>{lang ? "Введите номер телефона" : "Telefon raqam kiriting"}</span>
              <Input maxLength={13} allowClear value={phoneNumber} onChange={phoneChange} className='w-full' placeholder='Telefon raqam' size='large' />
            </label>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Contact
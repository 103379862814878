import React, { useRef } from 'react'
import Buttons from '../../components/Buttons/Buttons'
import { useState, useEffect } from 'react'
import Inputs from '../../components/Inputs/Inputs'
import dayjs from "dayjs";
import { DatePicker } from "antd";
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function MessageAdd() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate()
  const { id } = useParams()
  const dateFormat = "YYYY-MM-DD";
  const date = new Date();
  const [activeLang, setActiveLang] = useState("O'z")
  const [activeLang2, setActiveLang2] = useState("O'z")

  // Title Change start
  const [titleOz, setTitleOz] = useState(window.localStorage.getItem("messTitleOz") || "")
  const [titleUz, setTitleUz] = useState(window.localStorage.getItem("messTitleUz") || "")
  const [titleRu, setTitleRu] = useState(window.localStorage.getItem("messTitleRu") || "")
  const handleTitleChangeOz = (evt) => {
    setTitleOz(evt.target.value)
  }
  const handleTitleChangeUz = (evt) => {
    setTitleUz(evt.target.value)
  }
  const handleTitleChangeRu = (evt) => {
    setTitleRu(evt.target.value)
  }
  window.localStorage.setItem("messTitleOz", titleOz)
  window.localStorage.setItem("messTitleUz", titleUz)
  window.localStorage.setItem("messTitleRu", titleRu)
  // Title Change end 

  // Content change start
  const [contentOz, setContentOz] = useState(window.localStorage.getItem("messContentOz") || "")
  const [contentUz, setContentUz] = useState(window.localStorage.getItem("messContentOz") || "")
  const [contentRu, setContentRu] = useState(window.localStorage.getItem("messContentOz") || "")
  const contentChangeOz = (evt) => {
    setContentOz(evt.target.value)
  }
  const contentChangeUz = (evt) => {
    setContentUz(evt.target.value)
  }
  const contentChangeRu = (evt) => {
    setContentRu(evt.target.value)
  }
  window.localStorage.setItem("messContentOz", contentOz)
  window.localStorage.setItem("messContentOz", contentOz)
  window.localStorage.setItem("messContentOz", contentOz)
  // Content change end

  // Sam Modul start
  const samModulRef = useRef()
  // Sam Modul end

  // Time change start

  const [devTimem, setDevTime] = useState(
    date.getDate().toString().padStart(2, "0")
  );
  const [devTimem2, setDevTime2] = useState(
    date.getDate().toString().padStart(2, "0")
  );
  const handleChangeDatePiccer = (evt) => {
    setDevTime(evt.$D);
  };
  const handleChangeDatePiccer2 = (evt) => {
    setDevTime2(evt.$D);
  };
  // Time change end

  // Update part start
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/message/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTitleOz(res.data.titleOz)
          setTitleUz(res.data.titleUz)
          setTitleRu(res.data.titleRu)
          setContentOz(res.data.contentOz)
          setContentUz(res.data.contentUz)
          setContentRu(res.data.contentRu)
          samModulRef.current.value = res.data?.samModul
          setDevTime(res.data.createdAt?.split("T")[0])
          setDevTime2(res.data.endDate?.split("T")[0])
        })
    }
    else {
      setTitleOz("")
      setTitleUz("")
      setTitleRu("")
      setContentOz("")
      setContentUz("")
      setContentRu("")
    }
  }, []);
  // Update part end

  // Create message form submit start
  const handleSabmitForm = () => {
    if (id) {
      const data = {
        titleOz: titleOz,
        titleUz: titleUz,
        titleRu: titleRu,
        contentOz: contentOz,
        contentUz: contentUz,
        contentRu: contentRu,
        startDate: devTimem,
        endDate: devTimem2,
        status: true,
        samModul: samModulRef.current.value
      }

      axios
        .put(`${env}cabinet/v2/message/edit/${id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success(res.data)
          setTitleOz("")
          setTitleUz("")
          setTitleRu("")
          setContentOz("")
          setContentUz("")
          setContentRu("")
          setTimeout(() => {
            navigate(-1)
          }, 500)
        })
    }
    else {
      const data = {
        titleOz: titleOz,
        titleUz: titleUz,
        titleRu: titleRu,
        contentOz: contentOz,
        contentUz: contentUz,
        contentRu: contentRu,
        startDate: devTimem,
        endDate: devTimem2,
        status: true,
        samModul: samModulRef.current.value
      }
      axios
        .post(`${env}cabinet/v2/message/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success(res.data)
          setTitleOz("")
          setTitleUz("")
          setTitleRu("")
          setContentOz("")
          setContentUz("")
          setContentRu("")
          setTimeout(() => {
            navigate(-1)
          }, 500)
        })
    }
  }
  // Create message form submit end
  return (
    <div className='p-5 bg-white'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='pb-5 flex justify-between'>
        <h2 className='font-bold text-[22px]'>{lang ? "Добавить сообщение" : "Xabar qo'shish"}</h2>
        <Buttons clicked={handleSabmitForm} extraSty={'w-[120px]'} bg={'blue'} title={lang ? "Сохранять" : 'Saqlash'} />
      </div>
      <div className='flex justify-between'>
        <div className='w-[29%] rounded-md border-[1px] border-slate-400'>
          <div className='flex p-5 border-b-[1px] border-x-slate-400 items-center justify-between'>
            <h3 className='font-semibold'>{lang ? "Тема" : "Mavzu"} </h3>
            <ul className='flex justify-end items-start'>
              <li onClick={() => setActiveLang("O'z")} className={`text-[14px] ${activeLang === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
              <li onClick={() => setActiveLang("Uz")} className={`text-[14px] ${activeLang === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
              <li onClick={() => setActiveLang("Ru")} className={`text-[14px] ${activeLang === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
            </ul>
          </div>
          <div className='flex flex-col py-5 justify-between'>
            <div className='px-5'>
              {activeLang === "O'z" &&
                <div>
                  <span>O'Z : </span>
                  <Inputs inputVal={titleOz} handleChange={handleTitleChangeOz} inputPlace={'Mavzu kiriting'} />
                </div>
              }
              {activeLang === "Uz" &&
                <div>
                  <span>UZ : </span>
                  <Inputs inputVal={titleUz} handleChange={handleTitleChangeUz} inputPlace={'Мавзу киритинг'} />
                </div>
              }
              {activeLang === "Ru" &&
                <div>
                  <span>RU : </span>
                  <Inputs inputVal={titleRu} handleChange={handleTitleChangeRu} inputPlace={'Введите тему'} />
                </div>
              }
            </div>
            <div className='px-5 mt-5'>
              {lang ? "Сам модуль" : "Sam modul"}
              <Inputs inputRef={samModulRef} inputPlace={lang ? "Войдите в Сам модуль" : 'Sam modul kiriting'} />
            </div>
            <div className='px-5 mt-5'>
              {lang ? "Время начала" : "Boshlanish vaqti"}
              <DatePicker
                size='large'
                className="w-full border-slate-400"
                onChange={handleChangeDatePiccer}
                defaultValue={dayjs(
                  `${date.getFullYear()}-${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`,
                  dateFormat
                )}
                format={dateFormat}
              />
            </div>
            <div className='px-5 mt-5'>
              {lang ? "Время окончания" : "Tugash vaqti"}
              <DatePicker
                size='large'
                className="w-full border-slate-400"
                onChange={handleChangeDatePiccer2}
                defaultValue={dayjs(
                  `${date.getFullYear()}-${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`,
                  dateFormat
                )}
                format={dateFormat}
              />
            </div>
          </div>
        </div>
        <div className='w-[70%] p-5 rounded-md border-[1px] border-slate-400'>
          <div className='flex justify-between'>
            <h3 className='font-semibold'>{lang ? "Сообщения" : "Habar"} </h3>
            <ul className='flex justify-end items-start'>
              <li onClick={() => setActiveLang2("O'z")} className={`text-[14px] ${activeLang2 === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
              <li onClick={() => setActiveLang2("Uz")} className={`text-[14px] ${activeLang2 === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
              <li onClick={() => setActiveLang2("Ru")} className={`text-[14px] ${activeLang2 === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
            </ul>
          </div>
          <div>
            {activeLang2 === "O'z" &&
              <div className='flex flex-col'>
                <span>O'Z : </span>
                <textarea defaultValue={contentOz} onChange={contentChangeOz} rows={13} className='border-[1px] rounded-md p-2 focus:border-blue-500 outline-none border-slate-300'></textarea>
              </div>
            }
            {activeLang2 === "Uz" &&
              <div className='flex flex-col'>
                <span>UZ : </span>
                <textarea defaultValue={contentUz} onChange={contentChangeUz} rows={13} className='border-[1px] rounded-md p-2 focus:border-blue-500 outline-none border-slate-300'></textarea>
              </div>
            }
            {activeLang2 === "Ru" &&
              <div className='flex flex-col'>
                <span>RU : </span>
                <textarea defaultValue={contentRu} onChange={contentChangeRu} rows={13} className='border-[1px] rounded-md p-2 focus:border-blue-500 outline-none border-slate-300'></textarea>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageAdd
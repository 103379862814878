import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import { AddBtn } from "../../components/NewBtn/NewBtn"
import Inputs from "../../components/Inputs/Inputs"
import useDebounce from "../../Hook/useDebounce";
import axios from 'axios'
import { Pagination, Select } from "antd";
import NotificationTableGet from '../../components/Table/NotificationTableGet';
import CompanySearch from "../../components/CompanySearch/CompanySearch"
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function NotifGet() {
    const { lang } = useContext(Context)
    const navigate = useNavigate()
    const env = process.env.REACT_APP_API;
    const token = JSON.parse(window.localStorage.getItem("token"));
    const [loading, setLoading] = useState(false)
    const [totalElement, setTotalElement] = React.useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    let filterRequest = {
        pageNumber: pageNum,
        pageSize: pageSize,
        sortingField: "id",
        sortDirection: "ASC",
    };

    // Search start
    const [searchVal, setSearchVal] = useState(window.sessionStorage.getItem("noftificationGetTitle") || "")
    const searchChange = (evt) => {
        setPageNum(1)
        setSearchVal(evt.target.value)
        setLoading(true)
    }
    const searchDebounce = useDebounce(searchVal, 800)
    // Search end

    // Company chnage start
    const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("notifCompName") || "")
    const [companyId, setCompanyId] = useState(JSON.parse(window.sessionStorage.getItem("notifCampanyId")) || 0)

    // Company chnage end

    // Kkm Change start
    let filterRequest1 = {
        pageNumber: 1,
        pageSize: 10000,
        sortingField: "id",
        sortDirection: "ASC",
    };
    const [kkmList, setKkmList] = useState(JSON.parse(window.sessionStorage.getItem("notifKkmList")) || [])
    const [kkmName, setkkmName] = useState(window.sessionStorage.getItem("notifKkmName") || "")
    const [kkmId, setKkmId] = useState(JSON.parse(window.sessionStorage.getItem("notifKkmId")) || 0)
    const kkmChange = (value, label) => {
        setPageNum(1)
        setKkmId(value)
        setkkmName(label?.label)
    }
    useEffect(() => {
        axios
            .post(`${env}cabinet/v2/checkout/getFilter`, filterRequest1, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setKkmList(res.data?.listOfItems?.map(item => ({ value: item.id, label: `${item.title}` })));
            })
    }, [])
    // Kkm Change end

    // User Change start
    const [userList, setUserList] = useState([])
    const [userName, setUserName] = useState(window.sessionStorage.getItem("notifUserName") || "")
    const [userId, setUserId] = useState(JSON.parse(window.sessionStorage.getItem("notifUserId")) || 0)
    const userChange = (value, name) => {
        setPageNum(1)
        setUserId(value)
        setUserName(name?.label)
    }
    useEffect(() => {
        axios
            .post(`${env}cabinet/v2/user`, filterRequest1, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
            .then((res) => {
                setUserList(res.data?.listOfItems?.map(item => ({ value: item.id, label: `${item.firstName} ${item.lastName}` })));
            })
    }, [])
    // User Change end

    // Pagination Start
    const onChange = (pageNumber, pageFullSize) => {
        setPageNum(pageNumber);
        setPageSize(pageFullSize);
    };
    // Pagination end

    // Get all start
    const [changeStatus, setChangeStatus] = useState(false)
    const [notificationList, setNotificationList] = useState([])

    useEffect(() => {
        axios
            .post(`${env}cabinet/v2/notification`, filterRequest, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    title: searchDebounce,
                    companyId: companyId,
                    deviceId: kkmId,
                    userId: userId
                },
            })
            .then((res) => {
                setNotificationList(res.data.listOfItems);
                setTotalElement(res.data.totalElements);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                if (err.response.status === 401) {
                    navigate('/');
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.location.reload();
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebounce, companyId, changeStatus, kkmId, userId, pageNum, pageSize]);
    // Get all end
    window.sessionStorage.setItem("noftificationGetTitle", searchVal)

    window.sessionStorage.setItem("notifCampanyId", JSON.stringify(companyId) ? JSON.stringify(companyId) : 0)
    window.sessionStorage.setItem("notifCompName", companyName)

    window.sessionStorage.setItem("notifKkmId", JSON.stringify(kkmId) ? JSON.stringify(kkmId) : 0)
    window.sessionStorage.setItem("notifKkmName", kkmName)

    window.sessionStorage.setItem("notifUserId", JSON.stringify(userId) ? JSON.stringify(userId) : 0)
    window.sessionStorage.setItem("notifUserName", userName)

    window.sessionStorage.setItem("notifKkmList", JSON.stringify(kkmList))
    return (
        <div className='bg-white py-5'>
            <div className='px-5 flex items-center justify-between'>
                <div>
                    <h2 className="font-bold text-[25px]">{lang ? "Уведомление" : "Bildirishnoma"}</h2>
                    <span className="text-slate-400">{notificationList.length} {lang ? "уведомление" : "bildirishnoma"}</span>
                </div>
                <AddBtn onClick={() => navigate('add')} title={lang ? "Добавить новое" : "Yangi qo'shish"} />
            </div>
            <div className='p-5 flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-14 items-center'>
                <div className='w-[350px]'>
                    <span className='text-[15px] font-semibold'>{lang ? "Поиск" : "Qidirish"}</span>
                    <Inputs inputVal={searchVal} handleChange={searchChange} inputPlace={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} />
                </div>
                <div className='w-[350px] relative'>
                    <span className='text-[15px] font-semibold'>{lang ? "Название кампании" : "Kampaniya nomi"}</span>
                    <CompanySearch setPageNum={setPageNum} value={companyId === 0 ? null : companyName} setCompanyName={setCompanyName} width={'350px'} letLoading={true} setLoading={setLoading} setCompanyId={setCompanyId} />
                </div>
                <div className='w-[350px] relative'>
                    <span className='text-[15px] font-semibold'>{lang ? "Название Ккм" : "Kkm nomi"}</span>
                    <Select
                        value={kkmId === 0 ? null : kkmName}
                        style={{ 'inlineSize': `100%` }}
                        allowClear
                        showSearch
                        size="large"
                        placeholder={lang ? "Выберите ККм" : "KKm tanlang"}
                        optionFilterProp="children"
                        onChange={kkmChange}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={kkmList}
                    />
                </div>
                <div className='w-[350px] relative'>
                    <span className='text-[15px] font-semibold'>{lang ? "Имя пользователя" : "Foydalanuvchi nomi"}</span>
                    <Select
                        value={userId === 0 ? null : userName}
                        style={{ 'inlineSize': `100%` }}
                        allowClear
                        showSearch
                        size="large"
                        placeholder={lang ? "Выберите пользователя" : "Foydalanuvchi tanlang"}
                        optionFilterProp="children"
                        onChange={userChange}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={userList}
                    />
                </div>
            </div>
            <div className='px-5'>
                <NotificationTableGet setChangeStatus={setChangeStatus} changeStatus={changeStatus} notificationList={notificationList} loading={loading} />
            </div>
            <div className="flex justify-center py-5">
                <Pagination
                    showQuickJumper
                    defaultCurrent={pageNum}
                    defaultPageSize={pageSize}
                    total={totalElement || notificationList.length > 0 ? totalElement : 1}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

export default NotifGet
import axios from "axios";
import React, { useEffect, useState } from "react";
import UsersTable from "../../components/Table/UsersTable";
import Inputs from "../../components/Inputs/Inputs"
import { Input, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import useDebounce from "../../Hook/useDebounce";
import { AddBtn } from "../../components/NewBtn/NewBtn"
// Pic
import Clear from "../../Assets/Images/x-icon.png"
import { useContext } from "react";
import { Context } from "../../Context/Context"
function Users() {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalElement, setTotalElement] = React.useState(0);
  const [usersList, setUsersList] = useState([])
  const [changeStatus, setChangeStatus] = useState(false)
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
  };

  // Search Users input start
  const searchUserInputRef = useRef()
  const [clear, setClear] = useState(JSON.parse(window.sessionStorage.getItem("userClear")) || false)
  const [searchUserValue, setSearchUserValue] = useState(window.sessionStorage.getItem("usersname") || "")
  const handleSearchUser = (evt) => {
    setSearchUserValue(evt.target.value)
    setLoading(true)
    setPageNum(1)
    if (evt.target.value === "") {
      setClear(false)
    }
    else {
      setClear(true)
    }
  }
  const searchWaitingVal = useDebounce(searchUserValue, 500)
  window.sessionStorage.setItem("usersname", searchUserValue)
  window.sessionStorage.setItem("userClear", JSON.stringify(clear))
  // Search Users input end


  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/user`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          username: searchWaitingVal,
        },
      })
      .then((res) => {
        setUsersList(res.data.listOfItems)
        setTotalElement(res.data.totalElements)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, searchWaitingVal, changeStatus]);
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  return <div className="bg-white">
    <div className="flex items-center justify-between px-5 ">
      <div >
        <h2 className="font-bold text-[20px]">{lang ? "Пользователи" : "Foydalanuvchilar"}</h2>
        <span className="text-slate-400">{totalElement} {lang ? "Пользователи" : "foydalanuvchilar"}</span>
      </div>
      <AddBtn onClick={() => navigate('add')} title={lang ? "Добавить" : 'Qoshish'} />
    </div>
    <label className="flex flex-col p-5">
      <span className="text-slate-500 inline-block pl-1 mb-1">{lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}</span>
      <Input allowClear className="w-[350px]" value={searchUserValue} ref={searchUserInputRef} onChange={handleSearchUser} placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} size="large" />
    </label>
    <UsersTable setChangeStatus={setChangeStatus} changeStatus={changeStatus} loading={loading} usersList={usersList} />
    <div className="flex justify-center py-5">
      <Pagination
        showQuickJumper
        defaultCurrent={pageNum}
        defaultPageSize={pageSize}
        total={totalElement || usersList.length > 0 ? totalElement : 1}
        onChange={onChange}
      />
    </div>
  </div>
}

export default Users;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Inputs from "../../components/Inputs/Inputs";
import RegionSearch from "../../components/RegionSearch/RegionSearch";
import DistricSearch from "../../components/DistricSearch/DistricSearch";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import { AddBtn } from "../../components/NewBtn/NewBtn";
import { Checkbox, Input } from "antd";
function PartinerUpdate() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate();
  const { id } = useParams()
  const [director, setDirector] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("+998")
  const [accountant, setAccountant] = useState(null)
  const [phoneNumber2, setPhoneNumber2] = useState("+998")
  const [legalName, setLegalName] = useState(null)
  const [title, setTitle] = useState(null)
  const [regionId, setRegionId] = useState(null);
  const [districId, setDistricId] = useState(null);

  const [checkBank, setCheckBank] = useState(false)
  const [bank, setBank] = useState(null)
  const [bankPC, setBankPC] = useState(null)
  const [MFOName, setMFOName] = useState(null);
  const [OKEDName, setOKEDName] = useState(null);

  // Region change start

  const [regionName, setRegionName] = useState("")
  // Region change end

  // Distric start

  const [districtName, setDistrictName] = useState("")
  // Distric end

  // Update Part start
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/partner/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDirector(res.data.director)
          setPhoneNumber(res.data.phoneNumber)
          setAccountant(res.data.accountant)
          setPhoneNumber2(res.data.phoneNumber2)
          setLegalName(res.data.legalName)
          setTitle(res.data.title)
          setRegionId(res.data.regionId)
          setDistricId(res.data.districtId)
          if (res.data.bank || res.data.bankAccount || res.data.bankCode || res.data.bankType) {
            setCheckBank(true)
            setBank(res.data.bank)
            setBankPC(res.data.bankAccount)
            setMFOName(res.data.bankType)
            setOKEDName(res.data.bankCode)
          }
        })
    }
  }, [])
  // Update Part end

  // Partiner Submit start
  const handlePartinerSubmit = (evt) => {
    evt.preventDefault();
    const data = {
      title: title,
      createdById: JSON.parse(window.localStorage.getItem("userId")),
      updatedById: JSON.parse(window.localStorage.getItem("userId")),
      accountant: accountant,
      bank: checkBank ? bank : "0",
      bankType: checkBank ? MFOName : 0,
      bankCode: checkBank ? OKEDName : "0",
      bankAccount: checkBank ? bankPC : 0,
      director: director,
      districtId: districId,
      latitude: 0,
      legalName: legalName,
      longitude: 0,
      organizationId: null,
      phoneNumber: phoneNumber,
      phoneNumber2: phoneNumber2,
      regionId: regionId,
    };
    if (id) {
      data.id = id - 0
      axios
        .put(`${env}cabinet/v2/update/partner`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success("Saqlandi");
          setTimeout(() => {
            navigate(-1);
          }, [800]);
        })
        .catch((err) => {
          toast.error("Xatolik bor !");
        });
    }
    else {
      axios
        .post(`${env}cabinet/v2/save/partner`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success("Saqlandi");
          setTimeout(() => {
            navigate(-1);
          }, [800]);
        })
        .catch((err) => {
          toast.error("Xatolik bor !");
        });
    }
  };
  // Partiner Submit end

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <form onSubmit={handlePartinerSubmit} className="">
        <div className="p-5 px-5 bg-white rounded-md border-b-2 flex items-center justify-between space-x-6">
          <h2 className="font-bold text-[25px]">{id ? lang ? "Редактировать партнера" : "Hamkor tahrirlash" : lang ? "Добавить партнера" : "Hamkor qo'shish"}</h2>
          <AddBtn type={'submit'} title={id ? lang ? "Редактировать" : "Tahrirlash" : lang ? "Добавить" : "Qo'shish"} />
        </div>
        <div className="p-5">
          <div className="pb-5 flex justify-between">
            <div className="bg-white rounded-md pb-3 shadow-md border-[1px] border-slate-300 w-[60%]">
              <h2 className="font-bold text-[20px] border-b-[1px] border-slate-300  p-3 mb-4">{lang ? "Информация о директоре" : "Rahbar haqida ma'lumot"}</h2>
              <div className="px-3 flex items-center mb-4 justify-between">
                <label className="w-[48%]">
                  <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Полное имя директора" : "Rahbarning to'liq ismi"}</span>
                  <Input
                    value={director}
                    onChange={(evt) => setDirector(evt.target.value)}
                    required
                    size="large"
                    allowClear
                    placeholder={lang ? "Введите имя" : "Ism kiriting"}
                  />
                </label>
                <label className="w-[48%]">
                  <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Телефон директора" : "Rahbarning raqami"}</span>
                  <Input
                    value={phoneNumber}
                    onChange={(evt) => setPhoneNumber(evt.target.value)}
                    type={"tel"}
                    maxLength={13}
                    size="large"
                    allowClear
                    placeholder={lang ? "Введите номер" : "Raqam kiting "}
                  />
                </label>
              </div>
              <div className="px-3 flex items-center mb-4 justify-between">
                <label className="w-[48%]">
                  <span className="text-slate-500 inline-block mb-1 pl-1"> {lang ? "Полное имя бухгалтера" : "Buxgalter to'liq ismi"}</span>
                  <Input
                    value={accountant}
                    onChange={(evt) => setAccountant(evt.target.value)}
                    size="large"
                    allowClear
                    required
                    placeholder={lang ? "Введите имя" : "Ism kiriting"}
                  />
                </label>
                <label className="w-[48%]">
                  <span className="text-slate-500 inline-block mb-1 pl-1"> {lang ? "Номер бухгалтера" : "Buxgalter raqami"}</span>
                  <Input
                    value={phoneNumber2}
                    size="large"
                    allowClear
                    onChange={(evt) => setPhoneNumber2(evt.target.value)}
                    type={"tel"}
                    maxLength={13}
                    placeholder={lang ? "Введите номер" : "Raqam kiting "}
                  />
                </label>
              </div>
              <div className="px-3 flex items-center justify-between">
                <label className="w-[48%]">
                  <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Юридическое лицо" : "Yuridik shaxs"}</span>
                  <Input
                    value={legalName}
                    onChange={(evt) => setLegalName(evt.target.value)}
                    required
                    size="large"
                    allowClear
                    placeholder={lang ? "Юридическое лицо" : "Yuridik shaxs"}
                  />
                </label>
                <label className="w-[48%]">
                  <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Имя" : "Ismi"} </span>
                  <Input
                    value={title}
                    onChange={(evt) => setTitle(evt.target.value)}
                    allowClear
                    size="large"
                    required
                    placeholder={lang ? "введите имя" : "ism kiriting"}
                  />
                </label>
              </div>
            </div>
            <div className="w-[38%]">
              <div className="bg-white shadow-md border-[1px] border-slate-300 rounded-md">
                <h2 className="font-semibold p-3 border-b-2 text-[18px]">{lang ? "Адрес" : "Manzil"}</h2>
                <div className="p-3 flex flex-col w-full space-y-4">
                  <label className="relative">
                    <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Провинция/Город" : "Viloyat/Shahar"}</span>
                    <RegionSearch value={regionId} setRegionName={setRegionName} letLoading={false} width={'100%'} setRegionId={setRegionId} />
                  </label>
                  <label className="relative">
                    <span className="text-slate-500 inline-block mb-1 pl-1"> {lang ? "Выберите район" : "Tuman tanlang"}</span>
                    <DistricSearch value={districId} setDistrictName={setDistrictName} regionId={regionId} width={'100%'} setDistricId={setDistricId} />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white border-[1px] w-[60%] border-slate-300 shadow-md mt-3 sm:mt-5 rounded-md">
            <div className={`p-3 ${checkBank && "border-b-2"}`}>
              <Checkbox checked={checkBank} className="w-full" onChange={(e) => setCheckBank(e.target.checked)}>
                <h3 className="font-semibold text-[18px]">{lang ? "Банковские реквизиты" : "Bankrekvizitlari"}</h3>
              </Checkbox>
            </div>
            {checkBank ?
              <>
                <div className="p-3 flex flex-col sm:flex-row justify-between ">
                  <label className="w-full sm:w-[48%] flex flex-col">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Банк" : "Bank"}</span>
                    <Input value={bank} allowClear onChange={(evt) => setBank(evt.target.value)} placeholder={lang ? "Название банка" : "Bank nomi"} size="large" />
                  </label>
                  <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">P/C</span>
                    <Input allowClear value={bankPC} onChange={(evt) => setBankPC(evt.target.value)} placeholder="P/C" size="large" />
                  </label>
                </div>
                <div className="p-3 flex flex-col sm:flex-row justify-between ">
                  <label className="w-full sm:w-[48%] flex flex-col">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "МФО" : "MFO"}</span>
                    <Input className="w-full" onChange={(evt) => setMFOName(evt.target.value)} allowClear value={MFOName} placeholder={lang ? "Название МФО" : "MFO nomi"} size="large" />
                  </label>
                  <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "ОКЕД" : "OKED"}</span>
                    <Input allowClear value={OKEDName} onChange={(evt) => setOKEDName(evt.target.value)} size="large" placeholder={lang ? "Название ОКЕД" : "OKED nomi"} />
                  </label>
                </div>
              </>
              : ""}
          </div>
        </div>
      </form>
    </div>
  );
}

export default PartinerUpdate;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Inputs from "../../components/Inputs/Inputs";
import AllBranchTable from "../../components/Table/AllBranchTable";
import useDebounce from "../../Hook/useDebounce"
import { AddBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from "react";
import { Context } from "../../Context/Context"
import { Input, Pagination } from "antd";

function PatnersList() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const navigate = useNavigate();
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  // Basic
  // Search Changes start
  const [branchList, setBranchList] = useState(
    JSON.parse(window.sessionStorage.getItem("partinerList")) || []
  );
  const [value, setValue] = useState(window.sessionStorage.getItem("employeeSearch") || "");
  const searchRef = useRef();
  const onChange = (evt) => {
    setLoading(true)
    if (evt.target.value === "") {
      setValue("");
    } else {
      setValue(evt.target.value);
    }
  };
  const searchValueAll = useDebounce(value, 300)
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/partners-all?title=${searchValueAll}`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setBranchList(res.data.listOfItems);
        setTotalElement(res.data.totalElements)
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValueAll, pageNum, pageSize]);
  // Search Changes end

  // Add Btn click start
  const handleAddRoute = () => {
    navigate(`add`);
  };
  // Add Btn click end

  // Pagination Start
  const onChangePagination = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end

  window.sessionStorage.setItem("partinerList", JSON.stringify(branchList));
  window.sessionStorage.setItem("employeeSearch", value);
  return (
    <div className="py-5 bg-white">
      <div className="px-5 flex items-center justify-between">
        <div>
          <h2 className="font-bold text-[25px]">{lang ? "Все партнеры" : "Barcha hamkorlar"}</h2>
          <span className="text-slate-500">{branchList.length} {lang ? "партнеры" : "hamkorlar"}</span>
        </div>
        <AddBtn onClick={handleAddRoute} title={lang ? "Добавить" : "Qo'shish"} />
      </div>
      <div className="p-5 relative inline-block">
        <label className="flex flex-col">
          <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}</span>
          <Input className="w-[350px]" value={value} ref={searchRef} onChange={onChange} placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} size="large" />
        </label>
      </div>
      <div className="px-5">
        <AllBranchTable loading={loading} branchList={branchList} />
      </div>
      <div className="hidden sm:flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || branchList.length > 0 ? totalElement : 1}
          onChange={onChangePagination}
        />
      </div>
    </div>
  );
}

export default PatnersList;

import React from 'react'
import { useState } from 'react';
import Create from "../../../../Assets/Images/create.svg"
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useContext } from 'react';
import { Context } from '../../../../Context/Context';
function CodeGenerate({ id }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));

  // Create New Generate start
  const [dataParol, setDataParol] = useState({})
  const createNewGenerate = (evt) => {
    axios
      .post(`${env}cabinet/v2/open-api/password/create?companyId=${id}`, {
        params: {
          companyId: id
        }
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        toast.success(lang ? "Сохраните пароль, он откроется после выхода со страницы!" : "Parolni saqlab qoying , sahifadan chiqgandan so'ng ochib ketadi!")
        setDataParol(res.data);
      }).catch((err) => {
        toast.error(lang ? "Есть ошибка" : "Xatolik bor!")
      })
  }
  // Create New Generate end
  return (
    <div className='sm:pl-10 mt-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <ul className='py-5 text-[18px] flex items-center justify-between sm:justify-start space-x-20'>
        <li className='flex flex-col sm:flex-row items-center  sm:space-x-16'>
          <div className='flex items-center space-x-2'>
            <span className='font-semibold '>{lang ? "Авторизоваться" : "Login"} : </span><span>{dataParol ? dataParol?.login : ""}</span>
          </div>
          <div className='flex items-center space-x-2 relative'>
            <span className='font-semibold '>{lang ? "Пароль" : "Parol"} : </span>
            <span className=''>{dataParol ? dataParol?.password : ""}</span>
          </div>
        </li>
        <li>
          <button id={id} onClick={createNewGenerate} className='p-1.5 rounded-md bg-[#124057] text-white flex items-center space-x-2 text-[14px] sm:text-[16px]'>
            <span> {lang ? "Создавать" : "Yaratish"}</span>
            <img src={Create} width={25} height={25} />
          </button>
        </li>
      </ul>
    </div>
  )
}

export default CodeGenerate
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { Modal } from "../../components/Modal/Modal"
import { Toaster, toast } from 'react-hot-toast';
import { useContext } from 'react';
import { Context } from "../../Context/Context"

function NotifAcceptMore({ }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [moreData, setMoreData] = useState(JSON.parse(window.localStorage.getItem("notifGetOne")) || {})
  const [senderName, setSenderName] = useState("")
  const [changeStatus, setChangeStatus] = useState(false)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/notification/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreData(res.data);
        axios
          .get(`${env}cabinet/v2/user/name/${res.data.createdById}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setSenderName(res.data);
          })
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [changeStatus]);

  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/notification/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })
  }
  const handleDelete = () => {
    setShowModal(true)
  }
  const handleDelNotif = () => {
    axios
      .put(
        `${env}cabinet/v2/notification/delete?id=${Number(id)}`,
        {
          params: {
            id: Number(id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("O'chirildi !")
        setTimeout(() => {
          navigate(-1)
        }, 800)
      })
  }
  const [activeLang, setActiveLang] = useState("O'z")
  window.localStorage.setItem("notifGetOne", JSON.stringify(moreData))
  return (
    <div className='p-5 '>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='bg-white rounded-md'>
        <div className='p-5 border-b-[1px] flex items-end justify-between border-slate-300'>
          <div>
            <h2 className='font-bold leading-5 text-[21px]'>{moreData?.titleOz}</h2>
            <span className='text-slate-400 text-[13px]'>{lang ? "Уведомление" : "Bildirishnoma"}</span>
          </div>
          <div className='flex items-center space-x-3'>
            <div
              id={moreData?.id}
              onClick={clickedActive}
              className="flex cursor-pointer justify-center space-x-2 items-center"
            >
              <span>{lang ? "Активный" : "Faol"}</span>
              <ul
                id={moreData?.id}
                className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${moreData?.status ? "bg-blue-500 " : "bg-slate-300"
                  } rounded-[50px]`}
              >
                <li
                  id={moreData?.id}
                  className={`absolute duration-200 ${moreData?.status ? "translate-x-4" : ""
                    } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                ></li>
              </ul>
            </div>
            <button onClick={handleDelete} className='p-2 rounded-md bg-[#FFE2E5]'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="red" className="bi bi-trash3" viewBox="0 0 16 16">
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              </svg>
            </button>
            <UpdateBtn onClick={() => navigate(`update/${id}`)} title={lang ? "Редактирование" : "Tahrirlash"} />
          </div>
        </div>
        <div className='p-5 flex items-start justify-between'>
          <div className='w-[53%]'>
            <div className='p-4 rounded-md border-[1px] border-slate-300'>
              <h3 className='text-[15px] font-semibold'>{lang ? "Тема" : "Mavzu"}</h3>
              <ul className='pl-5'>
                <li>
                  <span className='text-[13px] text-slate-400'>O'Z : </span>
                  <span>{moreData?.titleOz}</span>
                </li>
                <li>
                  <span className='text-[13px] text-slate-400'>RU : </span>
                  <span>{moreData?.titleRu}</span>
                </li>
                <li>
                  <span className='text-[13px] text-slate-400'>UZ : </span>
                  <span>{moreData?.titleUz}</span>
                </li>
              </ul>
            </div>
            <div className='p-4 flex justify-between rounded-md mt-5 border-[1px] border-slate-300'>
              <div>
                <h3 className='text-[15px] font-semibold'>{lang ? "Контент" : "Kontent"}</h3>
                <ul className='pl-5'>
                  {activeLang === "O'z" && <li>
                    <span className='text-[13px] text-slate-400'>O'Z : </span>
                    <span>{moreData?.contentOz}</span>
                  </li>}
                  {activeLang === "Ru" &&
                    <li>
                      <span className='text-[13px] text-slate-400'>RU : </span>
                      <span>{moreData?.contentRu}</span>
                    </li>}
                  {activeLang === "Uz" &&
                    <li>
                      <span className='text-[13px] text-slate-400'>UZ : </span>
                      <span>{moreData?.contentUz}</span>
                    </li>}
                </ul>
              </div>
              <ul className='flex  items-start'>
                <li onClick={() => setActiveLang("O'z")} className={`text-[14px] ${activeLang === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
                <li onClick={() => setActiveLang("Uz")} className={`text-[14px] ${activeLang === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
                <li onClick={() => setActiveLang("Ru")} className={`text-[14px] ${activeLang === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
              </ul>
            </div>
            <div className='p-4 rounded-md mt-5 border-[1px] border-slate-300'>
              <h3 className='text-[15px] font-semibold'>{lang ? "Дата и время" : "Sana va vaqt"}</h3>
              <p>{moreData.createdAt?.split("T")[0]} - {moreData.createdAt?.split("T")[1]?.split(".")[0]}</p>
            </div>
          </div>
          <div className='w-[45%]'>
            <div className=' rounded-md border-[1px] border-slate-300'>
              <h3 className='font-semibold p-4 text-[16px] border-b-[1px] border-slate-300'>{lang ? "Получатель" : "Qabul qiluvchi"}</h3>
              <div className='flex justify-between py-5'>
                <ul className='w-[32%] text-center'>
                  <h3 className='text-[15px] pb-1 text-slate-400'>{lang ? "Кампания" : "Kampaniya"}</h3>
                  {moreData?.companies?.map(item => (
                    <li className='text-[15px] hover:bg-[#FAFAFA] cursor-pointer border-t-[0.5px] border-slate-300 py-1' key={item.id}>{item.name}</li>
                  ))}
                </ul>
                <ul className='w-[32%] text-center'>
                  <h3 className='text-[15px] pb-1 text-slate-400'>{lang ? "ККМ" : "Kkm"}</h3>
                  {moreData?.devices?.map(item => (
                    <li className='text-[15px] hover:bg-[#FAFAFA] cursor-pointer border-t-[0.5px] border-slate-300 py-1' key={item.id}>{item.name}</li>
                  ))}
                </ul>
                <ul className='w-[32%] text-center'>
                  <h3 className='text-[15px]  pb-1 text-slate-400'>{lang ? "Пользователь" : "Foydalanuvchi"}</h3>
                  {moreData?.users?.map(item => (
                    <li className='text-[15px] hover:bg-[#FAFAFA] cursor-pointer border-t-[0.5px] border-slate-300 py-1' key={item.id}>{item.name}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='p-4 rounded-md mt-5 border-[1px] border-slate-300'>
              <h3 className='text-[15px] font-semibold'>{lang ? "Отправитель" : "Yuboruvchi"}</h3>
              <p>
                {senderName ? senderName : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <h2 className="font-bold text-center text-[20px] text-[#333]">
          {lang ? "Удалите уведомление!" : "Bildirishnomani o'chirish !"}
        </h2>
        <div className="flex items-center mt-3 justify-between">
          <button
            onClick={() => setShowModal(false)}
            className="border-2 w-[48%] border-green-500 bg-green-500 text-white hover:opacity-90 p-2 rounded-md"
          >
            {lang ? "Отмена" : "Bekor qilish"}
          </button>
          <button
            onClick={handleDelNotif}
            className="border-2 w-[48%] bg-red-500 text-white border-red-500 p-2 rounded-md hover:opacity-90"
          >
            {lang ? "Удалить" : " O'chirish"}
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default NotifAcceptMore
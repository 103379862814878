import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../components/Buttons/Buttons";
import Inputs from "../../components/Inputs/Inputs";
import { Modal } from "../../components/Modal/Modal";
import { Pagination } from "antd";
import CompanySearch from '../../components/CompanySearch/CompanySearch'
import BranchSearch from '../../components/BranchSearch/BranchSearch'
import { Toaster } from "react-hot-toast";
import useDebounce from "../../Hook/useDebounce";
import { useNavigate } from "react-router-dom";
// Pictures
import Loading from "../../Assets/Images/loading/loading3.png";
import Drop from "../../Assets/Images/drop2.svg";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function CashsList() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [renderList, setRenderList] = useState([]);
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [refresh, setRefresh] = useState(false);

  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  const [checkType, setCheckType] = useState(
    JSON.parse(window.sessionStorage.getItem("checktype")) || false
  );
  // company change input start
  const [companyId, setCompanyId] = useState(role == "company" ? roleId : null)
  const [companyName, setCompanyName] = useState("")
  // company change input end

  // branches Start
  const [branchId, setBranchId] = useState(0)
  const [branchName, setBranchName] = useState("")
  // branch GET

  // KKM change start
  const [kkmVal, setKkmVal] = useState("")
  const kkkmChanged = (evt) => {
    setPageNum(1)
    setKkmVal(evt.target.value)
    setLoading(true)
  }
  const kkmVal1 = useDebounce(kkmVal, 300)
  // KKM change end

  // sam modul change start
  const [samModulVal, setSamModulVal] = useState("")
  const samModulChanged = (evt) => {
    setPageNum(1)
    setSamModulVal(evt.target.value)
    setLoading(true)
  }
  const samModulVal1 = useDebounce(samModulVal, 300)
  // sam modul change end

  // new checks btn click start
  const handleNewCheckBtn = () => {
    if (checkType === true) {
      setLoading(true);
    }
    setCheckType(false);
    setPageNum(1);
  };
  // new checks btn click end

  // old check btn clicked start
  const handleOldCheckBtn = (evt) => {
    if (checkType === false) {
      setLoading(true);
    }
    setCheckType(true);
    setPageNum(1);
  };
  // old check btn clicked end

  useEffect(() => {
    if (checkType) {
      axios
        .post(`${env}cabinet/v2/cheque/old`, filterRequest,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              branchId: branchId ? branchId : 0,
              kkm: kkmVal,
              samModul: samModulVal,
              role: role,
              roleId: roleId
            }
          },

        )
        .then((res) => {
          setTotalElement(res.data.totalElements);
          setLoading(false);
          setRenderList(res.data.listOfItems);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            navigate('/')
            window.localStorage.clear()
            window.sessionStorage.clear()
            window.location.reload()
          }
        });
    } else {
      axios
        .post(`${env}cabinet/v2/cheque/new`, filterRequest,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              branchId: branchId ? branchId : 0,
              kkm: kkmVal,
              samModul: samModulVal,
              role: role,
              roleId: roleId
            }
          },

        )
        .then((res) => {
          setTotalElement(res.data.totalElements);
          setLoading(false);
          setRenderList(res.data.listOfItems);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            navigate('/')
            window.localStorage.clear()
            window.sessionStorage.clear()
            window.location.reload()
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, checkType, pageNum, pageSize, kkmVal1, samModulVal1]);
  // getResAPI end


  // More btn click start
  const [moreModalList, setMoreModalList] = useState({});
  const [oldCheuqeObj, setOldChequeObj] = useState({});
  const [oldCheuqeItem, setOldChequeItem] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const moreBtnClicked = (evt) => {
    setCheckLoading(true);
    setShowModal(true);
    const btnId = Number(evt.target.id);
    if (checkType) {
      axios
        .get(`${env}cabinet/v2/cheque/old/${btnId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCheckLoading(false);
          setOldChequeObj(res.data);
        })
        .catch((err) => {
          setCheckLoading(false);
        });
      axios
        .get(`${env}cabinet/v2/chequeItem/${btnId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCheckLoading(false);
          setOldChequeItem(res.data);
        })
        .catch((err) => {
          setCheckLoading(false);
        });

    } else {
      axios
        .get(`${env}cabinet/v2/cheque/new/${btnId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCheckLoading(false);
          setMoreModalList(res.data);
        })
        .catch((err) => {
          setCheckLoading(false);
        });
    }
  };

  // More btn click end
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
    if (pageNum !== 1) {
      setLoading(true);
    }
  };
  // Pagination end
  window.sessionStorage.setItem("checktype", JSON.stringify(checkType));

  function formatNumber(number) {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  // Role part start
  useEffect(() => {
    if (role == "company") {
      setCompanyId(roleId)
    }
  }, [])
  // Role part end
  function tinCheckFormatPrice(qiymat) {
    var natija = qiymat * 0.01;
    return natija.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace(/,/g, ' ');
  }
  return (
    <div>
      <div className="bg-white p-5 rounded-md">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="flex items-center justify-between">
          <div>
            <h2 className="font-bold text-[16px] sm:text-[25px]">{lang ? "Чеки организации" : "Tashkilot cheklari"}</h2>
            <span className="text-slate-400 text-[14px] sm:text-[16px]">{totalElement} {lang ? "Чеки" : "cheklar"}</span>
          </div>
          <div className="flex items-center space-x-3">
            <button
              className={`font-semibold text-[14px] sm:text-[16px] ${checkType ? "" : "text-blue-500 border-b-2 border-blue-500"
                } `}
              onClick={handleNewCheckBtn}
            >
              {lang ? "Новые чеки" : "Yangi cheklar"}
            </button>
            <button>
              <img
                className={`duration-300 ${checkType ? "-rotate-180" : ""}`}
                src={Drop}
                width={20}
                height={20}
                alt="Drop"
              />
            </button>
            <button
              className={`font-semibold ${checkType ? "text-blue-500 border-b-2 border-blue-500" : ""
                } `}
              onClick={handleOldCheckBtn}
            >
              {lang ? "Старые  чеки" : "Eski cheklar"}
            </button>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 mt-5 items-center relative xl:space-x-10">
          <label className="flex flex-col w-full sm:w-[350px]">
            <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Поиск компании" : "Kompaniya qidirish"}</span>
            <CompanySearch setBranchName={setBranchName} setBranchId={setBranchId} isDisalbed={role == "company" ? true : false} setPageNum={setPageNum} value={companyId === 0 ? null : companyId} setCompanyName={setCompanyName} letLoading={false} width={'100%'} setCompanyId={setCompanyId} />
          </label>
          <div className="flex flex-col w-full sm:w-[350px]">
            <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Партнерский поиск" : "Fillial qidirish"}</span>
            <BranchSearch refresh={refresh} setRefresh={setRefresh} setPageNum={setPageNum} value={branchId === 0 ? null : branchName} width={'100%'} setBranchName={setBranchName} companyId={companyId} setLoading={setLoading} setBranchId={setBranchId} />
          </div>
          <div className="relative w-full sm:w-[350px]">
            <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Введите ККМ" : "KKM kiritng"}</span>
            <Inputs
              inputVal={kkmVal}
              handleChange={kkkmChanged}
              typeInp={"text"}
              inputPlace={lang ? "Введите ККМ" : "KKM kiriting "}
              noneDis={"w-full cursor-pointer"}
            />
          </div>
          <div className="relative w-full sm:w-[350px]">
            <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Войдите в Сам модуль" : "Sam Modul kiritng"}</span>
            <Inputs
              inputVal={samModulVal}
              handleChange={samModulChanged}
              typeInp={"text"}
              inputPlace={lang ? "Войдите в Сам модуль" : "Sam Modul kiritng"}
              noneDis={"w-full cursor-pointer"}
            />
          </div>
        </div>
        <ul className="mt-5 flex items-center justify-between flex-wrap space-y-4">
          {loading ? (
            <li className="w-[65px] mx-auto">
              <img src={Loading} width={65} height={65} alt="Loaing Icon" />
            </li>
          ) : (
            renderList.length > 0 ?
              renderList.map((item) => (
                <li key={item.id} className="w-full sm:w-[300px] p-3 rounded-md bg-slate-200 shadow-md">
                  {checkType ? "" :
                    <p className="pb-2 font-semibold border-b-[1px] border-black border-dashed text-center">{item.refund ? lang ? "Чек на возврат" : "Qaytarish cheki" : item.refundCheque == -1 ? lang ? "Квитанция о продаже/продажа" : "Sotuv cheki/Sotuv" : lang ? "Квитанция о продаже/возврат" : "Sotuv cheki/Qaytarilgan"}</p>
                  }
                  <p className="mt-2">№ : {item?.chequeNumber}</p>
                  <p className="mt-2">{lang ? "СН" : "SN"} : {checkType ? item.kkm : item.deviceNumber}</p>
                  <p className=" mt-2">{lang ? "Сам Модуль" : "Sam Modul"} : {checkType ? item.deviceNumber : item.samNumber}</p>
                  <p className="mt-2">{lang ? "Всего к оплате" : "Jami to'lov"} : {tinCheckFormatPrice(item.totalPrice)}</p>
                  <p className="mt-2">{lang ? "Дата" : "Sana"} : {item.date?.split("T")[0]}</p>
                  <div className="flex mt-2 items-center justify-between">
                    <Buttons
                      id={item.id}
                      clicked={moreBtnClicked}
                      extraSty={"w-[45%]"}
                      title={lang ? "Подробно" : "Batafsil"}
                      bg={"blue"}
                    />
                    <a
                      href={item.qrcodeUrl ? item.qrcodeUrl : "#"}
                      target={item.qrcodeUrl ? "_blank" : "_self"}
                      className={`p-2 border-2 w-[45%] text-center rounded-md ${item.qrcodeUrl ? "border-green-500 bg-green-500 hover:bg-transparent hover:text-green-500" : "border-green-500 bg-green-500 opacity-30 cursor-not-allowed"}  text-white `}
                      rel="noreferrer"
                    >
                      {lang ? "Ссылка ОФД" : "OFD Link"}
                    </a>
                  </div>
                </li>
              )) : <span className="block mx-auto">Ma'lumot yo'q</span>
          )}
        </ul>
      </div>
      <div className="hidden sm:flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
      <div className="flex sm:hidden justify-center py-5">
        <Pagination
          size="small"
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
      {checkType ?
        <Modal className={"w-full sm:w-[1200px]"} isVisible={showModal} onClose={() => setShowModal(false)}>
          <div>
            <h2 className="text-center pb-3 border-b-[1px] font-normal border-slate-400 text-[16px] sm:text-[22px]">
              {lang ? "Чек" : "Chek"}
            </h2>
            <div className="mt-3 border-b-[1px] text-[14px] sm:text-[16px] border-slate-600 border-dashed">
              <div className="flex items-center mb-1">
                <span>{lang ? "Сам модуль" : "Sam modul"} : </span>
                <span>{oldCheuqeObj?.deviceNumber}</span>
              </div>
              <div className="flex items-center space-x-2 mb-1">
                <span>{lang ? "Номер чека" : "Chek raqami"} : </span>
                <span>{oldCheuqeObj?.number}</span>
              </div>
              <div className="flex items-center space-x-2 mb-1">
                <span className="">{lang ? "СН" : "SN"}: </span>
                <span>{oldCheuqeObj?.kkm}</span>
              </div>
            </div>
            {checkLoading ? (
              <div className="flex items-center justify-center">
                <img src={Loading} width={35} height={35} alt="Loading" />
              </div>
            ) :
              <div className="mb-2">
                <div className="border-b-[1px] px-2 border-slate-400 flex justify-between">
                  <p className="w-[33%] text-left font-semibold">{lang ? "Именование" : "Nomi"}</p>
                  <p className="w-[33%] text-center font-semibold">{lang ? "Количество" : "Soni"}</p>
                  <p className="w-[33%] font-semibold text-end">{lang ? "Цена" : "Narxi"}</p>
                </div>
                <div className="h-[250px] px-2 overflow-y-auto border-b-[1px] border-slate-300">
                  {oldCheuqeItem?.map((item) => (
                    <div className="border-b-[1px] mb-5 border-black border-dashed flex justify-between" key={item.id}>
                      <div className="w-[40%]  flex flex-col">
                        <span className="font-semibold">{item.productName}</span>
                        <span>{lang ? "Значение QSS" : "QSS qiymati"}</span>
                        <span>{lang ? "НДС %" : "QQS foizi"}</span>
                        <span>{lang ? "Штрих-код" : "Shtrix kodi"}</span>
                        <span>{lang ? "ИНН-код" : "MXIK kodi"}</span>
                        <span>{lang ? "Владелец продукта" : "Mahsulot egasi"}</span>
                      </div>
                      <div className="w-[20%]  text-center align-top text-[18px]">{item.amount}</div>
                      <div className="w-[40%]  text-end flex flex-col">
                        <span className="font-semibold text-[20px]">{formatNumber(item.totalPrice)}</span>
                        <span>{item.taxPrice ? item.taxPrice : "0"}</span>
                        <span>{item.tax ? item.tax : "0"}%</span>
                        <span>{item.barcode ? item.barcode : "-"}</span>
                        <span>{item.mxik ? item.mxik : "-"}</span>
                        <span>Tavar egasi</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            }
            <div className="mt-3">
              <div className="flex items-center justify-between mb-1">
                <span className="text-[18px]">{lang ? "Наличные" : "Naqd pul"} : </span>
                <span className="text-[18px]">{formatNumber(oldCheuqeObj.cashAmount)}</span>
              </div>
              <div className="flex items-center justify-between mb-1">
                <span className="text-[18px]">{lang ? "Банковские карты" : "Bank kartalari"} : </span>
                <span className="text-[18px]">{formatNumber(oldCheuqeObj.cardAmount)}</span>
              </div>
              <div className="flex items-center justify-between mb-1">
                <span className="font-semibold text-[25px]">{lang ? "Общая плата" : "Jami tolov"} : </span>
                <span className="font-semibold text-[25px]">{formatNumber(oldCheuqeObj.totalPrice)}</span>
              </div>
              <div className="flex pb-3 mb-3 border-b-2 border-slate-300 items-center justify-between">
                <span className="text-[18px]">{lang ? "Общее значение НДС" : "Umumiy QQS qiymati"} : </span>
                <span className="text-[18px]">{oldCheuqeObj.totalTaxPrice}</span>
              </div>

              {checkType ? "" : <div className="flex items-center justify-between mb-1">
                <span className="font-semibold">{lang ? "Адрес" : "Manzil"} : </span>
                <a href={`${oldCheuqeObj.location}`} target="_blank" className=" text-blue-500 border-b-2 border-blue-500 w-[130px] text-center block ml-auto cursor-pointer">Manzilni ko'rish</a>
              </div>}

            </div>
          </div>
        </Modal>
        :
        <Modal className={"w-full md:w-[1100px]"} isVisible={showModal} onClose={() => setShowModal(false)}>
          <div>
            <h2 className="text-center pb-3 border-b-[1px] font-normal border-slate-400 text-[16px] sm:text-[22px]">
              {moreModalList.refund ? lang ? "Чек на возврат" : "Qaytarish cheki" : moreModalList.refundCheque == -1 ? lang ? "Квитанция о продаже/продажа" : "Sotuv cheki/Sotuv" : lang ? "Квитанция о продаже/возврат" : "Sotuv cheki/Qaytarilgan"}
            </h2>
            <div className="mt-3 text-[14px] sm:text-[16px] border-b-[1px] border-slate-600 border-dashed">
              <div className="flex items-center space-x-2">
                <span>{lang ? "Сам модуль" : "Sam modul"} : </span>
                <span>{moreModalList.samNumber}</span>
              </div>
              <div className="flex items-center space-x-2 mb-1">
                <span>{lang ? "Номер чека" : "Chek raqami"} : </span>
                <span>{moreModalList.chequeNumber}</span>
              </div>
              <div className="flex items-center space-x-2 mb-1">
                <span className="">{lang ? "СН" : "SN"}: </span>
                <span>{moreModalList.deviceNumber}</span>
              </div>
            </div>
            {checkLoading ? (
              <div className="flex items-center justify-center">
                <img src={Loading} width={35} height={35} alt="Loading" />
              </div>
            ) : (
              <div className="w-full border-b-[1px]">
                <div className="border-b-[1px] px-2 border-slate-400 flex justify-between">
                  <p className="w-[33%] text-left font-semibold">{lang ? "Именование" : "Nomi"}</p>
                  <p className="w-[33%] text-center font-semibold">{lang ? "Количество" : "Soni"}</p>
                  <p className="w-[33%] font-semibold text-end">{lang ? "Цена" : "Narxi"}</p>
                </div>
                <div className="h-[280px] px-2 overflow-y-auto">
                  {moreModalList?.chequeItem?.map((item) => (
                    <div key={item.id}>
                      <div className="flex border-b-[0.5px] border-slate-300 items-center justify-between">
                        <span className="font-semibold w-[40%]">{item.name}</span>
                        <span className="font-semibold text-center w-[40%] text-[18px]">{item.amount}</span>
                        <span className="font-semibold text-end w-[40%] text-[20px]">{formatNumber(item.totalPrice)}</span>
                      </div>
                      <div className="border-b-[1px] mb-5 border-black border-dashed flex justify-between" key={item.id}>
                        <div className="w-[50%]  flex flex-col">
                          <span className="border-b-[1px] py-0.5 border-slate-300">{lang ? "Значение QSS" : "QSS qiymati"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{lang ? "НДС %" : "QQS foizi"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{lang ? "Штрих-код" : "Shtrix kodi"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{lang ? "ИНН-код" : "MXIK kodi"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{lang ? "Единица измерения" : "O'lchov birligi"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{lang ? "Владелец продукта" : "Mahsulot egasi"}</span>
                          <span className="py-0.5">{lang ? "Зафиксировать STIRi" : "Komitent STIRi"}</span>
                        </div>
                        <div className="w-[50%]  text-end flex flex-col">
                          <span className="border-b-[1px] py-0.5 border-slate-300">{item.ndsPrice ? item.ndsPrice : "0"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{item.nds ? item.nds : "0"}%</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{moreModalList.discountAmount}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{item.mxik ? item.mxik : "-"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">{item.unitName ? item.unitName : "-"}</span>
                          <span className="border-b-[1px] py-0.5 border-slate-300">
                            {item.ownerType == 0 && "Sotib olib, sotayotgan"}
                            {item.ownerType == 1 && "O'zi ishlab chiqargan"}
                            {item.ownerType == 2 && "Xizmat ko'rsatuvchi"}
                          </span>
                          <span className=" py-0.5 ">{item.comitentInn ? item.comitentInn : "-"}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-3">
              <div className="flex items-center justify-between mb-1">
                <span className="text-[18px]">{lang ? "Номер Z-отчёт" : "Z-hisob raqami"} : </span>
                <span className="text-[18px]">{formatNumber(moreModalList.znumber)}</span>
              </div>
              <div className="flex items-center justify-between mb-1">
                <span className="text-[18px]">{lang ? "Наличные" : "Naqd pul"} : </span>
                <span className="text-[18px]">{tinCheckFormatPrice(moreModalList.cashAmount)}</span>
              </div>
              <div className="flex items-center justify-between mb-1">
                <span className="text-[18px]">{lang ? "Банковские карты" : "Bank kartalari"} : </span>
                <span className="text-[18px]">{tinCheckFormatPrice(moreModalList.cardAmount)}</span>
              </div>
              <div className="flex items-center justify-between mb-1">
                <span className="font-semibold text-[25px]">{lang ? "Общая плата" : "Jami tolov"} : </span>
                <span className="font-semibold text-[25px]">{tinCheckFormatPrice(moreModalList.totalPrice)}</span>
              </div>
              <div className="flex pb-3 mb-3 border-b-2 border-slate-300 items-center justify-between">
                <span className="text-[18px]">{lang ? "Общее значение НДС" : "Umumiy QQS qiymati"} : </span>
                <span className="text-[18px]">{moreModalList.totalNds?.toFixed(2)}</span>
              </div>

              {checkType ? "" : <div className="flex items-center justify-between mb-1">
                <span className="font-semibold">{lang ? "Адрес" : "Manzil"} : </span>
                <a href={`${moreModalList.location}`} target="_blank" className=" text-blue-500 border-b-2 border-blue-500 w-[150px] text-center block ml-auto cursor-pointer">{lang ? "Посмотреть адрес" : "Manzilni ko'rish"}</a>
              </div>}

            </div>
          </div>
        </Modal>
      }

    </div>
  );
}

export default CashsList;

import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios';
import { Checkbox, Input, Select } from 'antd'
import { Toaster, toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import CompanySearch from '../../components/CompanySearch/CompanySearch';
import BranchSearch from '../../components/BranchSearch/BranchSearch';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import { AddBtn } from '../../components/NewBtn/NewBtn';

function EmployeeAdd() {
  const { lang, role, roleId } = useContext(Context)
  const { id } = useParams()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate()
  const userId = JSON.parse(window.localStorage.getItem("userId"));

  // company change input start
  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState("")
  // company change input end

  // branches Start
  const [branchId, setBranchId] = useState(null);
  const [branchName, setBranchName] = useState("")
  // branches end

  // Role start
  const [roles, setRoles] = useState([])
  const [rolesId, setRolesId] = useState(null)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/employees/short-roles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRoles(res.data.map(item => (
          { value: item.id, label: item.name }
        )))
      })
  }, [])
  const onChangeRole = (value) => {
    setRolesId(value);
  };

  // Role end

  // Let part input click start



  const [addProduct, setAddProduct] = useState(false)
  const checkboxInputChange = (e) => {
    setAddProduct(e.target.checked);
  }
  const [changePrice, setChangePrice] = useState(false)
  const checkboxInputChange2 = (e) => {
    setChangePrice(e.target.checked);
  }
  const [changeTax, setChangeTax] = useState(false)
  const checkboxInputChange3 = (e) => {
    setChangeTax(e.target.checked);
  }
  const [viewReport, setViewReport] = useState(false)
  const checkboxInputChange4 = (e) => {
    setViewReport(e.target.checked);
  }

  const [allCheck, setAllCheck] = useState(false)
  const checkAllChange = (e) => {
    setAllCheck(e.target.checked)
    if (e.target.checked) {
      setAddProduct(true)
      setChangePrice(true)
      setChangeTax(true)
      setViewReport(true)
    }
    else {
      setAddProduct(false)
      setChangePrice(false)
      setChangeTax(false)
      setViewReport(false)
    }
  }
  // Let part input click end

  // Submit Data to Add start
  const [username, setUsername] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)


  // Update part start
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/employees/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUsername(res.data.username)
          setFirstName(res.data.firstName)
          setLastName(res.data.lastName)
          setCompanyId(res.data.companyId)
          if (res.data.companyId) {
            setBranchId(res.data.branchId)
          }
          setRolesId(res.data.roleNumber)
          setAddProduct(res.data.addProduct)
          setChangePrice(res.data.changePrice)
          setChangeTax(res.data.changeTax)
          setViewReport(res.data.viewReport)
        })
    }
  }, [])
  // Update part end


  const handleSubmitAddForm = (evt) => {
    evt.preventDefault()
    const data = {
      username: username,
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      role: rolesId - 0,
      companyId: companyId - 0,
      branchId: branchId - 0,
      password: password,
      addProduct: addProduct,
      changePrice: changePrice,
      changeTax: changeTax,
      viewReport: viewReport
    }
    if (password == confirmPassword) {
      if (id) {
        axios
          .put(`${env}cabinet/v2/employees/edit/${id}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            toast.success("Ma'lumotlar saqlandi ")
            setTimeout(() => {
              navigate('/employeslist')
            }, 1000)
          }).catch(err => {
            if (err.response.status === 500) {
              toast.error(lang ? "Ошибка при вводе информации!" : "Ma'lumot kiritishda hatolik bor!");
            }
          })
      }
      else {
        axios
          .post(`${env}cabinet/v2/employees/add`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            toast.success("Ma'lumotlar saqlandi ")
            setTimeout(() => {
              navigate('/employeslist')
            }, 1000)
          }).catch(err => {
            if (err.response.status === 500) {
              toast.error(lang ? "Ошибка при вводе информации!" : "Ma'lumot kiritishda hatolik bor!");
            }
          })
      }
    }
    else {
      toast.error(lang ? "Пароль должен быть тот же!" : "Parol bir xil bo'lishi kerak!")
    }
  }
  // Submit Data to Add end
  useEffect(() => {
    if (id) { }
    else {
      if (window.localStorage.getItem("newFactoryInn")) {
        // setInn(window.localStorage.getItem("newFactoryInn"))
        axios
          .get(`${env}cabinet/v2/get-inn/company`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              idField: role,
              id: roleId,
              inn: window.localStorage.getItem("newFactoryInn")
            }
          })
          .then((res) => {
            setCompanyId(res.data?.company?.id)
          })
      }
    }
  }, [])
  return (
    <form onSubmit={handleSubmitAddForm}>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="py-3 bg-white px-5 flex items-center justify-between">
        <div className='flex items-center space-x-7'>
          <h2 className="font-bold text-[16px] sm:text-[23px]">{id ? lang ? "Редактировать сотрудника" : "Xodimni tahrirlash" : lang ? "Добавить сотрудника" : "Xodimni qoʻshish"}</h2>
        </div>
        <AddBtn type={"submit"} title={lang ? "Сохранять" : 'Saqlash'} />
      </div>
      <div className='px-5 flex flex-col md:flex-row justify-between' >
        <div className='bg-white w-full md:w-[60%] border-[1px] border-slate-300 shadow-md rounded-md'>
          <div className='p-5 border-b-[1px] border-slate-300'>
            <h2 className='font-semibold text-[16px] sm:text-[18px]'>{lang ? "Общая информация" : "Umumiy maʼlumot"}</h2>
          </div>
          <div className='flex flex-col sm:flex-row justify-between p-5'>
            <div className='w-full sm:w-[48%] flex flex-col space-y-5'>
              <label>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Авторизоваться" : "Login"}</span>
                <Input required value={username} onChange={(evt) => setUsername(evt.target.value)} size='large' allowClear placeholder={lang ? "Введите ваш логин" : "Login kiriting"} />
              </label>
              <label>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Фамилия" : "Familiya"}</span>
                <Input value={lastName} onChange={(evt) => setLastName(evt.target.value)} size='large' allowClear placeholder={lang ? "Введите фамилию" : "Familiya kiriting"} />
              </label>
              <label className='relative flex flex-col'>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Организация" : "Tashkilot"}</span>
                <CompanySearch setBranchId={setBranchId} value={companyId} width={'100%'} letLoading={false} setCompanyId={setCompanyId} setCompanyName={setCompanyName} />
              </label>
              <label className='relative'>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Пароль" : "Parol"}</span>
                <Input.Password required minLength={8} value={password} onChange={(evt) => setPassword(evt.target.value)} size='large' placeholder={lang ? "Пароль" : "Parol"} />
              </label>
            </div>
            <div className='w-full mt-5 sm:mt-0 sm:w-[48%] flex flex-col space-y-5'>
              <label>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Имя" : "Ism"}</span>
                <Input value={firstName} onChange={(evt) => setFirstName(evt.target.value)} allowClear size='large' placeholder={lang ? "Введите имя" : "Ism kiriting"} />
              </label>
              <label className='relative flex flex-col'>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Позиция" : "Lavozim"}</span>
                <Select
                  value={rolesId}
                  style={{ 'inlineSize': '100%' }}
                  showSearch
                  allowClear
                  size="large"
                  placeholder={lang ? "Выберите позицию" : "Lavozim tanlang"}
                  optionFilterProp="children"
                  onChange={onChangeRole}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={roles}
                />
              </label>
              <label className='relative '>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Ветвь" : "Filial"}</span>
                <BranchSearch value={branchId} width={'100%'} letLoading={false} companyId={companyId} setBranchId={setBranchId} setBranchName={setBranchName} />
              </label>
              <label className='relative'>
                <span className='text-[14px] sm:text-[16px] mb-1 inline-block'>{lang ? "Подтвердите пароль" : "Parolni tasdiqlang"}</span>
                <Input.Password required minLength={8} value={confirmPassword} onChange={(evt) => setConfirmPassword(evt.target.value)} size='large' placeholder={lang ? "Введите пароль еще раз" : "Parolni qayta kiriting"} />
              </label>
            </div>
          </div>
        </div>
        <div className=' bg-white w-full mt-3 md:mt-0 md:w-[38%] border-[1px] border-slate-300 shadow-md rounded-md'>
          <div className='p-5 border-b-[1px] border-slate-300'>
            <h2 className='font-semibold text-[16px] sm:text-[18px]'>{lang ? "Разрешение" : "Ruxsat"}</h2>
          </div>
          <div className='flex flex-col space-y-3'>
            <Checkbox checked={allCheck} className='text-[17px]  border-b-[1px] border-slate-300 p-3' onChange={checkAllChange}>{lang ? "Выбрать все" : "Barchasini tanlash"}</Checkbox>
            <div className='flex flex-col space-y-3 px-5'>
              <Checkbox checked={addProduct} className='text-[17px] ml-2' onChange={checkboxInputChange}>{lang ? "Добавить продукт" : "Mahsulot qoʻshish"}</Checkbox>
              <Checkbox checked={changePrice} className='text-[17px] ' onChange={checkboxInputChange2}>{lang ? "Изменить цену" : "Narxni tahrirlash"}</Checkbox>
              <Checkbox checked={changeTax} className='text-[17px]' onChange={checkboxInputChange3}>{lang ? "Изменить налог" : "Soliqni tahrirlash"}</Checkbox>
              <Checkbox checked={viewReport} className='text-[17px]' onChange={checkboxInputChange4}> {lang ? "Просмотр отчетов" : "Hisobotlarni koʻrish"}</Checkbox>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EmployeeAdd
import React, { useContext, useState } from 'react'
import { Context } from '../../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { AddBtn } from '../../../components/NewBtn/NewBtn';
import { Input } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';

function AnswerCreate() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id, more, answerId } = useParams()

  const [answerUz, setAnswerUz] = useState("")
  const [answerRu, setAnswerRu] = useState("")

  const [questionMore, setQuestionMore] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setQuestionMore(res.data.questions.find(item => item.id === more - 0));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [more]);
  // Question getbyid end


  const handleAddAnswer = () => {
    const data = {
      questionId: more - 0,
      answerUz: answerUz,
      answerRu: answerRu
    }
    if (answerId) {
      data.id = answerId - 0
      axios
        .put(`${env}cabinet/v2/answer/edit`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          toast.success(lang ? "Сохранено" : "Saqlandi")
          setTimeout(() => {
            navigate(-1)
          }, 800)
        })
    }
    else {
      axios
        .post(`${env}cabinet/v2/answer/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          toast.success(lang ? "Сохранено" : "Saqlandi")
          setTimeout(() => {
            navigate(-1)
          }, 800)
        })
    }

  }

  // Update part start
  const [questionId, setQuestionId] = useState(null)
  useEffect(() => {
    if (answerId) {
      axios
        .get(`${env}cabinet/v2/answer/get-by`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            questionId: more - 0
          }
        })
        .then((res) => {
          setAnswerUz(res.data.find((item => item.id === answerId - 0))?.answerUz)
          setAnswerRu(res.data.find((item => item.id === answerId - 0))?.answerRu)
          setQuestionId(res.data.find((item => item.id === answerId - 0))?.questionId)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerId]);
  // Update part end
  return (
    <div className='bg-white py-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[20px] ">{lang ? "Вопрос" : "Savol"} : {lang ? questionMore?.questionRu : questionMore?.questionUz}</h2>
          <h2 className="font-bold text-[25px] ">{answerId ? lang ? "Редактировать ответ" : "Javob tahrirlash" : lang ? "Добавить ответ" : "Javob qo'shish"} </h2>
        </div>
        <AddBtn onClick={handleAddAnswer} title={answerId ? lang ? "Редактировать" : "Tahrirlash" : lang ? "Сохранять" : "Saqlash"} />
      </div>
      <div className='p-5'>
        <div className=' rounded-md bg-white shadow-md w-[50%] border-[0.1px] border-slate-300'>
          <div className='px-5 py-3 border-b-[0.1px] border-slate-300'>
            <h2 className='font-semibold text-[18px]'>{lang ? "Введите название ответа" : "Javob nomini kiriting"}</h2>
          </div>
          <div className='p-5 flex flex-col space-y-3'>
            <label>
              <span className='text-[14px] pl-2 text-slate-600 font-semibold'>{lang ? "Название ответа УЗ" : "Javob nomi UZ"}</span>
              <Input value={answerUz} onChange={(evt) => setAnswerUz(evt.target.value)} size='large' placeholder={lang ? "Название ответа УЗ" : "Javob nomi UZ"} />
            </label>
            <label>
              <span className='text-[14px] pl-2 text-slate-600 font-semibold'>{lang ? "Название ответа RU" : "Javob nomi RU"}</span>
              <Input value={answerRu} onChange={(evt) => setAnswerRu(evt.target.value)} size='large' placeholder={lang ? "Название ответа RU" : "Javob nomi RU"} />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnswerCreate
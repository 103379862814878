import React from 'react'
import { useContext } from 'react'
import { Context } from '../../../Context/Context'
import Loading from "../../../Assets/Images/loading/loading3.png";
function MessageTable({ loading, messageList }) {
  const { lang } = useContext(Context)
  return (
    <div className='px-5'>
      <table className="w-full px-5">
        <thead>
          <tr className="text-slate-400 ">
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">ID</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Сообщение" : "Xabar"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Номер телефона" : "Telefon raqam"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center  p-2.5">{lang ? "Телеграм ID" : "Telegram Id"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Время отправки" : "Yuborilgan vaqt"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading}
                  width={70}
                  height={70}
                  alt="Loading Icon"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {messageList.length > 0 ?
              messageList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item?.content}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item?.receiverPhoneNumber}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item?.receiverId}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    {item?.createdAt?.split("T")[0]} - {item?.createdAt?.split("T")[1]?.split(".")[0]}
                  </td>
                </tr>
              )) :
              <tr>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
              </tr>
            }
          </tbody>
        )}
      </table>

    </div>
  )
}

export default MessageTable
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import EmpliyeeTable from "../../components/Table/EmpliyeeTable";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import CompanySearch from "../../components/CompanySearch/CompanySearch";
import BranchSearch from "../../components/BranchSearch/BranchSearch";
import RoleSearch from "../../components/RoleSearch/RoleSearch";
import Loading1 from "../../Assets/Images/loading/loading3.png";
import { Context } from "../../Context/Context";
import Edit from "../../Assets/Images/dots.svg";
import toast, { Toaster } from "react-hot-toast";

// Pic

function EmployesList() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false)
  const [refResh, setRefResh] = useState(false)

  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
  };

  const [companyName, setCompanyName] = useState(window.localStorage.getItem("employeeCompName") || "")
  const [companyId, setCompanyId] = useState(JSON.parse(window.localStorage.getItem("employeeCampanyId")) || 0)

  const [branchId, setBranchId] = useState(JSON.parse(window.localStorage.getItem("employeeBranchId")) || 0)
  const [branchName, setBranchName] = useState(window.localStorage.getItem("employeeBranchName") || "")

  const [rolesId, setRolesId] = useState(0)
  const [empData, setEmpData] = useState([])

  const [statusOrder, setStatusOrder] = useState(0)
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/employees`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId: companyId,
          branchId: branchId,
          role: rolesId,
          partnerId: role == "partner" ? roleId : 0,
          statusOrder: statusOrder
        },
      })
      .then((res) => {
        setLoading(false)
        setEmpData(res.data.listOfItems);
        setTotalElement(res.data.totalElements)
      })
      .catch((err) => {
        setLoading(false)

      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, branchId, rolesId, companyId, refResh, statusOrder]);
  // Pagination Start

  const onChange1 = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end

  const clickedActive = (evt) => {
    if (role == "partner") {
      if (empData.find(item => item.id == evt.target.id).isActive) {
        axios
          .post(
            `${env}cabinet/v2/employees/change-status?id=${Number(evt.target.id)}`,
            {
              params: {
                id: Number(evt.target.id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(lang ? "Измененный" : "O'zgartirildi")
            setRefResh(!refResh)
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Нет возможности изменить" : "O'zgartirish imkoniyati mavjud emas")
    }
    else {
      axios
        .post(
          `${env}cabinet/v2/employees/change-status?id=${Number(evt.target.id)}`,
          {
            params: {
              id: Number(evt.target.id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setRefResh(!refResh)
          toast.success(lang ? "Измененный" : "O'zgartirildi")
        })
    }
  }

  useEffect(() => {
    if (role == "company") {
      setCompanyId(roleId)
    }
  }, [])


  window.localStorage.setItem("employeeCampanyId", JSON.stringify(companyId) ? JSON.stringify(companyId) : 0)
  window.localStorage.setItem("employeeCompName", companyName)
  window.localStorage.setItem("employeeBranchId", JSON.stringify(branchId) ? JSON.stringify(branchId) : 0)
  window.localStorage.setItem("employeeBranchName", branchName)

  return <div className="bg-white">
    <Toaster position="top-center" reverseOrder={false} />
    <div className="py-3 px-4 flex items-center justify-between">
      <div >
        <h2 className="font-bold text-[16px] sm:text-[25px]">{lang ? "Список сотрудников" : "Xodimlar Ro'yhati"}</h2>
        <span className="text-slate-400 text-[14px] sm:text-[16px]">{totalElement} {lang ? "сотрудников" : "hodimlar"}</span>
      </div>
      {role != "company" &&
        <AddBtn onClick={() => navigate("add")} title={lang ? "Добавить сотрудника" : "Xodim Qo'shish"} />
      }
    </div>
    <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row items-center md:space-x-12 my-6 px-4">
      <label className="w-full md:w-[350px]">
        <span className="text-[14px] sm:text-[16px] text-slate-600 pl-1 inline-block mb-1">{lang ? "Поиск компании" : "Kompaniya qidirish"}</span>
        <CompanySearch isDisalbed={role == "company" ? true : false} setPageNum={setPageNum} width={'100%'} value={role == "company" ? companyId : companyId === 0 ? null : companyName} setCompanyName={setCompanyName} letLoading={false} setCompanyId={setCompanyId} />
      </label>
      <label className="w-full md:w-[350px]">
        <span className="text-[14px] sm:text-[16px] text-slate-600 pl-1 inline-block mb-1">{lang ? "Выберите позицию" : "Fillial tanlang"}</span>
        <BranchSearch setPageNum={setPageNum} width={'100%'} letLoading={true} value={branchId === 0 ? null : branchName} setBranchName={setBranchName} setLoading={setLoading} companyId={companyId} setBranchId={setBranchId} />
      </label>
      <label className="w-full md:w-[350px]">
        <span className="text-[14px] sm:text-[16px] text-slate-600 pl-1 inline-block mb-1">{lang ? "Выберите позицию" : "Lavozim tanlang"}</span>
        <RoleSearch setPageNum={setPageNum} setLoading={setLoading} setRoleId={setRolesId} />
      </label>
    </div>
    <div className="px-5 hidden lg:block">
      <EmpliyeeTable statusOrder={statusOrder} setStatusOrder={setStatusOrder} refResh={refResh} setRefResh={setRefResh} empData={empData} loading={loading} />
    </div>
    {/* Mobile start */}
    <ul className="lg:hidden px-5">
      {loading ?
        <div className="flex justify-center">
          <img
            className="flex mx-auto items-start justify-center"
            src={Loading1}
            width={70}
            height={70}
            alt="Loading Icon"
          />
        </div>
        :
        empData.length > 0 && empData.map(item => (
          <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] text-end font-semibold">ID</span>
              <span className="w-[50%]">: {item.id}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Кампания" : "Kampaniya"}</span>
              <span className="w-[50%]">{item.companyName}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Филиал" : "Filial"}</span>
              <span className="w-[50%]">{item.branchName}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Авторизоваться" : "Login"}</span>
              <span className="w-[50%]">{item.username}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Позиция" : "Lavozim"}</span>
              <span className="w-[50%]">{item.roleNumber === 1 && "Administrator"}{item.roleNumber === 4 && "Xamkor"}{item.roleNumber === 5 && "Kompaniya"}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Статус" : "Holati"}</span>
              <div className="w-[50%]">
                <div
                  id={item.id}
                  onClick={clickedActive}
                  className="flex cursor-pointer justify-start py-1 items-center"
                >
                  <ul
                    id={item.id}
                    className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.isActive ? "bg-blue-500 " : "bg-slate-300"
                      } rounded-[50px]`}
                  >
                    <li
                      id={item.id}
                      className={`absolute duration-200 ${item.isActive ? "translate-x-4" : ""
                        } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                    ></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Подробно" : "Batafsil"}</span>
              <div className="w-[50%]">
                <button
                  onClick={(evt) => navigate(`${evt.target.id}`)}
                  id={item.id}
                  className="flex justify-center items-center rounded-[50%] w-[20px] h-[20px] bg-[#BFDDFC]"
                >
                  <img className="w-[15px] h-[15px]" id={item.id} src={Edit} width={'100%'} height={"100%"} alt="Edit" />
                </button>
              </div>
            </div>

          </li>
        ))
      }
    </ul>
    {/* Mobile end */}
    <div className=" hidden md:flex justify-center py-5">
      <Pagination
        showQuickJumper
        defaultCurrent={pageNum}
        defaultPageSize={pageSize}
        total={totalElement || empData.length > 0 ? totalElement : 1}
        onChange={onChange1}
      />
    </div>
    <div className="flex md:hidden justify-center py-5">
      <Pagination
        size="small"
        showQuickJumper
        defaultCurrent={pageNum}
        defaultPageSize={pageSize}
        total={totalElement || empData.length > 0 ? totalElement : 1}
        onChange={onChange1}
      />
    </div>
  </div>;
}

export default EmployesList;

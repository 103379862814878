import React, { useContext, useEffect, useState } from 'react'
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../../Context/Context';
function TermMore() {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id } = useParams()
  const [more, setMore] = useState({})
  const [activeItem, setActiveItem] = useState(false);
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/terms/updateStatus?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setActiveItem(!activeItem);
      })

  };
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/terms/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMore(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
  }, [activeItem])

  return (
    <div className='p-5'>
      <div className='bg-white rounded-md'>
        <div className='p-5 border-b-[1px] flex items-center justify-between border-slate-300'>
          <h2 className='text-[21px] font-bold '>{more?.changes}</h2>
          <div className='flex items-center space-x-2'>
            <div
              id={more?.id}
              onClick={clickedActive}
              className="flex cursor-pointer justify-center items-center"
            >
              <span className='text-slate-400'>{lang ? "Активный" : "Faol"}</span>
              <ul
                id={more?.id}
                className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${more?.status ? "bg-blue-500 " : "bg-slate-300"
                  } rounded-[50px]`}
              >
                <li
                  id={more?.id}
                  className={`absolute duration-200 ${more?.status ? "translate-x-4" : ""
                    } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                ></li>
              </ul>
            </div>
            <UpdateBtn onClick={() => navigate(`/terms/update/${id}`)} title={lang ? "Редактирование" : 'Tahrirlash'} />
          </div>
        </div>
        <div className='p-5'>
          <div className='flex justify-between'>
            <div className='p-5 border-[1px] border-slate-300 rounded-md w-[20%]'>
              <h2 className='text-[15px] text-slate-400 '>{lang ? "Изменения:" : "O'zgarishlar :"}</h2>
              <p className='font-semibold'>{more?.changes}</p>
              <div className='mt-5'>
                <h2 className='text-[15px] text-slate-400 '>{lang ? "Время создания:" : "Yaratilgan vaqt :"}</h2>
                <p className='font-semibold'>{more?.createdAt?.split("T")[0]} -- {more?.createdAt?.split("T")[1]?.split(".")[0]}{" "}</p>
              </div>
              <div className='mt-5'>
                <h2 className='text-[15px] text-slate-400 '>{lang ? "Изменено время:" : "O'zgartirilgan vaqt :"}</h2>
                <p className='font-semibold'>{more?.updatedAt?.split("T")[0]} -- {more?.updatedAt?.split("T")[1]?.split(".")[0]}{" "}</p>
              </div>
            </div>
            <div className='p-5 lintsec border-[1px] border-slate-300 rounded-md w-[79%]'>
              <h2 className='text-[15px] text-slate-400 '>{lang ? "Лицензия:" : "Licence : "}</h2>
              <div dangerouslySetInnerHTML={{ __html: more?.licence }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermMore
import { Input, Pagination } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanySearch from '../../components/CompanySearch/CompanySearch';
import useDebounce from '../../Hook/useDebounce';
import BalanceTable from '../../components/Table/BalanceTable';
import { Toaster } from 'react-hot-toast';
import { Context } from '../../Context/Context';
function Balance() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false)
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  // Basic

  // Company search Start
  const [companyName, setCompanyName] = useState("")
  // Company search end

  // Device number start
  const deviceSearchChange = (evt) => {
    setPageNum(1)
    setSearchText(evt.target.value)
    setSearchField("deviceNumber")
    setLoading(true)
    setSearchField("deviceNumber")
    if (evt.target.value == "") {
      setSearchField("inn")
    }
  }
  // Device number end

  // INN number start
  const innChangeSerach = (evt) => {
    setPageNum(1)
    setSearchText(evt.target.value)
    setSearchField("inn")
    setLoading(true)
    setSearchField("inn")
  }
  // INN number end

  // Get All Start
  const [changeComment, setChangeComment] = useState(false)
  const [balanceList, setBalanceList] = useState([])

  const [searchField, setSearchField] = useState(role != "company" ? "inn" : "companyId")
  const [searchText, setSearchText] = useState(role != "company" ? "" : roleId)
  const [statusOrder, setStatusOrder] = useState(0)
  const [balanceOrder, setBalanceOrder] = useState(0)
  const [partnerId, setPartnerId] = useState(0)
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/balance`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          searchField: searchField,
          searchText: searchText ? searchText : "",
          statusOrder: statusOrder,
          balanceOrder: balanceOrder,
          isError: false,
          partnerId: role == "partner" ? roleId : 0
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setBalanceList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, searchText, changeComment, partnerId, statusOrder, balanceOrder]);
  // Get All end
  window.localStorage.setItem("balanceSearchField", searchField)
  window.localStorage.setItem("balanceSearchText", searchText == undefined ? "" : searchText)
  window.localStorage.setItem("balanceStatusOrder", statusOrder)
  window.localStorage.setItem("balanceBalanceOrder", balanceOrder)
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end

  // Balance statistic start
  const [balanceStatistic, setBalanceStatistic] = useState({})
  useEffect(() => {
    axios.get(`${env}cabinet/v2/balance/get-stat`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        isError: false,
        partnerId: role == "partner" ? roleId : 0
      }
    }).then(res => {
      setBalanceStatistic(res.data);
    })
  }, [changeComment])
  // Balance statistic end  

  return (
    <div className='bg-white'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Посмотреть баланс" : "Balans ko'rish"}</h2>
          <span className="text-slate-400">{totalElement} {lang ? "балансовый список" : "balans ro'yhati"}</span>
        </div>
        {role != "company" &&
          <div className='px-8 flex items-end space-x-14'>
            <div className='space-y-1'>
              <p>Activelar : {balanceStatistic.trueCount ? balanceStatistic.trueCount : 0} ta</p>
              <p>Active bo'lmaganlar : {balanceStatistic.falseCount ? balanceStatistic.falseCount : 0} ta</p>
            </div>
            <div className='space-y-1'>
              <p>Qarzdorlar : {balanceStatistic.debtCount ? balanceStatistic.debtCount : 0} ta</p>
              <p>Predoplata: {balanceStatistic.predCount ? balanceStatistic.predCount : 0} ta</p>
            </div>
            <p>Qarzdor bo'lmaganlar : {balanceStatistic.noDebtCount ? balanceStatistic.noDebtCount : 0} ta</p>
          </div>
        }
      </div>
      <div className='p-5 flex flex-col xl:flex-row items-center xl:space-x-16'>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "Поиск компании" : "Kompaniya qidirish"}</span>
          <CompanySearch value={role == "company" ? searchText : searchField == "companyId" ? searchText : null} setField={setSearchField} isDisalbed={role == "company" ? true : false} setPageNum={setPageNum} width={'350px'} setCompanyName={setCompanyName} letLoading={true} setLoading={setLoading} setCompanyId={setSearchText} />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "ИНН номер" : "INN nomer"}</span>
          <Input allowClear value={searchField == "inn" ? searchText : null} onChange={innChangeSerach} className='w-[350px]' placeholder={lang ? "ИНН номер" : "INN nomer"} size='large' />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "Поиск номера устройства" : "Aparat nomer qidirish"}</span>
          <Input allowClear value={searchField == "deviceNumber" ? searchText : null} onChange={deviceSearchChange} className='w-[350px]' placeholder={lang ? "Поиск номера устройства" : "Aparat nomer qidirish"} size='large' />
        </label>
      </div>
      <div className='px-5'>
        <BalanceTable setStatusOrder={setStatusOrder} statusOrder={statusOrder} balanceOrder={balanceOrder} setBalanceOrder={setBalanceOrder} setChangeComment={setChangeComment} changeComment={changeComment} balanceList={balanceList} loading={loading} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || balanceList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Balance
import axios from "axios";
import React from "react";
import { toast, Toaster } from "react-hot-toast";
// Pictures
import Edit from "../../Assets/Images/dots.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function TermTable({ termsList, activeItem, setActiveItem }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  // Active Icon start

  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/terms/updateStatus?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        setActiveItem(!activeItem);
      })
      .catch((err) => {
        toast.error("Xatolik bor :(");
      });
  };
  // Active Icon end

  // Go More Start
  const handleMoreClick = (e) => {
    navigate(`more/${e.target.id}`)
  }
  // Go More end
  function convertHtmlToDom(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.firstChild;
  }
  return (
    <div className="bg-white rounded-md">
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className="font-normal text-center uppercase text-[13px] xl:text-[15px] p-2.5">ID</th>
            <th className="font-normal text-center uppercase text-[13px] xl:text-[15px] p-2.5">{lang ? "Изменения" : "O'zgarishlar"}</th>
            <th className="font-normal text-center  uppercase text-[13px] xl:text-[15px] p-2.5">{lang ? "Лицензия" : "Licence"}</th>
            <th className="font-normal text-center uppercase text-[13px] xl:text-[15px] p-2.5">{lang ? "Дата" : "Sana"}</th>
            <th className="font-normal text-center uppercase text-[13px] xl:text-[15px] p-2.5">{lang ? "Статус" : "Holat"}</th>
            <th className="font-normal text-center uppercase text-[13px] xl:text-[15px] p-2.5">{lang ? "ПОДРОБНО" : "Bafsil"}</th>
          </tr>
        </thead>
        <tbody>
          {termsList.length > 0 &&
            termsList.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA]">
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center  p-2.5">{item.changes}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center text-ellipsis whitespace-nowrap overflow-hidden max-w-[400px] p-2.5">{convertHtmlToDom(item.licence).textContent ? convertHtmlToDom(item.licence).textContent : item.licence}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                  {item.updatedAt?.split("T")[0]} -- {item.updatedAt?.split("T")[1]?.split(".")[0]}{" "}
                </td>

                <td className="border-t-[1px] text-[15px] relative border-slate-300 text-center p-2.5">
                  <div
                    id={item.id}
                    onClick={clickedActive}
                    className="flex cursor-pointer justify-center items-center"
                  >
                    <ul
                      id={item.id}
                      className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                        } rounded-[50px]`}
                    >
                      <li
                        id={item.id}
                        className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                          } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                      ></li>
                    </ul>
                  </div>
                </td>
                <td className="border-t-[1px] relative text-[15px] border-slate-300 text-center p-2.5">
                  <button
                    onClick={handleMoreClick}
                    id={item.id}
                    className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                  >
                    <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default TermTable;

import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import OrganizationTable from "../../components/Table/OrganizationTable";
import RegionSearch from "../../components/RegionSearch/RegionSearch"
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Input, Pagination } from "antd";
import PartinerSearch from "../../components/PartinerSearch/PartinerSearch"
import { Context } from "../../Context/Context";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import Edit from "../../Assets/Images/dots.svg";
import Loading1 from "../../Assets/Images/loading/loading3.png";
// Pictures
function AddNew() {
  const navigate = useNavigate()
  const { lang, role, roleId } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [changeStatus, setChangeStatus] = useState(false)
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  // Basic
  // New RegionSearch start
  const [field, setField] = useState(window.localStorage.getItem("allFactoriesField") || "name")
  const [name, setName] = useState(window.localStorage.getItem("allFactoriesName") || "")
  const [regionName, setRegionName] = useState("")
  // New RegionSearch end

  // new Search All First Input start
  const newSearchAllFirstInpChange = (evt) => {
    setPageNum(1)
    setLoading(true)
    setName(evt.target.value);
    setField("name")
  }
  // new Search All First Input ed

  // Global AXIOS start
  const [partnerId, setPartnerId] = useState(role == "partner" ? roleId : null)

  const [statusOrder, setStatusOrder] = useState(JSON.parse(window.localStorage.getItem("allFactoriesStatusOrder")) || 0)
  const [partinerName, setPartinerName] = useState("")
  const [resultList, setResultList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/companies`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: name ? name : "",
          field: field,
          statusOrder: statusOrder,
          partnerId: partnerId ? partnerId : 0
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setResultList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, name, partnerId, changeStatus, statusOrder]);
  // Global AXIOS end
  window.localStorage.setItem("allFactoriesName", name)
  window.localStorage.setItem("allFactoriesField", field)
  window.localStorage.setItem("allFactoriesStatusOrder", statusOrder)
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  window.localStorage.setItem("allOrganizationRenderList", JSON.stringify(resultList))
  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white p-5 rounded-md">
        <div className=" flex items-center justify-between">
          <div>
            <h2 className="font-bold text-[18px] sm:text-[25px]">{lang ? "Организации" : "Tashkilotlar"}</h2>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{totalElement} {lang ? "организации" : "tashkilotalar"}</span>
          </div>
          {role != "company" ?
            <AddBtn onClick={() => navigate("add")} title={lang ? "Добавить" : "Qo'shish"} />
            : ""}
        </div>
        <div className="flex flex-col xl:flex-row mt-[30px] mb-5 xl:pr-12 items-center space-y-3 xl:space-y-0 xl:space-x-24">
          <label className="flex flex-col w-full sm:w-[350px]">
            <span className="text-[14px] sm:text-[16px] text-slate-600 pl-1 sm:mb-1 inline-block">{lang ? "Поиск" : "Qidirish"}</span>
            <Input className="w-[100%]" allowClear value={field == "name" ? name : null} size="large" placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} onChange={newSearchAllFirstInpChange} />
          </label>
          <label className="flex flex-col w-full sm:w-[350px]">
            <span className="text-[14px] sm:text-[16px] text-slate-600 pl-1 sm:mb-1 inline-block">{lang ? "Выберите регион" : "Viloyat tanlang"}</span>
            <RegionSearch setName={setName} value={field == "region" ? name - 0 : null} setField={setField} setPageNum={setPageNum} setRegionName={setRegionName} letLoading={true} setLoading={setLoading} setRegionId={setName} width={'100%'} />
          </label>
          <label className="flex flex-col w-full sm:w-[350px]">
            <span className="text-[14px] sm:text-[16px] text-slate-600 pl-1 sm:mb-1 inline-block">{lang ? "Выберите партнера" : "Hamkor tanlang"}</span>
            <PartinerSearch setPageNum={setPageNum} setPartinerName={setPartinerName} width={'100%'} value={partnerId} setPartinerId={setPartnerId} setLoading={setLoading} letLoading={true} />
          </label>
        </div>
        <div className="hidden lg:block">
          <OrganizationTable setStatusOrder={setStatusOrder} statusOrder={statusOrder} loading={loading} resultList={resultList} setChangeDelStatus={setChangeStatus} changeDelStatus={changeStatus} />
        </div>
        {/* Mobile start */}
        <ul className="lg:hidden">
          {loading ?
            <div className="flex justify-center">
              <img
                className="flex mx-auto items-start justify-center"
                src={Loading1}
                width={70}
                height={70}
                alt="Loading Icon"
              />
            </div>
            :
            resultList.length > 0 && resultList.map(item => (
              <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] text-end font-semibold">ID</span>
                  <span className="w-[50%]">: {item.id}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Юридическое название" : "Yuridik nomi"}</span>
                  <span className="w-[50%]">{item.title}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Директор" : "Rahbar"}</span>
                  <span className="w-[50%]">{item.director}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Баланс вашей системы" : "Tizimdagi balansinggiz"}</span>
                  <span className="w-[50%]">{item.account}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Статус" : "Holat"}</span>
                  <span className="w-[50%]">{item.active ? "Active" : "Active emas"}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Инн" : "Inn"}</span>
                  <span className="w-[50%]">{item.inn}</span>
                </div>
                <div className="flex items-center justify-between py-1 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Подробно" : "Batafsil"}</span>
                  <div className="w-[50%]">
                    <button
                      onClick={(evt) => navigate(evt.target.id)}
                      id={item.id}
                      className="flex justify-center items-center rounded-[50%] w-[20px] h-[20px] bg-[#BFDDFC]"
                    >
                      <img className="w-[15px] h-[15px]" id={item.id} src={Edit} width={'100%'} height={"100%"} alt="Edit" />
                    </button>
                  </div>
                </div>

              </li>
            ))
          }
        </ul>
        {/* Mobile end */}
        <div className="hidden sm:flex justify-center py-5">
          <Pagination
            showQuickJumper
            defaultCurrent={pageNum}
            defaultPageSize={pageSize}
            total={totalElement || resultList.length > 0 ? totalElement : 1}
            onChange={onChange}
          />
        </div>
        <div className="flex sm:hidden justify-center py-5">
          <Pagination
            size="small"
            showQuickJumper
            defaultCurrent={pageNum}
            defaultPageSize={pageSize}
            total={totalElement || resultList.length > 0 ? totalElement : 1}
            onChange={onChange}
          />
        </div>
      </div>

    </div>
  );
}

export default AddNew;

import React, { useContext, useState } from 'react'
import Loading from "../../../Assets/Images/loading/loading3.png";
import { Context } from "../../../Context/Context"
import Delete from "../../../Assets/Images/delete1.svg";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import UpdateIcon from "../../../Assets/Images/edit.svg"
import { Input, Modal, Select } from 'antd';
import CompanySearch from '../../CompanySearch/CompanySearch';
function GetMessageTable({ telegramGetMessageList, loading, setChangeStatus, changeStatus }) {
  const { lang } = useContext(Context)
  const [open, setOpen] = useState(false)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/contact/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })

  }

  const [delId, setDelId] = useState(null)
  const clickDelBtn = (evt) => {
    setOpen(true)
    setDelId(evt.target.id)
  }
  const handleDelteItem = (evt) => {
    axios
      .post(
        `${env}cabinet/v2/contact/delete?id=${Number(delId)}`,
        {
          params: {
            id: Number(delId),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setOpen(false)
        toast.success(lang ? "Удалено!" : "O'chirildi !")
        setChangeStatus(!changeStatus)
      })
  }


  // Update start
  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)
  const lavozimList = [
    {
      value: true,
      label: "Administrator"
    },
    {
      value: false,
      label: "Foydalanuvchi"
    }
  ]
  const lavozimChange = (value) => {
    setIsAdmin(value)
  }

  // Update end

  const [openEditModal, setOpenEditModal] = useState(false)
  const [clickUpdateId, setClickUpdateId] = useState(null)
  const clickUpdateBtn = (evt) => {
    setOpenEditModal(true)
    setClickUpdateId(evt.target.id)
    axios
      .get(
        `${env}cabinet/v2/contact/${Number(evt.target.id)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setName(res.data.name)
        setPhoneNumber(res.data.phoneNumber)
        setCompanyId(res.data.companyId)
        setIsAdmin(res.data.isAdmin)
      })
  }

  const editSave = () => {
    const data = {
      id: clickUpdateId - 0,
      name: name ? name : "",
      phoneNumber: phoneNumber,
      companyId: isAdmin ? 0 : companyId - 0,
      isAdmin: isAdmin
    }
    if (name && phoneNumber) {
      axios
        .post(`${env}cabinet/v2/contact/edit`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: data
        })
        .then((res) => {
          toast.success(lang ? "Сохранено" : "Saqlandi ")
          setOpenEditModal(false)
          setChangeStatus(!changeStatus)
        })
        .catch((err) => {
          if (err.response.status === 406) {
            toast.error(lang ? "Этот номер имеется в базе данных" : "Bu raqam bazada mavjud !")
          }
          else {
            toast.error(lang ? "Информация не сохранена" : "Ma'lumot saqlanmadi !")
          }
        });
    }
    else {
      toast.error(lang ? "Введите имя или номер телефона" : "Ism yoki telefon raqam kiriting ")
    }
  }
  return (
    <div className='px-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full px-5">
        <thead>
          <tr className="text-slate-400 ">
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">ID</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Имя" : "Ismi"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Номер телефона" : "Telefon raqam"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center  p-2.5">{lang ? "Телеграм ID" : "Telegram Id"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Позиция" : "Lavozim"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Время последнего использования" : "Oxirigi foydalanish vaqti"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Время создания" : "Yaratilgan vaqt"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Статус" : "Holati"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "Редактирование" : "Tahrirlash"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading}
                  width={70}
                  height={70}
                  alt="Loading Icon"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {telegramGetMessageList.length > 0 ?
              telegramGetMessageList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.name ? item.name : <span className='text-red-500'>Ism kiritilmagan</span>}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item?.phoneNumber}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item?.telegramId}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item?.isAdmin ? "Administrator" : "Foydalanuvchi"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    {item.lastActiveDate === null ? "Hali foydalanmagan" :
                      item?.lastActiveDate?.split("T")[0]} - {item?.lastActiveDate?.split("T")[1]?.split(".")[0]}
                  </td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    {item?.createdAt?.split("T")[0]} - {item?.createdAt?.split("T")[1]?.split(".")[0]}
                  </td>

                  <td className="border-t-[1px] border-slate-300 text-center p-2.5">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center py-1 items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.isActive ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.isActive ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td className="border-t-[1px] flex  justify-center space-x-3 relative border-slate-300 text-center p-2.5">
                    <button
                      onClick={clickUpdateBtn}
                      id={item.id}
                      className="bg-blue-500 flex items-center justify-center hover:opacity-70 rounded-lg h-[35px] w-[35px] "
                    >
                      <img id={item.id} src={UpdateIcon} width={25} height={25} alt="Edit" />
                    </button>
                    <button
                      onClick={clickDelBtn}
                      id={item.id}
                      className=" bg-red-500 flex items-center justify-center hover:opacity-70 rounded-lg h-[35px] w-[35px]"
                    >
                      <img id={item.id} src={Delete} width={25} height={25} alt="Edit" />
                    </button>
                  </td>
                </tr>
              )) :
              <tr>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
              </tr>
            }
          </tbody>
        )}
      </table>
      <Modal
        centered
        open={open}
        onOk={handleDelteItem}
        onCancel={() => setOpen(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Удалить информацию" : "Ma'lumot o'chirish"}</h2>
      </Modal>
      <Modal
        centered
        open={openEditModal}
        onOk={editSave}
        onCancel={() => setOpenEditModal(false)}
        width={1000}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Редактировать" : "Tahrirlash"}</h2>
        <div className='flex justify-between'>
          <div className='w-[48%]'>
            <label className='flex flex-col w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Введите имя" : "Ism kiriting"}</span>
              <Input maxLength={13} value={name} allowClear onChange={(evt) => setName(evt.target.value)} size='large' className='w-[100%]' placeholder={lang ? "Введите имя" : "Ism kiriting"} />
            </label>
            <label className='flex mt-3 flex-col w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Поиск компании" : "Kompaniya qidirish"}</span>
              <CompanySearch isDisalbed={isAdmin} letLoading={false} setCompanyId={setCompanyId} setCompanyName={setCompanyName} width={"100%"} value={companyId} />
            </label>
          </div>
          <div className='w-[48%]'>
            <label className='flex flex-col w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Введите номер телефона" : "Telefon raqam kiriting"}</span>
              <Input maxLength={13} value={phoneNumber} allowClear onChange={(evt) => setPhoneNumber(evt.target.value)} size='large' className='w-[100%]' placeholder={lang ? "Введите номер телефона" : "Telefon raqam kiriting"} />
            </label>
            <label className='flex flex-col mt-3 w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Выберите позицию" : "Lavozim tanlang"}</span>
              <Select
                value={isAdmin}
                style={{ 'inlineSize': `100%` }}
                showSearch
                allowClear
                size="large"
                placeholder={lang ? "Выберите позицию" : "Lavozim tanlang"}
                optionFilterProp="children"
                onChange={lavozimChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={lavozimList}
              />
            </label>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default GetMessageTable
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function MessageMore() {
  const { lang } = useContext(Context)
  const { id } = useParams()
  const env = process.env.REACT_APP_API;
  const navigate = useNavigate()
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [moreData, setMoreData] = useState({})
  const [activeLang, setActiveLang] = useState("O'z")
  const [changeStatus, setChangeStatus] = useState(false)
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/message/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })
  }
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/message/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreData(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatus]);
  return (
    <div className='bg-white p-5'>
      <div className='pb-5 flex justify-between'>
        <h2 className='font-bold text-[21px]'>{moreData?.titleOz}</h2>
        <div className='flex items-center space-x-2'>
          <div
            id={id}
            onClick={clickedActive}
            className="flex cursor-pointer justify-center items-center"
          >
            {lang ? "Активный" : "Faol"}
            <ul
              id={id}
              className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${moreData.status ? "bg-blue-500 " : "bg-slate-300"
                } rounded-[50px]`}
            >
              <li
                id={id}
                className={`absolute duration-200 ${moreData.status ? "translate-x-4" : ""
                  } inset-0.5 w-[45%] rounded-[50%] bg-white`}
              ></li>
            </ul>
          </div>
          <UpdateBtn onClick={() => navigate(`/messages/update/${id}`)} title={lang ? "Редактирование" : "Tahrirlash"} />
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='w-[29%] border-[1px] p-5 border-slate-300 rounded-md'>
          <div>
            <h3 className='font-semibold'>{lang ? "Тема:" : "Mavzu :"}</h3>
            <ul className='pl-3 flex flex-col space-y-1'>
              <li>
                <span className='text-slate-400'>O'Z : </span>
                {moreData?.titleOz}
              </li>
              <li>
                <span className='text-slate-400'>UZ : </span>
                {moreData?.titleUz}
              </li>
              <li>
                <span className='text-slate-400'>RU : </span>
                {moreData?.titleRu}
              </li>
            </ul>
          </div>
          <div className='mt-4'>
            <h3 className='font-semibold'>{lang ? "Время создания:" : "Yaratilgan vaqt :"} </h3>
            <p className='pl-3'>{moreData?.createdAt?.split("T")[0]} - {moreData?.createdAt?.split("T")[1]?.split(".")[0]}</p>
          </div>
          <div className='mt-4'>
            <h3 className='font-semibold'>{lang ? "Изменено время:" : "O'zgartirilgan vaqt :"} </h3>
            <p className='pl-3'>{moreData?.endDate?.split("T")[0]} - {moreData?.endDate?.split("T")[1]?.split(".")[0]}</p>
          </div>
        </div>
        <div className='w-[70%] border-[1px] p-5 border-slate-300 rounded-md'>
          <div className='flex justify-between'>
            <h3 className='font-semibold'>{lang ? "Сообщение :" : "Habar :"} </h3>
            <ul className='flex justify-end items-start'>
              <li onClick={() => setActiveLang("O'z")} className={`text-[14px] ${activeLang === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
              <li onClick={() => setActiveLang("Uz")} className={`text-[14px] ${activeLang === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
              <li onClick={() => setActiveLang("Ru")} className={`text-[14px] ${activeLang === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
            </ul>
          </div>
          {activeLang === "O'z" && moreData.contentOz}
          {activeLang === "Uz" && moreData.contentUz}
          {activeLang === "Ru" && moreData.contentRu}
        </div>
      </div>
    </div>
  )
}

export default MessageMore
import React, { useContext } from "react";
import SiteBar from "./components/SiteBar/SiteBar";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
// All factoies
import AllOganization from "./Pages/AllFactories/AllOrganization";
import AddOrganProduct from "./Pages/AllFactories/AddOrganProduct/AddOrganProduct";
import AddNewProduct from "./Pages/FactoryProduct/AddNewProduct/AddNewProduct";
// Branchs
import ListBranch from "./Pages/Branchs/AllList";
import BranchMore from "./Pages/Branchs/BranchMore";
// Employes
import EmpList from "./Pages/Employees/EmployesList";
// Factory Checks
import FacList from "./Pages/FactoryChecks/FactoryCheckList";
// Cashs
import CashsList from "./Pages/Cashs/CashsList";
// FactoryProducts
import CheckedList from "./Pages/FactoryProduct/FactoryList";
// Produts
import AllInfoProd from "./Pages/Products/AllProducts";
import AddInfoProd from "./Pages/Products/AddProducts";
// Patners
import PatnersList from "./Pages/Partners/PatnersList";
import PatnersAdd from "./Pages/Partners/PatnersAdd";
// Users
import UsersList from "./Pages/Users/Users";
import UsersAdd from "./Pages/Users/AddUsers";
import UsersMore from "./Pages/Users/UsersMores";
// Vertions
import VersionsList from "./Pages/Control/VersionList";
import Terms from "./Pages/Control/Terms";
import CheckMxik from "./Pages/Products/CheckMxik";

import Main from "./Pages/Main/Main";
import TermAdd from "./Pages/Control/TermAdd";
import OrganitionMore from "./Pages/AllFactories/OrganitionMore/OrganitionMore";
import Measure from "./Pages/Measure/Measure";
import BranchAdd from "./Pages/Branchs/BranchAdd";
import PartinerMore from "./Pages/Partners/PartinerMore";
import CashsAdd from "./Pages/Cashs/CashsAdd";
import NotifGet from "./Pages/Notification/NotifGet";
import NotifAccept from "./Pages/Notification/NotifAccept";
import NotifGetAdd from "./Pages/Notification/NotifGetAdd";
import CashMore from "./Pages/Cashs/CashMore";
import EmploeeMore from "./Pages/Employees/EmploeeMore";
import EmployeeAdd from "./Pages/Employees/EmployeeAdd";
import NotifAcceptMore from "./Pages/Notification/NotifGetMore";
import NotifLastAccMore from "./Pages/Notification/NotifLastAccMore";
import TermMore from "./Pages/Control/TermMore";
import VersionMore from "./Pages/Control/VersionMore";
import Messages from "./Pages/Messages/Messages";
import MessageMore from "./Pages/Messages/MessageMore";
import MessageAdd from "./Pages/Messages/MessageAdd";
import Balance from "./Pages/Balance/Balance";
import AddBalance from "./Pages/Balance/AddBalance";
import Agreements from "./Pages/Control/Agreements";
import AgreementsMore from "./Pages/Control/AgreementsMore";
import GetMessage from "./Pages/TelegramBotGetId/GetMessage"
import TelegramUsers from "./Pages/TelegramBotGetId/Users"
import TelegramUsersAdd from "./Pages/TelegramBotGetId/UsersAdd"
import BalanceHistory from "./Pages/Balance/BalanceHistory";
import AllMessage from "./Pages/TelegramBotGetId/AllMessage";
import Research from "./Pages/TelegramBotGetId/Research";
import ResearchAdd from "./Pages/TelegramBotGetId/ResearchAdd";
import ResearchMore from "./Pages/TelegramBotGetId/ResearchMore";
import ResearchQuestionAdd from "./Pages/TelegramBotGetId/ResearchQestion/ResearchQuestionAdd";
import ResearchQuestionMore from "./Pages/TelegramBotGetId/ResearchQestion/ResearchQuestionMore";
import AnswerCreate from "./Pages/TelegramBotGetId/Answer/AnswerCreate";
import AnswerMore from "./Pages/TelegramBotGetId/Answer/AnswerMore";
import Result from "./Pages/TelegramBotGetId/ResearchResult/Result";
import ResultAnserMore from "./Pages/TelegramBotGetId/ResearchResult/ResultAnserMore";
import { Context } from "./Context/Context";
import AllProductMore from "./Pages/Products/AllProductMore";

function Authentication() {
  const { role } = useContext(Context)
  return (
    <>
      <div className="flex">
        <SiteBar />
        <div className="w-full h-[100vh] overflow-y-auto">
          <Header />
          {role == "administrator" ?
            <Routes>
              {/* Main start */}
              <Route path={"/"} element={<Main />} />
              {/* Main end */}
              <Route path={"/organization"} element={<AllOganization />} />
              <Route path={"/organization/add"} element={<AddOrganProduct />} />
              <Route path={"/organization/:id"} element={<OrganitionMore />} />
              <Route path={"/organization/:id/update"} element={<AddOrganProduct />} />
              {/* Fillillar start */}
              <Route path={"/filist"} element={<ListBranch />} />
              <Route path={"/filist/more/:id"} element={<BranchMore />} />
              <Route path={"/filist/add"} element={<BranchAdd />} />
              <Route path={"/filist/edit/:id"} element={<BranchAdd />} />
              {/* Fillillar end */}
              {/* Hodimlar start */}
              <Route path={"/employeslist"} element={<EmpList />} />
              <Route path={"/employeslist/:id"} element={<EmploeeMore />} />
              <Route path={"/employeslist/add"} element={<EmployeeAdd />} />
              <Route path={"/employeslist/:id/update"} element={<EmployeeAdd />} />
              {/* Hodimlar end */}
              {/* Check start */}
              <Route path={"/checklist"} element={<FacList />} />
              {/* Check end */}
              {/* Kassa start */}
              <Route path={"/cashlist"} element={<CashsList />} />
              <Route path={"/cashlist/:id"} element={<CashMore />} />
              <Route path={"/cashlist/:id/update/:id"} element={<CashsAdd />} />
              <Route path={"/cashlist/add"} element={<CashsAdd />} />
              {/* Kassa end */}
              {/* Company product start */}
              <Route path={"/organproducts"} element={<CheckedList />} />
              <Route path={"/organproducts/add"} element={<AddNewProduct />} />
              {/* Company product end */}
              {/*  product start */}
              <Route path={"/allproducts"} element={<AllInfoProd />} />
              <Route path={"/allproducts/:id"} element={<AllProductMore />} />
              <Route path={"/allpordadd"} element={<AddInfoProd />} />
              <Route path={"/allproducts/check"} element={<CheckMxik />} />
              <Route path={"/measure"} element={<Measure />} />
              {/*  product end */}
              {/* Hamkorlar start */}
              <Route path={"/patnerslist"} element={<PatnersList />} />
              <Route path={"/patnerslist/more/:id"} element={<PartinerMore />} />
              <Route path={"/patnerslist/add"} element={<PatnersAdd />} />
              <Route path={"/patnerslist/update/:id"} element={<PatnersAdd />} />
              {/* Hamkorlar end */}
              {/* Foydalanuvchilar start */}
              <Route path={"/userslist"} element={<UsersList />} />
              <Route path={"/userslist/:id"} element={<UsersMore />} />
              <Route path={"/userslist/add"} element={<UsersAdd />} />
              <Route path={"/userslist/update/:id"} element={<UsersAdd />} />
              {/* Foydalanuvchilar end */}
              {/* Verstions Start */}
              <Route path={"/versionlist"} element={<VersionsList />} />
              <Route path={"/versionlist/more/:id"} element={<VersionMore />} />
              <Route path={"/terms"} element={<Terms />} />
              <Route path={"/agreements"} element={<Agreements />} />
              <Route path={"/agreements/more/:id"} element={<AgreementsMore />} />
              <Route path={"/terms/more/:id"} element={<TermMore />} />
              <Route path={"/terms/add"} element={<TermAdd />} />
              <Route path={"/terms/update/:id"} element={<TermAdd />} />
              {/* Verstions End */}
              {/* Notification start */}
              <Route path={"/notif-get"} element={<NotifGet />} />
              <Route path={"/notif-get/:id"} element={<NotifAcceptMore />} />
              <Route path={"/notif-get/:id/update/:id"} element={<NotifGetAdd />} />
              <Route path={"/notif-get/add"} element={<NotifGetAdd />} />
              <Route path={"/nofit-accept"} element={<NotifAccept />} />
              <Route path={"/nofit-accept/more/:id"} element={<NotifLastAccMore />} />
              {/* Notification end */}
              {/* Messages start */}
              <Route path={"/messages"} element={<Messages />} />
              <Route path={"/messages/add"} element={<MessageAdd />} />
              <Route path={"messages/more/:id"} element={<MessageMore />} />
              <Route path={"/messages/update/:id"} element={<MessageAdd />} />
              {/* Messages end */}
              {/* Balance start */}
              <Route path={"/balance"} element={<Balance />} />
              <Route path={"/balance/history/:id"} element={<BalanceHistory />} />
              <Route path={"/add-balance"} element={<AddBalance />} />
              <Route path={"/add-balance/history/:id"} element={<BalanceHistory />} />
              {/* Balance end */}
              {/* Telegram bot start */}
              <Route path={"/all-messages"} element={<AllMessage />} />
              <Route path={"/all-messages/get"} element={<GetMessage />} />
              <Route path={"/telegrambot-users"} element={<TelegramUsers />} />
              <Route path={"/telegrambot-users/add"} element={<TelegramUsersAdd />} />
              {/* Telegram bot end */}
              {/* Research start */}
              <Route path={"/research"} element={<Research />} />
              <Route path={"/research/create"} element={<ResearchAdd />} />
              <Route path={"/research/edit/:id"} element={<ResearchAdd />} />
              <Route path={"/research/result/:id"} element={<Result />} />
              <Route path={"/research/result/:id/aswers/:answerId"} element={<ResultAnserMore />} />
              <Route path={"/research/more/:id"} element={<ResearchMore />} />
              <Route path={"/research/more/:id/question/add"} element={<ResearchQuestionAdd />} />
              <Route path={"/research/more/:id/question/edit/:updateId"} element={<ResearchQuestionAdd />} />
              <Route path={"/research/more/:id/question/more/:more"} element={<ResearchQuestionMore />} />
              <Route path={"/research/more/:id/question/more/:more/anwer-create"} element={<AnswerCreate />} />
              <Route path={"/research/more/:id/question/more/:more/answer-more/:answerId"} element={<AnswerMore />} />
              <Route path={"/research/more/:id/question/more/:more/answer-more/:answerId/edit"} element={<AnswerCreate />} />

              {/* Research end */}
            </Routes>
            : ""}
          {role == "partner" ?
            <Routes>
              {/* Main start */}
              <Route path={"/"} element={<Main />} />
              {/* Main end */}
              <Route path={"/organization"} element={<AllOganization />} />
              <Route path={"/organization/add"} element={<AddOrganProduct />} />
              <Route path={"/organization/:id"} element={<OrganitionMore />} />
              <Route path={"/organization/:id/update"} element={<AddOrganProduct />} />
              {/* Fillillar start */}
              <Route path={"/filist"} element={<ListBranch />} />
              <Route path={"/filist/more/:id"} element={<BranchMore />} />
              <Route path={"/filist/add"} element={<BranchAdd />} />
              <Route path={"/filist/edit/:id"} element={<BranchAdd />} />
              {/* Fillillar end */}
              {/* Hodimlar start */}
              <Route path={"/employeslist"} element={<EmpList />} />
              <Route path={"/employeslist/:id"} element={<EmploeeMore />} />
              <Route path={"/employeslist/add"} element={<EmployeeAdd />} />
              <Route path={"/employeslist/:id/update"} element={<EmployeeAdd />} />
              {/* Hodimlar end */}
              {/* Check start */}
              <Route path={"/checklist"} element={<FacList />} />
              {/* Check end */}
              {/* Kassa start */}
              <Route path={"/cashlist"} element={<CashsList />} />
              <Route path={"/cashlist/:id"} element={<CashMore />} />
              <Route path={"/cashlist/:id/update/:id"} element={<CashsAdd />} />
              <Route path={"/cashlist/add"} element={<CashsAdd />} />
              {/* Kassa end */}
              {/* Company product start */}
              <Route path={"/organproducts"} element={<CheckedList />} />
              <Route path={"/organproducts/add"} element={<AddNewProduct />} />
              {/* Company product end */}

              {/* Notification start */}
              <Route path={"/notif-get/:id"} element={<NotifAcceptMore />} />
              <Route path={"/notif-get/:id/update/:id"} element={<NotifGetAdd />} />
              <Route path={"/notif-get/add"} element={<NotifGetAdd />} />
              <Route path={"/nofit-accept"} element={<NotifAccept />} />
              <Route path={"/nofit-accept/more/:id"} element={<NotifLastAccMore />} />
              {/* Notification end */}

              {/* Balance start */}
              <Route path={"/balance"} element={<Balance />} />
              <Route path={"/balance/history/:id"} element={<BalanceHistory />} />
              {/* Balance end */}

            </Routes>
            : ""}
          {role == "company" ?
            <Routes>
              {/* Main start */}
              <Route path={"/"} element={<Main />} />
              {/* Main end */}
              <Route path={"/organization/:id"} element={<OrganitionMore />} />
              {/* Fillillar start */}
              <Route path={"/filist"} element={<ListBranch />} />
              <Route path={"/filist/more/:id"} element={<BranchMore />} />
              <Route path={"/filist/edit/:id"} element={<BranchAdd />} />
              {/* Fillillar end */}
              {/* Hodimlar start */}
              <Route path={"/employeslist"} element={<EmpList />} />
              <Route path={"/employeslist/:id"} element={<EmploeeMore />} />
              {/* Hodimlar end */}
              {/* Check start */}
              <Route path={"/checklist"} element={<FacList />} />
              {/* Check end */}
              {/* Kassa start */}
              <Route path={"/cashlist"} element={<CashsList />} />
              <Route path={"/cashlist/:id"} element={<CashMore />} />
              {/* Kassa end */}
              {/* Company product start */}
              <Route path={"/organproducts"} element={<CheckedList />} />
              <Route path={"/organproducts/add"} element={<AddNewProduct />} />
              {/* Company product end */}

              {/* Notification start */}
              <Route path={"/notif-get/:id"} element={<NotifAcceptMore />} />
              <Route path={"/notif-get/:id/update/:id"} element={<NotifGetAdd />} />
              <Route path={"/notif-get/add"} element={<NotifGetAdd />} />
              <Route path={"/nofit-accept"} element={<NotifAccept />} />
              <Route path={"/nofit-accept/more/:id"} element={<NotifLastAccMore />} />
              {/* Notification end */}
            </Routes>
            : ""}

        </div>
      </div>
    </>
  );
}

export default Authentication;

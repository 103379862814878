import React, { useEffect, useRef, useState } from "react";
import Buttons from "../../components/Buttons/Buttons";
import VersionListTable from "../../components/Table/VersionListTable";
import { Modal } from "../../components/Modal/Modal";
import { Radio } from "antd";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from "react";
import { Context } from "../../Context/Context"
function VersionList() {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const addInputCount = useRef();
  const aboutVersionRef = useRef();
  const env = process.env.REACT_APP_API;
  const [versionList, setVersionList] = useState(JSON.parse(window.localStorage.getItem("versionList")) || []);
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [refResh, setRefResh] = useState(false);
  // Add versions Start
  const [showModal, setShowModal] = useState(false);
  const handleAddVersion = (evt) => {
    setShowModal(true);
  };
  // Add versions end

  // Radio buttons changes start
  const [value, setValue] = useState(true);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  // Radio buttons changes end

  // Version Add submit start
  const handleAddVersionSubmit = (evt) => {
    const env = process.env.REACT_APP_API;
    const token = JSON.parse(window.localStorage.getItem("token"));
    evt.preventDefault();
    const data = {
      title: aboutVersionRef.current.value,
      versionNumber: addInputCount.current.value - 0,
      important: value,
      status: true,
    };
    setShowModal(false);
    axios
      .post(`${env}cabinet/v2/addVersion`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success(res.data);
        setRefResh(!refResh);
      })
      .catch((err) => { });
  };
  // Version Add submit end

  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/all-versions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setVersionList(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refResh]);
  window.localStorage.setItem("versionList", JSON.stringify(versionList))
  return (
    <div className="bg-white">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex items-center px-5 py-5 justify-between">
        <div>
          <h2 className="font-bold text-[25px]">{lang ? "Список версий" : "Versiyalar royhati"}</h2>
          <span className="text-slate-400">{versionList.length} {lang ? "версий" : "versiyalar"}</span>
        </div>
        <AddBtn onClick={handleAddVersion} title={lang ? "Добавить версию" : "Versiya qo'shish"} />
      </div>
      <div className="px-5">
        <VersionListTable versionList={versionList} refResh={refResh} setRefResh={setRefResh} />
      </div>
      <Modal className={'w-[70%] overflow-y-auto max-h-[85%]'} isVisible={showModal} onClose={() => setShowModal(false)}>
        <h2 className="text-center text-[25px] font-semibold ">{lang ? "Добавить новую версию" : "Yangi versiya qo'shish"}</h2>
        <form onSubmit={handleAddVersionSubmit}>
          <label className="flex flex-col">
            {lang ? "Номер версии" : "Versiya raqami"}
            <input
              ref={addInputCount}
              type={"number"}
              className="outline-none border-2 py-2  text-center w-[30%]"
            />
          </label>
          <label className="flex flex-col mt-5">
            {lang ? "О версии..." : "Versiya haqida ..."}
            <textarea
              rows={10}
              ref={aboutVersionRef}
              className="outline-none border-2 rounded-md p-2"
              placeholder={lang ? "информация" : "malumot"}
            ></textarea>
          </label>
          <div className="flex justify-between items-end w-full">
            <label className="flex flex-col mt-5">
              {lang ? "Уровень важности:" : "Muhimlik darajasi :"}
              <Radio.Group
                onChange={onChange}
                className="mt-3"
                name="radiogroup"
                defaultValue={false}
              >
                <Radio className="p-2 rounded-md border-2 border-slate-200font-semibold" value={true}>
                  {lang ? "Важный" : "Muhim"}
                </Radio>
                <Radio
                  className="p-2 rounded-md border-2 border-slate-200 font-semibold"
                  value={false}
                >
                  {lang ? "Не важно" : "Muhim emas"}
                </Radio>
              </Radio.Group>
            </label>
            <Buttons
              extraSty={"h-[45px] w-[250px]"}
              btnType={"submit"}
              bg={"blue"}
              title={lang ? "Добавить" : "Qo'shish"}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default VersionList;

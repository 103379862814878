import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AddBtn } from '../../components/NewBtn/NewBtn';
import toast, { Toaster } from 'react-hot-toast';
import { Context } from "../../Context/Context"
import { Checkbox, Input, Select } from 'antd';
import { useEffect } from 'react';
import axios from 'axios';
function GetMessage() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate()
  const [message, setMessage] = useState("")

  const [selectList, setSelectList] = useState([{ value: "", label: "" }])
  const [telegramId, setTelegramId] = useState(null)
  const onChange = (value) => {
    setTelegramId(value)
  }
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/contact/select`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setSelectList(res.data.map(item => ({ value: item.telegramId, label: item.phoneNumber, disabled: !item.status })))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [checkedHammasi, setCheckedHammasi] = useState(false)
  const checkHammasi = (e) => {
    setCheckedHammasi(e.target.checked);
  };
  const handleSubmit = () => {
    const data = {
      message: message,
      telegramId: checkedHammasi ? -1 : telegramId
    }
    if (message !== " " && telegramId || checkedHammasi || telegramId === 0) {
      axios
        .post(`${env}cabinet/v2/bot/message?message=${message}&telegramId=${checkedHammasi ? 0 : telegramId}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          toast.success(lang ? "Сообщение отправлена !" : "Xabar jo'natildi !")
          setMessage("")
          setTelegramId(null)
          setTimeout(() => {
            navigate(-1)
          }, [800])
        })
    }
    else {
      toast.error(lang ? "Заполните информацию полностью !" : "Ma'lumotni to'liq to'ldiring !")
    }

    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return (
    <div className='bg-white pb-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Отправить сообщение" : "Xabar yuborish"}</h2>
        </div>
        <AddBtn onClick={handleSubmit} title={lang ? "Отправить" : "Jo'natish"} />
      </div>
      <div className='p-5 '>
        <div className='flex space-x-10 w-[80%]'>
          <textarea value={message} rows={5} className='w-[55%] outline-none border-[1px] border-[#D9D9D9] p-3 rounded-md' placeholder={lang ? "Напиши сообщение!" : "Xabar yozing !"} onChange={(evt) => setMessage(evt.target.value)}>

          </textarea>
          <div className='w-[45%]'>
            <Checkbox className='mb-2' onChange={checkHammasi}>{lang ? "Выбрать все" : "Hammasini tanlash"}</Checkbox>
            <div>
              <Select
                value={telegramId}
                style={{ 'inlineSize': `100%` }}
                showSearch
                disabled={checkedHammasi ? true : false}
                allowClear
                size="large"
                placeholder={lang ? "Выберите номер телефона!" : "Telefon raqam tanlang !"}
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={selectList}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default GetMessage
import React from "react";
import { useContext } from "react";
import { Context } from "../../../Context/Context";

function ClientsTable({ clients, loading }) {
  const { lang } = useContext(Context)
  return (
    <div>
      <table className="w-full mt-5">
        <thead>
          <tr className="text-slate-400">
            <th className="font-normal text-center uppercase p-2">ID</th>
            <th className="font-normal text-center uppercase p-2">{lang ? "Именование" : "Nomlanishi"}</th>
            <th className="font-normal text-center uppercase  p-2">{lang ? "Стир" : "Stir"}</th>
            <th className="font-normal text-center uppercase w-[18%] p-2">{lang ? "Директор" : "Rahbar"}</th>
            <th className="font-normal text-center uppercase w-[18%] p-2">{lang ? "Баланс в системе" : "Tizimdagi balans"}</th>
            <th className="font-normal text-center uppercase p-2 w-[20%]">{lang ? "Юридический адрес" : "Yuridik manzil"}</th>
            <th className="font-normal text-center uppercase p-2 w-[25%]">
              {lang ? "Дата регистрации" : "Royhatga olingan sana"}
            </th>
          </tr>
        </thead>
        <tbody>
          {clients.length > 0 &&
            clients.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA]">
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2 relative before:absolute before:w-[7px] before:h-[7px] before:bg-[#3699FF] before:rounded-[50px] before:bottom-6 before:left-0">{item.id}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] whitespace-nowrap overflow-hidden text-ellipsis p-2">{item.title}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2">{item.inn}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2">{item.director}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2">{item.account}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[350px]">{item.address}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2">
                  {item.createdAt.split("T")[0]} | {item.createdAt.split("T")[1].split(".")[0]}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default ClientsTable;

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import ProductsTable from "../../components/Table/ProductsTable";
import axios from "axios";
import { Input, Pagination, Select } from "antd";
import useDebounce from "../../Hook/useDebounce"
import { Context } from "../../Context/Context"
// Pictures
import Buttons from "../../components/Buttons/Buttons";
function AllProducts() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const allSearchRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  // Basic
  const [pageNum, setPageNum] = useState(JSON.parse(localStorage.getItem("productPageNum")) || 1);
  const [pageSize, setPageSize] = useState(20);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  localStorage.setItem("productPageNum", JSON.stringify(pageNum))
  // Basic
  // Category get start
  const [categoryId, setCategoryId] = useState(0)
  const [categoryList, setCategoryList] = useState([])
  const onChange1 = (value) => {
    setPageNum(1)
    setCategoryId(value);
  };

  React.useEffect(() => {
    axios
      .get(`${env}cabinet/v2/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategoryList(res.data.map(item => (
          {
            value: item.id,
            label: item.categoryOz,
          }
        )));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Category get end

  // all change input start
  const [searchValue, setSearchValue] = useState(
    window.sessionStorage.getItem("searchAllCategory") || ""
  );
  const [renderList, setRenderList] = useState(
    JSON.parse(window.localStorage.getItem("renderProductList")) || []
  );
  const [innClear, setInnClear] = useState(
    JSON.parse(window.sessionStorage.getItem("allProInnClear")) || false
  );
  const handleAllCange = (evt) => {
    setPageNum(1);
    setSearchValue(evt.target.value);
    if (evt.target.value === "") {
      setInnClear(false);
    } else {
      setLoading(true);
      setInnClear(true);
    }
  };
  const [refOFD, setRefOFD] = React.useState(false);
  const [totalElements, setTotalElements] = React.useState(0);
  // Result get post start
  const searchValueAll = useDebounce(searchValue, 300)
  React.useEffect(() => {
    setLoading(true)
    axios
      .post(
        `${env}cabinet/v2/all-products?searchText=${searchValueAll}`,
        filterRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            catId: categoryId
          }
        }
      )
      .then((res) => {
        setLoading(false);
        setTotalElements(res.data.totalElements);
        setRenderList(res.data.listOfItems);
      })
      .catch((err) => {
        setRenderList([{ id: 1, title: "Ma'lumot yo'q" }]);
        if (err.response?.status === 500) {
          toast.error("Serverda xatoli bor !");
        } else if (err.response.status === 401) {
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refOFD, pageNum, pageSize, searchValueAll, categoryId]);
  // Result get post end

  const [unCheckEdData, setUnCheckedData] = React.useState(
    window.sessionStorage.getItem("checkTotalEl") || 0
  );
  React.useEffect(() => {
    axios
      .get(`${env}cabinet/v2/getuncheck_mxik`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUnCheckedData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refOFD]);

  window.sessionStorage.setItem("searchAllCategory", searchValue);
  window.sessionStorage.setItem("allProInnClear", JSON.stringify(innClear));
  window.localStorage.setItem("renderProductList", JSON.stringify(renderList));
  window.localStorage.setItem("productTotalEl", totalElements);
  window.sessionStorage.setItem("checkTotalEl", unCheckEdData);

  // all change input end
  // OFD link click start
  const clickedOfdLink = (evt) => {
    toast.success("Tekshirilmoqda... ");
    setRefOFD(!refOFD);
    axios
      .get(`${env}cabinet/v2/check-products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRefOFD(!refOFD);
        toast.success("Muvaffaqiyatli tekshirildi :)");
      })
      .catch((err) => {
        toast.error("Tekshirilmadi :(");
      });
  };
  // OFD link click end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };

  // Pagination end
  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white px-5 p-4 ">
        <div>
          <h2 className="font-bold text-[25px]">{lang ? "Список продуктов" : "Mahsulotlar ro'yxati"}</h2>
          <span className="text-slate-400">{totalElements} {lang ? "продуктов" : "mahsulotlar"}</span>
        </div>
        <div className="flex flex-col xl:flex-row justify-between mt-8 items-center">
          <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-12 items-center">
            <label className="flex flex-col">
              <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z "}</span>
              <Input allowClear className="w-[350px]" value={searchValue} ref={allSearchRef} onChange={handleAllCange} size="large" placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z "} />
            </label>
            <label className="flex flex-col">
              <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Выберите категорию" : "Kategoriya tanlang"}</span>
              <Select
                style={{ 'inlineSize': '350px' }}
                allowClear
                showSearch
                size="large"
                placeholder={lang ? "Выберите категорию" : "Kategoriya tanlang"}
                optionFilterProp="children"
                onChange={onChange1}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={categoryList}
              />
            </label>
          </div>
          <div className="flex space-x-12 mt-5 xl:mt-0 items-center">
            <Buttons clicked={clickedOfdLink} title={lang ? "Проверять из ОФД" : "OFDdan tekshirish"} />
            <Buttons clicked={() => navigate("check")} title={lang ? "Проверка через Mxik" : "Mxik Orqali Tekshirish"} />
          </div>
        </div>
      </div>
      <div className="px-5">
        <h2 className="font-semibold text-[18px] mt-2">{lang ? "Продукты" : "Mahsulotlar"} ({totalElements} {lang ? "шт" : "ta"}) </h2>
        <h2 className="font-semibold text-[18px] mt-1">
          {lang ? "Непроверенные продукты" : "Tekshirilmagan mahsulotlar"} ({unCheckEdData} {lang ? "шт" : "ta"})
        </h2>
      </div>
      <div className="mt-4 px-5 w-full">
        <ProductsTable loading={loading} renderList={renderList} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElements || renderList.length > 0 ? totalElements : 1}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default AllProducts;

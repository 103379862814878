import React from 'react'
import { Select } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function RegionSearch({ setRegionId, setLoading, letLoading, value, width, setRegionName, setPageNum, setField, setName }) {
    const { lang } = useContext(Context)
    const env = process.env.REACT_APP_API;
    const token = JSON.parse(window.localStorage.getItem("token"));

    const onChange = (value, name) => {
        if (setPageNum) {
            setPageNum(1)
        }
        setRegionId(value);
        setRegionName(name?.label)
        if (letLoading) {
            setLoading(true)
        }
        if (setField) {
            setField("region")
            if (value == undefined) {
                setField("name")
                setName("")
            }
        }
    };
    const [regionList, setRegionList] = useState([])
    useEffect(() => {
        axios
            .get(`${env}cabinet/v2/regions`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setRegionList([...res.data.map(item => ({ value: item.id, label: item.title }))]);
            })
    }, [])
    return (
        <Select
            value={value}
            style={{ 'inlineSize': `${width}` }}
            allowClear
            showSearch
            size="large"
            placeholder={lang ? "Выберите регион" : "Viloyat tanlang"}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={regionList}
        />
    )
}

export default RegionSearch
import axios from "axios";
import React, { useEffect, useState } from "react";
import AddProductsTable from "../../components/Table/AddProductsTable";
import { Pagination } from "antd";
// Pictures
import UploadIcon from "../../Assets/upload-file.svg";
import Buttons from "../../components/Buttons/Buttons";
import Loading from "../../Assets/Images/loading/loading.svg";
import { toast, Toaster } from "react-hot-toast";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function AddProducts() {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const dateFormat = "YYYY-MM-DD";
  const date = new Date();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [refResh, setRefResh] = React.useState(false);
  // input change file start
  let formdata = new FormData();
  const [saveFiles, setSaveFiles] = React.useState([]);
  const handleChangeFile = (evt) => {
    setSaveFiles(evt.target.files[0]);
  };
  const [loading, setLoading] = React.useState(false);
  const [messageSuccess, setMessageSuccess] = React.useState(false);
  formdata.append("file", saveFiles);
  const handleSubmitFile = () => {
    setLoading(true);
    axios
      .post(`${env}cabinet/v2/import-product-upload`, formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setMessageSuccess(true);
        toast.success("Muvaffaqiyatli jonatildi");
      })
      .catch((err) => {
        toast.error("Jonatilmadi :(");
        setLoading(false);
      });
  };
  // input change file end

  // save product btn click start
  const handlSaveProduct = () => {
    axios
      .post(`${env}cabinet/v2/save-to-db `, formdata, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setMessageSuccess(true);
        toast.success("Muvaffaqiyatli saqlandi");
      })
      .catch((err) => {
        toast.error("Saqlanmadi :(");
        setLoading(false);
      });
  };
  // save product btn click end

  // Delete Products start
  const handleDeleteProducts = () => {
    axios
      .delete(`${env}cabinet/v2/delete-import-product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success("Muvaffaqiyatli o'chirildi");

      })
      .catch((err) => {
        toast.error("O'chirilmadi :(");
      });
  };
  // Delete Products end

  // Date Picker start

  // Ending Data start
  const [starttingDate, setStartingDate] = React.useState("");
  const [startTime, setStartingTime] = React.useState("");
  // Ending Data end

  const [lastProductCount, setLastProductCount] = React.useState(0);
  const [lastProductAdd, setLastProductAdd] = React.useState(0);
  const [lastProductDel, setLastProductDel] = React.useState(0);
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/modifyDate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setLastProductCount(res.data.countProduct);
        setLastProductAdd(res.data.addingProduct);
        setLastProductDel(res.data.disablingProduct);
        setStartingDate(res.data.endingDate.toString().split("T")[0]);
        setStartingTime(res.data.endingDate.toString().split("T")[1].split(".")[0]);
      })
      .catch((err) => {
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refResh]);
  // Date Picker end
  // ending tayyor
  const dateString = starttingDate + " " + startTime; // start dayt tayyor
  // Ant Date Piccer start
  const [devTimem, setDevTime] = useState(
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0") +
    " " +
    "00:00:00"
  );
  const handleChangeDatePiccer = (evt) => {
    setDevTime(
      evt.$y +
      "-" +
      (evt.$M + 1).toString().padStart(2, "0") +
      "-" +
      evt.$D.toString().padStart(2, "0") +
      " " +
      "00:00:00"
    );
  };
  // Ant Date Piccer end
  // checkbtn clicked start
  const [checkLoading, setCheckLoading] = React.useState(false);
  const handleResultDatePost = () => {
    setCheckLoading(true);
    axios
      .get(`${env}cabinet/v2/integration?endDate=${devTimem}&startDate=${dateString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {

        toast.success("Muvaffaqiyatli saqlandi :)");
        setRefResh(!refResh);
        setCheckLoading(false);

      })
      .catch((err) => {

        toast.error("Muammo bor :(");
        setCheckLoading(false);
        // setCategoryList([{ id: 1, title: "Ma'lumot yo'q" }]);
      });
  };
  // checkbtn clicked end

  // Basic
  const [pageNum, setPageNum] = React.useState(
    JSON.parse(window.localStorage.getItem("pagNumProductAdd")) || 1
  );
  const [pageSize, setPageSize] = React.useState(
    JSON.parse(window.localStorage.getItem("pagSizeProductAdd")) || 20
  );

  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  // Basic

  // Auto post mxiks start
  const [getMxikList, setGetMxikList] = React.useState(
    JSON.parse(window.sessionStorage.getItem("productRednderMxikList")) || []
  );
  const [getMxikTotalPage, setGetMxikTotalPage] = React.useState(
    JSON.parse(window.sessionStorage.getItem("getMxikTotalPage")) || 0
  );

  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/import-product`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setGetMxikList(res.data.listOfItems);
        setGetMxikTotalPage(res.data.totalElements);
      })
      .catch((err) => {
        setGetMxikList([{ id: 1, mxik: "Ma'lumot yo'q" }]);
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize]);
  // Auto post mxiks end
  window.sessionStorage.setItem("productRednderMxikList", JSON.stringify(getMxikList));
  window.sessionStorage.setItem("getMxikTotalPage", JSON.stringify(getMxikTotalPage));
  window.localStorage.setItem("pagNumProductAdd", JSON.stringify(pageNum));
  window.localStorage.setItem("pagSizeProductAdd", JSON.stringify(pageSize));
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="p-4 bg-white">
        <h2 className="font-bold text-[25px]">{lang ? "Добавить продукт" : "Mahsulot qo'shish"}</h2>
      </div>
      <div className="flex px-4 justify-between mt-5">
        <div className="w-[48%]  shadow-md border-[1px] border-slate-300 bg-white py-4 rounded-md">
          <h3 className="px-4 border-b-2 pb-3 font-semibold">
            {lang ? "Загрузка списка налоговой классификации" : "Soliq tasnifdan olingan ro`yxatni yuklash"}
          </h3>
          <h3 className="px-4 text-[15px] mt-4 ">{lang ? "Продукты с кодом MXIK загружают файл EXCEL:" : "MXIK kodli mahsulotlar EXCEL faylini yuklash:"}</h3>
          <div className="mt-3 flex items-center px-4 space-x-5">
            <label className="flex items-center space-x-1 bg-blue-500 justify-center p-2 w-[150px] rounded-md text-white cursor-pointer hover:opacity-90">
              <input onChange={handleChangeFile} className="visually-hidden" type={"file"} />
              {lang ? "Выберите файл" : "File tanlang"}
              <div className="flex justify-center">
                <img className="mx-auto" src={UploadIcon} width={28} height={28} alt="Upload img" />
              </div>
            </label>
            <Buttons clicked={handleSubmitFile} title={lang ? "Перевозки" : "Jo'natish"} />
          </div>
          {loading ? (
            <img className="mx-auto mt-2" src={Loading} width={20} height={20} alt="Loading..." />
          ) : messageSuccess ? (
            <p className="px-4 mt-2">{lang ? "Успешно отправлено" : "Muvaffaqiyatli jo'natildi"}</p>
          ) : (
            ""
          )}
          <div className="mt-3 flex items-center px-4 space-x-5">
            <button
              onClick={handlSaveProduct}
              className="bg-blue-500 p-2 rounded-md text-white hover:opacity-90"
            >
              {lang ? "Сохранить список" : "Royhatni saqlash"}
            </button>
            <button
              onClick={handleDeleteProducts}
              className="bg-red-500 p-2 rounded-md text-white hover:opacity-90"
            >
              {lang ? "Удалить список" : "Ro'yhatni o'chirish"}

            </button>
          </div>
        </div>
        <div className="w-[48%] bg-white shadow-md border-[1px] border-slate-300 py-4 rounded-md">
          <div className="border-b-2 pb-2">
            <div className="px-4 flex justify-between">
              <button className="border-2 py-0.5 rounded-md w-[48%] bg-slate-300 cursor-not-allowed">{`${starttingDate}  ${startTime}`}</button>
              <div className="relative w-[48%]">
                <DatePicker
                  className="w-full border-slate-400"
                  onChange={handleChangeDatePiccer}
                  defaultValue={dayjs(
                    `${date.getFullYear()}-${(date.getMonth() + 1)
                      .toString()
                      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`,
                    dateFormat
                  )}
                  format={dateFormat}
                />
              </div>
            </div>
            {checkLoading ? (
              <div className="flex items-center justify-center mt-2">
                <img src={Loading} width={20} height={20} alt="Loading..." />
              </div>
            ) : (
              ""
            )}
            <Buttons
              clicked={handleResultDatePost}
              extraSty={"mx-auto mt-2"}
              title={lang ? "Проверка" : "Tekshitish"}
            />
          </div>
          <div className="px-4">
            <h4 className="text-center mt-2">{lang ? "Последнее обновление" : "Oxirgi yangilanish"}</h4>
            <p className="mt-3 mb-2.5">{lang ? "Количество последних продуктов" : "Oxirgi Mahsulotalar soni:"} {lastProductCount}</p>
            <p className="mb-2.5">{lang ? "Количество последних добавленных товаров:" : "Oxirgi Qo'shilgan Mahsulotalar soni:"} {lastProductAdd}</p>
            <p className="">{lang ? "Количество последних удаленных продуктов:" : "Oxirgi O'chirilgan Mahsulotalar soni:"} {lastProductDel}</p>
          </div>
        </div>
      </div>
      <div className="px-5">
        <AddProductsTable getMxikList={getMxikList} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={getMxikTotalPage}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default AddProducts;

import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../Context/Context'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

function BalanceHistory() {
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { lang } = useContext(Context)
  const { id } = useParams()
  const [moreData, setMoreDate] = useState([])
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/balance/history`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId: window.location.pathname.split("/").includes("balance") ? id : 0,
          inn: window.location.pathname.split("/").includes("add-balance") ? id : ""
        },
      })
      .then((res) => {
        setMoreDate(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function formatNumber(number) {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  return (
    <div className='p-5 bg-white'>
      <div>
        <h2 className="font-bold text-[25px] ">{lang ? "История баланса" : "Balance tarixi"}</h2>
        <p>Kim tomonidan{moreData[0]?.status?.status ? " yoqilgan:" : " o'chirilgan:"} <span className='text-[18px] font-semibold'>{moreData[0]?.status?.changedBy == " " ? "-" : moreData[0]?.status?.changedBy}</span></p>
      </div>
      <div className='p-5'>
        <table className="w-full">
          <thead>
            <tr className="font-normal text-slate-400">
              <th className=" text-center text-[15px] font-normal p-2.5">ID</th>
              <th className=" text-center text-[15px] font-normal p-2.5">{lang ? "КАМПАНИЯ" : "KAMPANIYA"}</th>
              <th className=" text-center text-[15px]  font-normal p-2.5">{lang ? "ИНН" : "INN"}</th>
              <th className=" text-center text-[15px]  font-normal p-2.5">{lang ? "ПИНФЛ" : "JSHSHIR"}</th>
              <th className=" text-center text-[15px] font-normal p-2.5">{lang ? "№ УСТРОЙСТВА" : "APARAT NOMER"}</th>
              <th className=" text-center text-[15px] font-normal p-2.5 min-w-[200px]">{lang ? "АБАНЕТ ОПЛАТА" : "ABANET TO'LOV"}</th>
              <th className=" text-center text-[15px] uppercase font-normal p-2.5">{lang ? "Комментарий" : "Komentariya"}</th>
              <th className=" text-center text-[15px] font-normal p-2.5-1.5">{lang ? "ВРЕМЯ ДОЛГА" : "QARZDORLIK SANASI"}</th>
            </tr>
          </thead>

          <tbody>
            {moreData.length > 0 ?
              moreData.map((item) => (
                <tr key={item.id} className={`even:bg-[#FAFAFA] `}>
                  <td className="border-t-[1px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] border-slate-300 text-center  p-2.5">{item?.companyName}</td>
                  <td className="border-t-[1px] border-slate-300 text-center  p-2.5">{item?.companyInn}</td>
                  <td className="border-t-[1px] border-slate-300 text-center  p-2.5">{item?.companyJshshir}</td>
                  <td className={`border-t-[1px] border-slate-300 text-center p-2.5`}>{item?.deviceNumber}</td>
                  <td className={`border-t-[1px] border-slate-300 text-center p-2.5 `}>
                    <span className={`${item?.abonentDebt !== null && item?.abonentDebt !== 0 ? "bg-red-100 p-[5px] rounded-lg text-red-700 font-semibold" : "bg-[#D5F2F1] font-semibold p-[5px] rounded-xl text-[#2b7c79]"}`}>
                      {item?.abonentDebt !== null && item?.abonentDebt !== 0 ? formatNumber(item?.abonentDebt) : formatNumber(item?.abonentPred)}
                    </span>
                  </td>
                  <td className="border-t-[1px] border-slate-300 text-center p-2.5">
                    {item?.comment}
                  </td>
                  <td className="border-t-[1px] border-slate-300 text-center p-2.5">
                    {item?.debtDate?.split("T")[0]}
                  </td>
                </tr>
              ))
              :
              <tr className="font-normal">
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5 min-w-[200px]">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BalanceHistory
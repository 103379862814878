import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Loading from "../../../Assets/Images/loading/loading3.png";
import { Input, Pagination, Select } from 'antd';
import CompanySearch from '../../../components/CompanySearch/CompanySearch';
import ResultDiogram from './ResultDiogram';
import Edit from "../../../Assets/Images/dots.svg";
import useDebounce from '../../../Hook/useDebounce';
function Result() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "DESC",
  };
  // Basic

  // Get By Id start
  const [pollMore, setPollMore] = useState({})
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [pollCompName, setPollCompName] = useState(null)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setPollMore(res.data);
        if (res.data.companyId) {
          axios
            .get(`${env}cabinet/v2/company/${res.data.companyId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setPollCompName(res.data.title);
            })
        }
        if (res.data.telegramId) {
          axios
            .get(`${env}cabinet/v2/contact/select`, {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            })
            .then((res1) => {
              setPhoneNumber(res1.data.find(item => item.telegramId === res.data.telegramId)?.phoneNumber);
            })
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // Get By Id end

  // Question Start
  const [questionList, setQuestionList] = useState([])
  const [questionId, setQuestionId] = useState(null)
  const changeQuestion = (value, name) => {
    setQuestionId(value)
  }
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/question/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pollId: id - 0
        }
      })
      .then((res) => {
        setQuestionList(res.data.map(item => ({ value: item.id, label: lang ? item.questionRu : item.questionUz })));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Question End

  // telegramId start
  const [phoneNumberSearch, setPhoneNumberSearch] = useState(null)
  const changeTelegramId = (value) => {
    setLoading(true)
    setPhoneNumberSearch(value.target.value)
  }
  const searchNumber = useDebounce(phoneNumberSearch, 500)
  // telegramId end

  // Company start
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState("")

  // Company end

  const [resultList, setResultList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/poll/result`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pollId: id - 0,
          phoneNumber: searchNumber,
          companyId: companyId
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setResultList(res.data.listOfItems)
      })
  }, [searchNumber, companyId, pageNum, pageSize])

  // chart Start
  const [chartList, setChartList] = useState([])
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/stat`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          questionId: questionId == null ? 0 : questionId,
        },
      })
      .then((res) => {
        setChartList(res.data);
      })
  }, [questionId])
  // chart end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  return (
    <div className='px-5'>
      <div className='py-5'>
        <h2 className='font-bold text-[16px] sm:text-[25px]'><span className='font-normal text-[14px] sm:text-[18px]'>{lang ? "Опрос:" : "So'rovnoma:"}</span> {pollMore?.titleUz}</h2>
      </div>
      <div className='mb-12 flex flex-col md:flex-row justify-between'>
        <ul className='p-2 flex flex-col space-y-2 border-[1.5px] border-slate-300 w-full md:w-[40%] shadow-md rounded-md'>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Имя Уз" : "Nomi Uz"}</span>
            <p className='text-[16px] sm:text-[18px]'>{pollMore?.titleUz}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Имя Ру" : "Nomi Ru"}</span>
            <p className='text-[16px] sm:text-[18px]'>{pollMore?.titleRu}</p>
          </li>
          {pollMore?.telegramId ?
            <li>
              <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Номер телефона" : "Telefon nomer"}</span>
              <p className='text-[16px] sm:text-[18px]'>{phoneNumber}</p>
            </li>
            :
            <li>
              <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Название компании" : "Kompaniya nomi"}</span>
              <p className='text-[16px] sm:text-[18px]'>{pollCompName}</p>
            </li>
          }

          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Срок" : "Muddati"}</span>
            <p className='text-[16px] sm:text-[18px]'>{pollMore?.expirationDate}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Дата создания" : "Yaratilgan vaqt"}</span>
            <p className='text-[16px] sm:text-[18px]'>{pollMore?.createdAt?.split("T")[0]} - {pollMore?.createdAt?.split("T")[1]?.split(".")[0]}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Дата изменения" : "O'zgartirilgan vaqt"}</span>
            <p className='text-[16px] sm:text-[18px]'>{pollMore?.updatedAt?.split("T")[0]} - {pollMore?.updatedAt?.split("T")[1]?.split(".")[0]}</p>
          </li>
        </ul>
        <div className='w-full relative mt-5 md:mt-0 md:w-[58%]'>
          <label className='w-full inline-block md:w-[350px]'>
            <span className='text-slate-500 text-[14px] md:text-[16px]'>{lang ? "Выберите вопрос" : "Savol tanlang"}</span>
            <Select
              value={questionId}
              style={{ 'inlineSize': `100%` }}
              showSearch
              allowClear
              size="large"
              placeholder={lang ? "Выберите вопрос " : "Savol tanlang "}
              optionFilterProp="children"
              onChange={changeQuestion}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={questionList}
            />
          </label>
          <ResultDiogram chartList={chartList} lang={lang} />
        </div>
      </div>
      <div>
        <div className='flex flex-col md:flex-row mb-5 space-y-4 md:space-y-0 md:space-x-4'>
          <label className='w-full inline-block md:w-[350px]'>
            <span className='text-slate-500 text-[14px] md:text-[16px]'>{lang ? "Выберите номер телефона" : "Telefon raqam tanlang"}</span>
            <Input maxLength={13} onChange={changeTelegramId} allowClear size='large' className='w-full' placeholder={lang ? "Поиск номера телефона" : "Telefon raqam qidirish"} />
          </label>
          <label className='w-full inline-block md:w-[350px]'>
            <span className='text-slate-500 text-[14px] md:text-[16px]'>{lang ? "Выберите организацию" : "Tashkilot tanlang"}</span>
            <CompanySearch letLoading={true} setLoading={setLoading} setCompanyId={setCompanyId} setPageNum={setPageNum} setCompanyName={setCompanyName} value={companyId} width={'100%'} />
          </label>
        </div>
        <div className='hidden lg:block'>
          <table className="w-full">
            <thead>
              <tr className="text-slate-400">
                <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">ID</th>
                <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Название компании" : "Kompaniya nomi"} </th>
                <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Номер клиента" : "Klient raqami"} </th>
                <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5 px-1">{lang ? "Подробно" : "Batafsil"}</th>
              </tr>
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td>
                    {loading ? (
                      <img
                        className="flex mx-auto items-start justify-center"
                        src={Loading}
                        width={60}
                        height={60}
                        alt="Loading Icon"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {resultList.length >= 0 &&
                  resultList.map((item) => (
                    <tr key={item.id} className="even:bg-[#FAFAFA]">
                      <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.id}</td>
                      <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.companyname}</td>
                      <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.clientnumber}</td>
                      <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">
                        <button
                          onClick={(evt) => navigate(`aswers/${item?.telegramid}`)}
                          id={item.id}
                          className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                        >
                          <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
        {/* Mobile start */}
        <ul className="lg:hidden">
          {loading ?
            <div className="flex justify-center">
              <img
                className="flex mx-auto items-start justify-center"
                src={Loading}
                width={70}
                height={70}
                alt="Loading Icon"
              />
            </div>
            :
            resultList.length > 0 && resultList.map(item => (
              <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] text-end font-semibold">ID</span>
                  <span className="w-[50%]">: {item.id}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Опросник" : "So'rovnoma"}</span>
                  <span className="w-[50%]">{item.poll?.split("|")[0]}<br />{item.poll?.split("|")[1]}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Название вопроса" : "Savol nomi"}</span>
                  <span className="w-[50%]">{item.question?.split("|")[0]} <br /> {item.question?.split("|")[1]}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Название ответа" : "Javob nomi"}</span>
                  <span className="w-[50%]">{item.answer}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Название компании" : "Kompaniya nomi"}</span>
                  <span className="w-[50%]">{item.companyname}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Номер клиента" : "Klient raqami"}</span>
                  <span className="w-[50%]">{item.clientnumber}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Время начала опроса" : "So'rovnoma boshlangan vaqti"}</span>
                  <span className="w-[50%]">{item?.startedtime?.split("T")[0]} - {item?.startedtime?.split("T")[1]?.split(".")[0]}</span>
                </div>
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Закончено" : "Tugallangan"}</span>
                  <span className="w-[50%]">{item?.iscompleted ? <span className='text-green-600 font-semibold'>Tugallangan</span> : <span className='text-yellow-600 font-semibold'>Tugallanmagan</span>}</span>
                </div>
              </li>
            ))
          }
        </ul>
        {/* Mobile end */}
      </div>
      <div className="hidden sm:flex justify-center pt-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
      <div className="flex sm:hidden justify-center pt-5">
        <Pagination
          size='small'
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Result
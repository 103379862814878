import React from 'react'
import { Toaster } from 'react-hot-toast'
import { AddBtn } from '../../components/NewBtn/NewBtn'
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Input, Select } from 'antd';
import useDebounce from '../../Hook/useDebounce';
import ResearchTable from '../../components/Table/ResearchTable';

function Research() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false)
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "DESC",
  };
  // Basic

  //  title change start
  const [title, setTitle] = useState("")
  const titleChange = (evt) => {
    setLoading(true)
    setTitle(evt.target.value)
  }
  const searchTitle = useDebounce(title, 300)
  //  title change end

  // telegramId start
  const [selectList, setSelectList] = useState([{ value: "", label: "" }])
  const [telegramId, setTelegramId] = useState(null)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/contact/select`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setSelectList(res.data.map(item => ({ value: item.telegramId, label: item.phoneNumber, disabled: !item.status })))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeTelegramId = (value) => {
    setLoading(true)
    setTelegramId(value)
  }
  // telegramId end

  // Get All users Start
  const [pollList, setPollList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/poll`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          title: searchTitle,
          telegramId: telegramId
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setPollList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, searchTitle, telegramId]);
  // Get All users end
  return (
    <div className='bg-white py-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Опрос" : "So'rovnoma"}</h2>
          <span className="text-slate-400">{totalElement} {lang ? "пользователи" : "foydalanuvchilar"}</span>
        </div>
        <AddBtn onClick={() => navigate("create")} title={lang ? "Добавлять" : "Qo'shish"} />
      </div>
      <div className='flex items-center p-5 space-x-5'>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Поиск по ключевому слову" : "Qidirish kalit so'z"}</span>
          <Input className='w-[350px]' onChange={titleChange} size='large' placeholder={lang ? "Поиск по ключевому слову" : "Qidirish kalit so'z"} />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Выберите номер телефона!" : "Telefon raqam tanlang !"}</span>
          <Select
            value={telegramId}
            style={{ 'inlineSize': `350px` }}
            showSearch
            allowClear
            size="large"
            placeholder={lang ? "Выберите номер телефона!" : "Telefon raqam tanlang !"}
            optionFilterProp="children"
            onChange={changeTelegramId}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={selectList}
          />
        </label>
      </div>
      <div className='px-5'>
        <ResearchTable loading={loading} pollList={pollList} />
      </div>
    </div>
  )
}

export default Research
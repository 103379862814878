import axios from "axios";
import React from "react";
import Buttons from "../../components/Buttons/Buttons";
import { toast, Toaster } from "react-hot-toast";
import { useState } from "react";
import { useContext } from "react";
import { Context } from "../../Context/Context";

function MeasureTable({ unitsList, setRefResh, refResh }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));

  // DevidedSell changes start
  const [selectVal, setSelectVal] = useState();
  const handleDevidedSellChanged = (evt) => {
    setSelectVal(evt.target.value);
  };
  // DevidedSell changes end

  // btn click start
  const handleClickBtn = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/units/update?id=${evt.target.id}&value=${selectVal}`,
        {
          params: {
            id: evt.target.id - 0,
            value: evt.target.value,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefResh(!refResh);
        window.location.reload();
        toast.success("O'zgartirildi");
      })
      .catch((err) => {
        toast.error("Xatolik bor !");
      });
  };
  // btn click end
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full mt-5">
        <thead>
          <tr className="text-slate-400">
            <th className="font-normal uppercase text-[15px] text-center  p-2.5">ID</th>
            <th className="font-normal uppercase text-[15px] text-center  p-2.5">{lang ? "Кириллическое имя" : "Kirilcha nomi"}</th>
            <th className="font-normal uppercase text-[15px] text-center   p-2.5">{lang ? "Узбекское имя" : "O'zbekcha nomi"}</th>
            <th className="font-normal uppercase text-[15px] text-center  p-2.5">{lang ? "Русское имя" : "Ruscha nomi"}</th>
            <th className="font-normal uppercase text-[15px] text-center  p-2.5">{lang ? "Продам в рассрочку" : "Bo'lib sotish"}</th>
            <th className="font-normal uppercase text-[15px] text-center  p-2.5">{lang ? "Подтверждение" : "Tasdiqlash"}</th>
          </tr>
        </thead>
        <tbody>
          {unitsList.length > 0 &&
            unitsList.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA]">
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{item.id}</td>
                <td className="border-t-[1px] border-slate-300 text-center  p-2.5">{item.nameUz}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{item.nameOz}</td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">{item.nameRu}</td>
                <td className="border-t-[1px] border-slate-300 text-center">
                  <select
                    id={item.id}
                    onChange={handleDevidedSellChanged}
                    className="border-2 p-1 cursor-pointer"
                  >
                    {item.dividedSell ? (
                      <>
                        <option value={true}>{lang ? "Возможно" : "Mumkin"}</option>
                        <option value={false}>{lang ? "Невозможно" : "Mumkin emas "}</option>
                      </>
                    ) : (
                      <>
                        <option value={false}>{lang ? "Невозможно" : "Mumkin emas "}</option>
                        <option value={true}>{lang ? "Возможно" : "Mumkin"} </option>
                      </>
                    )}
                  </select>
                </td>
                <td className="border-t-[1px] border-slate-300 text-center p-2.5">
                  <Buttons
                    clicked={handleClickBtn}
                    id={item.id}
                    // bg={"blue"}
                    title={lang ? "Сохранять" : "Saqlash"}
                    extraSty={"w-full py-0.5 mx-auto"}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default MeasureTable;

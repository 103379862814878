import React, { useEffect } from 'react'
import { Select } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import { setRef } from '@mui/material';
function BranchSearch({ setBranchId, companyId, setLoading, setBranchName, value, letLoading, width, setPageNum, setRefresh, refresh }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));

  const onChange = (value, name) => {
    if (setPageNum) {
      setPageNum(1)
    }
    setBranchId(value);
    if (setRefresh) {
      setRefresh(!refresh)
    }
    setBranchName(name?.label)
    if (letLoading) {
      setLoading(true)
    }
  };
  const [branchList, setbranchList] = useState([])

  useEffect(() => {
    if (companyId) {
      axios
        .get(`${env}cabinet/v2/branch/${companyId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setbranchList([...res.data.map(item => ({ value: item.id, label: item.title }))]);
        })
    }
  }, [companyId])

  return (
    <Select
      value={value}
      style={{ 'inlineSize': `${width}` }}
      showSearch
      allowClear
      size="large"
      placeholder={lang ? "Выберите филиал" : "Filial tanlang"}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={branchList}
    />
  )
}

export default BranchSearch
import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import toast, { Toaster } from 'react-hot-toast';

function CashMore() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id } = useParams()
  const [changeStatus, setChangeStatus] = useState(false)
  const clickedActive = () => {
    if (role == "partner") {
      if (data.status) {
        axios
          .put(
            `${env}cabinet/v2/checkout/change-status?id=${Number(id)}`,
            {
              params: {
                id: Number(id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setChangeStatus(!changeStatus)
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
    }
    else {
      axios
        .put(
          `${env}cabinet/v2/checkout/change-status?id=${Number(id)}`,
          {
            params: {
              id: Number(id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setChangeStatus(!changeStatus)
        })
    }

  }

  const goToUpdate = (evt) => {
    navigate(`update/${id}`)
  }


  const [data, setData] = useState({})
  useEffect(() => {
    axios
      .get(
        `${env}cabinet/v2/checkout/get/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeStatus]);

  return (
    <div className='p-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='bg-white rounded-md '>
        <div className='flex p-5 border-b-[1px] border-slate-200 items-center justify-between'>
          <div className=" bg-white flex items-center space-x-6">
            <h2 className='font-bold text-[25px]'>{data.title ? data.title : "-"}</h2>
          </div>
          <div className='flex items-end space-x-3'>
            <div
              onClick={clickedActive}
              className="flex cursor-pointer justify-center py-1 items-center"
            >
              {lang ? "Активный" : "Faol"}
              <ul
                className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${data.status ? "bg-blue-500 " : "bg-slate-300"
                  } rounded-[50px]`}
              >
                <li
                  className={`absolute duration-200 ${data.status ? "translate-x-4" : ""
                    } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                ></li>
              </ul>
            </div>
            {role != "company" &&
              <UpdateBtn id={id} title={lang ? "Редактирование" : 'Tahrirlash'} onClick={goToUpdate} />
            }
          </div>
        </div>
        <div className='flex justify-between p-5'>
          <div className='w-[48%] border-[1px] border-slate-200 shadow-md p-3 rounded-md'>
            <div className='flex flex-col'>
              <span className='text-[13px] text-slate-400'>{lang ? "Имя" : "Nomi"}</span>
              <span className=''>{data.title ? data.title : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Организация" : "Tashkilot"}</span>
              <span className=''>{data.companyName ? data.companyName : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Филиал" : "Fillial"}</span>
              <span className=''>{data.branchName ? data.branchName : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Партнер" : "Hamkor"}</span>
              <span className=''>{data.partnerName ? data.partnerName : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Инн" : "Inn"}</span>
              <span className=''>{data.companyInn ? data.companyInn : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Сам Модуль" : "CAM moduli identifikatori"} </span>
              <span className=''>{data.deviceModule ? data.deviceModule : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Идентификатор устройства" : "Device ID"}</span>
              <span className=''>{data.deviceNumber ? data.deviceNumber : "-"}</span>
            </div>
          </div>
          <div className='w-[48%] border-[1px] border-slate-200 shadow-md p-3 rounded-md'>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Тип касса" : "Kassa turi"}</span>
              <span className=''>
                {data.statusId == 1 && "Faol"}
                {data.statusId == 2 && "Omborda(Cashboxda)"}
                {data.statusId == 3 && "Tamir ostida"}
                {data.statusId == 4 && "Hamkorda(Sotish kutilmoqda)"}
              </span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className='text-[13px] text-slate-400'>{lang ? "Дата регистрации" : "Ro'yxatga olingan sana"} </span>
              <span className=''>{data?.createdAt?.split("T")[0]} - {data?.createdAt?.split("T")[1]?.split(".")[0]}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Сделано" : "Kim tomonidan yaratilgan"}</span>
              <span className="text-[16px] sm:text-[18px]">{data.createdBy ? data.createdBy : "-"}</span>
            </div>
            <div className='flex flex-col mt-5'>
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Обновлено" : "Kim tomonidan o'zgartirilgan"}</span>
              <span className="text-[16px] sm:text-[18px]">{data.updatedBy ? data.updatedBy : "-"}</span>
            </div>
            <div className="flex flex-col mt-5">
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
              <span className="text-[16px] sm:text-[18px]">{data?.createdAt?.split("T")[0]} - {data?.createdAt?.split("T")[1].split(".")[0]}</span>
            </div>
            <div className="flex flex-col mt-5">
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
              <span className="text-[16px] sm:text-[18px]">{data?.updatedAt?.split("T")[0]} - {data?.updatedAt?.split("T")[1].split(".")[0]}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashMore
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { AddBtn } from '../../../components/NewBtn/NewBtn';
import { Checkbox, Input, Select } from 'antd';
import axios from 'axios';
import TextArea from 'antd/es/input/TextArea';

export default function ResearchQuestionAdd() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id, updateId } = useParams()

  // Boglash check start
  const [checkBoglash, setCheckBoglash] = useState(false)
  const boglashCheckChange = (e) => {
    setCheckBoglash(e.target.checked);
  };
  // Boglash check end

  // Prev question start
  const [prevQuesionList, setPrevQuestionList] = useState([])
  const [prevQuestionId, setPrevQuestionId] = useState(null)

  const [prevAnswerList, setPrevAnswerList] = useState([])
  const changePrevQuestion = (value, name) => {
    setPrevQuestionId(value)
    setPrevAnswerId(null)
    setIsErrorQues(false)
    if (value) {
      axios.get(`${env}cabinet/v2/answer/get-by`,
        {
          params: {
            questionId: value
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setPrevAnswerList(res.data?.map(item => ({ value: item.id, label: lang ? item.answerRu : item.answerUz })));
        })
    }
  }
  const [pollMore, setPollMore] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setPollMore(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, lang]);
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/question/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pollId: id - 0
        }
      })
      .then((res) => {
        setPrevQuestionList(res.data.map(item => ({ value: item.id, label: lang ? item.questionRu : item.questionUz })));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, lang]);
  // Prev question end

  // Get orginal number start
  const [ordinalNumber, setOrdinalNumber] = useState(null)
  useEffect(() => {
    if (updateId) {

    }
    else {
      axios
        .get(`${env}cabinet/v2/question/ordinal-number`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            pollId: id - 0,
          }
        })
        .then((res) => {
          setOrdinalNumber(res.data.ordinalNumber);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  // Get orginal number end


  // Prev Answer start
  const [prevAnswerId, setPrevAnswerId] = useState(null)
  const changePrevAnswerChange = (value) => {
    setPrevAnswerId(value)
    setIsErrorQues(false)
    setIsErrorAns(false)
  }
  // Prev Answer end

  // Answer type start
  const [answerTypeList, setAnswerTypeList] = useState([
    {
      value: 0,
      label: lang ? "Без ответа" : "Javobsiz"
    },
    {
      value: 1,
      label: lang ? "Текст" : "Matn"
    },
    {
      value: 2,
      label: lang ? "Кнопка" : "Knopka"
    },
  ])
  useEffect(() => {
    setAnswerTypeList([
      {
        value: 0,
        label: lang ? "Без ответа" : "Javobsiz"
      },
      {
        value: 1,
        label: lang ? "Текст" : "Matn"
      },
      {
        value: 2,
        label: lang ? "Кнопка" : "Knopka"
      },
    ])
  }, [lang])
  const [answerTypeId, setAnswerTypeId] = useState(null)
  const changeAnswerType = (value) => {
    setAnswerTypeId(value)
  }
  // Answer type end

  // Question Content Uz Ru Start
  const [questionUz, setQuestionUz] = useState(null)
  const [questionRu, setQuestionRu] = useState(null)
  // Question Content Uz Ru end

  // Create Question start
  const [isErrorQues, setIsErrorQues] = useState(false)
  const [isErrorAns, setIsErrorAns] = useState(false)
  const handleCreateQuestion = () => {
    const data = {
      pollId: id - 0,
      prevQuestionId: checkBoglash ? prevQuestionId : 0,
      prevAnswerId: checkBoglash ? prevAnswerId : 0,
      questionUz: questionUz,
      questionRu: questionRu,
      answerType: answerTypeId,
      ordinalNumber: checkBoglash ? 0 : ordinalNumber
    }
    if (updateId) {
      data.id = updateId - 0
      if (data.prevQuestionId == null || data.prevAnswerId == null) {
        toast.error(lang ? "Никаких вопросов и ответов не прилагается!" : "Savol yoki javob bog'lanmagan!")
      }
      else {
        axios
          .put(`${env}cabinet/v2/question/edit`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then((res) => {
            toast.success(lang ? "Сохранено" : "Saqlandi")
            setTimeout(() => {
              navigate(-1)
            }, 500)
          })
      }
    }
    else {
      if (data.prevQuestionId == null) {
        setIsErrorQues(true)
        toast.error(lang ? "Нужно связаться с вопросом или отменить контакт" : "Savol bilan bog'lanish kerak yoki bog'lanishni bekor qiling")
      }
      if (data.prevAnswerId == null) {
        setIsErrorAns(true)
        toast.error(lang ? "Ответ должен быть связан или не связан" : "Javob bilan bog'lanish kerak yoki bog'lanishni bekor qiling")
      }
      else {
        if (questionUz != null && questionUz != null) {
          axios
            .post(`${env}cabinet/v2/question/create`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            })
            .then((res) => {
              toast.success(lang ? "Сохранено" : "Saqlandi")
              setTimeout(() => {
                navigate(-1)
              }, 500)
            })
        }
        else {
          toast.error(lang ? "При добавлении нового вопроса информация не заполняется" : "Yangi savol qo'shishda ma'lumotlar to'ldirilmagan")
        }
      }
    }

  }
  // Create Question end

  // Update part start
  useEffect(() => {
    if (updateId) {
      axios
        .get(`${env}cabinet/v2/question/${updateId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          setQuestionUz(res.data.questionUz)
          setQuestionRu(res.data.questionRu)
          setAnswerTypeId(res.data.answerType)
          setOrdinalNumber(res.data.ordinalNumber)
          if (res.data.prevQuestionId == 0 && res.data.prevAnswerId == 0) {
            setCheckBoglash(false)
          }
          else {
            setCheckBoglash(true)
            setPrevQuestionId(res.data.prevQuestionId)

            axios.get(`${env}cabinet/v2/answer/get-by`,
              {
                params: {
                  questionId: res.data.prevQuestionId
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
              .then((res1) => {
                setPrevAnswerList(res1.data?.map(item => ({ value: item.id, label: lang ? item.answerRu : item.answerUz })));
                setPrevAnswerId(res1.data?.map(item => ({ value: item.id, label: lang ? item.answerRu : item.answerUz })).find(item => item.value == res.data.prevAnswerId)?.value)
              })
          }

        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateId]);
  // Update part end
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[20px] ">{lang ? "Опрос" : "Sorovnoma"} : {lang ? pollMore?.titleRu : pollMore?.titleUz}</h2>
          <h2 className="font-bold text-[25px] ">{updateId ? lang ? "Изменить вопрос" : "Savol tahrirlash" : lang ? "Добавить вопрос" : "Savol qo'shish"}</h2>
        </div>
        <AddBtn onClick={handleCreateQuestion} title={updateId ? lang ? "Изменять" : "O'zgartirish" : lang ? "Сохранять" : "Saqlash"} />
      </div>
      <div className='flex justify-between px-8'>
        <div className='w-full flex justify-between'>
          <div className='bg-white w-[49%] shadow-md border-[0.5px] border-slate-200 rounded-md'>
            <div className='p-3 border-b-[0.5px] border-slate-200'>
              <Checkbox checked={checkBoglash ? true : false} onChange={boglashCheckChange}>
                <h2 className='font-semibold text-[18px]'>{lang ? "Привязать к ответу" : "Javob bilan bog'lash"}</h2>
              </Checkbox>
            </div>
            <div className='p-4 flex flex-col space-y-3'>
              {checkBoglash ?
                <>
                  <label>
                    <span className='text-slate-500 text-[16px]'>{lang ? "Выберите вопрос" : "Savol tanlang"}</span>
                    <Select
                      className={isErrorQues ? "border-[1px] border-red-500 rounded-lg" : "border-[1px] border-transparent"}
                      value={prevQuestionId}
                      style={{ 'inlineSize': `100%` }}
                      showSearch
                      allowClear
                      size="large"
                      placeholder={lang ? "Выберите вопрос " : "Savol tanlang "}
                      optionFilterProp="children"
                      onChange={changePrevQuestion}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={prevQuesionList}
                    />
                  </label>
                  <label>
                    <span className='text-slate-500 text-[16px]'>{lang ? "Выберите ответ" : "Javob tanlang"}</span>
                    <Select
                      className={isErrorAns ? "border-[1px] border-red-500 rounded-lg" : "border-[1px] border-transparent"}
                      value={prevAnswerId}
                      style={{ 'inlineSize': `100%` }}
                      showSearch
                      allowClear
                      size="large"
                      placeholder={lang ? "Выберите ответ " : "Javob tanlang "}
                      optionFilterProp="children"
                      onChange={changePrevAnswerChange}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={prevAnswerList}
                    />
                  </label>
                </>
                :
                <p>{lang ? "Вопрос и ответ не связаны" : "Savol va javob bir-biriga bog'lanmagan"}</p>
              }

            </div>
          </div>
          <div className='bg-white w-[49%] shadow-md border-[0.5px] border-slate-200 rounded-md'>
            <div className='p-3 border-b-[0.5px] border-slate-200'>
              <h2 className='font-semibold'>{lang ? "Добавить новый вопрос" : "Yangi savol qo'shish"}</h2>
            </div>
            <div className='p-4 flex flex-col space-y-3'>
              <label>
                <span className='text-slate-500 text-[16px]'>{lang ? "Введите вопрос UZ" : "Savol kiriting UZ"}</span>
                <TextArea value={questionUz} onChange={(evt) => setQuestionUz(evt.target.value)} size='large' placeholder={lang ? "Введите вопрос UZ" : "Savol kiriting UZ"} />
              </label>
              <label>
                <span className='text-slate-500 text-[16px]'>{lang ? "Введите вопрос RU" : "Savol kiriting RU"}</span>
                <TextArea value={questionRu} onChange={(evt) => setQuestionRu(evt.target.value)} size='large' placeholder={lang ? "Введите вопрос RU" : "Savol kiriting RU"} />
              </label>
              <label>
                <span className='text-slate-500 text-[16px]'>{lang ? "Выберите тип ответа" : "Javob turini tanlang"}</span>
                <Select
                  value={answerTypeId}
                  style={{ 'inlineSize': `100%` }}
                  showSearch
                  allowClear
                  size="large"
                  placeholder={lang ? "Выберите тип ответа" : "Javob turi tanlang "}
                  optionFilterProp="children"
                  onChange={changeAnswerType}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={answerTypeList}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import TermTable from "../../components/Table/TermTable";
import { useNavigate } from "react-router-dom";
import { Pagination } from "antd";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../Context/Context"
function Terms() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const termList = JSON.parse(window.localStorage.getItem("termList"));
  const [totalElement, setTotalElement] = React.useState(0);
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  const [termsList, setTermsList] = useState(
    JSON.parse(window.localStorage.getItem("termList")) || []
  );
  const [activeItem, setActiveItem] = useState(false);
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/terms/get`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTermsList(res.data.listOfItems);
        setTotalElement(res.data.totalElements);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
        setTermsList([
          { id: 1, changes: "Xatolik bor !", licence: "Xatolik bor !", updatedAt: "Xatolik bor !" },
        ]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem, pageNum, pageSize]);
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  window.localStorage.setItem("termList", JSON.stringify(termsList));

  return (
    <div className="bg-white">
      <div className=" px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Список терминов" : "Termlar ro'yhati"}</h2>
          <span className="text-slate-400">{termList?.length} {lang ? "Список терминов" : "termlar ro'yhati"}</span>
        </div>
        <AddBtn onClick={() => navigate("add")} title={lang ? "Добавить терм" : "Term qo'shish"} />
      </div>
      <div className="px-5 pb-5">
        <TermTable termsList={termsList} activeItem={activeItem} setActiveItem={setActiveItem} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || termsList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default Terms;

import React from "react";
import { useEffect } from "react";
import { createContext, useState } from "react";

const Context = createContext();

const TokentContext = ({ children }) => {
  const [token, setToken] = useState(JSON.parse(window.localStorage.getItem("token")) || "");
  const [lang, setLang] = useState(JSON.parse(window.localStorage.getItem("lang")) || false)
  const [role, setRole] = useState(JSON.parse(window.localStorage.getItem("role")))
  const [roleId, setRoleId] = useState(JSON.parse(window.localStorage.getItem("roleId")))
  useEffect(() => {
    if (token) {
      window.localStorage.setItem("token", JSON.stringify(token));
      window.localStorage.setItem("lang", JSON.stringify(lang));
      window.localStorage.setItem("role", JSON.stringify(role));
      window.localStorage.setItem("roleId", JSON.stringify(roleId));
    } else {
      window.localStorage.removeItem("token");
    }
  }, [token]);
  return <Context.Provider value={{ token, setToken, lang, setLang, role, setRole, roleId, setRoleId }}>{children}</Context.Provider>;
};

export { Context, TokentContext };

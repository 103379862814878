import React from 'react'
import { Select } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function PartinerSearch({ setPartinerId, setLoading, setField, letLoading, value, width, setPartinerName, setPageNum }) {
  const { lang, role } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));

  const onChange = (value, name) => {
    if (setPageNum) {
      setPageNum(1)
    }
    setPartinerId(value);
    setPartinerName(name?.label);
    if (letLoading) {
      setLoading(true)
    }
    if (setField) {
      setField("partner")
      if (value == undefined) {
        setField("name")
      }
    }
  };
  const [partinerList, setPartinerList] = useState([])
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/partners`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPartinerList([...res.data.map(item => ({ value: item.id, label: item.title }))]);
      })
  }, [])
  return (
    <Select
      value={value}
      disabled={role == "partner" ? true : false}
      style={{ 'inlineSize': `${width}` }}
      allowClear
      showSearch
      size="large"
      placeholder={lang ? "Выберите партнера" : "Hamkor tanlang"}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={partinerList}
    />
  )
}

export default PartinerSearch
import React, { useContext, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { AddBtn } from "../../components/NewBtn/NewBtn"
import { Context } from "../../Context/Context"
import { useNavigate } from 'react-router-dom'
import { Input, Select } from 'antd'
import CompanySearch from "../../components/CompanySearch/CompanySearch"
import axios from 'axios'
function UsersAdd() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));

  const [name, setName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("+998")
  const [companyId, setCompanyId] = useState(null)
  const [companyName, setCompanyName] = useState("")

  const lavozimList = [
    {
      value: true,
      label: "Administrator"
    },
    {
      value: false,
      label: "Foydalanuvchi"
    }
  ]
  const [isAdmin, setIsAdmin] = useState(false)
  const onChange = (value) => {
    setIsAdmin(value)
  }

  // submit start
  const editFile = () => {
    const data = {
      name: name,
      phoneNumber: phoneNumber,
      companyId: isAdmin ? 0 : companyId,
      isAdmin: isAdmin
    }
    if (phoneNumber != "+998" && name || companyId) {
      axios
        .post(`${env}cabinet/v2/contact/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(() => {
          toast.success(lang ? "Информация сохранена" : "Ma'lumot saqlandi !")
          setTimeout(() => {
            navigate(-1)
          }, 900)
        })
        .catch((err) => {
          if (err.response.status === 406) {
            toast.error(lang ? "Этот номер имеется в базе данных" : "Bu raqam bazada mavjud !")
          }
          else {
            toast.error(lang ? "Информация не сохранена" : "Ma'lumot saqlanmadi !")
          }
        });
    }
    else {
      toast.error(lang ? "Данные введены не полностью" : "Ma'lumotlar to'liq kiritilmagan")
    }
  }
  // submit end
  return (
    <div className='bg-white'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="flex items-center border-b-[1px] border-slate-300 justify-between p-4 rounded-md">
        <h2 className="font-bold text-[25px] ">{lang ? "Добавить пользователей" : "Foydalanuvchilar qo'shish"}</h2>
        <AddBtn onClick={editFile} title={lang ? "Сохранять" : "Saqlash"} />
      </div>
      <div className='p-5'>
        <div className='p-5 w-[70%] shadow-md border-[1px] border-slate-300 flex justify-between rounded-md'>
          <div className='w-[48%]'>
            <label className='flex flex-col w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Введите имя" : "Ism kiriting"}</span>
              <Input maxLength={13} value={name} allowClear onChange={(evt) => setName(evt.target.value)} size='large' className='w-[100%]' placeholder={lang ? "Введите имя" : "Ism kiriting"} />
            </label>
            <label className='flex mt-3 flex-col w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Поиск компании" : "Kompaniya qidirish"}</span>
              <CompanySearch isDisalbed={isAdmin} letLoading={false} setCompanyId={setCompanyId} setCompanyName={setCompanyName} width={"100%"} />
            </label>
          </div>
          <div className='w-[48%]'>
            <label className='flex flex-col w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Введите номер телефона" : "Telefon raqam kiriting"}</span>
              <Input maxLength={13} value={phoneNumber} allowClear onChange={(evt) => setPhoneNumber(evt.target.value)} size='large' className='w-[100%]' placeholder={lang ? "Введите номер телефона" : "Telefon raqam kiriting"} />
            </label>
            <label className='flex flex-col mt-3 w-[100%]'>
              <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Выберите позицию" : "Lavozim tanlang"}</span>
              <Select
                style={{ 'inlineSize': `100%` }}
                showSearch
                allowClear
                size="large"
                placeholder={lang ? "Выберите позицию" : "Lavozim tanlang"}
                optionFilterProp="children"
                onChange={onChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={lavozimList}
              />
            </label>
          </div>
        </div>
      </div>

    </div>
  )
}

export default UsersAdd
import React from "react";
import Clear from "../../Assets/Images/x-icon.png";
export const Modal = ({ isVisible, onClose, children, className, glava }) => {
  if (!isVisible) return null;

  const handleClick = (e) => {
    if (e.target.id === "wrapper") onClose();
  };

  return (
    <div
      id="wrapper"
      onClick={handleClick}
      className={`fixed inset-0 bg-[#0000004d] bg-opacity-40 flex justify-center items-center z-50 ${glava}`}
    >
      <div
        className={`bg-white relative w-[500px] p-4 md:p-6 rounded-lg mx-3 md:mx-4 ${className}`}
      >
        <img
          onClick={onClose}
          className="absolute cursor-pointer right-2 top-2"
          src={Clear}
          width={20}
          height={20}
          alt="close modal"
        />
        {children}
      </div>
    </div>
  );
};

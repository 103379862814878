import React, { useContext } from 'react'
import { useParams } from 'react-router-dom';
import { Context } from '../../Context/Context';

function VersionMore() {
  const { lang } = useContext(Context)
  const { id } = useParams()
  const versionList = JSON.parse(window.localStorage.getItem("versionList")).find(item => item.id === Number(id))
  return (
    <div className='p-5'>
      <div className='bg-white rounded-md'>
        <div>
          <h2 className='text-[21px] font-bold p-5 border-b-[1px] border-slate-300'>{versionList.versionNumber}</h2>
        </div>
        <div className='p-5'>
          <div className='flex justify-between'>
            <div className='border-[1px] border-slate-300 rounded-md p-5 w-[30%]'>
              <div>
                <h3 className='text-slate-400'>{lang ? "Дата создания:" : "Yaratilgan sana : "}</h3>
                <p className='font-semibold'>{versionList.createdAt?.split("T")[0]} -- {versionList.createdAt?.split("T")[1]?.split(".")[0]}{" "}</p>
              </div>
              <div className='mt-5'>
                <h3 className='text-slate-400'>{lang ? "Дата изменения:" : "O'zgartirilgan sana :"} </h3>
                <p className='font-semibold'>{versionList.updatedAt?.split("T")[0]} -- {versionList.updatedAt?.split("T")[1]?.split(".")[0]}{" "}</p>
              </div>
              <div className='mt-5'>
                <h3 className='text-slate-400'>{lang ? "Важность:" : "Muhimligi :"} </h3>
                <p className='font-semibold'>{versionList.important ? "Muhim" : "Muhim emas"}</p>
              </div>
              <div className='mt-5'>
                <h3 className='text-slate-400'>{lang ? "Статус" : "Holati :"} </h3>
                <p className='font-semibold'>{versionList.status ? "Active" : "Active emas"}</p>
              </div>
            </div>
            <div className='border-[1px] border-slate-300 rounded-md p-5 w-[69%]'>
              <p>{versionList.title}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VersionMore
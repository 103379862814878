import React from "react";
import Loading from "../../Assets/Images/loading/loading3.png";
import { useState } from "react";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import Edit from "../../Assets/Images/dots.svg";
import { useNavigate } from "react-router-dom";

function ProductsTable({ renderList, loading }) {
  const { lang } = useContext(Context)
  const [activeLang1, setActiveLang1] = useState("O'z")
  const [activeLang2, setActiveLang2] = useState("O'z")
  const navigate = useNavigate()
  return (
    <table className="w-full">
      <thead>
        <tr className="text-slate-400">
          <th className="font-normal text-center text-[16px] uppercase p-2.5">ID</th>
          <th className="font-normal text-center text-[16px] uppercase p-2.5">{lang ? "Мхик" : "Mxik"}</th>
          <th className="font-normal text-center text-[16px] uppercase p-2.5">{lang ? "Имя бренда" : "Brand nomi"} </th>
          <th className="font-normal text-center  text-[16px] uppercase p-2.5">
            {lang ? "Имя Уз" : "Name Uz"}
            <ul className='flex justify-center items-start'>
              <li onClick={() => setActiveLang2("O'z")} className={`text-[14px] ${activeLang2 === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
              <li onClick={() => setActiveLang2("Uz")} className={`text-[14px] ${activeLang2 === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
              <li onClick={() => setActiveLang2("Ru")} className={`text-[14px] ${activeLang2 === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
            </ul>
          </th>
          <th className="font-normal text-center text-[16px]  uppercase p-2.5">
            {lang ? "Атрибут" : "Attribute"}
            <ul className='flex justify-center items-start'>
              <li onClick={() => setActiveLang1("O'z")} className={`text-[14px] ${activeLang1 === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
              <li onClick={() => setActiveLang1("Uz")} className={`text-[14px] ${activeLang1 === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
              <li onClick={() => setActiveLang1("Ru")} className={`text-[14px] ${activeLang1 === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
            </ul>
          </th>
          <th className="font-normal text-center text-[16px] uppercase p-2.5">{lang ? "ПОДРОБНО" : "Batafsil"}</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td>
              <img
                className="flex mx-auto items-start justify-center"
                src={Loading}
                width={60}
                height={60}
                alt="Loading Icon"
              />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {renderList.length > 0 &&
            renderList.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA]">
                <td className="border-t-[1px] border-slate-300 text-[15px] text-center p-2.5 ">{item.id}</td>
                <td className="border-t-[1px] border-slate-300 text-[15px] text-center  p-2.5">{item.mxik}</td>
                <td className="border-t-[1px] border-slate-300 text-[15px] text-center p-2.5">{item.brand}</td>
                <td className="border-t-[1px] border-slate-300 text-[15px] text-center p-2.5">
                  {activeLang2 === "O'z" && item.nameOz}
                  {activeLang2 === "Uz" && item.nameUz}
                  {activeLang2 === "Ru" && item.nameRu}
                </td>
                <td className="border-t-[1px] border-slate-300 text-[15px] text-center p-2.5">
                  {activeLang1 === "O'z" && item.atributeOz}{activeLang1 === "Uz" && item.atributeUz}
                  {activeLang1 === "Ru" && item.atributeRu}
                </td>
                <td id={item.id} className=" border-t-[1px] text-[15px] border-slate-300 relative border-slate-center">
                  <button
                    onClick={() => navigate(`${item.mxik}`)}
                    id={item.id}
                    className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#C2E0FF]"
                  >
                    <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </table>
  );
}

export default ProductsTable;
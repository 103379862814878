import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AddBtn } from "../../components/NewBtn/NewBtn"
import { Input, Pagination } from 'antd';
import MessageTable from '../../components/Table/MessageTable';
import Inputs from "../../components/Inputs/Inputs"
import useDebounce from "../../Hook/useDebounce"
import { useContext } from 'react';
import { Context } from "../../Context/Context"

function Messages() {
  const navigate = useNavigate()
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [changeStatus, setChangeStatus] = useState(false)
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  // Search start
  const [searchVal, setSearchVal] = useState("")
  const messageSearchChange = (evt) => {
    setPageNum(1)
    setLoading(true)
    setSearchVal(evt.target.value)
  }
  const searchWaiting = useDebounce(searchVal, 500)
  // Search end

  const [messageList, setMessageList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/messages`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          searchText: searchWaiting,
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setMessageList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWaiting, changeStatus]);
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  return (
    <div className='bg-white p-5'>
      <div className=" flex items-center justify-between">
        <div>
          <h2 className="font-bold text-[25px]">{lang ? "Сообщения" : "Xabarlar"}</h2>
          <span className="text-slate-500">{totalElement} {lang ? "сообщения" : "xabar"}</span>
        </div>
        <AddBtn onClick={() => navigate('add')} title={lang ? "Добавить" : "Qo'shish"} />
      </div>
      <div className='p-5'>
        <label className='w-[28%] flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}</span>
          <Input onChange={messageSearchChange} size='large' placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} />
        </label>
      </div>
      <div>
        <MessageTable changeStatus={changeStatus} setChangeStatus={setChangeStatus} branchList={messageList} loading={loading} />
      </div>
      <div className="flex justify-center pt-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Messages
import React from "react";
import Loading from "../../Assets/Images/loading/loading3.png";
function LoadingFull() {
  return (
    <div className="fixed z-50 top-0 bottom-0 right-0 left-0 bg-[#00000080] text-white text-[25px] text-center">
      <img
        className="absolute left-0 right-0 mx-auto top-[48%]"
        src={Loading}
        width={65}
        height={65}
        alt="Loading.."
      />
    </div>
  );
}

export default LoadingFull;

import React from "react";

function Inputs({
  productName,
  typeInp,
  inputPlace,
  isReqiure,
  isDisabled,
  noneDis,
  tax,
  handleChange,
  inputVal,
  handleBlur,
  inputRef,
  onFocus,
  handleClick,
  labelClass,
  readOnly,
  onKeyUp,
}) {
  return (
    <label className={`flex items-center font-semibold  ${labelClass}`}>
      <span>{productName}</span>
      <input
        onKeyUp={onKeyUp}
        onClick={handleClick}
        readOnly={readOnly ? true : false}
        ref={inputRef}
        onBlur={handleBlur}
        onChange={handleChange}
        defaultValue={inputVal}
        required={isReqiure}
        className={`py-2 px-2 w-[90%] font-[300] focus:${onFocus} placeholder:text-[#BFBFD4] cursor-pointer ${noneDis} focus:border-blue-500 rounded-[8px] border-[1.75px] border-[#D9D9D9] outline-none`}
        type={typeInp}
        placeholder={inputPlace}
        disabled={isDisabled}
        autoComplete="off"
        max={tax}
      />
    </label>
  );
}

export default Inputs;

import React, { useContext, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import { Context } from '../../Context/Context';
import { AddBtn } from '../../components/NewBtn/NewBtn';
import { Input, Pagination, Select } from 'antd';
import MessageTable from "../../components/Table/TelegramBot/MessageTable"
import { useEffect } from 'react';
import axios from 'axios';
import useDebounce from '../../Hook/useDebounce';
function AllMessage() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false)
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  // Basic

  // Content start
  const [content, setContent] = useState(window.sessionStorage.getItem("telegramGetMessageContent") || "")
  const contentChange = (evt) => {
    setLoading(true)
    setContent(evt.target.value)
  }
  const contentWaiting = useDebounce(content, 400)
  // Content end

  // telegramId start
  const [selectList, setSelectList] = useState([{ value: "", label: "" }])
  const [telegramId, setTelegramId] = useState(JSON.parse(window.sessionStorage.getItem("telegramGetMessageTelegramId")) || null)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/contact/select`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setSelectList(res.data.map(item => ({ value: item.telegramId, label: item.phoneNumber, disabled: !item.status })))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeTelegramId = (value) => {
    setLoading(true)
    setTelegramId(value)
  }
  // telegramId end

  // Get All users Start
  const [messageList, setMessageList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/botMessage`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          content: contentWaiting,
          telegramId: telegramId
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setMessageList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, contentWaiting, telegramId]);
  // Get All users end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end.
  window.sessionStorage.setItem("telegramGetMessageContent", content)
  window.sessionStorage.setItem("telegramGetMessageTelegramId", telegramId ? JSON.stringify(telegramId) : "0")

  return (
    <div className='bg-white py-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Сообщения" : "Xabarlar "}</h2>
          <span className="text-slate-400">{totalElement} {lang ? "сообщения" : "xabar"}</span>
        </div>
        <AddBtn onClick={() => navigate("get")} title={lang ? "Отправить сообщение" : "Xabar jo'natish"} />
      </div>
      <div className='flex px-5 items-center flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-10 mb-5'>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Поиск по ключевому слову" : "Qidirish kalit so'z"}</span>
          <Input className='w-[350px]' size='large' onChange={contentChange} value={content} placeholder={lang ? "Поиск по ключевому слову" : "Qidirish kalit so'z"} />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Выберите номер телефона!" : "Telefon raqam tanlang !"}</span>
          <Select
            value={telegramId}
            style={{ 'inlineSize': `350px` }}
            showSearch
            allowClear
            size="large"
            placeholder={lang ? "Выберите номер телефона!" : "Telefon raqam tanlang !"}
            optionFilterProp="children"
            onChange={changeTelegramId}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={selectList}
          />
        </label>
      </div>
      <MessageTable loading={loading} messageList={messageList} />
      <div className="flex justify-center pt-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default AllMessage
import React, { useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { AddBtn } from '../../components/NewBtn/NewBtn'
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { Checkbox, DatePicker, Input, Select } from 'antd';
import CompanySearch from "../../components/CompanySearch/CompanySearch"
import axios from 'axios';
import dayjs from 'dayjs';
function ResearchAdd() {
  const dateFormat = 'YYYY-MM-DD';
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const date = new Date()
  const { id } = useParams()

  const [titleUz, setTitleUz] = useState(null)
  const [titleRu, setTitleRu] = useState(null)
  const [telegramId, setTelegramId] = useState(null)


  // telegramId start
  const [selectList, setSelectList] = useState([{ value: "", label: "" }])
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/contact/select`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setSelectList([{ value: -1, label: "Hammasi" }].concat(res.data.map(item => ({ value: item.telegramId, label: item.phoneNumber, disabled: !item.status }))))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const changeTelegramId = (value) => {
    setTelegramId(value)
  }
  // telegramId end
  // Company Search Start
  const [companyId, setCompanyId] = useState(null)

  const [companyList, setCompanyList] = useState([])
  const changeCompany = (value) => {
    setCompanyId(value)
  }
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/companies/with-contact`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: ""
        }
      })
      .then((res) => {
        setCompanyList([...res.data.map(item => ({ value: item.id, label: `${item.title} -- ${item.inn}` }))])
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Company Search end

  // Date piccer start
  const [expirationDate, setExpirationDate] = useState(date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, 0) + "-" + date.getDate().toString().padStart(2, 0))
  const datePicerChange = (date, dateString) => {
    setExpirationDate(dateString);
  };
  // Date piccer end

  // Result add start
  const handleAddPoll = () => {
    const data = {
      titleUz: titleUz,
      titleRu: titleRu,
      telegramId: phoneNumberShow ? telegramId : null,
      expirationDate: expirationDate,
      companyId: companyShow ? companyId : null
    }
    if (id) {
      data.id = id
      axios
        .put(`${env}cabinet/v2/poll/edit`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          toast.success(lang ? "Сохранено" : "Saqlandi")
          setTimeout(() => {
            navigate(-1)
          }, 800)
        })
    }
    else {
      if (titleUz && titleRu) {
        axios
          .post(`${env}cabinet/v2/poll/create`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then((res) => {
            toast.success(lang ? "Сохранено" : "Saqlandi")
            setTimeout(() => {
              navigate(-1)
            }, 800)
          })
      }
      else {
        toast.error(lang ? "Информация неполная" : "Ma'lumot to'liq to'ldirilmagan")
      }
    }
  }
  // Result add end

  // check factory start
  const [phoneNumberShow, setPhoneNumberShow] = useState(false)
  const checkPhone = (e) => {
    setPhoneNumberShow(e.target.checked);
    setCompanyShow(false)
    setCompanyId(null)
  }
  const [companyShow, setCompanyShow] = useState(false)
  const checkCompany = (e) => {
    setCompanyShow(e.target.checked);
    setPhoneNumberShow(false)
    setTelegramId(null)
  }
  // check factory end  

  // Update part start
  const [pollMore, setPollMore] = useState({})
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/poll/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then((res) => {
          setPollMore(res.data);
          setTitleUz(res.data.titleUz)
          setTitleRu(res.data.titleRu)
          setExpirationDate(res.data.expirationDate)
          if (res.data.telegramId) {
            setPhoneNumberShow(true)
            setCompanyShow(false)
            setTelegramId(res.data.telegramId)
          }
          if (res.data.companyId) {
            setCompanyShow(true)
            setPhoneNumberShow(false)
            setCompanyId(res.data.companyId)

          }
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  // Update part end

  return (
    <div className='bg-white py-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 border-b-[1px] border-slate-300 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{id ? lang ? "Редактировать опрос" : "So'rovnoma tahrirlash" : lang ? "Добавить опрос" : "So'rovnoma qo'shish"} </h2>
        </div>
        <AddBtn onClick={handleAddPoll} title={lang ? "Сохранять" : "Saqlash"} />
      </div>
      <div className='flex justify-between w-[80%] p-5'>
        <div className='w-[49%] flex flex-col  rounded-md border-[0.5px] border-slate-200 shadow-md'>
          <div className='px-5 py-3 text-[16px] font-semibold border-b-[0.5px] border-slate-200'>
            <h2>{lang ? "Выберите номер телефона или организацию" : "Telefon raqam yoki Tashkilot tanlang"}</h2>
          </div>
          <div className='flex flex-col space-y-3 px-5 py-3'>
            <div>
              <Checkbox checked={phoneNumberShow} className='text-[17px]' onChange={checkPhone}>{lang ? "Выберите номер телефона" : "Telefon raqam tanlash"}</Checkbox>
              {phoneNumberShow &&
                <Select
                  value={telegramId}
                  style={{ 'inlineSize': `100%` }}
                  showSearch
                  allowClear
                  size="large"
                  placeholder={lang ? "Выберите номер телефона!" : "Telefon raqam tanlang !"}
                  optionFilterProp="children"
                  onChange={changeTelegramId}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={selectList}
                />}
            </div>
            <div>
              <Checkbox checked={companyShow} className='text-[17px]' onChange={checkCompany}>{lang ? "Выберите организацию" : "Tashkilot tanlang"}</Checkbox>
              {companyShow &&
                <Select
                  value={companyId}
                  style={{ 'inlineSize': `100%` }}
                  showSearch
                  allowClear
                  size="large"
                  placeholder={lang ? "Выберите организацию" : "Tashkilot tanlang"}
                  optionFilterProp="children"
                  onChange={changeCompany}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={companyList}
                />
              }
            </div>
          </div>
        </div>
        <div className='w-[49%] flex flex-col rounded-md border-[0.5px] border-slate-200 shadow-md' >
          <div className='px-5 py-3 text-[16px] font-semibold border-b-[0.5px] border-slate-200'>
            <h2>{lang ? "Введите название опроса и сроки" : "Sorovnoma nomi va muddatni kiriting"}</h2>
          </div>
          <div className='p-5 flex flex-col space-y-3'>
            <label>
              <span>{lang ? "Имя Уз" : "Nomi Uz"}</span>
              <Input value={titleUz} onChange={(evt) => setTitleUz(evt.target.value)} size='large' placeholder={lang ? "Имя Уз" : "Nomi Uz"} />
            </label>
            <label>
              <span>{lang ? "Имя Ру" : "Nomi Ru"}</span>
              <Input value={titleRu} onChange={(evt) => setTitleRu(evt.target.value)} size='large' placeholder={lang ? "Имя Ру" : "Nomi Ru"} />
            </label>
            <label className='flex flex-col'>
              <span>{lang ? "Выберите срок" : "Muddatini tanlang"}</span>
              <DatePicker defaultValue={dayjs(expirationDate, dateFormat)} inputReadOnly size='large' onChange={datePicerChange} />
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResearchAdd
import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { AddBtn, UpdateBtn } from '../../../components/NewBtn/NewBtn';
import { Modal } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import Edit1 from "../../../Assets/Images/dots.svg"
function ResearchQuestionMore() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id, more } = useParams()
  // Question getbyid start
  const [questionMore, setQuestionMore] = useState({})
  const [pollMore, setPollMore] = useState({})
  const [prevQuestionName, setPrevQuestionName] = useState(null)
  const [prevAnswerName, setPrevAnswerName] = useState(null)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setPollMore(res.data)
        setQuestionMore(res.data.questions.find(item => item.id === more - 0));
        axios
          .get(`${env}cabinet/v2/question/${res.data.questions.find(item => item.id === more - 0)?.prevQuestionId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then((res1) => {
            setPrevQuestionName(res1.data);
          })
        axios
          .get(`${env}cabinet/v2/answer/${res.data.questions.find(item => item.id === more - 0)?.prevAnswerId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then((res2) => {
            setPrevAnswerName(res2.data);
          })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [more]);
  // Question getbyid end

  // Question delete start
  const [deleteModal, setDeleteModal] = useState(false)
  const questionDelete = () => {
    const data = {}
    axios.post(`${env}cabinet/v2/question/delete`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        id: more - 0,
        pollId: id - 0
      }
    })
      .then((res) => {
        toast.success("Muvaffaqiyatli o'chirildi");
        setDeleteModal(false)
        setTimeout(() => {
          navigate(-1)
        }, 500)
      })
  }
  // Question delete end

  // Get answer start
  const [answerList, setAnswerList] = useState([])
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/answer/get-by`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          questionId: more - 0
        }
      })
      .then((res) => {
        setAnswerList(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [more]);
  // Get answer end
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <h2 className="font-bold text-[25px] ">{lang ? "Вопрос" : "Savol"} : {lang ? questionMore?.questionRu : questionMore?.questionUz}</h2>
        <div className='flex items-end space-x-3'>
          <button onClick={() => setDeleteModal(true)} className='bg-red-500 p-1 rounded-md'>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#fff" className="bi bi-trash3" viewBox="0 0 16 16">
              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
            </svg>
          </button>
          <UpdateBtn onClick={(evt) => navigate(`/research/more/${id}/question/edit/${questionMore?.id}`)} title={lang ? "Редактирование" : 'Tahrirlash'} />
        </div>
      </div>
      <div className='p-3 flex justify-between'>
        <div className='w-[49%]'>
          <h2 className='font-semibold text-[18px]'>{lang ? "Информация" : "Ma'lumotlar"}</h2>
          <ul className='p-3 flex flex-col space-y-2 border-[1.5px] border-slate-300  rounded-md'>
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Опросник УЗ" : "Sorovnoma UZ"}</span>
              <p className='text-[18px]'>{pollMore?.titleUz}</p>
            </li>
            <li className='border-b-[1px] pb-3 border-slate-500'>
              <span className='text-slate-400 text-[14px]'>{lang ? "Опросник RU" : "Sorovnoma RU"}</span>
              <p className='text-[18px]'>{pollMore?.titleRu}</p>
            </li>
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Название вопроса УЗ" : "Savol nomi UZ"}</span>
              <p className='text-[18px]'>{questionMore?.questionUz}</p>
            </li>
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Название вопроса RU" : "Savol nomi UZ"}</span>
              <p className='text-[18px]'>{questionMore?.questionRu}</p>
            </li>
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Тип ответа" : "Javob turi"}</span>
              <p className='text-[18px]'>{questionMore?.answerType === 0 ? lang ? "Без ответа" : "Javobsiz" : ""}{questionMore?.answerType === 1 ? lang ? "Текст" : "Matn" : ""}{questionMore?.answerType === 2 ? lang ? "Кнопка" : "Knopka" : ""}</p>
            </li>
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
              <p className='text-[18px]'>{questionMore?.createdAt?.split("T")[0]} - {questionMore?.createdAt?.split("T")[1]?.split(".")[0]}</p>
            </li>
            <li>
              <span className='text-slate-400 text-[14px]'>{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
              <p className='text-[18px]'>{questionMore?.updatedAt?.split("T")[0]} - {questionMore?.updatedAt?.split("T")[1]?.split(".")[0]}</p>
            </li>
          </ul>
        </div>
        {prevAnswerName && prevQuestionName ?
          <div className='w-[49%]'>
            <h2 className='font-semibold text-[18px]'>{lang ? "Соединения" : "Bog'lanishlar"}</h2>
            <ul className='p-3 flex flex-col space-y-2 border-[1.5px] border-slate-300  rounded-md'>
              <li className='flex justify-between'>
                <ul className='w-[49%] flex flex-col space-y-3'>
                  <li>
                    <span className='text-slate-400 text-[14px]'>{lang ? "Название связанного вопроса: УЗ." : "Bog'langan savol nomi UZ"}</span>
                    <p className='text-[18px]'>{prevQuestionName?.questionUz ? prevQuestionName?.questionUz : "-"}</p>
                  </li>
                  <li>
                    <span className='text-slate-400 text-[14px]'>{lang ? "Название связанного вопроса: RU" : "Bog'langan savol nomi RU"}</span>
                    <p className='text-[18px]'>{prevQuestionName?.questionRu ? prevQuestionName?.questionRu : "-"}</p>
                  </li>
                </ul>
                <ul className='w-[49%] flex flex-col space-y-3'>
                  <li>
                    <span className='text-slate-400 text-[14px]'>{lang ? "Название связанного ответ: УЗ." : "Bog'langan javob nomi UZ"}</span>
                    <p className='text-[18px]'>{prevAnswerName?.answerUz ? prevAnswerName?.answerUz : "-"}</p>
                  </li>
                  <li>
                    <span className='text-slate-400 text-[14px]'>{lang ? "Название связанного ответ: RU" : "Bog'langan javob nomi RU"}</span>
                    <p className='text-[18px]'>{prevAnswerName?.answerRu ? prevAnswerName?.answerRu : "-"}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          : ""}

      </div>
      <div className='p-3'>
        <div className='flex justify-end py-1'>
          {questionMore?.answerType == 2 &&
            <AddBtn onClick={() => navigate(`anwer-create`)} title={lang ? "Добавить ответ" : "Javob qo'shish"} />
          }
        </div>
        <table className="w-full">
          <thead>
            <tr className="text-slate-400">
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">ID</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Ответ Уз" : "Javob Uz"}</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Ответ в Ру" : "Javob Ru"}</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Время создания" : "Yaratilgan vaqt"} </th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5 px-1">{lang ? "ПОДРОБНО" : "BARAFSIL"}</th>
            </tr>
          </thead>
          <tbody>
            {answerList?.length > 0 ?
              answerList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.answerUz}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.answerRu}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.createdAt?.split("T")[0]} - {item?.createdAt?.split("T")[1]?.split(".")[0]}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">
                    <button
                      onClick={() => navigate(`answer-more/${item.id}`)}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC] hover:bg-[#a6d3ff]"
                    >
                      <img id={item.id} src={Edit1} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))
              :
              <tr>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">{lang ? "Нет информации" : "Ma'lumot yo'q"}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <Modal
        centered
        open={deleteModal}
        onOk={questionDelete}
        onCancel={() => setDeleteModal(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Удалить вопрос" : "Savolni o'chirish"}</h2>
      </Modal>
    </div>
  )
}

export default ResearchQuestionMore
import React, { useEffect, useRef, useState } from 'react'
import Inputs from '../../components/Inputs/Inputs'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../Hook/useDebounce';
import NotificationAccept from '../../components/Table/NotificationAcceptTable';
import { Pagination } from "antd";
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function NotifAccept() {
    const { lang } = useContext(Context)
    const navigate = useNavigate()
    const env = process.env.REACT_APP_API;
    const token = JSON.parse(window.localStorage.getItem("token"));
    const [loading, setLoading] = useState(false)
    const [totalElement, setTotalElement] = React.useState(0);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    let filterRequest = {
        pageNumber: pageNum,
        pageSize: pageSize,
        sortingField: "id",
        sortDirection: "ASC",
    };
    // Sea
    // Search start
    const titleRef = useRef()
    const [searchVal, setSearchVal] = useState("")
    const searchChange = (evt) => {
        setPageNum(1)
        setSearchVal(evt.target.value)
        setLoading(true)
    }
    const searchDebounce = useDebounce(searchVal, 800)
    // Search end
    const [notificationList, setNotificationList] = useState([])
    useEffect(() => {
        axios
            .post(`${env}cabinet/v2/notification`, filterRequest, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    title: searchDebounce,
                    userId: JSON.parse(window.localStorage.getItem("userId"))
                },
            })
            .then((res) => {
                setNotificationList(res.data.listOfItems);
                setTotalElement(res.data.totalElements);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                if (err.response.status === 401) {
                    navigate('/');
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.location.reload();
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchDebounce, pageNum, pageSize]);

    // Pagination Start
    const onChange = (pageNumber, pageFullSize) => {
        setPageNum(pageNumber);
        setPageSize(pageFullSize);
    };
    // Pagination end
    return (
        <div className=''>
            <div className='bg-white rounded-md'>
                <div className='p-5'>
                    <h2 className='font-bold text-[25px]'>{lang ? "Принял" : "Qabul qilingan"}</h2>
                    <span className="text-slate-400">{totalElement} {lang ? "принял" : "qabul qilingan"}</span>
                </div>
                <div className='p-5'>
                    <div className='w-[22%]'>
                        <span className='text-[15px] font-semibold'>{lang ? "Поиск" : "Qidirish"}</span>
                        <Inputs handleChange={searchChange} inputRef={titleRef} inputPlace={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"} />
                    </div>
                </div>
                <div className='px-5'>
                    <NotificationAccept notificationList={notificationList} loading={loading} />
                </div>
                <div className="flex justify-center py-5">
                    <Pagination
                        showQuickJumper
                        defaultCurrent={pageNum}
                        defaultPageSize={pageSize}
                        total={totalElement || notificationList.length > 0 ? totalElement : 1}
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default NotifAccept
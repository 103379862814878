import { DatePicker, Input, Modal, Pagination, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import UploadIcon from "../../Assets/upload-file.svg";
import Buttons from '../../components/Buttons/Buttons';
import axios from 'axios';
import BalanceTable from '../../components/Table/BalanceTable';
import CompanySearch from '../../components/CompanySearch/CompanySearch';
import { Toaster, toast } from 'react-hot-toast';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';

function AddBalance() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  // Basic
  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sertingField, setSertingField] = useState("id")
  const [sertingDirection, setSertingDirection] = useState("ASC")


  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: sertingField,
    sortDirection: sertingDirection,
  };
  // Basic


  // Choose file start
  let formdata = new FormData();
  const [chooseXL, setChooseXL] = useState(false)
  const [xlFile, setXlFile] = useState(null)
  const [chooseXlFile, setChoosXlFile] = useState("")
  const handleChangeFile = (evt) => {
    setXlFile(evt.target.files[0])
    setChoosXlFile(evt.target.value.split("\\")[2]);
    setChooseXL(false)
  }
  // Choose file end

  // Add XL To Commit start
  const [openModalJonatish, setOpenModalJonatish] = useState(false)
  const [addCommentJonatish, setAddCommentJonatish] = useState(null)
  // Add XL To Commit end

  // Choose File Input start
  const [checkBalance, setCheckBalance] = useState('');

  const handleInputChange = (event) => {
    // Убираем все нецифровые символы из введенного значения
    const cleanedValue = event.target.value.replace(/\D/g, '');

    // Преобразуем отформатированное значение обратно в числовой тип данных
    const numericValue = parseInt(cleanedValue, 10);

    // Проверяем, что значение является числом, прежде чем устанавливать его в state
    if (!isNaN(numericValue)) {
      setCheckBalance(numericValue);
    }
  };
  // Choose File Input end

  // Check file start
  const [changeFile, setChangeFile] = useState(false)
  const [startCounter, setStartCounter] = useState(false)
  const [checkCommentErr, setCheckCommentErr] = useState(false)
  const handleSubmitFile = () => {
    if (xlFile) {
      if (addCommentJonatish) {
        formdata.append("file", xlFile)
        setLoading(true)
        setOpenModalJonatish(false)
        toast.success("Biroz vaqt kuting va sahifani tark etmang !")
        axios
          .post(`${env}cabinet/v2/balance/save`, formdata, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              comment: addCommentJonatish,
              checkBalance: checkBalance
            }
          })
          .then((res) => {
            toast.success("Ma'lumotlar o'zgartirildi !")
            setChangeFile(!changeFile)
            setXlFile(null)
            setChoosXlFile("")
            setChooseXL(false)
            setStartCounter(true)
            setCheckBalance(null)
          })
          .catch(err => {
            if (err.response.status === 500) {
              toast.error("Xatolik bor !")
            }
          })

      }
      else {
        toast.error(lang ? "Требуется комментарий" : "Komentariya yozish shart")
        setCheckCommentErr(true)
        setCheckBalance(null)
      }
    }
    else {
      setOpenModalJonatish(false)
      toast.error(lang ? "Выберите файл!" : "File tanlang!")
      setChooseXL(true)
      setCheckBalance(null)
    }

  }
  // Check file end

  // Device number start
  const deviceSearchChange = (evt) => {
    setPageNum(1)
    setSearchText(evt.target.value)
    setSearchField("deviceNumber")
    setLoading(true)
    if (evt.target.value === "") {
      setSearchField("inn")
      setSertingDirection("ASC")
      setSertingField("id")
      setSearchField("inn")
    }
    else {
      setSearchField("deviceNumber")
      setSertingDirection("DESC")
      setSertingField("createdAt")
    }
  }
  // Device number end

  // INN number start
  const innChangeSerach = (evt) => {
    setPageNum(1)
    setSearchText(evt.target.value)
    setSearchField("inn")
    setSearchText(evt.target.value)
    setSearchField("inn")
    setLoading(true)
    if (evt.target.value === "") {
      setSertingDirection("ASC")
      setSertingField("id")
    }
    else {
      setSertingDirection("DESC")
      setSertingField("createdAt")
    }

  }
  // INN number end

  // Delete data start
  const dateFormat = 'YYYY/MM/DD';
  const [open, setOpen] = useState(false);
  const data = new Date()
  const [getData, setGetData] = useState(data.getFullYear() + "-" + (data.getMonth() + 1)?.toString().padStart(2, 0) + "-" + data.getDate()?.toString().padStart(2, 0))
  const handleChnageData = (evt) => {
    setGetData(evt.$y + "-" + (evt.$M + 1)?.toString().padStart(2, 0) + "-" + evt.$D?.toString().padStart(2, 0))
  }
  const [changeAfterDate, setChnageAfterData] = useState(false)
  const handleDelToken = () => {
    const data = {}
    axios
      .post(`${env}cabinet/v2/balance/delete`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          date: getData
        },
      })
      .then((res) => {
        setOpen(false)
        setLoading(true)
        setChnageAfterData(!changeAfterDate)
      })
  }
  // Delete data end

  // Get All Start
  const [isActive, setIsActive] = useState(false)

  const [balanceList, setBalanceList] = useState([])
  const [changeComment, setChangeComment] = useState(false)

  const [searchField, setSearchField] = useState(role != "company" ? "inn" : "companyId")
  const [balanceOrder, setBalanceOrder] = useState(0)
  const [searchText, setSearchText] = useState(role != "company" ? "" : roleId)
  const [statusOrder, setStatusOrder] = useState(0)


  // Balance statistic start
  const [balanceStatistic, setBalanceStatistic] = useState({})
  useEffect(() => {
    axios.get(`${env}cabinet/v2/balance/get-stat`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        isError: true,
        partnerId: role == "partner" ? roleId : 0
      }
    }).then(res => {
      setBalanceStatistic(res.data);
    })
  }, [])
  // Balance statistic end  

  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/balance`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          searchField: searchField,
          searchText: searchText ? searchText : "",
          statusOrder: 0,
          balanceOrder: balanceOrder,
          isError: true,
          partnerId: role == "partner" ? roleId : 0
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setBalanceList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, searchText, balanceOrder, changeFile, isActive, changeAfterDate, startCounter, changeComment]);
  // Get All end
  const clickedActive = () => {
    setLoading(true)
    const data = {}
    axios
      .post(`${env}cabinet/v2/balance/change-error`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setTimeout(() => {
          toast.success(lang ? "Данные изменены!" : "Ma'lumotlar o'zgartirildi !")
          setIsActive(!isActive)
        }, 3000)
      })
  }

  window.localStorage.setItem("addBalanceSearchField", searchField)
  window.localStorage.setItem("addBalanceSearchText", searchText)
  window.localStorage.setItem("addBalanceStatusOrder", statusOrder)
  window.localStorage.setItem("addBalanceBalanceOrder", balanceOrder)

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end

  const [timer, setTimer] = useState(60)
  useEffect(() => {
    if (startCounter) {
      setTimeout(() => {
        if (timer > 0) {
          setTimer(timer - 1)
        }
        if (timer == 0) {
          setStartCounter(false)
          setTimer(null)
        }
      }, 1000)
    }
  }, [timer, startCounter])


  return (
    <div className='bg-white add-balance'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between rounded-md">
        <div className='flex items-end space-x-14'>
          <div>
            <h2 className="font-bold text-[25px] ">{lang ? "Добавить баланс" : "Balans qo'shish"}</h2>
            <span className="text-slate-400">{totalElement} {lang ? "балансовый список" : "balans ro'yhati"}</span>
          </div>
          {role != "company" &&
            <div className='px-5 flex items-end space-x-14'>
              <div className='space-y-1'>
                <p>Qarzdorlar : {balanceStatistic.debtCount ? balanceStatistic.debtCount : 0} ta</p>
                <p>Predoplata: {balanceStatistic.predCount ? balanceStatistic.predCount : 0} ta</p>
              </div>
              <p>Qarzdor bo'lmaganlar : {balanceStatistic.noDebtCount ? balanceStatistic.noDebtCount : 0} ta</p>
            </div>
          }
        </div>
        <div className='flex flex-col items-end'>
          <div className="mt-3 flex  items-center space-x-5">
            <label className={`flex ${startCounter ? "cursor-not-allowed opacity-20 hover:opacity-20" : ""} items-center ${chooseXL ? "border-red-500" : "border-transparent"} border-[2px] space-x-1  justify-center p-2 w-[170px] rounded-md ${chooseXL ? "text-red-500 bg-blue-300 font-semibold" : "text-white bg-blue-500"}  cursor-pointer hover:opacity-90`}>
              <input accept='.xlsx' onChange={handleChangeFile} className="visually-hidden" type={startCounter ? "button" : "file"} />
              {lang ? "Выберите файл" : "File tanlang"}
              <div className="flex justify-center">
                <img className="mx-auto" src={UploadIcon} width={28} height={28} alt="Upload img" />
              </div>
            </label>
            <Buttons extraSty={`${startCounter ? "cursor-not-allowed opacity-20 hover:opacity-20" : ""}`} clicked={() => setOpenModalJonatish(true)} title={lang ? "Отправка" : "Jonatish"} />
          </div>
          <span className='mt-1'>{chooseXlFile}</span>
          <span>{startCounter ? `00:` + timer?.toString().padStart(2, 0) : ""}</span>
        </div>
      </div>
      <div className='p-5 flex items-end justify-between'>
        <div className='flex items-end space-x-16'>
          <label className='flex flex-col'>
            <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "ИНН номер" : "INN nomer"}</span>
            <Input allowClear value={searchField == "inn" ? searchText : null} onChange={innChangeSerach} className='w-[350px]' placeholder={lang ? "ИНН номер" : "INN nomer"} size='large' />
          </label>
          <label className='flex flex-col'>
            <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "Поиск номера устройства" : "Aparat nomer qidirish"}</span>
            <Input allowClear value={searchField == "deviceNumber" ? searchText : null} onChange={deviceSearchChange} className='w-[350px]' placeholder={lang ? "Номер устройства" : "Aparat nomer"} size='large' />
          </label>
        </div>
        <div className='flex items-end space-x-16'>
          <Buttons bg={"blue"} clicked={clickedActive} title={lang ? "Проверять" : "Tekshirish"} />
          <Buttons bg={'red'} clicked={() => setOpen(true)} title={lang ? "Удалить" : "O'chirish"} />
        </div>
      </div>
      <div className='px-5'>
        <BalanceTable balanceOrder={balanceOrder} setStatusOrder={setStatusOrder} setBalanceOrder={setBalanceOrder} setChangeComment={setChangeComment} changeComment={changeComment} balanceList={balanceList} loading={loading} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || balanceList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
      <Modal
        centered
        open={open}
        onOk={handleDelToken}
        onCancel={() => setOpen(false)}
        width={600}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Выберите дату!" : "Sana tanlang !"}</h2>
        <DatePicker inputReadOnly className='w-[350px] mt-5' size='large' onChange={handleChnageData} defaultValue={dayjs(getData, dateFormat)} format={dateFormat} />
      </Modal>
      <Modal
        centered
        open={openModalJonatish}
        onOk={handleSubmitFile}
        onCancel={() => {
          setOpenModalJonatish(false)
          setCheckBalance(null)
        }}
        width={600}
      >
        <h2 className='font-semibold text-[22px] mb-2'>{lang ? "Написать комментарий" : "Komentariya yozing"}</h2>
        <TextArea className={`${checkCommentErr ? "border-[0.5px] border-red-500" : ""}`} onChange={(evt) => setAddCommentJonatish(evt.target.value)} rows={5} size='large' placeholder={lang ? "Написать комментарий" : "Komentariya yozing"} />
        <label className='mt-3 inline-block w-full'>
          <span className='text-slate-400 pl-2 mb-1'>{lang ? "Введите сумму вывода" : "O'chirish summasini kiriting"}</span>
          <Input
            type="text"
            id="userInput"
            value={new Intl.NumberFormat('ru-RU').format(checkBalance)}
            onChange={handleInputChange}
            size='large'
            allowClear
            placeholder={lang ? "Введите сумму вывода" : "O'chirish summasini kiriting"}
          />
        </label>
      </Modal>
    </div>
  )
}

export default AddBalance
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Buttons from "../../components/Buttons/Buttons";
import { UpdateBtn } from "../../components/NewBtn/NewBtn";
import ClientsTable from "./MoreTables/ClientsTable";
import { Pagination } from "antd";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function PartinerMore() {
  const { lang } = useContext(Context)
  const { id } = useParams();
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [data, setData] = useState({});

  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/partner/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData(res.data);
        axios
          .get(`${env}cabinet/v2/regions`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res1) => {
            setRegionList(res1.data);
          });

        axios
          .get(`${env}cabinet/v2/get-all/district?regionId=${res.data.regionId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res2) => {
            setDistric(res2.data);
          });
      })
  }, [])

  // Region Start
  const [regionList, setRegionList] = useState([]);
  const [distric, setDistric] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  // Basic
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(500);
  const requestStatus = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/searchby?partner_id=${id}`, requestStatus, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setClients(res.data.listOfItems);
        setTotalElements(res.data.totalElements);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize]);
  // Region end
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  return (
    <div className="p-3">
      <div className="p-3 bg-white rounded-md">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-5">
            <h2 className="font-bold text-[25px]">{data?.title}</h2>
          </div>
          <UpdateBtn onClick={() => navigate(`/patnerslist/update/1`)} title={lang ? "Редактирование" : "Tahrirlash"} />
        </div>
        <div className="flex items-start justify-between">
          <div className="flex space-x-20 shadow-md border-[1px] rounded-md border-slate-300 w-[48%] p-4">
            <ul className="w-[50%]">
              <li className="flex flex-col mb-2 text-[18px]">
                <span className="text-slate-500 text-[14px]">ID</span>
                {data.id}
              </li>
              <li className="flex flex-col mb-2 text-[18px]">
                <span className="text-slate-500 text-[14px]">{lang ? "Имя" : "Nomi"}</span>
                {data.title}
              </li>
              <li className="flex flex-col mb-2 text-[18px]">
                <span className="text-slate-500 text-[14px]">{lang ? "Юридическое название" : "Yuridik nomi"} </span>
                {data.legalName}
              </li>
              <li className="flex flex-col mb-2 text-[18px]">
                <span className="text-slate-500 text-[14px]">{lang ? "Директор" : "Rahbar"}</span>
                {data.director}
              </li>
              <li className="flex flex-col mb-2 text-[18px]">
                <span className="text-slate-500 text-[14px]">{lang ? "Номер директора" : "Rahbar raqami"} </span>
                {data.phoneNumber}
              </li>
            </ul>
            <ul className="w-[50%]">
              <li className="flex flex-col mb-2 text-[18px]">
                <span className="text-slate-500 text-[14px]">{lang ? "Провинция" : "Viloyat"}</span>
                {regionList.find((item) => item.id === data.regionId)?.title}
              </li>
              <li className="flex flex-col text-[18px]">
                <span className="text-slate-500 text-[14px]">{lang ? "Провинция/Город" : "Viloyat/Shahar"}</span>
                {distric.find((item) => item.id === data.districtId)?.title}
              </li>
              <li className='flex flex-col mt-5'>
                <span className="text-slate-500 sm:text-[14px]">{lang ? "Сделано" : "Kim tomonidan yaratilgan"}</span>
                <span className="text-[16px] sm:text-[18px]">{data?.createdBy ? data?.createdBy : "-"}</span>
              </li>
              <li className='flex flex-col mt-5'>
                <span className="text-slate-500 sm:text-[14px]">{lang ? "Обновлено" : "Kim tomonidan o'zgartirilgan"}</span>
                <span className="text-[16px] sm:text-[18px]">{data?.updatedBy ? data?.updatedBy : "-"}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-5 p-5 rounded-md bg-white">
        <div>
          <h2 className="font-semibold text-[19px]">{lang ? "Список клиентов" : "Klientlar ro'yhati"} </h2>
          <span className="text-slate-400">{totalElements} {lang ? "клиенты" : "klientlar"}</span>
        </div>
        <ClientsTable loading={loading} clients={clients} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElements}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default PartinerMore;

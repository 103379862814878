import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table/Table";
import { Modal, Pagination, Select } from "antd";
import CompanySearch from "../../components/CompanySearch/CompanySearch"
import BranchSearch from "../../components/BranchSearch/BranchSearch"
import toast, { Toaster } from "react-hot-toast";
import { Context } from "../../Context/Context";
import Loading1 from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
const env = process.env.REACT_APP_API;

function AddNew() {
  const navigate = useNavigate();
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { lang, role, roleId } = useContext(Context)
  // Basic
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [facProTotalpage, setFacProTotalpage] = React.useState(10);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  // Basic

  const [letToAdd, setLetToAdd] = React.useState(JSON.parse(window.sessionStorage.getItem("FPAddBtn1")) || false);
  const [letToAdd1, setLetToAdd1] = React.useState(JSON.parse(window.sessionStorage.getItem("FPAddBtn2")) || false);


  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [thirdData, setThidData] = useState([]);


  // Company start
  const [companyId, setCompanyId] = useState(JSON.parse(window.localStorage.getItem("factoryCampanyId")) || role == "company" ? roleId : 0)
  const [companyName, setCompanyName] = useState(window.localStorage.getItem("factoryCompName") || "")
  // Company List click end

  // BRanch start
  const [branchName, setBranchName] = useState(window.localStorage.getItem("factoryBranchName") || "");
  const [branchId, setBranchId] = useState(JSON.parse(window.localStorage.getItem("factoryBranchId")) || 0);



  // Branch End

  // mxik  start
  const [mxikList, setMxikList] = useState([])
  const [mxikName, setMxikName] = useState(window.localStorage.getItem("factoryMxikName") || "")
  useEffect(() => {
    if (branchId) {
      axios
        .post(`${env}cabinet/v2/get/mxik-title/${branchId}`,
          {
            params: {
              id: branchId
            }
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        .then((res) => {
          setMxikList(res.data.map(item => ({ value: item.id, label: `${item.mxik} - ${item.title}` })));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate("/")
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.location.reload();
          }
          if (err.response.status === 500) {
            toast.error("Bu filialga tegishli mahsulot yo'q !")
          }
        });
    }
  }, [branchId])
  const [mxikId, setMxikId] = useState(JSON.parse(window.localStorage.getItem("factoryMxikId")) || 0);
  const mxikChange = (value, name) => {
    setPageNum(1)
    setMxikName(name?.label)
    setMxikId(value);
  };
  // mxik end



  // post Result start
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/branch_products`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          branchId: branchId,
          id: mxikId,
          role: role,
          roleId: roleId,
          partnerId: role == "partner" ? roleId : 0
        }
      })
      .then((res) => {
        setLoading(false)
        setFacProTotalpage(res.data.totalElements);
        setThidData(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate("/")
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, pageNum, pageSize, mxikId, branchId]);

  // post Result end
  useEffect(() => {
    if (branchId > 0 && companyId > 0) {
      setLetToAdd(true)
      setLetToAdd1(true)
    }
    else {
      setLetToAdd(false)
      setLetToAdd1(false)
    }
  }, [branchId, companyId])
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end


  window.sessionStorage.setItem("FPAddBtn1", JSON.stringify(letToAdd))
  window.sessionStorage.setItem("FPAddBtn2", JSON.stringify(letToAdd1))

  window.localStorage.setItem("factoryCampanyId", JSON.stringify(companyId) ? JSON.stringify(companyId) : 0)
  window.localStorage.setItem("factoryCompName", companyName)
  window.localStorage.setItem("factoryBranchId", JSON.stringify(branchId) ? JSON.stringify(branchId) : 0)
  window.localStorage.setItem("factoryBranchName", branchName)
  window.localStorage.setItem("factoryMxikId", JSON.stringify(mxikId) ? JSON.stringify(mxikId) : 0)
  window.localStorage.setItem("factoryMxikName", mxikName)


  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/product/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefresh(!refresh)
        toast.success(lang ? "Измененный" : "O'zgartirildi");
      })
      .catch((err) => {
        if (err.status === 500) {
          toast.error(lang ? "Мхик старый!" : "Mxik eskirgan !");
        }
      });
  }

  // Delete part select start
  const [action, setAction] = useState(null)
  const actionList = role == "partner" ? [
    {
      value: 1,
      label: "Statusni ochirish",
    }
  ] : [
    {
      value: 1,
      label: "Statusni ochirish",
    },
    {
      value: 2,
      label: "O'chirib tashlash",
    },
    {
      value: 3,
      label: "Active bo'lmaganlani o'chirish",
    }
  ]
  const actionChange = (value, action) => {
    setAction(value);
    setOffStatus(true)
  }
  // Delete part select end
  // Off all status start
  const [offStatus, setOffStatus] = useState(false)

  const offStatusOnModal = (evt) => {
    if (action == 1) {
      const data = {}
      axios.post(`${env}cabinet/v2/product/deActivate-all`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          branchId: branchId,
        }
      })
        .then((res) => {
          setRefresh(!refresh)
          toast.success(lang ? "Все товары выключены" : "Barcha maxsulotlar o'chirildi");
        })
    }
    else if (action == 2) {
      const data = {}
      axios.post(`${env}cabinet/v2/product/delete-all`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          branchId: branchId,
        }
      })
        .then((res) => {
          setRefresh(!refresh)
          toast.success(lang ? "Все товары выключены" : "Barcha maxsulotlar o'chirildi");
        })
    }
    else {
      const data = {}
      axios.post(`${env}cabinet/v2/product/delete-notActive`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          branchId: branchId,
        }
      })
        .then((res) => {
          setRefresh(!refresh)
          toast.success(lang ? "Все товары выключены" : "Barcha maxsulotlar o'chirildi");
        })
    }
    setOffStatus(false)
    setAction(null)
  }
  // Off all status end
  return (
    <div className="bg-white">
      <Toaster position="top-center" reverseOrder={false} />
      <div className=" px-5 flex items-center justify-between">
        <div>
          <h2 className="font-bold sm:text-[25px]">{lang ? "Продукция организации" : "Tashkilot mahsulotlari"}</h2>
          <span className="text-[14px] sm:text-[16px] text-slate-400">{facProTotalpage} {lang ? "организации" : "tashkilotlar"}</span>
        </div>
        <button
          onClick={() => {
            if (letToAdd && letToAdd1) {
              navigate("add");
            }
          }}
          className={`text-[14px] sm:text-[16px] border-2 ${letToAdd && letToAdd1
            ? "border-[#124057] bg-[#124057] p-2 rounded-md hover:opacity-80 text-white"
            : "border-slate-200 text-slate-200 p-2 cursor-not-allowed rounded-md hover:bg-slate-200"
            }  hover:text-white`}
        >
          {lang ? "Добавить новое" : "Yangi qo'shish"}
        </button>
      </div>
      <div className="bg-white flex flex-col xl:flex-row items-center space-y-2 xl:space-y-0 xl:space-x-10 px-5 py-3">
        <div className="relative w-full sm:w-[400px]">
          <label className=" flex flex-col">
            <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Название компании" : "Kompaniya nomi"}</span>
            <CompanySearch isDisalbed={role == "company" ? true : false} setPageNum={setPageNum} value={role == "company" ? companyId : companyId === 0 ? null : companyName} letLoading={false} setCompanyId={setCompanyId} setCompanyName={setCompanyName} width={'100%'} />
          </label>
        </div>
        <div className="relative w-full sm:w-[400px]">
          <label className=" flex flex-col ">
            <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Название филиала" : "Filial nomi"}</span>
            <BranchSearch setPageNum={setPageNum} value={branchId === 0 ? null : branchName} companyId={companyId} letLoading={true} setBranchId={setBranchId} setBranchName={setBranchName} setLoading={setLoading} width={'100%'} />
          </label>
        </div>
        <div className={`relative w-full sm:w-[400px] ${role != "company" ? "pr-5" : ""}`}>
          <label className=" flex flex-col">
            <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Мшик | Наименование товара" : "Mxik | Mahsulot nomi"}</span>
            <Select
              value={mxikId === 0 ? null : mxikName}
              style={{ 'inlineSize': "100%" }}
              allowClear
              showSearch
              size="large"
              placeholder={lang ? "Выбери Мхик" : "Mxik tanlang"}
              optionFilterProp="children"
              onChange={mxikChange}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={mxikList}
            />
          </label>
        </div>
        {role != "company" &&
          <div className="relative w-full sm:w-[400px]">
            <label className=" flex flex-col ">
              <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Всё" : "Barchasini"}</span>
              <Select
                disabled={branchId == 0 || branchId == undefined ? true : false}
                value={action}
                style={{ 'inlineSize': `100%` }}
                allowClear
                showSearch
                size="large"
                placeholder={lang ? "Отключения" : "O'chirish amaliyoti"}
                optionFilterProp="children"
                onChange={actionChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={actionList}
              />
            </label>
          </div>}
      </div>
      <div className="hidden xl:block px-5">
        <Table thirdData={thirdData} loading={loading} setRefresh={setRefresh} refresh={refresh} />
      </div>
      {/* Mobile start */}
      <ul className="xl:hidden px-5">
        {loading ?
          <div className="flex justify-center">
            <img
              className="flex mx-auto items-start justify-center"
              src={Loading1}
              width={70}
              height={70}
              alt="Loading Icon"
            />
          </div>
          :
          thirdData.length > 0 && thirdData.map(item => (
            <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] text-end font-semibold">{lang ? "Имя" : "Nomi"}</span>
                <span className="w-[50%]">: {item.title}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "МХИК" : "MXIK"}</span>
                <span className="w-[50%]">{item.mxik}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "Цена" : "Narxi"}</span>
                <span className="w-[50%]">{item.price}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "Количество" : "Miqdori"}</span>
                <span className="w-[50%]">{item.amount}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "Единица измерения" : "O'lchov birligi"}</span>
                <span className="w-[50%]">{item.image}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "Процент" : "Foiz"}</span>
                <span className="w-[50%]">{item.taxPercent}</span>
              </div>
              {role != "company" &&
                <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                  <span className="w-[50%] font-semibold">{lang ? "Статус" : "Status"}</span>
                  <div
                    id={item.id}
                    onClick={clickedActive}
                    className="flex cursor-pointer items-center w-[50%]"
                  >
                    <ul
                      id={item.id}
                      className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                        } rounded-[50px]`}
                    >
                      <li
                        id={item.id}
                        className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                          } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                      ></li>
                    </ul>
                  </div>
                </div>
              }


            </li>
          ))
        }
      </ul>
      {/* Mobile end */}
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={facProTotalpage || thirdData.length > 0 ? facProTotalpage : 1}
          onChange={onChange}
        />
      </div>
      <Modal
        centered
        open={offStatus}
        onOk={offStatusOnModal}
        onCancel={() => {
          setOffStatus(false)
          setAction(null)
        }}
        width={500}
      >
        <h2 className="font-bold text-[22px]">
          {action == 1 ? lang ? "Вы уверены, что хотите удалить товары с неактивным статусом?" : "Siz haqiqatdan ham statusi neaktivlarni o'chirib tashlamoqchimisiz?" : ""}
          {action == 2 ? lang ? "Вы уверены, что хотите удалить все товары?" : "Siz haqiqatdan ham barcha mahsulotlarni o'chirib tashlamoqchimisiz?" : ""}
          {action == 3 ? lang ? "Вы уверены, что хотите отключить статус всех товаров?" : "Siz haqiqatdan ham barcha mahsulotlar statusini o'chirmoqchimisiz?" : ""}
        </h2>
      </Modal>
    </div>
  );
}

export default AddNew;

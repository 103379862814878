import React from "react";
import "./Assets/main.css";
import Authentication from "./Authentication";
import useToken from "./Hook/useToken";
import UnAuthentication from "./UnAuthentication";

function App() {
  const [token] = useToken();
  // const token = true;
  if (token) {
    return <Authentication />;
  } else {
    return <UnAuthentication />;
  }
}

export default App;

import React from "react";
import Buttons from "../../components/Buttons/Buttons";
import SearchInput from "../../components/SearchInput/SearchInput";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
// Pictures
import Loading from "../../Assets/Images/loading/loading3.png";

function CheckMxik() {
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const searchCheckInput = React.useRef();
  const [activeBtn, setActiveBtn] = React.useState(false);
  // searchMxik start
  const [mxikValue, setMxikValue] = React.useState("");
  const checkMxikChange = (evt) => {
    if (evt.target.value.length >= 4) {
      setMxikValue(evt.target.value);
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  };
  // searchMxik end

  // Btn click so get response start
  const [emptyArr, setEmptyArr] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [findedRenderList, setFindedRenderList] = React.useState([]);
  const handleBtnClick = () => {
    if (mxikValue.length >= 4) {
      setLoading(true);
      toast.success("Biroz kuting tekshirilmoqda...");
      axios
        .get(`${env}cabinet/v2/save?getMxik=${mxikValue}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFindedRenderList(res.data);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error("Mxik ochirilgan");
            setEmptyArr([{ id: 1, title: "Malumot yoq" }]);
            setLoading(false);
          }
        });
    }
  };
  // Btn click so get response end
  return (
    <div className="px-5">
      <div className="bg-white mt-5 p-4 rounded-md">
        <Toaster position="top-center" reverseOrder={false} />
        <div className=" flex items-center w-[60%] justify-between">
          <label className="flex items-center">
            Qidirish :
            <SearchInput
              handleRef={searchCheckInput}
              onChange={checkMxikChange}
              width={"ml-2 w-[400px]"}
              pls={"Qidirish uchun kalit so'z"}
            />
            <Buttons
              clicked={handleBtnClick}
              extraSty={`ml-2 ${activeBtn ? "" : "opacity-[0.3] cursor-not-allowed"}`}
              bg={"blue"}
              title={"Qidirsh"}
            />
          </label>
        </div>
      </div>
      <div>
        {loading ? (
          <div className="flex items-center justify-center mt-5">
            <img src={Loading} width={65} height={65} alt="Loading Icon" />
          </div>
        ) : (
          <div className="flex flex-wrap">
            {findedRenderList.length
              ? findedRenderList.map((item) => (
                <div
                  key={item.id}
                  className="bg-white  flex flex-col space-y-2 border-2 border-slate-300 shadow-md w-[450px] mt-4 p-4 rounded-md "
                >
                  <h2>
                    <span className="font-semibold w-[32%] pr-1 inline-block">Nomi : </span>
                    <span className="pl-1 border-l-2">
                      {" "}
                      {item.nameOz} {item.brand && item.brand !== "" ? " | " + item.brand : ""}
                      {item.atributeOz && item.atributeOz !== "" ? " | " + item.atributeOz : ""}
                    </span>
                  </h2>
                  <p>
                    <span className="font-semibold w-[32%] pr-1 inline-block">Mxik raqami :</span>
                    <span className="pl-1 border-l-2">{item.mxik}</span>
                  </p>
                  <p>
                    <span className="font-semibold w-[32%] pr-1 inline-block">Unit :</span>
                    <span className="pl-1 border-l-2">{item.unitId}</span>
                  </p>
                  <p>
                    <span className="font-semibold w-[32%] pr-1 inline-block">Data Matrix:</span>
                    <span className="pl-1 border-l-2">{item.updatedAt?.split("T")[0]}  {item.updatedAt?.split("T")[1]?.split(".")[0]}</span>
                  </p>
                  <p>
                    <span className="font-semibold w-[32%] pr-1 inline-block">Bar Code:</span>
                    <span className="pl-1 border-l-2">{item.barcodes}</span>
                  </p>
                  <p>
                    <span className="font-semibold w-[32%] pr-1 inline-block">Holati:</span>
                    <span className="pl-1 border-l-2">
                      {item.status ? "Active" : "Active emas"}
                    </span>
                  </p>
                </div>
              ))
              : emptyArr.map((item) => (
                <h2 className="text-center mx-auto mt-3 font-semibold text-[20px]" key={item.id}>
                  {item.title}
                </h2>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}
export default CheckMxik;

import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Inputs from "../../components/Inputs/Inputs";
import axios from "axios";
import { Input, Select } from "antd";
import PartinerSearch from "../../components/PartinerSearch/PartinerSearch";
import BranchSearch from "../../components/BranchSearch/BranchSearch";
import CompanySearch from "../../components/CompanySearch/CompanySearch";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from "react";
import { Context } from "../../Context/Context";
function CashsAdd() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id } = useParams()

  // Update Part Start
  const [letBranchInput, setLetBranchInput] = useState("Omborda(Cashboxda)")
  const [statusName, setStatusName] = useState("")
  const [updateDate, setUpdateData] = useState({})
  const [partinerId, setPartinerId] = useState(null)
  const [companyListId, setCompantListId] = useState(null);
  const [branchListId, setBranchListId] = useState(null);
  const [statusId, setStatusId] = useState(null)
  const [partinerName, setPartinerName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [branchName, setBranchName] = useState("")
  useEffect(() => {
    if (id) {
      axios
        .get(
          `${env}cabinet/v2/checkout/get/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUpdateData(res.data);
          setPartinerName(res.data.partnerName)
          setCompanyName(res.data.companyName)
          setBranchName(res.data.branchName)
          if (res.data.statusId === 1) {
            setStatusId(res.data.statusId)
            setLetBranchInput("Faol")
            setStatusName("Faol")
            if (role != "partner") {
              setPartinerId(res.data.partnerId)
            }
            setCompantListId(res.data.companyId)
            setBranchListId(res.data.branchId)
          }
          if (res.data.statusId === 2) {
            setStatusId(res.data.statusId)
            setLetBranchInput("Omborda(Cashboxda)")
            setStatusName("Omborda(Cashboxda)")
          }
          if (res.data.statusId === 3) {
            setStatusId(res.data.statusId)
            setLetBranchInput("Tamir ostida")
            setStatusName("Tamir ostida")
            setPartinerId(res.data.partnerId)
            setCompantListId(res.data.companyId)
            setBranchListId(res.data.branchId)
          }
          if (res.data.statusId === 4) {
            setStatusId(res.data.statusId)
            setLetBranchInput("Hamkorda(Sotish kutilmoqda)")
            setStatusName("Hamkorda(Sotish kutilmoqda)")
            setPartinerId(res.data.partnerId)
            setCompantListId(res.data.companyId)
            setBranchListId(res.data.branchId)
          }

        })
    }
  }, [])
  // Update Part end


  // Status List Click start

  const [statusList, setStatusList] = useState([
    {
      value: 1,
      label: "Faol",
    },
    {
      value: 2,
      label: "Omborda(Cashboxda)",
    },
    {
      value: 3,
      label: "Tamir ostida",
    },
    {
      value: 4,
      label: "Hamkorda(Sotish kutilmoqda)",
    },
  ])
  const statusChange = (value, name) => {
    setStatusId(value);
    setStatusName(name.label)
    setLetBranchInput(name.label)
    if (name.label === "Omborda(Cashboxda)") {
      setPartinerId(null)
      setCompantListId(null)
      setBranchListId(null)
      setPartinerName(null);
      setCompanyName(null);
      setBranchName(null);
    }
  };
  // Status List Click End

  // ADD submit start
  const nameRef = useRef()
  const samModulRef = useRef()
  const deviceRef = useRef()
  const handleCashAddSubmit = (evt) => {
    evt.preventDefault();
    const data = {
      title: nameRef.current.value,
      deviceNumber: deviceRef.current.value,
      deviceModule: samModulRef.current.value,
      branchId: branchListId,
      companyId: companyListId,
      partnerId: partinerId,
      status: statusId - 0
    }

    if (id) {
      data.id = id - 0
      if (companyListId && branchListId) {
        axios
          .put(
            `${env}cabinet/v2/checkout/update`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success("Ma'lumot saqlandi !")
            setTimeout(() => {
              navigate(-1)
            }, 1000)
          })
          .catch((err) => {
            toast.error("Xatolik bor !")
          });
      }
      else {
        toast.error(lang ? "Необходимо выбрать кампанию или филиал!" : "Kampaniya yoki filial tanlash shart!")
      }
    }
    else {
      if (nameRef && samModulRef && deviceRef && statusId) {
        axios
          .post(
            `${env}cabinet/v2/checkout/save`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success("Ma'lumot qo'shildi")
            setTimeout(() => {
              navigate('/organization')
              window.localStorage.removeItem("newFactoryInn")
            }, 1000)
          })
          .catch((err) => {
            toast.error("Xatolik bor !")
          });
      }
      else {
        toast.error(lang ? "Информация не введена" : "Ma'lumot kiritilmagan")
      }
    }
  }
  // ADD submit end

  // Role part start
  useEffect(() => {
    if (role == "partner") {
      setPartinerId(roleId)
      setStatusId(1)
      setLetBranchInput("Faol")
    }
  }, [statusId])
  // Role part end

  useEffect(() => {
    if (!id) {
      if (window.localStorage.getItem("newFactoryInn")) {
        // setInn(window.localStorage.getItem("newFactoryInn"))
        setStatusId(1)
        setLetBranchInput("Faol")
        axios
          .get(`${env}cabinet/v2/get-inn/company`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              idField: role,
              id: roleId,
              inn: window.localStorage.getItem("newFactoryInn")
            }
          })
          .then((res) => {
            setPartinerId(res.data?.company.partnerId)
            setCompantListId(res.data?.company?.id)
          })
      }
    }
  }, [])

  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 border-b-[1px] border-slate-300 pb-5 pt-2 bg-white flex items-end justify-between w-full">
        <div className="flex items-center space-x-6">
          <h2 className="text-[22px] font-bold ">{id ? lang ? "Обновление кассового аппарата" : "Kassa yangilash" : lang ? "Добавить кассового аппарата" : "Kassa qo'shish"}</h2>
        </div>
        <AddBtn title={id ? lang ? "Изменять" : "O'zgartirish" : lang ? "Сохранять" : "Saqlash"} onClick={handleCashAddSubmit} />
      </div>
      <div className="px-5 ">
        <div className="bg-white shadow-md w-[70%] border-[1px] border-slate-300 mt-5 p-5 rounded-md">
          <div className="flex items-start justify-between p-5 w-[100%]">
            <div className="w-[48%]">
              <label className="block mb-4">
                <span className="text-slate-500 pl-1 mb-1 inline-block">{lang ? "Имя" : "Nomi"}</span>
                <Inputs inputVal={id ? updateDate.title : ""} inputRef={nameRef} inputPlace={lang ? "Введите имя кассового аппарата" : "Kassa nomini kiriting"} />
              </label>
              <label className="relative flex flex-col mb-4">
                <span className="text-slate-500 pl-1 mb-1 inline-block">{lang ? "Статусь" : "Holati"}</span>
                <Select
                  value={statusId}
                  style={{ 'inlineSize': "100%" }}
                  allowClear
                  disabled={role == "partner" ? true : false}
                  showSearch
                  size="large"
                  placeholder={lang ? "Выберите тип организации" : "Tashkilot turi tanlang"}
                  optionFilterProp="children"
                  onChange={statusChange}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  options={statusList}
                />
              </label>
              {letBranchInput !== "Omborda(Cashboxda)" &&
                <div className="font-normal mb-4 relative">
                  <span className="text-slate-500 pl-1 mb-1 inline-block">{lang ? "Партнер" : "Hamkor"}</span>
                  <PartinerSearch value={role == "partner" ? partinerId : partinerId} letLoading={false} setPartinerId={setPartinerId} width={'100%'} setPartinerName={setPartinerName} />
                </div>}
              {companyListId !== null &&
                <div className="relative ">
                  <span className="text-slate-500 inline-block mb-1 pl-1">{lang ? "Название филиала" : "Filial nomi"}</span>
                  <BranchSearch value={id && branchName} companyId={companyListId} letLoading={false} setBranchId={setBranchListId} setBranchName={setBranchName} width={'100%'} />
                </div>
              }
            </div>
            <div className="w-[48%]">
              <div className=" mb-4">
                <span className="text-slate-500 inline-block mb-1 pl-1">
                  {lang ? "Сам модуль" : "Sam Modul"}
                </span>
                <Inputs inputVal={id ? updateDate.deviceModule : ""} inputRef={samModulRef} inputPlace={lang ? "Войдите Сам Модуль" : "Sam Modul kiriting"} />
              </div>
              <div className=" mb-4">
                <span className="text-slate-500 inline-block mb-1 pl-1">
                  {lang ? "Номер устройства" : "Apparat nomer"}
                </span>
                <Inputs inputVal={id ? updateDate.deviceNumber : ""} inputRef={deviceRef} inputPlace={lang ? "Введите номер устройства" : "Apparat nomer kiriting"} />
              </div>
              {partinerId !== null &&
                <div className="relative">
                  <span className="text-slate-500 inline-block mb-1 pl-1 ">{lang ? "Название компании" : "Kompaniya nomi"}</span>
                  <CompanySearch setBranchId={setBranchListId} setBranchName={setBranchName} value={companyListId} letLoading={false} setCompanyId={setCompantListId} setCompanyName={setCompanyName} width={'100%'} />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashsAdd;

import React from "react";
import CashboxImg from "../../Assets/Images/cashbox-img.png";
function Main() {
  return (
    <div className="flex items-center chabox-img justify-center mt-10 ">
      <img src={CashboxImg} width={500} height={500} alt="chashbox img" />
    </div>
  );
}

export default Main;

import React from "react";
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import toast, { Toaster } from "react-hot-toast";

function CashsTable({ loading, cashsList, changeStatus, setChangeStatus }) {
  const { lang, role } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const clickedActive = (evt) => {
    if (role == "partner") {
      if (cashsList.find(item => item.id == evt.target.id).status) {
        axios
          .put(
            `${env}cabinet/v2/checkout/change-status?id=${Number(evt.target.id)}`,
            {
              params: {
                id: Number(evt.target.id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setChangeStatus(!changeStatus)
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
    }
    else {
      axios
        .put(
          `${env}cabinet/v2/checkout/change-status?id=${Number(evt.target.id)}`,
          {
            params: {
              id: Number(evt.target.id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setChangeStatus(!changeStatus)
        })
    }

  }
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="font-normal text-slate-400">
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">ID</th>
            <th className=" text-center text-[13px] xl:text-[15px] font-normal  p-2.5">{lang ? "ИМЯ" : "NOMI"}</th>
            <th className=" text-center text-[13px] xl:text-[15px]  font-normal p-2.5">{lang ? "ОРГАНИЗАЦИЯ, ФИЛИАЛ" : "TASHKILOT,FILIAL"}</th>
            <th className=" text-center text-[13px] xl:text-[15px]  font-normal p-2.5">{lang ? "ИНН" : "INN"}</th>
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">{lang ? "САМ МОДУЛЬ" : "SAM MODUL"} </th>
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">{lang ? "№ УСТРОЙСТВА" : "APPARAT NOMER"} </th>
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5-1.5">{lang ? "ВРЕМЯ СОЗДАНИЯ" : "YARATILGAN VAQT"}</th>
            {role != "company" &&
              <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5-1.5">{lang ? "СТАТУСЬ" : "HOLAT"}</th>}
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5-1.5">{lang ? "ПОДРОБНО" : "BATAFSIL"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                {loading ? (
                  <img
                    className="flex mx-auto items-start justify-center"
                    src={Loading}
                    width={60}
                    height={60}
                    alt="Loading Icon"
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {cashsList.length > 0 &&
              cashsList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">{item.title}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">
                    <span>TN : {item.companyName}</span>
                    <br />
                    <span>FN : {item.branchName}</span>
                  </td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">{item.companyInn}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">{item.deviceModule}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">{item.deviceNumber}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">
                    {item.createdAt.split("T")[0]} - {item.createdAt.split("T")[1].split(".")[0]}
                  </td>
                  {role != "company" &&
                    <td className="border-t-[1px] border-slate-300 text-center p-2.5">
                      <div
                        id={item.id}
                        onClick={clickedActive}
                        className="flex cursor-pointer justify-center py-1 items-center"
                      >
                        <ul
                          id={item.id}
                          className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                            } rounded-[50px]`}
                        >
                          <li
                            id={item.id}
                            className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                              } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                          ></li>
                        </ul>
                      </div>
                    </td>}
                  <td className="border-t-[1px] border-slate-300 text-center p-2 relative">
                    <button
                      onClick={() => navigate(`${item.id}`)}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#C2E0FF]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default CashsTable;

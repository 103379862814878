import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useContext } from "react";
import { Context } from "../../Context/Context";

function AllBranchTable({ branchList, loading }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const handleMoreClick = (evt) => {
    navigate(`more/${evt.target.id}`);
  };
  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">ID</th>
            <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Именование" : "Nomlanishi"}</th>
            <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center  p-2.5">{lang ? "Директор" : "Rahbar"}</th>
            <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Юридическое название" : "Yuridik Nomi"} </th>
            <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Телефон директора" : "Rahbar raqami"} </th>
            <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5 px-1">{lang ? "ПОДРОБНО" : "BATAFSIL"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                {loading ? (
                  <img
                    className="flex mx-auto items-start justify-center"
                    src={Loading}
                    width={60}
                    height={60}
                    alt="Loading Icon"
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {branchList.length > 0 &&
              branchList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.title}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.director}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.legalName}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.phoneNumber}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">
                    <button
                      onClick={handleMoreClick}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default AllBranchTable;

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import axios from 'axios'
import { useState } from 'react'
import Buttons from '../../components/Buttons/Buttons'
import { Modal } from "../../components/Modal/Modal";
import { Toaster, toast } from 'react-hot-toast'
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from 'react'
import { Context } from '../../Context/Context'
function UsersMores() {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const { id } = useParams()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [moreList, setMoreList] = useState({})
  const [companyName, setCompanyName] = useState({})
  const [changeStatus, setChangeStatus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const clickedActive = () => {
    axios
      .put(
        `${env}cabinet/v2/user/change-status?id=${Number(id)}`,
        {
          params: {
            id: Number(id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })
  }
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/user/get/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreList(res.data);
        if (res.data.companyId) {
          axios
            .get(`${env}cabinet/v2/company/${res.data.companyId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setCompanyName(res.data);
            })
        }
        if (res.data.partnerId) {
          axios
            .get(`${env}cabinet/v2/partner/${res.data.partnerId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setCompanyName(res.data);
            })
        }
      })
      .catch((err) => {
      });
  }, [changeStatus])

  // Delete user start
  const handleDeleteUser = () => {
    axios
      .post(
        `${env}cabinet/v2/user/delete/${id}`,
        {
          params: {
            id: Number(id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setShowModal(false)
        toast.success("O'chirildi !")
        setTimeout(() => {
          navigate(-1)
        }, 1000)
      })
  }
  // Delete user end
  return (
    <div className=''>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='bg-white rounded-md'>
        <div className='flex border-b-[1px] p-5 border-slate-300 items-end justify-between'>
          <div className='flex items-center space-x-5'>
            <h2 className='text-[22px] font-bold '>{moreList.firstName} {moreList.lastName}</h2>
          </div>
          <div className='flex items-end space-x-3'>
            <div
              onClick={clickedActive}
              className="flex cursor-pointer justify-center py-1 items-center"
            >
              {lang ? "Активный" : "Faol"}
              <ul
                className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${moreList.status ? "bg-blue-500 " : "bg-slate-300"
                  } rounded-[50px]`}
              >
                <li
                  className={`absolute duration-200 ${moreList.status ? "translate-x-4" : ""
                    } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                ></li>
              </ul>
            </div>
            <button onClick={() => setShowModal(true)} className='bg-red-500 p-1 rounded-md'>
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#fff" className="bi bi-trash3" viewBox="0 0 16 16">
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
              </svg>
            </button>
            <UpdateBtn onClick={() => navigate(`/userslist/update/${id}`)} title={lang ? "Редактирование" : 'Tahrirlash'} />
          </div>
        </div>
        <div className='p-5'>
          <div className='p-5 rounded-md shadow-md border-[1px] border-slate-300 w-[70%] flex justify-between space-x-32'>
            <div className='w-[48%]'>
              <div className='flex flex-col'>
                <span className='text-[13px] text-slate-400'>{lang ? "Имя" : "Ism"}</span>
                <span className='text-[19px]'>{moreList.firstName}</span>
              </div>
              <div className='flex flex-col mt-5'>
                <span className='text-[13px] text-slate-400'>{lang ? "Фамилия" : "Familiya"}</span>
                <span className='text-[19px]'>{moreList.lastName}</span>
              </div>
              <div className='flex flex-col mt-5'>
                <span className='text-[13px] text-slate-400'>{lang ? "Позиция" : "Lavozim"}</span>
                <span className='text-[19px]'>{moreList.role === 1 && "Adminstrator"}{moreList.role === 4 && "Xamkor"}{moreList.role === 5 && "Kompaniya"}</span>
              </div>
              <div className='flex flex-col mt-5'>
                <span className='text-[13px] text-slate-400'>{lang ? "Номер телефона" : "Raqam"}</span>
                <span className='text-[19px]'>{moreList.phoneNumber ? moreList.phoneNumber : "Yo'q"}</span>
              </div>
            </div>
            <div className='w-[48%]'>
              <div className='flex flex-col mt-5'>
                <span className='text-[13px] text-slate-400'>{lang ? "Авторизоваться" : "Login"}</span>
                <span className='text-[19px]'>{moreList.username}</span>
              </div>
              {moreList.partnerId &&
                <div className='flex flex-col mt-5'>
                  <span className='text-[13px] text-slate-400'>{lang ? "Партнер" : "Hamkor"}</span>
                  <span className='text-[19px]'>{companyName?.title ? companyName?.title : "-"}</span>
                </div>
              }
              {moreList.companyId &&
                <div className='flex flex-col mt-5'>
                  <span className='text-[13px] text-slate-400'>{lang ? "Кампания" : "Kampaniya"}</span>
                  <span className='text-[19px]'>{companyName?.title ? companyName?.title : "-"}</span>
                </div>
              }
              <div className='flex flex-col mt-5'>
                <span className="text-slate-500 text-[13px]">{lang ? "Сделано" : "Kim tomonidan yaratilgan"}</span>
                <span className="text-[16px] sm:text-[19px]">{moreList.createdBy}</span>
              </div>
              <div className='flex flex-col mt-5'>
                <span className="text-slate-500 text-[13px]">{lang ? "Обновлено" : "Kim tomonidan o'zgartirilgan"}</span>
                <span className="text-[16px] sm:text-[19px]">{moreList.updatedBy}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <h2 className="font-bold text-center text-[20px] text-[#333]">
          {lang ? "Удаление пользователя" : "Foydalanuvchini o'chirish"}
        </h2>
        <div className='flex items-center justify-between mt-5'>
          <Buttons extraSty={'w-[48%]'} clicked={() => setShowModal(false)} title={lang ? "Отмена" : 'Bekor qilish'} />
          <Buttons clicked={handleDeleteUser} extraSty={'w-[48%]'} bg={'blue'} title={lang ? "Выключать" : "O'chirish"} />
        </div>
      </Modal>
    </div>
  )
}

export default UsersMores
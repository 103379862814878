import axios from 'axios';
import React from 'react'
import { useNavigate } from "react-router-dom"
import Loading from "../../Assets/Images/loading/loading3.png"
import Edit from "../../Assets/Images/dots.svg";
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import toast, { Toaster } from 'react-hot-toast';
function EmpliyeeTable({ empData, loading, refResh, setRefResh, statusOrder, setStatusOrder }) {
    const { lang, role } = useContext(Context)
    const env = process.env.REACT_APP_API;
    const token = JSON.parse(window.localStorage.getItem("token"));
    const navigate = useNavigate()
    const clickedActive = (evt) => {
        if (role == "partner") {
            if (empData.find(item => item.id == evt.target.id).isActive) {
                axios
                    .post(
                        `${env}cabinet/v2/employees/change-status?id=${Number(evt.target.id)}`,
                        {
                            params: {
                                id: Number(evt.target.id),
                            },
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((res) => {
                        setRefResh(!refResh)
                        toast.success(lang ? "Сохранено" : "Saqlandi")
                    })
            }
            else {
                toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
            }
        }
        else if (role == "company") {
            toast.error(lang ? "Нет возможности изменить" : "O'zgartirish imkoniyati mavjud emas")
        }
        else {
            axios
                .post(
                    `${env}cabinet/v2/employees/change-status?id=${Number(evt.target.id)}`,
                    {
                        params: {
                            id: Number(evt.target.id),
                        },
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    setRefResh(!refResh)
                    toast.success(lang ? "Измененный" : "O'zgartirildi")
                })
        }
    }
    // More Btn click start
    const goToMoreSection = (evt) => {
        navigate(`${evt.target.id}`)
    }
    // More Btn click end

    // Cange Status Order start
    const handleStatusOrderChange = () => {
        if (statusOrder == 0) {
            setStatusOrder(1)
        }
        if (statusOrder == 1) {
            setStatusOrder(2)
        }
        if (statusOrder == 2) {
            setStatusOrder(0)
        }
    }
    // Cange Status Order end
    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <table className="w-full">
                <thead>
                    <tr className="text-slate-400">
                        <th className="font-normal text-center uppercase text-[13px] xl:text-[16px] p-2.5">ID</th>
                        <th className="font-normal text-center uppercase text-[13px] xl:text-[16px]  p-2.5">{lang ? "Кампания" : "Kampaniya"}</th>
                        <th className="font-normal text-center uppercase text-[13px] xl:text-[16px]  p-2.5">{lang ? "Филиал" : "Filial"}</th>
                        <th className="font-normal text-center uppercase text-[13px] xl:text-[16px] p-2.5">{lang ? "Авторизоваться" : "Login"}</th>
                        <th className="font-normal text-center uppercase text-[13px] xl:text-[16px] p-2.5">{lang ? "Позиция" : "Lavozim"}</th>
                        {role != "company" &&
                            <th onClick={handleStatusOrderChange} className={`hover:scale-110 duration-200 cursor-pointer font-semibold text-center uppercase text-[13px] xl:text-[16px] p-2.5 ${statusOrder == 1 ? "text-green-500" : ""} ${statusOrder == 2 ? "text-red-500" : ""}`}>{lang ? "Статус" : "Holati"}</th>}
                        <th className="font-normal text-center uppercase text-[13px] xl:text-[16px] p-2.5">{lang ? "ПОДРОБНО" : "Batafsil"}</th>
                    </tr>
                </thead>
                {loading ? (
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    className="flex mx-auto items-start justify-center"
                                    src={Loading}
                                    width={60}
                                    height={60}
                                    alt="Loading Icon"
                                />
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {empData.length > 0 ?
                            empData.map((item) => (
                                <tr key={item.id} className="even:bg-[#FAFAFA]">
                                    <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5 ">{item.id}</td>
                                    <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300   p-2.5">
                                        {item.companyName}
                                    </td>
                                    <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300   p-2.5">
                                        {item.branchName}
                                    </td>
                                    <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300  p-2.5">{item.username}</td>
                                    <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">{item.roleNumber === 1 && "Administrator"}{item.roleNumber === 4 && "Xamkor"}{item.roleNumber === 5 && "Kompaniya"}</td>
                                    {role != "company" &&
                                        <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center py-1">
                                            <div
                                                id={item.id}
                                                onClick={clickedActive}
                                                className="flex cursor-pointer justify-center py-1 items-center"
                                            >
                                                <ul
                                                    id={item.id}
                                                    className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.isActive ? "bg-blue-500 " : "bg-slate-300"
                                                        } rounded-[50px]`}
                                                >
                                                    <li
                                                        id={item.id}
                                                        className={`absolute duration-200 ${item.isActive ? "translate-x-4" : ""
                                                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                                                    ></li>
                                                </ul>
                                            </div>
                                        </td>
                                    }
                                    <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center relative border-slate-300  p-2.5">
                                        <button
                                            onClick={goToMoreSection}
                                            id={item.id}
                                            className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                                        >
                                            <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                                        </button>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                                <td className="border-t-[1px] text-[13px] xl:text-[15px] text-center border-slate-300 p-2.5">Ma'lumot yo'q</td>
                            </tr>
                        }
                    </tbody>
                )}
            </table>
        </>
    )
}

export default EmpliyeeTable
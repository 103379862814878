import React, { useContext } from 'react'
import { Toaster } from 'react-hot-toast'
import Edit from "../../Assets/Images/dots.svg";
import Loading from "../../Assets/Images/loading/loading3.png";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../../Context/Context';
function UsersTable({ usersList, loading, changeStatus, setChangeStatus }) {
  const navigate = useNavigate()
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/user/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })
  }
  return (
    <div className="bg-white rounded-md px-5">
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">ID</th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Авторизоваться" : "Login"}</th>
            <th className=" text-center  font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Имя" : "Ism"}</th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Фамилия" : "Familiya"}</th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Роль" : "Rol"}</th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Дата Добавлена" : "Qo'shilgan sana"}</th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Дата последней" : "Oxirgi aktivlik sana"} </th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "Статус" : "Holat"}</th>
            <th className=" text-center font-normal text-[13px] xl:text-[15px] uppercase p-2.5">{lang ? "ПОДРОБНО" : "Batafsil"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                {loading ? (
                  <img
                    className="flex mx-auto items-start justify-center"
                    src={Loading}
                    width={60}
                    height={60}
                    alt="Loading Icon"
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {usersList.length > 0 &&
              usersList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item.username}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item.firstName}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item.lastName}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item.role === 1 && "Administrator"}{item.role === 4 && "Xamkor"}{item.role === 5 && "Kompaniya"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">
                    {item.createdAt.split("T")[0]} - {item.createdAt.split("T")[1].split(".")[0]}
                  </td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">
                    {item.updatedAt.split("T")[0]} - {item.updatedAt.split("T")[1].split(".")[0]}
                  </td>

                  <td className="border-t-[1px] border-slate-300 text-center p-2.5">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center py-1 items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.active ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.active ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td className="border-t-[1px] border-slate-300 text-center relative">
                    <button
                      onClick={(evt) => navigate(`${evt.target.id}`)}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#C2E0FF]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>)}
      </table>
    </div>
  )
}

export default UsersTable
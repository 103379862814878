import React from "react";
function Buttons({ clicked, bg, title, extraSty, id, img, imgIcon, imgClass, btnType }) {
  return (
    <button
      type={btnType}
      id={id}
      onClick={clicked}
      className={`p-2 border-2 ${extraSty} rounded-md flex text-[14px] sm:text-[16px] justify-center items-center ${bg === "blue"
        ? " border-[#3699FF] bg-[#3699FF] text-white hover:opacity-90"
        : bg === "red" ? "border-red-500 bg-red-500 text-white hover:opacity-90" : " border-[#4abe5a] bg-[#4abe5a] text-white hover:opacity-90"
        }`}
    >
      {img ? (
        imgIcon
      ) : (
        ""
      )}
      {title}
    </button>
  );
}


export default Buttons;

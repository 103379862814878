import React from "react";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

function DatePicer({ setCheckedTime, letTime, setOnlyDate }) {
  const handleChangePicer = (evt) => {
    letTime(true);
    setCheckedTime(
      evt.$y +
        "-" +
        (evt.$M + 1).toString().padStart(2, "0") +
        "-" +
        evt.$D.toString().padStart(2, "0")
    );
  };
  return (
    <Space className=" border-2 rounded cursor-pointer" direction="vertical">
      <DatePicker
        className="p-2.5 cursor-pointer border-0 w-[100%]"
        onChange={handleChangePicer}
        format="YYYY-MM-DD HH:mm:ss"
        showTime={{
          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
        }}
      />
    </Space>
  );
}

export default DatePicer;

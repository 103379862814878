import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Inputs from "../../components/Inputs/Inputs";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import PartinerSearch from "../../components/PartinerSearch/PartinerSearch"
import RegionSearch from "../../components/RegionSearch/RegionSearch"
import DistricSearch from "../../components/DistricSearch/DistricSearch"
import { Checkbox, Input, Select } from "antd";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import { AddBtn } from "../../components/NewBtn/NewBtn"
import CompanySearch from "../../components/CompanySearch/CompanySearch"
import TextArea from "antd/es/input/TextArea";
// Pictures

function BranchAdd() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate();
  const { id } = useParams()
  const userIdLog = JSON.parse(window.localStorage.getItem("userId"));
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  //First Refs start

  // Refs end

  const [inn, setInn] = useState(null)
  const [legalName, setLegalName] = useState(null)
  const [title, setTitle] = useState(null)
  const [typeId, setTypeId] = useState(null);
  const [partinerId, setPartinerId] = useState(role == "partner" ? roleId : null);
  const [companyId, setCompanyId] = useState(null)
  const [director, setDirector] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("+998")
  const [accountant, setAccountant] = useState(null)
  const [phoneNumber2, setPhoneNumber2] = useState("+998")
  const [bank, setBank] = useState(null)
  const [bankAccount, setBankAccount] = useState(null)
  const [bankType, setBankType] = useState(null)
  const [bankCode, setBankCode] = useState(null)
  const [regionId, setRegionId] = useState(null);
  const [districId, setDistricId] = useState(null);
  const [address, setAddress] = useState(null)
  const [taxPercent, setTaxPercent] = useState(0)
  // director start
  const [checkDirector, setCheckDirector] = useState(false)
  const [checkBugalter, setCheckBugalter] = useState(false)
  const [checkBank, setCheckBank] = useState(false)
  const [checkQQS, setCheckQQS] = useState(false)
  // director end

  // Inn start
  const [partinerName, setPartinerName] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [regionName, setRegionName] = useState(null)
  const [districName, setDistrictName] = useState(null)

  const handleFillBtnClic = () => { };

  const handleFillBtnClick = () => {
    axios
      .get(`${env}cabinet/v2/get-inn/company?inn=${inn}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          id: roleId,
          idField: role
        }
      })
      .then((res) => {
        if (role != "partner") {
          setPartinerId(res.data.company.partnerId)
        }
        setPartinerName(res.data.partnerName)
        setTypeId(res.data.company.typeId)
        setRegionId(res.data.company.regionId)
        setDistricId(res.data.company.districtId)
        setCompanyId(res.data.company.id)
        setLegalName(res.data.company.legalName)
        setTitle(res.data.company.title)
        setAddress(res.data.company.address)
        if (res.data.company.director || res.data.company.phoneNumber) {
          // setCheckDirector(true)
          setDirector(res.data.company.director)
          setPhoneNumber(res.data.company.phoneNumber)
        }
        if (res.data.company.accountant || res.data.company.phoneNumber2) {
          // setCheckBugalter(true)
          setAccountant(res.data.company.accountant)
          setPhoneNumber2(res.data.company.phoneNumber2)
        }
        if (res.data.company.bank || res.data.company.bankAccount || res.data.company.bankType || res.data.company.bankCode) {
          // setCheckBank(true)
          setBank(res.data.company.bank)
          setBankAccount(res.data.company.bankAccount)
          setBankType(res.data.company.bankType)
          setBankCode(res.data.company.bankCode)
        }
        if (res.data.company.taxPercent) {
          // setCheckQQS(true)
          setTaxPercent(res.data.company.taxPercent)
        }

      })
      .catch((err) => {
        if (err.response?.status === 406) {
          toast.error(lang ? "Эта организация вам не принадлежит" : "Bu tashkilot sizga tegishli emas");
        }
        if (err.response?.status === 418) {
          toast.error(lang ? "Введите существующий ИНН" : "Mavjud INN kiriting");
        }
        if (err.response?.status === 500) {
          toast.error(lang ? "Введите существующий ИНН" : "Mavjud INN kiriting");
        }
      });
  };
  // Inn end

  // Active Click start
  const [activeItem, setActiveItem] = useState(true);
  const clickedActive = () => {
    setActiveItem(!activeItem);
  };
  //   Active Click end

  // Organization type start
  const favList = [
    {
      value: 1,
      label: "Mas'uliyati cheklangan yoki qo'shimcha mas'uliyatli jamiyat",
    },
    {
      value: 2,
      label: "Xususiy korxona",
    },
  ];
  const facChange = (value) => {
    setTypeId(value);
  };
  // Organization type end

  // Update part start
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/branches/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setInn(res.data.inn)
          if (role != "partner") {
            setPartinerId(res.data.partnerId)
          }
          setLegalName(res.data.legalName)
          setTitle(res.data.title)
          setTypeId(res.data.typeId)
          setCheckDirector(res.data.director == "0" && res.data.phoneNumber == "+998" ? false : true)
          setDirector(res.data.director)
          setPhoneNumber(res.data.phoneNumber)
          setCheckBugalter(res.data.accountant == "0" && res.data.phoneNumber2 == "+998" ? false : true)
          setAccountant(res.data.accountant)
          setPhoneNumber2(res.data.phoneNumber2)
          setCheckBank(res.data.bank == "0" ? false : true)
          setBank(res.data.bank)
          setBankAccount(res.data.bankAccount)
          setBankType(res.data.bankType)
          setBankCode(res.data.bankCode)
          setRegionId(res.data.regionId)
          setDistricId(res.data.districtId)
          setAddress(res.data.address)
          setCheckQQS(res.data.taxPercent == 0 ? false : true)
          setTaxPercent(res.data.taxPercent)
          setActiveItem(res.data.active)
          setCompanyId(res.data.companyId)
        })
    }
  }, [id])
  // Update part end

  // Bank Tash soliqqa tortish start
  const handleSubmitResult = (evt) => {
    evt.preventDefault();
    const data = {
      active: activeItem,
      extraTax: true,
      taxPercent: taxPercent,
      title: title,
      inn: inn,
      innKey: null,
      legalName: legalName,
      address: address,
      director: checkDirector ? director : "0",
      accountant: checkBugalter ? accountant : "0",
      phoneNumber: phoneNumber,
      phoneNumber2: phoneNumber2,
      bank: checkBank ? bank : "0",
      bankType: checkBank ? bankType : 0,
      bankCode: checkBank ? bankCode : "0",
      bankAccount: checkBank ? bankAccount : 0,
      latitude: 0,
      longitude: 0,
      createdById: userIdLog,
      districtId: Number(districId),
      partnerId: Number(partinerId),
      regionId: Number(regionId),
      typeId: typeId - 0,
      companyId: companyId - 0,
      updatedById: userIdLog,
      userId: null,
    };
    if (id) {
      data.id = id - 0
      axios
        .put(`${env}cabinet/v2/update/branch`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          toast.success("Saqlandi");
          setTimeout(() => {
            navigate(-1);
          }, [500]);
        })
        .catch((err) => {
          toast.error("Xatolik bor !");
        });
    }
    else {
      axios
        .post(`${env}cabinet/v2/save/branch`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          toast.success("Saqlandi");
          setTimeout(() => {
            navigate('/employeslist/add');
            // window.localStorage.removeItem("newFactoryInn")
          }, [500]);
        })
        .catch((err) => {
          toast.error("Xatolik bor !");
        });
    }
  };
  // Bank Tash soliqqa tortish end

  useEffect(() => {
    if (id) { }
    else {
      if (window.localStorage.getItem("newFactoryInn")) {
        setInn(window.localStorage.getItem("newFactoryInn"))
      }
    }
  }, [])
  window.sessionStorage.setItem("addRegionID", JSON.stringify(regionId));
  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <form onSubmit={handleSubmitResult}>
        <div className="p-5 px-5 border-b-2 flex items-center justify-between">
          <h2 className="font-bold text-[18px] sm:text-[25px]">{id ? lang ? "Редактировать филиал" : "Filial tahrirlash" : lang ? "Добавить филиал" : "Filial qo'shish"}</h2>
          <AddBtn type={"submit"} title={lang ? "Сохранить" : "Saqlash"} />
        </div>
        <div className="p-5" >
          <div className="flex flex-col md:flex-row justify-between">
            <div className="w-full md:w-[60%]">
              <div className="bg-white shadow-md mb-4 border-[1px] border-slate-300 rounded-md">
                <div className="p-3 border-b-2 flex items-center justify-between">
                  <h2 className="font-semibold text-[16px] sm:text-[18px]">{lang ? "Легальная информация" : "Yuridik ma'lumotlar"}</h2>
                  <button
                    type="button"
                    onClick={inn?.length >= 9 ? handleFillBtnClick : handleFillBtnClic}
                    className={`bg-[#124057] text-[14px] sm:text-[16px] ${inn?.length >= 9 ? "hover:opacity-90" : "opacity-20 cursor-not-allowed"
                      } text-white p-2 rounded-md`}
                  >
                    {lang ? "Наполнение" : "To'ldirish"}
                  </button>
                </div>
                <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                  <label className="w-full mb-3 sm:mb-0 sm:w-[48%]">
                    <span className="text-slate-500 inline-block mb-1 text-[14px] sm:text-[16px] pl-1">{lang ? "СТИР" : "STIR"}</span>
                    <Input allowClear required minLength={9} className="w-full" type="number" value={inn} onChange={(evt) => setInn(evt.target.value)} placeholder={lang ? "ИНН" : "STIR"} size="large" />
                  </label>
                  <label className="w-full sm:w-[48%] flex flex-col relative">
                    <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Партнер" : "Xamkor"}</span>
                    <PartinerSearch value={partinerId} setPartinerName={setPartinerName} letLoading={false} setPartinerId={setPartinerId} width={"100%"} />
                  </label>
                </div>
                <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                  <label className="w-full sm:w-[48%]">
                    <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1"> {lang ? "Именование" : "Nomlanishi"}</span>
                    <Input onChange={(evt) => setLegalName(evt.target.value)} required value={legalName} className="w-full" allowClear size="large" placeholder={lang ? "Именование" : "Nomlanishi"} />
                  </label>
                  <label className="w-full mt-3 sm:mt-0 sm:w-[48%]">
                    <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Юридическое название" : "Yuridik nomi"}</span>
                    <Input className="w-full" value={title} onChange={(evt) => setTitle(evt.target.value)} required allowClear size="large" placeholder={lang ? "Юридическое название" : "Yuridik nomi"} />
                  </label>
                </div>
                <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                  <label className="relative w-full sm:w-[48%]">
                    <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Тип организации" : "Tashkilot turi"}</span>
                    <Select
                      style={{ 'inlineSize': "100%" }}
                      allowClear
                      showSearch
                      size="large"
                      placeholder={lang ? "Выберите тип организации" : "Tashkilot turi tanlang"}
                      optionFilterProp="children"
                      onChange={facChange}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={favList}
                      value={typeId}
                    />
                  </label>
                  <label className="w-full mt-3 sm:mt-0 sm:w-[48%]">
                    <span className="text-slate-500 text-[14px] sm:text-[16px] inline-block mb-1 pl-1">{lang ? "Выберите организацию" : "Tashkilot tanlang"}</span>
                    <CompanySearch letLoading={false} setCompanyName={setCompanyName} setCompanyId={setCompanyId} value={companyId} width={'100%'} />
                  </label>
                </div>
              </div>
              <div className="bg-white border-[1px] border-slate-300 shadow-md mt-5 rounded-md">
                <div className={`p-3 ${checkDirector && "border-b-2"}`}>
                  <Checkbox checked={checkDirector} className="w-full" onChange={(e) => setCheckDirector(e.target.checked)}>
                    <h2 className="font-semibold text-[18px]">{lang ? "Информация о директоре" : "Rahbar haqida ma'lumot"}</h2>
                  </Checkbox>
                </div>
                {checkDirector ?
                  <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                    <label className="w-full sm:w-[48%]">
                      <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Полное имя директора" : "Rahbarning to'liq ismi"}</span>
                      <Input className="w-full" allowClear value={director} onChange={(evt) => setDirector(evt.target.value)} placeholder={lang ? "Полное имя директора" : "Rahbarning to'liq ismi"} size="large" />
                    </label>
                    <label className="w-full mt-3 sm:mt-0 sm:w-[48%]">
                      <span className=" text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Телефон директора" : "Direktorning telefon raqami"}</span>
                      <Input className="w-full" maxLength={13} type="tel" allowClear size="large" value={phoneNumber} onChange={(evt) => setPhoneNumber(evt.target.value)} placeholder={lang ? "Телефон директора" : "Direktorning telefon raqami"} />
                    </label>
                  </div>
                  : ""}
              </div>
              <div className="bg-white border-[1px] border-slate-300 shadow-md mt-5 rounded-md">
                <div className={`p-3 ${checkBugalter && "border-b-2"} `}>
                  <Checkbox checked={checkBugalter} className="w-full" onChange={(e) => setCheckBugalter(e.target.checked)}>
                    <h2 className="font-semibold text-[18px]">
                      {lang ? "Информация о бухгалтере" : "Buxgalter haqida ma'lumot"}
                    </h2>
                  </Checkbox>
                </div>
                {checkBugalter ?
                  <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                    <label className="w-full sm:w-[48%]">
                      <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Полное имя бухгалтере" : "Buxgalter to'liq ismi"}</span>
                      <Input className="w-full" allowClear value={accountant} onChange={(evt) => setAccountant(evt.target.value)} placeholder={lang ? "Полное имя бухгалтере" : "Buxgalter to'liq ismi"} size="large" />
                    </label>
                    <label className="w-full mt-3 sm:mt-0 sm:w-[48%]">
                      <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Телефон бухгалтере" : "Buxgalterning telefon raqami"}</span>
                      <Input className="w-full" maxLength={13} type="tel" allowClear size="large" value={phoneNumber2} onChange={(evt) => setPhoneNumber2(evt.target.value)} placeholder={lang ? "Телефон бухгалтере" : "Buxgalterning telefon raqami"} />
                    </label>
                  </div>
                  : ""}
              </div>
              <div className="bg-white border-[1px] border-slate-300 shadow-md mt-5 rounded-md">
                <div className={`p-3 ${checkBank && "border-b-2"}`}>
                  <Checkbox checked={checkBank} className="w-full" onChange={(e) => setCheckBank(e.target.checked)}>
                    <h3 className="font-semibold text-[18px]">{lang ? "Банковские реквизиты" : "Bankrekvizitlari"}</h3>
                  </Checkbox>
                </div>
                {checkBank ?
                  <>
                    <div className="p-3 flex flex-col sm:flex-row justify-between ">
                      <label className="w-full sm:w-[48%] flex flex-col">
                        <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Банк" : "Bank"}</span>
                        <Input value={bank} allowClear onChange={(evt) => setBank(evt.target.value)} placeholder={lang ? "Название банка" : "Bank nomi"} size="large" />
                      </label>
                      <label className="w-full sm:w-[48%] mt-3 sm:mt-0 flex flex-col">
                        <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">P/C</span>
                        <Input type="number" allowClear value={bankAccount} onChange={(evt) => setBankAccount(evt.target.value)} placeholder="P/C" size="large" />
                      </label>
                    </div>
                    <div className="p-3 flex flex-col sm:flex-row justify-between ">
                      <label className="w-full sm:w-[48%] flex flex-col">
                        <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "МФО" : "MFO"}</span>
                        <Input value={bankType} className="w-full" onChange={(evt) => setBankType(evt.target.value)} type="number" allowClear placeholder={lang ? "Название МФО" : "MFO nomi"} size="large" />
                      </label>
                      <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                        <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "ОКЕД" : "OKED"}</span>
                        <Input allowClear value={bankCode} onChange={(evt) => setBankCode(evt.target.value)} size="large" placeholder={lang ? "Название ОКЕД" : "OKED nomi"} />
                      </label>
                    </div>
                  </>
                  : ""}
              </div>
            </div>
            <div className="w-full mt-4 md:mt-0 md:w-[38%] rounded-lg shadow-md shadow-blue-100 bg-white">
              <div className="bg-white shadow-md border-[1px] border-slate-300 mb-3 rounded-md">
                <h2 className="font-semibold p-3 border-b-2 text-[18px]">{lang ? "Адрес" : "Manzil"}</h2>
                <div className="p-3 flex flex-col w-full space-y-4">
                  <label className="flex flex-col">
                    <span className="text-slate-500 text-[14px] sm:text-[16px] items-baseline mb-1 pl-1">{lang ? "Выберите регион" : "Viloyat tanlang"}</span>
                    <RegionSearch value={regionId} setRegionName={setRegionName} setLoading={false} letLoading={false} width={'100%'} setRegionId={setRegionId} />
                  </label>
                  <label className="relative">
                    <label className="flex flex-col">
                      <span className="text-slate-500 text-[14px] sm:text-[16px] items-baseline mb-1 pl-1">{lang ? "Выберите район" : "Tuman tanlang"}</span>
                      <DistricSearch value={districId} setDistrictName={setDistrictName} regionId={regionId} setDistricId={setDistricId} width={'100%'} />
                    </label>
                  </label>
                  <label className=" flex flex-col w-full">
                    <span className="text-[16px] pl-1 mb-1 inline-block text-slate-500">{lang ? "Выберите регион" : "Viloyat tanlang"}</span>
                    <TextArea
                      onChange={(evt) => setAddress(evt.target.value)}
                      value={address}
                      required
                      size="large"
                      rows={5}
                      placeholder={lang ? "Введите адрес" : "Manzil kiriting"}
                    ></TextArea>
                  </label>
                </div>
              </div>
              <div className="bg-white border-[1px] mt-3 border-slate-300 shadow-md rounded-md">
                <div className={`border-b-2 flex items-center justify-between p-3`}>
                  <Checkbox checked={checkQQS} className="w-full" onChange={(e) => setCheckQQS(e.target.checked)}>
                    <h3 className="font-semibold text-[18px]">QQS</h3>
                  </Checkbox>
                  <div onClick={clickedActive} className="flex cursor-pointer items-center">
                    {lang ? "Активный" : "Faol"}
                    <ul
                      className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${activeItem ? "bg-blue-500 " : "bg-slate-300"
                        } rounded-[50px]`}
                    >
                      <li
                        className={`absolute duration-200 ${activeItem ? "translate-x-4" : ""
                          } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                      ></li>
                    </ul>
                  </div>
                </div>
                {checkQQS ?
                  <div className="p-3 flex justify-between ">
                    <label className="w-full">
                      <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Процент НДС на товар" : "Mahsulotga QQS foizi"}</span>
                      <Input max={12} type="number" allowClear value={taxPercent} size="large" onChange={(evt) => setTaxPercent(evt.target.value)} placeholder={lang ? "НДС на товар" : "QQS foizi "} />
                    </label>
                  </div>
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default BranchAdd;

import React, { useEffect } from 'react'
import { Select } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function CompanySearch({ setCompanyId, setField, isDisalbed, setLoading, letLoading, setBranchName, setCompanyName, value, width, setPageNum, setBranchId }) {
  const env = process.env.REACT_APP_API;
  const { lang, role, roleId } = useContext(Context)
  const token = JSON.parse(window.localStorage.getItem("token"));

  const onChange = (value, name) => {
    if (setPageNum) {
      setPageNum(1)
    }
    setCompanyId(value)
    setCompanyName(name?.label)
    if (letLoading) {
      setLoading(true)
    }
    if (setField) {
      setField("companyId")
    }
    if (value == undefined && setField) {
      setField("inn")
    }
    if (setBranchId && setBranchName) {
      setBranchId(null)
      setBranchName(null)
    }
  };
  const [compnayList, setCompanyList] = useState([])
  const [val, setVal] = useState("")
  const onSearch = (value) => {
    setVal(value)
    if (setField) {
      setField("companyId")
    }

  };

  useEffect(() => {
    if (val.length > 0) {
      axios
        .get(`${env}cabinet/v2/companies?name=${val}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            partner_id: role == "partner" ? roleId : 0
          }
        })
        .then((res) => {
          setCompanyList([...res.data.map(item => ({ value: item.id, label: `${item.title} -- ${item.inn}` }))]);
        })
    }
  }, [value, val])

  return (
    <Select
      disabled={isDisalbed}
      value={value}
      style={{ 'inlineSize': `${width}` }}
      allowClear
      showSearch
      size="large"
      placeholder={lang ? "Поиск компании" : "Kompaniya qidirish"}
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={compnayList}
    />
  )
}

export default CompanySearch
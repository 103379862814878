import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Context } from "../../../../Context/Context";

function MoreUsers({ id, users }) {
  const { lang } = useContext(Context)
  return (
    <>
      <div className="hidden lg:block">
        <table className="w-full mt-5">
          <thead>
            <tr className="text-center text-slate-400">
              <th className=" py-2.5 font-normal text-[17px] ">ID</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Имя" : "Ismi"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Имя пользователя" : "Username"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Филиал" : "Fillial"}</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 ?
              users.map((item, index) => (
                <tr className="odd:bg-[#F7F7F7]" key={index}>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center">{index + 1}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] pl-3 text-center">{item.fullName}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] pl-3 text-center">{item.username}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] pl-3 text-center">{item.branchName}</td>
                </tr>
              )) :
              <tr key={Math.random()} className="text-center">
                <td className="border-t-[1px] py-2.5 text-[15px] text-center">{lang ? "Нравится информация" : "Ma'lumot yoq"}</td>
                <td className="border-t-[1px] py-2.5 text-[15px] text-center">{lang ? "Нравится информация" : "Ma'lumot yoq"}</td>
                <td className="border-t-[1px] py-2.5 text-[15px] text-center">{lang ? "Нравится информация" : "Ma'lumot yoq"}</td>
                <td className="border-t-[1px] py-2.5 text-[15px] text-center">{lang ? "Нравится информация" : "Ma'lumot yoq"}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <ul className="lg:hidden">
        {users.length > 0 && users.map((item, index) => (
          <li key={index} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] text-end font-semibold">ID</span>
              <span className="w-[50%]">: {index + 1}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Имя" : "Ismi"}</span>
              <span className="w-[50%]">{item.fullName}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Имя пользователя" : "Username"}</span>
              <span className="w-[50%]">{item.username}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Филиал" : "Fillial"}</span>
              <span className="w-[50%]">{item.branchName}</span>
            </div>
          </li>
        ))
        }
      </ul>
    </>
  );
}

export default MoreUsers;

import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import EmptyIcon from "../../../Assets/Images/empty-folder-icon.svg"
let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ResultDiogram({ chartList, lang }) {
  const options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Statistika"
    },
    data: [{
      type: "pie",
      startAngle: 75,
      toolTipContent: `<b>${lang ? "Количество ответов" : "Javoblar soni"}</b>: {x}`,
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints:
        chartList?.map(item => (
          { y: item?.percent, x: item?.count, label: item?.text }
        ))
    }]
  }
  return (
    chartList?.length ?
      <CanvasJSChart options={options}
      /* onRef={ref => this.chart = ref} */
      />
      :
      <img className='absolute top-0 bottom-0 left-0 right-0 m-auto' src={EmptyIcon} width={100} height={100} alt='Empty folder Icon' />

  )
}

export default ResultDiogram
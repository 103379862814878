import React from 'react'
import { useNavigate } from "react-router-dom";
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import ResultIcon from "../../Assets/Images/result.svg"
function ResearchTable({ pollList, loading }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  return (
    <table className="w-full">
      <thead>
        <tr className="text-slate-400">
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">ID</th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Название Уз" : "Nomlanishi Uz"}</th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Именование RU" : "Nomlanishi Ru"}</th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center  p-2.5">{lang ? "Кому отправляется" : "Kimga yuborilgan"}</th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Срок" : "Muddati"} </th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Дата создания" : "Yaratilgan vaqt"} </th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Результаты" : "Natijalar"} </th>
          <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5 px-1">{lang ? "ПОДРОБНО" : "BATAFSIL"}</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td>
              {loading ? (
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading}
                  width={60}
                  height={60}
                  alt="Loading Icon"
                />
              ) : (
                ""
              )}
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {pollList.length > 0 &&
            pollList.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA]">
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{item.id}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.titleUz}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.titleRu}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.name ? item.name : "-"}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.expirationDate}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.createdAt?.split("T")[0]} - {item?.createdAt?.split("T")[1]?.split(".")[0]}</td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">
                  <button
                    onClick={(evt) => navigate(`result/${item.id}`)}
                    id={item.id}
                    className="absolute top-0 hover:scale-110 duration-200 left-0 right-0 bottom-0 m-auto flex justify-center items-center"
                  >
                    <img id={item.id} src={ResultIcon} width={30} height={35} alt="Edit" />
                  </button>
                </td>
                <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] relative">
                  <button
                    onClick={(evt) => navigate(`more/${item.id}`)}
                    id={item.id}
                    className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                  >
                    <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </table>
  )
}

export default ResearchTable
import React, { useContext, useEffect, useState } from 'react'
import Buttons from '../../components/Buttons/Buttons'
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { Context } from '../../Context/Context';
import toast, { Toaster } from 'react-hot-toast';
function EmploeeMore() {
  const { lang, role } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate()
  const { id } = useParams()
  const [refResh, setRefResh] = useState(false)
  const clickedActive = (evt) => {
    if (role == "partner") {
      if (moreDate.isActive) {
        axios
          .post(
            `${env}cabinet/v2/employees/change-status?id=${Number(evt.target.id)}`,
            {
              params: {
                id: Number(evt.target.id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            toast.success(lang ? "Измененный" : "O'zgartirildi")
            setRefResh(!refResh)
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Нет возможности изменить" : "O'zgartirish imkoniyati mavjud emas")
    }
    else {
      axios
        .post(
          `${env}cabinet/v2/employees/change-status?id=${Number(evt.target.id)}`,
          {
            params: {
              id: Number(evt.target.id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setRefResh(!refResh)
          toast.success(lang ? "Измененный" : "O'zgartirildi")
        })
    }
  }
  const [moreDate, setMoreDate] = useState(JSON.parse(window.localStorage.getItem("employeeMore")) || {})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/employees/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreDate(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refResh]);
  window.localStorage.setItem("employeeMore", JSON.stringify(moreDate))
  return (
    <div className=''>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='bg-white rounded-md'>
        <div className="py-4 px-5 border-b-[1px] border-slate-200 flex items-center justify-between">
          <h2 className="font-bold text-[16px] sm:text-[22px]">{moreDate.companyName}</h2>
          {role != "company" &&
            <div className='flex items-end space-x-5'>
              <div
                id={moreDate.id}
                onClick={clickedActive}
                className="flex cursor-pointer justify-center py-1 items-center"
              >
                <span className='hidden sm:inline-block'>{lang ? "Активный" : "Faol"}</span>
                <ul
                  id={moreDate.id}
                  className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${moreDate.isActive ? "bg-blue-500 " : "bg-slate-300"
                    } rounded-[50px]`}
                >
                  <li
                    id={moreDate.id}
                    className={`absolute duration-200 ${moreDate.isActive ? "translate-x-4" : ""
                      } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                  ></li>
                </ul>
              </div>
              <UpdateBtn onClick={() => navigate("update")} title={lang ? "Редактирование" : 'Tahrirlash'} />
            </div>
          }
        </div>
        <div className='p-5 flex flex-col sm:flex-row justify-between'>
          <ul className='border-[1px] shadow-md flex flex-col space-y-5 border-slate-200 w-full sm:w-[49%] rounded-md p-5'>
            <li className='flex flex-col'>
              <span className='text-[12px] text-slate-500 sm:text-[16px] '>{lang ? "Авторизоваться" : "Login"}</span>
              <span className='text-[16px] sm:text-[18px]'>{moreDate?.username}</span>
            </li>
            <li className='flex flex-col'>
              <span className='text-[12px] text-slate-500 sm:text-[16px] '>{lang ? "Имя и фамилия" : "Ism va familiya"} </span>
              <span className='text-[16px] sm:text-[18px]'>{moreDate.name}</span>
            </li>
            <li className='flex flex-col'>
              <span className='text-[12px] text-slate-500 sm:text-[16px] '>{lang ? "Позиция" : "Lavozim"}</span>
              <span className='text-[16px] sm:text-[18px]'>{moreDate.roleName}</span>
            </li>
            <li className='flex flex-col'>
              <span className='text-[12px] text-slate-500 sm:text-[16px] '>{lang ? "Организация" : "Tashkilot"}</span>
              <span className='text-[16px] sm:text-[18px]'>{moreDate.companyName}</span>
            </li>
            <li className='flex flex-col'>
              <span className='text-[12px] text-slate-500 sm:text-[16px] '>{lang ? "Филиал" : "Filial"}</span>
              <span className='text-[16px] sm:text-[18px]'>{moreDate.branchName}</span>
            </li>
          </ul>
          <ul className='border-[1px] shadow-md flex flex-col space-y-5 border-slate-200 w-full mt-5 sm:mt-0 sm:w-[49%] rounded-md p-5'>
            <li className='flex flex-col'>
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Сделано" : "Kim tomonidan yaratilgan"}</span>
              <span className="text-[16px] sm:text-[18px]">{moreDate.createdBy}</span>
            </li>
            <li className='flex flex-col'>
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Обновлено" : "Kim tomonidan o'zgartirilgan"}</span>
              <span className="text-[16px] sm:text-[18px]">{moreDate.updatedBy}</span>
            </li>
            <li className="flex flex-col">
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
              <span className="text-[16px] sm:text-[18px]">{moreDate?.createdAt?.split("T")[0]} - {moreDate?.createdAt?.split("T")[1].split(".")[0]}</span>
            </li>
            <li className="flex flex-col">
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
              <span className="text-[16px] sm:text-[18px]">{moreDate?.updatedAt?.split("T")[0]} - {moreDate?.updatedAt?.split("T")[1].split(".")[0]}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default EmploeeMore
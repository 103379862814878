import axios from 'axios';
import React from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../Context/Context';
function NotifLastAccMore({ }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate()
  const { id } = useParams()
  const [activeLang, setActiveLang] = useState("O'z")
  const [moreData, setMoreData] = useState(JSON.parse(window.localStorage.getItem("notifGetOne")) || {})
  const [senderName, setSenderName] = useState("")
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/notification/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreData(res.data);
        axios
          .get(`${env}cabinet/v2/user/name/${res.data.createdById}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setSenderName(res.data);
          })
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  window.localStorage.setItem("notifGetOne", JSON.stringify(moreData))
  return (
    <div className='p-5 '>
      <div className='bg-white rounded-md'>
        <div className='p-5 border-b-[1px] flex items-end justify-between border-slate-300'>
          <div>
            <h2 className='font-bold leading-5 text-[21px]'>{moreData?.titleOz}</h2>
            <span className='text-slate-400 text-[13px]'>{lang ? "Уведомление" : "Bildirishnoma"}</span>
          </div>
        </div>
        <div className='p-5 flex items-start justify-between'>
          <div className='w-full'>
            <div className='flex justify-between'>
              <div className='p-4 w-[48%] rounded-md border-[1px] border-slate-300'>
                <h3 className='text-[15px] font-semibold'>{lang ? "Тема" : "Mavzu"}</h3>
                <ul className='pl-5'>
                  <li>
                    <span className='text-[13px] text-slate-400'>O'Z : </span>
                    <span>{moreData?.titleOz}</span>
                  </li>
                  <li>
                    <span className='text-[13px] text-slate-400'>RU : </span>
                    <span>{moreData?.titleRu}</span>
                  </li>
                  <li>
                    <span className='text-[13px] text-slate-400'>UZ : </span>
                    <span>{moreData?.titleUz}</span>
                  </li>
                </ul>
              </div>
              <div className='w-[48%]'>
                <div className='p-4 rounded-md border-[1px] border-slate-300'>
                  <h3 className='text-[15px] font-semibold'>{lang ? "Дата и время" : "Sana va vaqt"}</h3>
                  <p>{moreData.createdAt?.split("T")[0]} - {moreData.createdAt?.split("T")[1]?.split(".")[0]}</p>
                  <h3 className='text-[15px] mt-3 font-semibold'>{lang ? "Отправитель" : "Yuboruvchi"}</h3>
                  <p>
                    {senderName ? senderName : ""}
                  </p>
                </div>

              </div>
            </div>
            <div className='p-4 flex justify-between rounded-md mt-5 border-[1px] border-slate-300'>
              <div>
                <h3 className='text-[15px] font-semibold'>{lang ? "Контент" : "Kontent"}</h3>
                <ul className='pl-5'>
                  {activeLang === "O'z" && <li>
                    <span className='text-[13px] text-slate-400'>O'Z : </span>
                    <span>{moreData?.contentOz}</span>
                  </li>}
                  {activeLang === "Ru" &&
                    <li>
                      <span className='text-[13px] text-slate-400'>RU : </span>
                      <span>{moreData?.contentRu}</span>
                    </li>}
                  {activeLang === "Uz" &&
                    <li>
                      <span className='text-[13px] text-slate-400'>UZ : </span>
                      <span>{moreData?.contentUz}</span>
                    </li>}
                </ul>
              </div>
              <ul className='flex  items-start'>
                <li onClick={() => setActiveLang("O'z")} className={`text-[14px] ${activeLang === "O'z" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>O'zb</li>
                <li onClick={() => setActiveLang("Uz")} className={`text-[14px] ${activeLang === "Uz" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Uzb</li>
                <li onClick={() => setActiveLang("Ru")} className={`text-[14px] ${activeLang === "Ru" ? "text-blue-600 bg-blue-200" : ""} font-semibold cursor-pointer p-2 rounded-md uppercase`}>Rus</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotifLastAccMore

import React from "react";
import { useContext } from "react";
import { Context } from "../../../../Context/Context";


function MoreCashs({ id, cashs }) {
  const { lang } = useContext(Context)
  return (
    <>
      <div className="hidden lg:block">
        <table className="w-full mt-5">
          <thead>
            <tr className="text-slate-400 text-center">
              <th className=" py-2.5 font-normal text-[17px] ">ID</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Имя" : "Nomi"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Филиал" : "Fillial"}</th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Сам Модуль" : "SamModul ID"} </th>
              <th className=" py-2.5 font-normal text-[17px] ">{lang ? "Аппарат ID" : "Device ID"} </th>
            </tr>
          </thead>
          <tbody>
            {cashs.length > 0 &&
              cashs.map((item, index) => (
                <tr className="odd:bg-[#F7F7F7]" key={index}>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{index + 1}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item?.title}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item?.branchName}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item?.deviceModule}</td>
                  <td className="border-t-[1px] py-2.5 text-[15px] text-center pl-3">{item?.deviceNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ul className="lg:hidden">
        {cashs.length > 0 && cashs.map((item, index) => (
          <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] text-end font-semibold">ID</span>
              <span className="w-[50%]">: {index + 1}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Имя" : "Nomi"}</span>
              <span className="w-[50%]">{item[3]}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Филиал" : "Fillial"}</span>
              <span className="w-[50%]">{item[17]}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Сам Модуль" : "SamModul ID"}</span>
              <span className="w-[50%]">{item[4]}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Аппарат ID" : "Device ID"}</span>
              <span className="w-[50%]">{item[5]}</span>
            </div>
          </li>
        ))
        }
      </ul>
    </>
  );
}

export default MoreCashs;

import React from 'react'
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function Agreements({ agreementsList, loading }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  return (
    <div className='px-5'>
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">ID</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "INN" : "INN"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Название кампании" : "Kampaniya nomi"} </th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Название филиал" : "Filial nomi"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Сам Модуль" : "Sam Modul"} </th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center  p-2.5">{lang ? "Номер устройства" : "Aparat Nomer"} </th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5 ">{lang ? "Версия" : "Versiya"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Статус" : "Holati"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Время создания" : "Yaratilgan vaqt"} </th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Подробна" : "Batafsil"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading}
                  width={70}
                  height={70}
                  alt="Loading Icon"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {agreementsList.length > 0 ?
              agreementsList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.inn ? item.inn : "-"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.companyName ? item.companyName : "-"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.branchName ? item.branchName : "-"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.samModul ? item.samModul : "-"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.deviceNumber ? item.deviceNumber : "-"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">{item.version ? item.version : "-"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">{item.status ? "Active" : "Active emas"}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    {item?.createdAt?.split("T")[0]} - {item?.createdAt?.split("T")[1]?.split(".")[0]}
                  </td>
                  <td id={item.id} className=" border-t-[1px] text-[15px] border-slate-300 relative border-slate-center">
                    <button
                      onClick={(evt) => navigate(`more/${evt.target.id}`)}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#C2E0FF]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              )) :
              <tr>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
              </tr>
            }
          </tbody>
        )}
      </table>
    </div>
  )
}

export default Agreements
import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Edit from "../../../../Assets/Images/dots.svg";
import { Context } from "../../../../Context/Context";
function MoreBranchs({ id, branchs }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const handleClickBranchMore = (evt) => {
    navigate(`/filist/more/${evt.target.id}`);
  };


  return (
    <>
      <div className="hidden lg:block">
        <table className="w-full mt-5 ">
          <thead>
            <tr className="text-slate-400">
              <th className=" py-2.5 font-normal text-center text-[17.5px] ">ID</th>
              <th className=" py-2.5 font-normal text-center text-[17.5px] ">{lang ? "Филлияли" : "Filial"}</th>
              <th className=" py-2.5 font-normal text-center text-[17.5px] ">{lang ? "Коммуникация" : "Aloqa"}</th>
              <th className=" py-2.5 font-normal text-center text-[17.5px] ">{lang ? "Дата регистрации" : "Ro'yhatga olingan sana"}</th>
              <th className=" py-2.5 font-normal text-center text-[17.5px] ">{lang ? "ИНН" : "Inn"}</th>
              <th className=" py-2.5 font-normal text-center text-[17.5px] ">{lang ? "ПОДРОБНО" : "Batafsil"}</th>
            </tr>
          </thead>
          <tbody>
            {branchs.length > 0 &&
              branchs.map((item, index) => (
                <tr className="odd:bg-[#F7F7F7]" key={item.id}>
                  <td className="border-t-[1px] text-[15px] py-2.5 text-center">{index + 1}</td>
                  <td className="border-t-[1px] text-[15px] py-2.5 text-center pl-3">{item.title}</td>
                  <td className="border-t-[1px] text-[15px] py-2.5 text-center pl-3">{item.phoneNumber}</td>
                  <td className="border-t-[1px] text-[15px] py-2.5 text-center pl-3">{item.createdAt.split("T")[0]}</td>
                  <td className="border-t-[1px] text-[15px] py-2.5 text-center pl-3">{item.inn}</td>
                  <td className="border-t-[1px] text-[15px] relative text-center py-1">
                    <button
                      onClick={handleClickBranchMore}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#C2E0FF]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {/* Mobile start */}
      <ul className="lg:hidden">
        {branchs.length > 0 && branchs.map((item, index) => (
          <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] text-end font-semibold">ID</span>
              <span className="w-[50%]">: {index + 1}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Филлияли" : "Filial"}</span>
              <span className="w-[50%]">{item.legalName}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Коммуникация" : "Aloqa"}</span>
              <span className="w-[50%]">{item.phoneNumber}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Дата регистрации" : "Ro'yhatga olingan sana"}</span>
              <span className="w-[50%]">{item.createdAt.split("T")[0]}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Инн" : "Inn"}</span>
              <span className="w-[50%]">{item.inn}</span>
            </div>
            <div className="flex items-center justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Подробно" : "Batafsil"}</span>
              <div className="w-[50%]">
                <button
                  onClick={handleClickBranchMore}
                  id={item.id}
                  className="flex justify-center items-center rounded-[50%] w-[20px] h-[20px] bg-[#BFDDFC]"
                >
                  <img className="w-[15px] h-[15px]" id={item.id} src={Edit} width={'100%'} height={"100%"} alt="Edit" />
                </button>
              </div>
            </div>
          </li>
        ))
        }
      </ul>
      {/* Mobile end */}
    </>
  );
}

export default MoreBranchs;

import axios from 'axios';
import React, { useState } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../Context/Context';

function AgreementsMore() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate();
  const { id } = useParams()
  const [moreData, setMoreData] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/agreements/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreData(res.data)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='p-5 bg-white'>
      <div className='pb-5'>
        <h2 className="font-bold text-[25px] ">{lang ? "Подробности о соглашениях" : "Agreements batafsil"}</h2>
      </div>
      <div className='flex justify-between'>
        <div className='w-[48%] rounded-md border-[1px] space-y-3 border-slate-300 shadow-md p-5'>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "INN" : "INN"}</span>
            <span className='font-semibold text-[17px]'>{moreData.inn ? moreData.inn : "-"}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Название кампании" : "Kampaniya nomi"}</span>
            <span className='font-semibold text-[17px]'>{moreData.companyName ? moreData.companyName : "-"}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Название филиал" : "Filial nomi"}</span>
            <span className='font-semibold text-[17px]'>{moreData.branchName ? moreData.branchName : "-"}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Сам Модуль" : "Sam Modul"} </span>
            <span className='font-semibold text-[17px]'>{moreData.samModul ? moreData.samModul : "-"}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Номер устройства" : "Aparat nomer"} </span>
            <span className='font-semibold text-[17px]'>{moreData.deviceNumber ? moreData.deviceNumber : "-"}</span>
          </div>
        </div>
        <div className='w-[48%] rounded-md border-[1px] space-y-3 border-slate-300 shadow-md p-5'>
          <div>
            <p className='text-slate-400 text-[15px]'>{lang ? "Терм" : "Term"} </p>
            <button onClick={() => navigate(`/terms/more/${moreData.termsId}`)} className='font-semibold text-blue-600 border-b-[1px] border-blue-600'>{lang ? "Увидеть терм" : "Term ko'rish"}</button>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Версия" : "Versiya"} </span>
            <span className='font-semibold text-[17px]'>{moreData.version ? moreData.version : "-"}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Статус" : "Holati"} </span>
            <span className='font-semibold text-[17px]'>{moreData.status ? lang ? "Активный" : "Active" : lang ? "Не активен" : "Active emas"}</span>
          </div>
          <div className='flex flex-col'>
            <span className='text-slate-400 text-[15px]'>{lang ? "Время создания" : "Yaratilgan vaqt"} </span>
            <span className='font-semibold text-[17px]'>{moreData?.createdAt?.split("T")[0]} - {moreData?.createdAt?.split("T")[1].split(".")[0]}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgreementsMore
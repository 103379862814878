import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AddBtn } from '../../components/NewBtn/NewBtn';
import { Input, Pagination } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import GetMessageTable from '../../components/Table/TelegramBot/GetMessageTable';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import useDebounce from "../../Hook/useDebounce"
import CompanySearch from "../../components/CompanySearch/CompanySearch"
function Users() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [changeStatus, setChangeStatus] = useState(false)
  const [loading, setLoading] = useState(false)
  // Basic
  const [totalElement, setTotalElement] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };
  // Basic

  // Phone number change start
  const [phoneNumber, setPhoneNumber] = useState(window.sessionStorage.getItem("telegramPhoneNumber") || "")
  const phoneNumberChange = (evt) => {
    setPageNum(1)
    setLoading(true)
    setPhoneNumber(evt.target.value)
  }
  const phoneNumberSearch = useDebounce(phoneNumber, 400)
  // Phone number change end

  // Company search start
  const [companyId, setCompanyId] = useState(JSON.parse(window.sessionStorage.getItem("telegramCampanyId")) || 0)
  const [companyName, setCompanyName] = useState(window.sessionStorage.getItem("telegramCompName") || "")
  // Company search end

  // Get All users Start
  const [telegramUsersList, setTelegramUserList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/contact`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          phoneNumber: phoneNumberSearch,
          companyId: companyId
        },
      })
      .then((res) => {
        setLoading(false)
        setTotalElement(res.data.totalElements);
        setTelegramUserList(res.data.listOfItems);
      })
      .catch((err) => {
        setLoading(false)
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, changeStatus, phoneNumberSearch, companyId]);
  // Get All users end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  window.sessionStorage.setItem("telegramPhoneNumber", phoneNumber)

  window.sessionStorage.setItem("telegramCampanyId", JSON.stringify(companyId) ? JSON.stringify(companyId) : 0)
  window.sessionStorage.setItem("telegramCompName", companyName)

  return (
    <div className='bg-white py-5'>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Пользователи Телеграм бота" : "Telegram Bot foydalanuvchilar"}</h2>
          <span className="text-slate-400">{totalElement} {lang ? "пользователи" : "foydalanuvchilar"}</span>
        </div>
        <AddBtn onClick={() => navigate("add")} title={lang ? "Добавлять" : "Qo'shish"} />
      </div>
      <div className='p-5 flex flex-col xl:flex-row space-y-2 items-center xl:items-start xl:space-y-0 xl:space-x-5'>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Выберите номер телефона" : "Telefon raqam tanlang "}</span>
          <Input value={phoneNumber} onChange={phoneNumberChange} allowClear className='w-[350px]' size='large' placeholder={lang ? "Введите номер телефона" : "Telefon raqam kiriting"} />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block pl-1 mb-1'>{lang ? "Поиск компании" : "Kompaniya qidirish"}</span>
          <CompanySearch value={companyId === 0 ? null : companyName} letLoading={true} setCompanyId={setCompanyId} setCompanyName={setCompanyName} setLoading={setLoading} setPageNum={setPageNum} width={'350px'} />
        </label>
      </div>
      <GetMessageTable changeStatus={changeStatus} setChangeStatus={setChangeStatus} telegramGetMessageList={telegramUsersList} loading={loading} />
      <div className="flex justify-center pt-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Users
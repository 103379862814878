import React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import NewSiteBar from "./NewSiteBar";

// Pictures
import Logo from "../../Assets/Images/logo1.png";
import openBar from "../../Assets/Images/turn/open.svg";
import closeBar from "../../Assets/Images/turn/close.svg";

function SiteBar() {
  const { lang } = useContext(Context)
  const [showBar, setShowBar] = React.useState(
    JSON.parse(window.sessionStorage.getItem("siteBar")) || false
  );
  const hadleBar = () => {
    setShowBar(!showBar);
    window.sessionStorage.setItem("siteBar", JSON.stringify(showBar));
  };
  window.sessionStorage.setItem("siteBar", JSON.stringify(showBar));
  return (
    <>
      <div>
        <div
          className={`${showBar ? "w-[80px]" : "w-[390px] overflow-y-auto"
            } relative duration-300 bg-[#001529] h-[100vh]`}
        >
          <div
            className={`border-b-2 ${showBar
              ? "px-2 pt-2 flex flex-col justify-between pb-1 items-center mb-2"
              : "pt-5 px-5 flex items-center justify-between pb-3"
              } `}
          >
            <Link className="flex items-center" to={"/"}>
              <img
                className={`${showBar ? "w-[35px] h-[35px]" : "mr-2"} rounded-2xl`}
                src={Logo}
                width={55}
                height={55}
                alt="Admin Logo"
              />
              <span className={`text-white ${showBar ? "hidden" : ""} font-bold text-[25px]`}>
                <span className="text-[#44A1D1]">CASH</span>BOX <span className="font-normal text-[18px]">{lang ? "Кабинет" : "Cabinet"}</span>
              </span>
            </Link>
            <button
              className={`duration-300 ${showBar ? "mt-2" : ""}`}
              type={"button"}
              onClick={hadleBar}
            >
              {showBar ? (
                <img
                  className={`${showBar ? "w-[25px] mx-auto" : ""}`}
                  src={openBar}
                  width={30}
                  height={30}
                  alt="Open Bar"
                />
              ) : (
                <img
                  className={`${showBar ? "w-[40px]" : ""}`}
                  src={closeBar}
                  width={30}
                  height={30}
                  alt="Close Bar"
                />
              )}
            </button>
          </div>
          <NewSiteBar showBar={showBar} />
        </div>
      </div>
    </>
  );
}

export default SiteBar;

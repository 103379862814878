import React, { useEffect } from "react";
import Buttons from "../../components/Buttons/Buttons";
import Inputs from "../../components/Inputs/Inputs";
import { useRef } from "react";
import { useState } from "react";
//import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import OpenAye from "../../Assets/Images/open-aye.svg"
import CloseAye from "../../Assets/Images/close-eye.svg"
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Input, Select } from "antd";
import PartinerSearch from "../../components/PartinerSearch/PartinerSearch";
import CompanySearch from "../../components/CompanySearch/CompanySearch";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import { AddBtn } from "../../components/NewBtn/NewBtn";
import Password from "antd/es/input/Password";
function AddUsers() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate()
  const { id } = useParams()

  // update part start
  const [rolListName, setRoleListName] = useState(null)
  const [partinerId, setPartinerId] = useState(null)
  const [companyId, setCompanyId] = useState(null)
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/user/get/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFirstName(res.data.firstName)
          setLastName(res.data.lastName)
          setUsername(res.data.username)
          setPhoneNumber(res.data.phoneNumber)
          if (res.data.role === 1) {
            setRoleListName("Adminstrator")
            setRoleId(res.data.role)
          }
          if (res.data.role === 4) {
            setRoleListName("Xamkor")
            setRoleId(res.data.role)
            setPartinerId(res.data.partnerId)
            axios
              .get(`${env}cabinet/v2/partners/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res1) => {
                setPartinerName(res1.data.find(item => item.id === res.data.partnerId)?.title)
              })
              .catch((err) => {
              });
          }
          if (res.data.role === 5) {
            setRoleListName("Kompaniya")
            setRoleId(res.data.role)
            setCompanyId(res.data.companyId)
            axios
              .get(`${env}cabinet/v2/company/${res.data.companyId}`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res1) => {
                setCompanyName(res1.data.title)
              })
              .catch((err) => {
              });
          }
        })
        .catch((err) => {
        });
    }
  }, [])
  // update part end

  // Rol start

  const roleList = [
    {
      value: 1,
      label: "Adminstrator",
    },
    {
      value: 4,
      label: "Xamkor",
    },
    {
      value: 5,
      label: "Kompaniya",
    },
  ];
  const [roleId, setRoleId] = useState(1);
  const facChange = (value, name) => {
    setRoleId(value);
    setRoleListName(name?.label)
  };

  // Rol end
  // Partiner start
  const [partinerName, setPartinerName] = useState(null)
  // Partiner end

  // Company change start
  const [companyName, setCompanyName] = useState(null)
  // Company change end


  // Finish start
  const submitDateToAdd = (evt) => {
    evt.preventDefault()
    const data = {
      firstName: firstName,
      lastName: lastName,
      role: roleId,
      username: username,
      password: password,
      phoneNumber: phoneNumber,
      companyId: rolListName === "Kompaniya" ? companyId - 0 : null,
      partnerId: rolListName === "Xamkor" ? partinerId - 0 : null,
      status: true
    }
    if (id) {
      if (password && password == passwordConfirm) {
        axios
          .put(`${env}cabinet/v2/user/edit/${id}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
          )
          .then((res) => {
            toast.success(res.data)
            setTimeout(() => {
              navigate(-1)
            }, 1000)
          })
          .catch((err) => {
            if (err.response.status === 500) {
              toast.error(err.response.data);
            }
          });
      }
      else {
        toast.error(lang ? "Пароль должен быть тот же" : "Password bir xil bo'lishi kerak")
      }
    }
    else {
      if (password && password == passwordConfirm) {
        axios
          .post(`${env}cabinet/v2/user/create`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            toast.success("Saqlandi !")
            setTimeout(() => {
              navigate(-1)
            }, 800)
          })
          .catch((err) => {
            if (err.response.status === 500) {
              toast.error(err.response.data);
            }
          });
      }
      else {
        toast.error(lang ? "Пароль должен быть тот же" : "Password bir xil bo'lishi kerak")
      }

    }
  }
  // Finish start
  const [firstName, setFirstName] = useState(null)
  const [password, setPassword] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("+998")
  const [lastName, setLastName] = useState(null)
  const [username, setUsername] = useState(null)
  const [passwordConfirm, setPasswordConfirm] = useState(null)
  return <div className="">
    <Toaster position="top-center" reverseOrder={false} />
    <form onSubmit={submitDateToAdd} className="bg-white pb-20 rounded-md">
      <div className="flex border-b-[1px] border-slate-300 px-5 py-3 items-center justify-between">
        <div className="flex items-center space-x-5">
          <h2 className="font-bold text-[25px]">{lang ? "Пользователь" : "Foydalanuvchini"} {id ? lang ? "изменять" : "o'zgartirish" : lang ? "сохранять" : "kiriting"}</h2>
        </div>
        <AddBtn type={'submit'} title={lang ? "Сохранять" : 'Saqlash'} />
      </div>
      <div className="px-5 mt-5">
        <div className="flex justify-between shadow-md rounded-md border-[1px] border-slate-300 w-[70%] p-5">
          <div className="w-[49%] flex flex-col space-y-4">
            <label className="w-full">
              <span>{lang ? "Имя" : "Ism"}</span>
              <Input required size="large" allowClear value={firstName} onChange={(evt) => setFirstName(evt.target.value)} placeholder={lang ? "Введите ваше имя" : 'Isminggizni kiriting'} />
            </label>
            <label className="w-full">
              <span>{lang ? "Роль" : "Rol"}</span>
              <Select
                value={rolListName}
                style={{ 'inlineSize': "100%" }}
                allowClear
                showSearch
                size="large"
                placeholder={lang ? "Выберите тип организации" : "Tashkilot turi tanlang"}
                optionFilterProp="children"
                onChange={facChange}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={roleList}
              />
            </label>
            <label className="w-full">
              <span>{lang ? "Пароль" : "Parol"}</span>
              <Password value={password} onChange={(evt) => setPassword(evt.target.value)} size="large" allowClear placeholder={lang ? "введите пароль" : 'Parol kiriting'} />
            </label>
            <label className="w-full">
              <span>{lang ? "Номер телефона" : "Telefon raqam"}</span>
              <Input required value={phoneNumber} maxLength={13} onChange={(evt) => setPhoneNumber(evt.target.value)} type={`tel`} allowClear size="large" placeholder={lang ? "Введите номер" : 'Raqam kiriting'} />
            </label>
          </div>
          <div className="w-[49%] flex flex-col space-y-4">
            <label className="w-full">
              <span>{lang ? "Фамилия" : "Familiya"}</span>
              <Input required value={lastName} onChange={(evt) => setLastName(evt.target.value)} size="large" allowClear placeholder={lang ? "Введите фамилию" : 'Familiyanggiz kiriting'} />
            </label>
            <label className="w-full">
              <span>{lang ? "Авторизоваться" : "Login"}</span>
              <Input required value={username} onChange={(evt) => setUsername(evt.target.value)} size="large" allowClear placeholder={lang ? "Введите логин" : 'Login kiriting'} />
            </label>
            <label className="w-full">
              <span>{lang ? "Подтвердите пароль" : "Parolni tasdiqlang"}</span>
              <Password value={passwordConfirm} onChange={(evt) => setPasswordConfirm(evt.target.value)} size="large" allowClear placeholder={lang ? "Подтвердите пароль" : 'Parol tasdiqlang'} />
            </label>
            {rolListName === "Xamkor" ?
              <label className="w-full">
                <span>{lang ? "Партнер" : "Xamkor"}</span>
                <PartinerSearch value={partinerName} letLoading={false} setPartinerId={setPartinerId} setPartinerName={setPartinerName} width={'100%'} />
              </label>
              : ""
            }
            {rolListName === "Kompaniya" ?
              <label className="w-full">
                <span>{lang ? "Компания" : "Kompaniya"}</span>
                <CompanySearch value={companyName} letLoading={false} setCompanyId={setCompanyId} setCompanyName={setCompanyName} width={'100%'} />
              </label>
              : ""
            }
          </div>
        </div>
      </div>
    </form>
  </div>;
}

export default AddUsers;

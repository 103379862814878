import React, { useContext } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Context } from '../../../Context/Context';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateBtn } from '../../../components/NewBtn/NewBtn';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Modal } from 'antd';

function AnswerMore() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id, more, answerId } = useParams()

  const [questionMore, setQuestionMore] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then((res) => {
        setQuestionMore(res.data.questions.find(item => item.id === more - 0));
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [more]);
  // Question getbyid end

  // Answer delete start
  const [deleteModal, setDeleteModal] = useState(false)
  const answerDelete = () => {
    axios
      .post(
        `${env}cabinet/v2/answer/delete?id=${Number(answerId)}`,
        {
          params: {
            id: Number(answerId),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then(res => {
        setDeleteModal(false)
        toast.success(lang ? "Сохранено" : "Saqlandi")
        setTimeout(() => {
          navigate(-1)
        }, 800)
      })
  }
  // Answer delete end

  // Get answer start
  const [answerList, setAnswerList] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/answer/get-by`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          questionId: more - 0
        }
      })
      .then((res) => {
        setAnswerList(res.data.find((item => item.id === answerId - 0)));

      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [more]);
  // Get answer end
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <h2 className="font-bold text-[25px] ">{lang ? "Ответ" : "Javob"} : {lang ? answerList?.answerRu : answerList?.answerUz}</h2>
        <div className='flex items-end space-x-3'>
          <button onClick={() => setDeleteModal(true)} className='bg-red-500 hover:opacity-80 p-1 rounded-md'>
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#fff" className="bi bi-trash3" viewBox="0 0 16 16">
              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
            </svg>
          </button>
          <UpdateBtn onClick={() => navigate(`edit`)} title={lang ? "Редактирование" : 'Tahrirlash'} />
        </div>
      </div>
      <div className='p-3'>
        <h2 className='font-semibold text-[18px]'>{lang ? "Информация" : "Ma'lumotlar"}</h2>
        <ul className='p-2 flex flex-col space-y-2 border-[1.5px] border-slate-300 w-[50%] rounded-md'>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Вопрос УЗ" : "Savol UZ"}</span>
            <p className='text-[18px]'>{questionMore?.questionUz}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Вопрос RU" : "Savol RU"}</span>
            <p className='text-[18px]'>{questionMore?.questionRu}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Название ответ УЗ" : "Javob nomi UZ"}</span>
            <p className='text-[18px]'>{answerList?.answerUz}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Название ответ RU" : "Javob nomi RU"}</span>
            <p className='text-[18px]'>{answerList?.answerRu}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
            <p className='text-[18px]'>{answerList?.createdAt?.split("T")[0]} - {answerList?.createdAt?.split("T")[1]?.split(".")[0]}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[14px]'>{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
            <p className='text-[18px]'>{answerList?.updatedAt?.split("T")[0]} - {answerList?.updatedAt?.split("T")[1]?.split(".")[0]}</p>
          </li>

        </ul>
      </div>
      <Modal
        centered
        open={deleteModal}
        onOk={answerDelete}
        onCancel={() => setDeleteModal(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px]">{lang ? "Удалить ответ" : "Javobni o'chirish"}</h2>
      </Modal>
    </div>
  )
}

export default AnswerMore
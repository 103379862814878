import React from "react";
import { useEffect, useState, useRef } from "react";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";
import { Modal } from "../../../../components/Modal/Modal";
import Buttons from "../../../../components/Buttons/Buttons";
import Inputs from "../../../../components/Inputs/Inputs";
import DatePicer from "../../../../components/DatePicer/DatePicer";

// Pictures
import Edit from "../../../../Assets/Images/adit.svg";
import { useContext } from "react";
import { Context } from "../../../../Context/Context";
function MorePayment({ id }) {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const date = new Date();
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [paymentList, setPaymentList] = useState([]);
  const [refResh, setRefResh] = useState(false);
  // Motion Click start
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/changeStatus?id=${evt.target.id}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefResh(!refResh);
      })
      .catch((err) => {
        toast.error("Xatolik bor !");
      });
  };
  // Motion Click end

  // Time picer start
  const devTime =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0") +
    " " +
    "00:00:00";
  // Time picer end

  // Edit Payment changes select start
  const editMerchandUserId = useRef();
  const editServicesIDRef = useRef();
  // Edit Payment changes select end

  // edit payment start
  const merChandKeyRef = useRef();
  const merchandIdRef = useRef();
  const paymeParams = useRef();
  const [showEditInps, setShowEditInps] = useState("payme");

  const [paymentDevVal, setPaymentDevVal] = useState({});
  const [letEditTerminalId, setLetEditTerminalId] = useState(false);

  const handleEditPayment = (evt) => {
    evt.preventDefault();
    setShowModal(true);
    setPaymentDevVal(paymentList.find((item) => item.id === Number(evt.target.id)));
    setLetEditTerminalId(
      paymentList.find((item) => item.id === Number(evt.target.id)).terminalId ? true : false
    );
    setShowEditInps(
      paymentList.find((item) => item.id === Number(evt.target.id)).name === "click" ||
        paymentList.find((item) => item.id === Number(evt.target.id)).name === "uzum"
        ? true
        : false
    );
  };

  const [editNameVal, setEditNameVal] = useState(paymentDevVal?.name);
  const nameEditRef = useRef();

  const [letEditList, setLetEditList] = useState(false);
  const handleEditClick = (evt) => {
    setLetEditList(true);
  };
  const handleEidtNameListClick = (evt) => {
    setEditNameVal(evt.target.textContent);
    nameEditRef.current.value = evt.target.textContent;
    setLetEditList(false);
    if (evt.target.textContent === "click" || evt.target.textContent === "uzum") {
      setShowEditInps(true);
    } else {
      setShowEditInps(false);
    }
  };
  // edit payment end

  // Date Add start
  const [editTime, setEditTime] = useState("");
  const [letEditTime, setLetEditTime] = useState(false);
  // Date Add end

  // checkbox edit change start
  const editTerminalRef = useRef();
  const onChangeEdit = (e) => {
    setLetEditTerminalId(!letEditTerminalId);
  };
  // checkbox edit change end

  // Edit submit start
  const handleEditSubmitForm = (evt) => {
    evt.preventDefault();
    const data = {
      id: paymentDevVal.id,
      expired_date: null,
      merchantId: merchandIdRef?.current?.value,
      merchantKey: merChandKeyRef?.current?.value,
      name: editNameVal,
      params: showEditInps
        ? '{"service_id":"' +
        editServicesIDRef.current?.value +
        '","merchant_user_id":"' +
        editServicesIDRef.current?.value +
        '"}'
        : paymeParams?.current?.value,
      terminalId: letEditTerminalId ? editTerminalRef.current.value : paymentDevVal.terminalId,
    };
    axios
      .put(`${env}cabinet/v2/update?date=${letEditTime ? editTime + " 00:00:00" : devTime}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("Saqlandi");
        setRefResh(!refResh);
        setShowModal(false);
      })
      .catch((err) => {
        toast.error("Xatolik bor !");
        setShowModal(false);
      });
  };
  // Edit submit end

  // Add payment start

  // After show inputs refs start
  const merchandUserIdRef = useRef();
  const servicesIDRef = useRef();
  // After show inputs refs end

  const addKeyRef = useRef();
  const addIdRef = useRef();
  const addParamsRef = useRef();
  const paymentAddBtnClick = (evt) => {
    setShowModal1(true);
  };
  // Add payment end

  // Payment info Changed start
  const [showInp, setShowInp] = useState(false);
  const nameRef = useRef();

  const [letNameList, setLetNameList] = useState(false);
  const handleClick = () => {
    setLetNameList(true);
  };
  const [nameSelect, setNameSelect] = useState("payme");
  const handleNameListClick = (evt) => {
    setNameSelect(evt.target.textContent);
    nameRef.current.value = evt.target.textContent;
    setLetNameList(false);
    if (evt.target.textContent === "click" || evt.target.textContent === "uzum") {
      setShowInp(true);
    } else {
      setShowInp(false);
    }
  };
  // Payment info Changed end

  // Date Add start
  const [addTime, setAddTime] = useState("");
  const [letAddTime, setLetAddTime] = useState(false);
  // Date Add end

  // checkbox change start
  const addTerminalIdRef = useRef();
  const [letTerminalId, setLetTerminalId] = useState(false);
  const onChange = () => {
    setLetTerminalId(!letTerminalId);
  };
  // checkbox change end

  // Add submit form start
  const handleAddSubmitPayment = (evt) => {
    evt.preventDefault();
    const data = {
      companyId: id - 0,
      expired_date: null,
      merchantId: addIdRef?.current?.value,
      merchantKey: addKeyRef?.current?.value,
      name: nameSelect,
      params: showInp
        ? '{"service_id":"' +
        servicesIDRef.current?.value +
        '","merchant_user_id":"' +
        merchandUserIdRef.current?.value +
        '"}'
        : addParamsRef?.current?.value,
      terminalId: letTerminalId ? addTerminalIdRef.current.value : "",
    };
    axios
      .post(`${env}cabinet/v2/save?date=${letAddTime ? addTime + " 00:00:00" : devTime}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setRefResh(!refResh);
        setShowModal1(false);
        toast.success("Qo'shildi ");
        setLetAddTime(false);
      })
      .catch((err) => {
        setShowModal1(false);

        toast.error("Xatolik bor ! ");
      });
  };

  // Add submit form end
  useEffect(() => {
    axios
      .post(
        `${env}cabinet/v2/selectby?companyId=${id || 0}`,
        {
          params: {
            companyId: id,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPaymentList(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refResh]);
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="hidden lg:block">
        <table className="w-full mt-5">
          <thead>
            <tr className="text-slate-400 text-center ">
              <th className="font-normal text-[17px] text-center py-2.5 w-[3%]">ID</th>
              <th className="font-normal text-[17px] text-center py-2.5 w-[7%]">{lang ? "Имя" : "Nomi"}</th>
              <th className="font-normal text-[17px] text-center py-2.5 w-[7%]">{lang ? "Статус" : "Holati"}</th>
              <th className="font-normal text-[17px] text-center py-2.5 ">Merchant Key</th>
              <th className="font-normal text-[17px] text-center py-2.5 ">Merchant Id</th>
              <th className="font-normal text-[17px] text-center py-2.5 ">Terminal Id</th>
              <th className="font-normal text-[17px] text-center py-2.5 ">Expired Data</th>
              <th className="font-normal text-[17px] text-center py-2.5 ">Params</th>
              <th className="font-normal text-[17px] text-center py-2.5">
                <Buttons
                  bg={"green"}
                  clicked={paymentAddBtnClick}
                  extraSty={"py-0.5 w-[100%] block mx-auto"}
                  title={lang ? "Добавлять" : "Qo'shish"}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentList.length > 0 &&
              paymentList.map((item, index) => (
                <tr className="odd:bg-[#F7F7F7]" key={item.id}>
                  <td className="border-t-[1px] text-center py-2 text-[15px]">{index + 1}</td>
                  <td className="border-t-[1px] text-center pl-3 py-2 text-[15px]">{item.name}</td>
                  <td className="border-t-[1px] text-center py-2 text-[15px]">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center py-2 text-[15px] items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td className="border-t-[1px] text-center pl-3 py-2 text-[15px]">{item.merchantKey}</td>
                  <td className="border-t-[1px] text-center pl-3 py-2 text-[15px]">{item.merchantId}</td>
                  <td className="border-t-[1px] text-center pl-3 py-2 text-[15px]">{item.terminalId}</td>
                  <td className="border-t-[1px] text-center pl-3 py-2 text-[15px]">{item.expiredDate?.split("T")[0]}</td>
                  <td className="border-t-[1px] text-center pl-3 py-2 text-[15px]">{item.params}</td>
                  <td className="border-t-[1px] flex text-center justify-center py-2 text-[15px]">
                    <img
                      onClick={handleEditPayment}
                      id={item.id}
                      className="cursor-pointer"
                      src={Edit}
                      width={30}
                      height={30}
                      alt="Edit"
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <ul className="lg:hidden">
        {paymentList.length > 0 && paymentList.map((item, index) => (
          <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] text-end font-semibold">ID</span>
              <span className="w-[50%]">: {index + 1}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Имя" : "Nomi"}</span>
              <span className="w-[50%]">{item.name ? item.name : "-"}</span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">{lang ? "Статус" : "Holati"}</span>
              <span className="w-[50%]">
                <div
                  id={item.id}
                  onClick={clickedActive}
                  className="flex cursor-pointer justify-center py-2 text-[15px] items-center"
                >
                  <ul
                    id={item.id}
                    className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                      } rounded-[50px]`}
                  >
                    <li
                      id={item.id}
                      className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                        } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                    ></li>
                  </ul>
                </div>
              </span>
            </div>
            <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
              <span className="w-[50%] font-semibold">Merchant Key</span>
              <span className="w-[50%]">{item.merchantKey ? item.merchantKey : "-"}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">Merchant Id</span>
              <span className="w-[50%]">{item.merchantId ? item.merchantId : "-"}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">Expired Data</span>
              <span className="w-[50%]">{item.expiredDate?.split("T")[0]}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">Params</span>
              <span className="w-[50%]">{item.params ? item.params : "-"}</span>
            </div>
            <div className="flex justify-between py-1 px-3">
              <span className="w-[50%] font-semibold">
                <Buttons
                  bg={"green"}
                  clicked={paymentAddBtnClick}
                  extraSty={"py-0.5 w-[100%] block mx-auto"}
                  title={lang ? "Добавлять" : "Qo'shish"}
                />
              </span>
              <span className="w-[50%] text-end">
                <img
                  onClick={handleEditPayment}
                  id={item.id}
                  className="cursor-pointer block w-[30px] h-[30px] mx-auto"
                  src={Edit}
                  width={30}
                  height={30}
                  alt="Edit"
                />
              </span>
            </div>
          </li>
        ))
        }
      </ul>
      {/* Edit Modal */}
      <Modal className={"w-[1000px]"} isVisible={showModal} onClose={() => setShowModal(false)}>
        <form onSubmit={handleEditSubmitForm}>
          <div className="flex flex-col sm:flex-row items-center justify-between mb-5">
            <div className="w-full mb-3 sm:mb-0 sm:w-[48%] relative flex flex-col">
              Nomi
              <Inputs
                noneDis={'py-2.5'}
                handleBlur={() => {
                  setTimeout(() => {
                    setLetEditList(false);
                  }, [150]);
                }}
                inputRef={nameEditRef}
                handleClick={handleEditClick}
                inputVal={paymentDevVal?.name}
                inputPlace={"nomini kiring "}
                readOnly
              />
              <ul
                className={`absolute ${letEditList ? "h-[99px]" : "h-0 overflow-hidden"
                  } duration-100 w-full bg-white rounded-b-md shadow-xl top-[68px] ${letNameList && "border-2 border-slate-300"
                  }`}
              >
                <li
                  onClick={handleEidtNameListClick}
                  className={`pl-2 py-1 hover:bg-slate-300 font-semibold cursor-pointer ${editNameVal === "payme" && "bg-slate-200"
                    }`}
                >
                  payme
                </li>
                <li
                  onClick={handleEidtNameListClick}
                  className={`pl-2 py-1 hover:bg-slate-300 font-semibold cursor-pointer ${editNameVal === "click" && "bg-slate-200"
                    }`}
                >
                  click
                </li>
                <li
                  onClick={handleEidtNameListClick}
                  className={`pl-2 py-1 hover:bg-slate-300 font-semibold cursor-pointer ${editNameVal === "uzum" && "bg-slate-200"
                    }`}
                >
                  uzum
                </li>
              </ul>
            </div>
            <label className="w-full sm:w-[48%] flex flex-col">
              Merchand Key
              <Inputs
                noneDis={'py-2.5'}
                inputRef={merChandKeyRef}
                inputVal={paymentDevVal.merchantKey}
                inputPlace={"key"}
              />
            </label>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between mb-5">
            <label className="w-full sm:w-[48%] mb-3 sm:mb-0 flex flex-col">
              Merchand Id
              <Inputs
                noneDis={'py-2.5'}
                inputRef={merchandIdRef}
                inputVal={paymentDevVal.merchantId}
                inputPlace={"id"}
              />
            </label>
            {showEditInps ? (
              <label className="w-full sm:w-[48%] flex flex-col">
                Services ID
                <input
                  noneDis={'py-2.5'}
                  defaultValue={
                    paymentDevVal.params && JSON.parse(paymentDevVal.params)?.service_id
                  }
                  ref={editServicesIDRef}
                  className="py-2 px-2 rounded-md outline-none focus:border-blue-500 border-2"
                  placeholder="services id"
                />
              </label>
            ) : (
              <label className="w-full sm:w-[48%] flex flex-col">
                Params
                <Inputs
                  noneDis={'py-2.5'}
                  inputVal={paymentDevVal.params}
                  inputRef={paymeParams}
                  inputPlace={"params"}
                />
              </label>
            )}
          </div>
          <div className="flex flex-col sm:flex-row items-end justify-between mb-5">
            <label className="flex flex-col w-full mb-3 sm:mb-0 sm:w-[48%]">
              Date
              <DatePicer letTime={setLetEditTime} setCheckedTime={setEditTime} />
            </label>
            {showEditInps ? (
              <label className="w-full sm:w-[48%] flex flex-col">
                Merchand user ID
                <Inputs
                  noneDis={'py-2.5'}
                  inputVal={
                    paymentDevVal.params && JSON.parse(paymentDevVal.params)?.merchant_user_id
                  }
                  inputRef={editMerchandUserId}
                  inputPlace={"id"}
                />
              </label>
            ) : (
              ""
            )}
          </div>
          <div>
            <div className="text-[15px] flex items-center cursor-pointer" onClick={onChangeEdit}>
              <span
                onClick={onChangeEdit}
                className={`w-[20px] flex items-center justify-center relative h-[20px] border-2 mr-2 rounded-[50%] border-slate-400`}
              >
                <span
                  className={`${letEditTerminalId ? "bg-blue-600" : ""
                    } w-[12px] h-[12px] absolute rounded-[50%]`}
                ></span>
              </span>
              Terminal qo'shish
            </div>
            {letEditTerminalId ? (
              <label className="w-full sm:w-[48%] mt-2 flex flex-col">
                <Inputs
                  noneDis={'py-2.5'}
                  inputRef={editTerminalRef}
                  inputVal={paymentDevVal.terminalId}
                  inputPlace={"id"}
                />
              </label>
            ) : (
              ""
            )}
          </div>
          <Buttons extraSty={"w-[48%] block mx-auto mt-4"} bg={"blue"} title={"Saqlash"} />
        </form>
      </Modal>
      {/* Add Modal */}
      <Modal className={"w-[1000px]"} isVisible={showModal1} onClose={() => setShowModal1(false)}>
        <form onSubmit={handleAddSubmitPayment}>
          <div className="flex flex-col sm:flex-row items-center justify-between mb-5">
            <div className="w-full sm:w-[48%] relative flex flex-col">
              Nomi
              <Inputs
                noneDis={'py-2.5'}
                handleBlur={() => {
                  setTimeout(() => {
                    setLetNameList(false);
                  }, [150]);
                }}
                inputVal={nameSelect}
                inputRef={nameRef}
                handleClick={handleClick}
                inputPlace={"nomini kiring "}
                readOnly
              />
              <ul
                className={`absolute ${letNameList ? "h-[99px]" : "h-0 overflow-hidden"
                  } duration-100 w-full bg-white rounded-b-md shadow-xl top-[68px] ${letNameList && "border-2 border-slate-300"
                  }`}
              >
                <li
                  onClick={handleNameListClick}
                  className={`pl-2 py-1 hover:bg-slate-300 font-semibold cursor-pointer ${nameSelect === "payme" && "bg-slate-200"
                    }`}
                >
                  payme
                </li>
                <li
                  onClick={handleNameListClick}
                  className={`pl-2 py-1 hover:bg-slate-300 font-semibold cursor-pointer ${nameSelect === "click" && "bg-slate-200"
                    }`}
                >
                  click
                </li>
                <li
                  onClick={handleNameListClick}
                  className={`pl-2 py-1 hover:bg-slate-300 font-semibold cursor-pointer ${nameSelect === "uzum" && "bg-slate-200"
                    }`}
                >
                  uzum
                </li>
              </ul>
            </div>
            <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
              Merchand Key
              <Inputs noneDis={'py-2.5'} isReqiure={true} inputRef={addKeyRef} inputPlace={"key"} />
            </label>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-between mb-5">
            <label className="w-full sm:w-[48%] flex flex-col">
              Merchand Id
              <Inputs noneDis={'py-2.5'} isReqiure={true} inputRef={addIdRef} inputPlace={"id"} />
            </label>
            {showInp ? (
              <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                Services ID
                <Inputs noneDis={'py-2.5'} isReqiure={true} inputRef={servicesIDRef} inputPlace={"services id"} />
              </label>
            ) : (
              <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                Params
                <Inputs noneDis={'py-2.5'} inputRef={addParamsRef} inputPlace={"params"} />
              </label>
            )}
          </div>
          <div className="flex flex-col sm:flex-row items-end mb-5 justify-between ">
            <label className="flex flex-col w-full mb-3 sm:mb-0 sm:w-[48%]">
              Date
              <DatePicer letTime={setLetAddTime} setCheckedTime={setAddTime} />
            </label>
            {showInp ? (
              <label className="w-full sm:w-[48%] flex flex-col">
                Merchand user ID
                <Inputs noneDis={'py-2.5'} isReqiure={true} inputRef={merchandUserIdRef} inputPlace={"id"} />
              </label>
            ) : (
              ""
            )}
          </div>
          <div>
            <div className="text-[15px] flex items-center cursor-pointer" onClick={onChange}>
              <span
                onClick={onChange}
                className={`w-[20px] flex items-center justify-center relative h-[20px] border-2 mr-2 rounded-[50%] border-slate-400`}
              >
                <span
                  className={`${letTerminalId ? "bg-blue-600" : ""
                    } w-[12px] h-[12px] absolute rounded-[50%]`}
                ></span>
              </span>
              Terminal qo'shish
            </div>
            {letTerminalId ? (
              <label className="w-full sm:w-[48%] mt-2 flex flex-col">
                <Inputs noneDis={'py-2.5'} inputRef={addTerminalIdRef} inputPlace={"id"} />
              </label>
            ) : (
              ""
            )}
          </div>
          <Buttons extraSty={"w-[48%] block mx-auto mt-4"} bg={"blue"} title={"Qo'shish"} />
        </form>
      </Modal>
    </>
  );
}

export default MorePayment;

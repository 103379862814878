import { Input, Pagination } from "antd";
import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import BranchsTable from "../../components/Table/BranchsTable";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import useDebounce from "../../Hook/useDebounce"
import { AddBtn } from "../../components/NewBtn/NewBtn";
import PartinerSearch from "../../components/PartinerSearch/PartinerSearch";
import { Context } from "../../Context/Context";
import Loading1 from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
function AllList() {
  const navigate = useNavigate();
  const { lang, role, roleId } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [refResh, setRefResh] = useState(false);
  const [loading, setLoading] = useState(false);
  // Basic
  const [totalElement, setTotalElement] = React.useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  const [clearSearch, setClearSearch] = useState(JSON.parse(window.localStorage.getItem("branchSearchClear")) || false);
  const [allSearch, setAllSearch] = useState(window.localStorage.getItem("branchSearch") || "");

  // All handleChange start

  const handleAllSearchChange = (evt) => {
    setLoading(true);
    setPageNum(1);
    if (evt.target.value === "") {
      setAllSearch("");
      setClearSearch(false);
    } else {
      setClearSearch(true);
      setAllSearch(evt.target.value);
    }
  };
  const branchSearchWaiting = useDebounce(allSearch, 300)
  // All handle change end

  // Result Search Effect start
  const [partinerId, setPartinerId] = useState(JSON.parse(window.localStorage.getItem("BranchPartId")) || role == "partner" ? roleId : 0)
  const [partinerName, setPartinerName] = useState(window.sessionStorage.getItem("branchPartinerName") || "")
  const [branchResList, setBranchResList] = useState(
    JSON.parse(window.localStorage.getItem("brancMoreList")) || []
  );
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/branches`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          name: branchSearchWaiting,
          partner_id: partinerId,
          company_id: role == "company" ? roleId : 0
        },
      })
      .then((res) => {
        setLoading(false);
        setBranchResList(res.data.listOfItems);
        setTotalElement(res.data.totalElements);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchSearchWaiting, partinerId, pageNum, pageSize, refResh]);
  // All handleChange end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end

  window.localStorage.setItem("brancMoreList", JSON.stringify(branchResList));
  window.sessionStorage.setItem("branchPartinerName", partinerName);
  window.localStorage.setItem("branchSearchClear", JSON.stringify(clearSearch));
  window.localStorage.setItem("branchSearch", allSearch);
  window.localStorage.setItem("BranchPartId", JSON.stringify(partinerId) ? JSON.stringify(partinerId) : 0)

  return (
    <div className="bg-white py-5">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5 flex items-center justify-between mb-5">
        <div>
          <h2 className="font-bold text-[16px] sm:text-[25px]">{lang ? "Филиалы" : "Filliallar"}</h2>
          <span className="text-slate-400 text-[14px] sm:text-[16px ]">{totalElement} {lang ? "филиалы" : "fillal"}</span>
        </div>
        {role != "company" &&
          <AddBtn onClick={() => navigate(`add`)} title={lang ? "Добавить филиал" : "Fillial qo'shish"} />
        }
      </div>
      <div className="flex items-center flex-col space-y-2 xl:space-y-0 xl:flex-row xl:space-x-[50px] px-5 mb-5">
        <div className="relative flex flex-col w-full sm:w-[350px]">
          <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 items-baseline">{lang ? "Kлючевое слово для поиска" : "Qidirish uchun kalit so'z"}</span>
          <Input allowClear value={allSearch} className="w-full" size="large" onChange={handleAllSearchChange} placeholder={lang ? "Kлючевое слово для поиска" : "Qidirish uchun kalit so'z"} />
        </div>
        {role != "company" &&
          <div className="flex flex-col w-full sm:w-[350px]">
            <span className="text-[14px] sm:text-[16px] text-slate-500 mb-1 pl-1 items-baseline">{lang ? "Выберите партнера" : "Hamkon tanlang"}</span>
            <PartinerSearch setPageNum={setPageNum} value={role == "partner" ? partinerId : partinerId === 0 ? null : partinerName} setPartinerName={setPartinerName} width={'100%'} setLoading={setLoading} letLoading={true} setPartinerId={setPartinerId} />
          </div>
        }
      </div>
      <div className="px-5 hidden lg:block">
        <BranchsTable
          refResh={refResh}
          setRefResh={setRefResh}
          loading={loading}
          branchResList={branchResList}
        />
      </div>
      {/* Mobile start */}
      <ul className="lg:hidden px-5 lg:px-0">
        {loading ?
          <div className="flex justify-center">
            <img
              className="flex mx-auto items-start justify-center"
              src={Loading1}
              width={70}
              height={70}
              alt="Loading Icon"
            />
          </div>
          :
          branchResList.length > 0 && branchResList.map(item => (
            <li key={item.id} className="even:bg-slate-100 shadow-md border-[1px] text-[13px] border-slate-300 mt-5 py-3 rounded-md">
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] text-end font-semibold">ID</span>
                <span className="w-[50%]">: {item.id}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ИНН" : "STIR"}</span>
                <span className="w-[50%]">{item.inn}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ОРГАНИЗАЦИЯ" : "TASHKILOT"}</span>
                <span className="w-[50%]">{item.legalName}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "АДРЕС" : "MANZIl"}</span>
                <span className="w-[50%]">{item.address}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold uppercase">{lang ? "названия филиала" : "FILIAL NOMI"}</span>
                <span className="w-[50%]">{item.title}</span>
              </div>
              <div className="flex justify-between py-1 border-b-[1px] border-slate-300 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ВРЕМЯ СОЗДАНИЯ" : "YARATILGAN VAQT"}</span>
                <span className="w-[50%]">{item.createdAt.split("T")[0] || item.createdAt.split("T")[0]}</span>
              </div>
              <div className="flex items-center justify-between py-1 px-3">
                <span className="w-[50%] font-semibold">{lang ? "ПОДРОБНО" : "BATAFSIL"}</span>
                <div className="w-[50%]">
                  <button
                    onClick={(evt) => navigate(`more/${evt.target.id}`)}
                    id={item.id}
                    className="flex justify-center items-center rounded-[50%] w-[20px] h-[20px] bg-[#BFDDFC]"
                  >
                    <img className="w-[15px] h-[15px]" id={item.id} src={Edit} width={'100%'} height={"100%"} alt="Edit" />
                  </button>
                </div>
              </div>

            </li>
          ))
        }
      </ul>
      {/* Mobile end */}
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || branchResList.length > 0 ? totalElement : 2}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default AllList;

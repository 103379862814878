import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import dayjs from "dayjs";
import { toast, Toaster } from "react-hot-toast";
import { DatePicker } from "antd";
// Pictures
import Buttons from "../../components/Buttons/Buttons";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function TermAdd() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const dateFormat = "YYYY-MM-DD";
  const date = new Date();
  const token = JSON.parse(window.localStorage.getItem("token"));
  const editor = useRef(null);
  const [content, setContent] = useState();

  // Update part start
  const [updateData, setUpdateData] = useState({})
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/terms/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUpdateData(res.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            navigate('/');
            window.localStorage.clear();
            window.sessionStorage.clear();
            window.location.reload();
          }
        });
    }
  }, [])
  // Update part end

  // Date Picer start
  const [devTimem, setDevTime] = useState(
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );

  const handleChangeDatePiccer = (evt) => {
    setDevTime(
      evt.$y +
      "-" +
      (evt.$M + 1).toString().padStart(2, "0") +
      "-" +
      evt.$D.toString().padStart(2, "0")
    );
  };
  // Date Picer end

  // Terms full submit start
  const changesRef = useRef();
  const termsFullSubmit = (evt) => {
    if (id) {
      const getEditObj = {
        id: id - 0,
        createdAt: null,
        updatedAt: null,
        status: true,
        licence: content,
        changes: changesRef.current.value,
        date: devTimem,
        userId: JSON.parse(window.localStorage.getItem("userId")),
      };
      axios
        .post(`${env}cabinet/v2/terms/updated`, getEditObj, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success("Saqlandi :)");
          setTimeout(() => {
            navigate(-1)
          }, 800)
        })
        .catch((err) => {
          toast.error("Saqlanmadi :(");
        });
    }
    else {
      const data = {
        createdAt: null,
        updatedAt: null,
        status: true,
        licence: content,
        changes: changesRef.current.value,
        date: devTimem,
        userId: JSON.parse(window.localStorage.getItem("userId")),
      };
      axios
        .post(`${env}cabinet/v2/terms/save`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          toast.success("Qo'shildi");
          setTimeout(() => {
            navigate("/terms");
          }, [1000]);
        })
        .catch((err) => {
          toast.error("Xatolik bor ");
        });
    }
  };
  // Terms full submit end
  return (
    <div className="">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-white flex items-center justify-between p-4 rounded-md relative">
        <h2 className="font-bold text-[22px]">{id ? lang ? "Изменение терм" : "Term o'zgartirish" : lang ? "Добавить терм" : "Term qo'shish"}</h2>
        <div className="flex space-x-2">
          <Buttons clicked={termsFullSubmit} btnType={"submit"} extraSty={""} bg={"blue"} title={id ? lang ? "Изменять" : "O'zgartirish" : lang ? "Сохранять" : "Saqlash"} />
        </div>
      </div>
      <form>
        <div className="flex justify-between bg-white px-4 rounded-t-md pt-5">
          <label className="flex flex-col w-[20%]">
            <span className="text-[18px] font-semibold">{lang ? "Изменения" : "O'zgarishlar"}</span>
            <textarea
              defaultValue={id ? updateData?.changes : ""}
              ref={changesRef}
              required
              rows={3}
              className="border-2 h-[100%] mt-2 outline-none cursor-pointer focus:border-blue-500 p-3 rounded-md"
              placeholder={lang ? "изменения" : "o'zgarishlar"}
            ></textarea>
          </label>
          <label className="w-[79%]">
            <JoditEditor

              className="w-full h-[800px]"
              ref={editor}
              value={id ? updateData?.licence : content}
              onChange={(newCOntent) => setContent(newCOntent)}
            />
          </label>
        </div>
        <div className="bg-white p-4 flex items-center justify-between">
          <label className="flex flex-col w-[20%]">
            {lang ? "Время" : "Vaqt"}
            <DatePicker
              size='large'
              className="w-full border-slate-400"
              onChange={handleChangeDatePiccer}
              defaultValue={dayjs(
                `${date.getFullYear()}-${(date.getMonth() + 1)
                  .toString()
                  .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`,
                dateFormat
              )}
              format={dateFormat}
            />
          </label>
        </div>
      </form>
    </div>
  );
}

export default TermAdd;

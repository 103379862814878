import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../Context/Context";

function MessageTable({ branchList, loading, setChangeStatus, changeStatus }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const handleMoreClick = (evt) => {
    navigate(`more/${evt.target.id}`);
  };
  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/message/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeStatus(!changeStatus)
      })
  }
  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className="font-normal uppercase text-[13px] xl:text-[16px] text-center p-2.5">ID</th>
            <th className="font-normal uppercase text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Заголовок" : "Sarlavha"}</th>
            <th className="font-normal uppercase text-[13px] xl:text-[16px] text-center  p-2.5">{lang ? "Сообщения" : "Habar"}</th>
            <th className="font-normal uppercase text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Дата создания" : "Yaratilgan sana"}</th>
            <th className="font-normal uppercase text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Статус" : "Holati"}</th>
            <th className="font-normal uppercase text-[13px] xl:text-[16px] text-center p-2.5 px-1">{lang ? "Редактирование" : "Tahrirlash"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading}
                  width={60}
                  height={60}
                  alt="Loading Icon"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {branchList.length > 0 &&
              branchList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px]  p-2.5">{item.titleOz}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.contentOz}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item?.createdAt?.split("T")[0]} - {item?.createdAt.split("T")[1].split(".")[0]}</td>

                  <td className="border-t-[1px] border-slate-300 text-center text-[15px] p-2.5">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[15px] relative">
                    <button
                      onClick={handleMoreClick}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        )}
      </table>
    </div>
  );
}

export default MessageTable;

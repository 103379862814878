import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import AgreementsTable from "../../components/Table/Agreements"
import { Input, Pagination, Select } from 'antd';
import useDebounce from '../../Hook/useDebounce';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function Agreements() {
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalElement, setTotalElement] = useState(0);
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  // Sam Modul Start
  const [samModul, setSamModul] = useState("")
  const samModulChange = (evt) => {
    setPageNum(1)
    setSamModul(evt.target.value)
    setLoading(true)
  }
  const samModulWaiting = useDebounce(samModul, 500)
  // Sam Modul end

  // Device number Start
  const [deviceNumber, setDeviceNumber] = useState("")
  const deviceNumberChnage = (evt) => {
    setPageNum(1)
    setDeviceNumber(evt.target.value)
    setLoading(true)
  }
  const deviceNumberWaiting = useDebounce(deviceNumber, 500)
  // Device number end

  // Term Start
  const [termList, setTermsList] = useState([])
  let filterRequest1 = {
    pageNumber: 1,
    pageSize: 1000,
    sortingField: "id",
    sortDirection: "ASC",
  };
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/terms/get`, filterRequest1, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTermsList(res.data.listOfItems.map(item => ({ value: item.id, label: item.changes })));
      })
  }, []);
  const [termId, setTermId] = useState(null)
  const termChange = (value) => {
    setPageNum(1)
    setTermId(value)
    setLoading(true)
  }
  // Term end


  const [agreementsList, setAgreementsList] = useState([])
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/agreements`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          deviceNumber: deviceNumberWaiting,
          samModul: samModulWaiting,
          termId: termId
        }
      })
      .then((res) => {
        setAgreementsList(res.data.listOfItems);
        setTotalElement(res.data.totalElements);
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, termId, samModulWaiting, deviceNumberWaiting]);
  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  return (
    <div className='bg-white'>
      <div className="px-5 flex items-center justify-between p-4 rounded-md">
        <div>
          <h2 className="font-bold text-[25px] ">{lang ? "Список соглашений" : "Agreements royhati"}</h2>
          <span className="text-slate-400">{totalElement} {lang ? "список" : "ro'yhati"}</span>
        </div>
      </div>
      <div className='flex items-center flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-5 px-5 pb-5'>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>
            {lang ? "Войдите в свм модуль " : 'Sam modul kiriting'}
          </span>
          <Input onChange={samModulChange} allowClear className='w-[350px]' size='large' placeholder={lang ? "Войдите в свм модуль " : 'Sam modul kiriting'} />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "Введите номер устройства" : 'Aparat raqam kiriting'}</span>
          <Input onChange={deviceNumberChnage} allowClear className='w-[350px]' size='large' placeholder={lang ? "Введите номер устройства" : 'Aparat raqam kiriting'} />
        </label>
        <label className='flex flex-col'>
          <span className='text-slate-500 inline-block mb-1 pl-1'>{lang ? "Выбрать терм" : "Term tanlang"}</span>
          <Select
            value={termId}
            style={{ 'inlineSize': `350px` }}
            showSearch
            allowClear
            size="large"
            placeholder={lang ? "Выбрать терм" : "Term tanlang"}
            optionFilterProp="children"
            onChange={termChange}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={termList}
          />
        </label>
      </div>
      <AgreementsTable agreementsList={agreementsList} loading={loading} />
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || agreementsList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Agreements
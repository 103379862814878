import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../../Context/Context';

function ResultAnserMore() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [loading, setLoading] = useState(false)
  const { id, answerId } = useParams()

  const [listResultMore, setListResultMore] = useState([])
  const [blobalMore, setGlobalMore] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/poll/result/${answerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          pollId: id - 0
        }
      })
      .then((res) => {
        setListResultMore(res.data);
        setGlobalMore(res.data[0]);
      })
  }, [answerId])
  return (
    <div>
      <div className='px-5'>
        <h2 className='font-bold text-[25px]'>{blobalMore?.clientnumber}</h2>
      </div>
      <div className='p-5'>
        <ul className='p-2 flex flex-col space-y-2 border-[1.5px] border-slate-300 w-full md:w-[40%] shadow-md rounded-md'>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Опросник" : "So'rovnoma"}</span>
            <p className='text-[16px] sm:text-[18px]'>{blobalMore?.poll}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Организация" : "Tashkilot"}</span>
            <p className='text-[16px] sm:text-[18px]'>{blobalMore?.companyname}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Имя клиента" : "Klient ismi"}</span>
            <p className='text-[16px] sm:text-[18px]'>{blobalMore?.clientname}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Номер клиента" : "Klient raqami"}</span>
            <p className='text-[16px] sm:text-[18px]'>{blobalMore?.clientnumber}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Статус" : "Holati"}</span>
            <p className='text-[16px] sm:text-[18px]'>{blobalMore?.iscompleted ? "Tugallangan" : "Tugallanmagan"}</p>
          </li>
          <li>
            <span className='text-slate-400 text-[12px] sm:text-[14px]'>{lang ? "Время начала" : "Boshlangan vaqt"}</span>
            <p className='text-[16px] sm:text-[18px]'>{blobalMore?.startedtime?.split("T")[0]} - {blobalMore?.startedtime?.split("T")[1]?.split(".")[0]}</p>
          </li>
        </ul>
        <table className="w-full mt-5">
          <thead>
            <tr className="text-slate-400">
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-start p-2.5">№</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Вопрос" : "Savol"}</th>
              <th className="font-normal uppercase text-[13px] xl:text-[15px] text-center p-2.5">{lang ? "Ответ" : "Javob"} </th>
            </tr>
          </thead>
          <tbody>
            {listResultMore.length >= 0 &&
              listResultMore.map((item, index) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] border-slate-300 text-start text-[13px] xl:text-[15px] p-2.5">{index + 1}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.question}</td>
                  <td className="border-t-[1px] border-slate-300 text-center text-[13px] xl:text-[15px] p-2.5">{item.answer}</td>
                </tr>
              ))}
          </tbody>

        </table>
      </div>
    </div>
  )
}

export default ResultAnserMore
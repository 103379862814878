import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import {
  AppstoreOutlined,
  BankOutlined,
  UsergroupDeleteOutlined,
  UserSwitchOutlined,
  NotificationOutlined,
  DollarOutlined,
  SendOutlined,
  SlidersOutlined,
  MinusOutlined,
} from '@ant-design/icons';


function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function NewSiteBar({ showBar }) {
  const { lang, role, roleId } = useContext(Context)
  const [items, setItems] = useState([])
  useEffect(() => {
    if (role == "administrator") {
      setItems([
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Организация" : "Tashkilotlar"}</strong>, 'sub1', <BankOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/organization'}>{lang ? "Организация" : "Barcha tashkilotlar"}</Link>, '1', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/filist'}>{lang ? "Филиалы" : "Filliallar"}</Link>, '2', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/employeslist'}>{lang ? "Сотрудники" : "Xodimlar"}</Link>, '3', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/checklist'}>{lang ? "Чеки oрганизации" : "Tashkilot cheklari"}</Link>, '4', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/cashlist'}>{lang ? "Кассы" : "Kassalar"}</Link>, '5', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/organproducts'}>{lang ? "Продукты организации" : "Tashkilot mahsulotlari"}</Link>, '6', <MinusOutlined />),
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Товары" : "Mahsulotlar"}</strong>, 'sub2', <AppstoreOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/allproducts'}>{lang ? "Все продукты" : "Barcha mahsulotlar"}</Link>, '7', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/allpordadd'}>{lang ? "Добавить продукт" : "Mahsulot qo`shish"}</Link>, '8', <MinusOutlined />)
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Партнёры" : "Hamkorlar"}</strong>, 'sub3', <UserSwitchOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/patnerslist'}>{lang ? "Список партнеров" : "Hamkorlar ro`yxati"}</Link>, '9', <MinusOutlined />),
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Пользователи" : "Foydalanuvchilar"}</strong>, 'sub4', <UsergroupDeleteOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/userslist'}>{lang ? "Список пользователей" : "Foydalanuvchilar ro`yxati"}</Link>, '10', <MinusOutlined />),
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Уведомление" : "Bildirishnoma"}</strong>, 'sub5', <NotificationOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/notif-get'}>{lang ? "Отправил" : "Yuborilgan"}</Link>, '11', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/nofit-accept'}>{lang ? "Принял" : "Qabul qilingan"}</Link>, '12', <MinusOutlined />)
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Баланс" : "Balans"}</strong>, 'sub6', <DollarOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/balance'}>{lang ? "Посмотреть баланс" : "Balans ko'rish"}</Link>, '13', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/add-balance'}>{lang ? "Добавить баланс" : "Balans qo`shish"}</Link>, '14', <MinusOutlined />)
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>Telegram bot</strong>, 'sub7', <SendOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/research'}>{lang ? "Опрос" : "So'rovnoma"}</Link>, '15', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/all-messages'}>{lang ? "Сообщения" : "Xabarlar"}</Link>, '17', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/telegrambot-users'}>{lang ? "Пользователи" : "Foydalanuvchilar"}</Link>, '18', <MinusOutlined />)
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Общее руководство" : "Umumiy boshqaruv"}</strong>, 'sub9', <SlidersOutlined style={{ fontSize: '25px' }} />, [
          getItem(<span className='text-[20px] align-middle'>Terms & Agreements</span>, "sub10", <MinusOutlined />, [
            getItem(<Link className='text-[20px]' to={'/terms'}>{"Term"}</Link>, '19', <MinusOutlined />),
            getItem(<Link className='text-[20px]' to={'/agreements'}>{"Agreements"}</Link>, '20', <MinusOutlined />)
          ]),
          getItem(<Link className='text-[20px]' to={'/versionlist'}>{lang ? "Список версий" : "Versiyalar ro`yxati"}</Link>, '21', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/messages'}>{lang ? "Новости" : 'Yangiliklar'}</Link>, '22', <MinusOutlined />)
        ]),
      ]);
    }
    else if (role == "partner") {
      setItems([
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Организация" : "Tashkilotlar"}</strong>, 'sub1', <BankOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/organization'}>{lang ? "Организация" : "Barcha tashkilotlar"}</Link>, '1', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/filist'}>{lang ? "Филиалы" : "Filliallar"}</Link>, '2', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/employeslist'}>{lang ? "Сотрудники" : "Xodimlar"}</Link>, '3', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/checklist'}>{lang ? "Чеки oрганизации" : "Tashkilot cheklari"}</Link>, '4', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/cashlist'}>{lang ? "Кассы" : "Kassalar"}</Link>, '5', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/organproducts'}>{lang ? "Продукты организации" : "Tashkilot mahsulotlari"}</Link>, '6', <MinusOutlined />),
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Уведомление" : "Bildirishnoma"}</strong>, 'sub5', <NotificationOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/nofit-accept'}>{lang ? "Принял" : "Qabul qilingan"}</Link>, '12', <MinusOutlined />)
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Баланс" : "Balans"}</strong>, 'sub6', <DollarOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/balance'}>{lang ? "Посмотреть баланс" : "Balans ko'rish"}</Link>, '13', <MinusOutlined />),
        ]),
      ]);
    }
    else if (role == "company") {
      setItems([
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Организация" : "Tashkilot"}</strong>, 'sub1', <BankOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={`/organization/${roleId}`}>{lang ? "Моя организация" : "Mening tashkilotim"}</Link>, '1', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/filist'}>{lang ? "Филиалы" : "Filliallar"}</Link>, '2', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/employeslist'}>{lang ? "Сотрудники" : "Xodimlar"}</Link>, '3', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/checklist'}>{lang ? "Чеки" : "Cheklar"}</Link>, '4', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/cashlist'}>{lang ? "Кассы" : "Kassalar"}</Link>, '5', <MinusOutlined />),
          getItem(<Link className='text-[20px]' to={'/organproducts'}>{lang ? "Продукты" : "Mahsulotlar"}</Link>, '6', <MinusOutlined />),
        ]),
        getItem(<strong className='text-[25px] font-semibold align-middle'>{lang ? "Уведомление" : "Bildirishnoma"}</strong>, 'sub5', <NotificationOutlined style={{ fontSize: '25px' }} />, [
          getItem(<Link className='text-[20px]' to={'/nofit-accept'}>{lang ? "Принял" : "Qabul qilingan"}</Link>, '12', <MinusOutlined />)
        ])
      ]);
    }
  }, [lang])
  return (
    <div
      style={{
        inlineSize: '100%',
      }}
    >
      <Menu
        defaultSelectedKeys={['0']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="dark"
        inlineCollapsed={showBar}
        items={items}
      />
    </div>
  )
}

export default NewSiteBar
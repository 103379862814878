import React from 'react'

function AddBtn({ title, onClick, id, type }) {
  return (
    <button type={type} id={id} className='bg-[#3699FF] hover:opacity-90 p-2.5 rounded-md text-white flex items-center space-x-1 text-[13px] sm:text-[16px]' onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" opacity="1" />
      </svg>
      <span id={id}>{title}</span>
    </button>
  )
}
function UpdateBtn({ title, onClick, id }) {
  return (
    <button id={id} className='bg-[#3699FF] bi bi-pencil-square hover:opacity-90 p-2.5 rounded-md text-white flex items-center space-x-1.5 text-[14px] sm:text-[16px]' onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" viewBox="0 0 16 16">
        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" opacity="1" />
        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" opacity="1" />
      </svg>
      <span id={id}>{title}</span>
    </button>
  )
}

export { AddBtn, UpdateBtn }
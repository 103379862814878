import React, { useContext } from "react";
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useNavigate } from "react-router-dom";
import { Context } from "../../Context/Context";

function NotificationAccept({ notificationList, loading, }) {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const goToMoreSection = (evt) => {
    navigate(`more/${evt.target.id}`);
  };
  return (
    <table className="w-full">
      <thead>
        <tr className="">
          <th className="text-slate-400 text-[13px] xl:text-[15px] font-normal  text-center p-3">ID</th>
          <th className="text-slate-400 text-[13px] xl:text-[15px] font-normal  text-center p-3">{lang ? "ЗАГОЛОВОК" : "SARLAVHA"}</th>
          <th className="text-slate-400 text-[13px] xl:text-[15px] font-normal  text-center  p-3">{lang ? "СООБЩЕНИЕ" : "HABAR"}</th>
          <th className="text-slate-400 text-[13px] xl:text-[15px] font-normal  text-center p-3">{lang ? "ДАТА" : "SANA"}</th>
          <th className="text-slate-400 text-[13px] xl:text-[15px] font-normal  text-center p-3">{lang ? "ПОДРОБНО" : "BATAFSIL"}</th>
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td>
              <img
                className="flex mx-auto items-start justify-center"
                src={Loading}
                width={60}
                height={60}
                alt="Loading Icon"
              />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>
          {notificationList.length > 0 &&
            notificationList.map((item) => (
              <tr key={item.id} className="even:bg-[#FAFAFA] text-[16px]">
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-3 ">{item.id}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-3">{item.titleOz}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-3">{item.contentOz}</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-3">{item.createdAt.split("T")[0]} - {item.createdAt.split("T")[1].split(".")[0]}</td>
                <td className="border-t-[1px] text-[15px] border-slate-300 text-center relative p-3">
                  <button
                    onClick={goToMoreSection}
                    id={item.id}
                    className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#BFDDFC]"
                  >
                    <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      )}
    </table>
  );
}

export default NotificationAccept;

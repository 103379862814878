import { Pagination } from "antd";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import MeasureTable from "../../components/Table/MeasureTable";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context/Context"
function Measure() {
  const { lang } = useContext(Context)
  const navigate = useNavigate()
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [refResh, setRefResh] = useState(false);
  // Basic

  const [totalElement, setTotalElement] = React.useState(
    JSON.parse(window.sessionStorage.getItem("totalPageMensure")) || 0
  );
  const [pageNum, setPageNum] = useState(
    JSON.parse(window.sessionStorage.getItem("pagNumMensure")) || 1
  );
  const [pageSize, setPageSize] = useState(
    JSON.parse(window.sessionStorage.getItem("pagSizeMensure")) || 20
  );
  let filterRequest = {
    pageNumber: pageNum,
    pageSize: pageSize,
    sortingField: "id",
    sortDirection: "ASC",
  };

  // Basic
  // basic post
  const [unitsList, setUnitsList] = useState([]);
  useEffect(() => {
    axios
      .post(`${env}cabinet/v2/units`, filterRequest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTotalElement(res.data.totalElements);
        setUnitsList(res.data.listOfItems);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.location.reload();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, pageSize, refResh]);
  // basic post end

  // Pagination Start
  const onChange = (pageNumber, pageFullSize) => {
    setPageNum(pageNumber);
    setPageSize(pageFullSize);
  };
  // Pagination end
  window.sessionStorage.setItem("totalPageMensure", JSON.stringify(totalElement));
  window.sessionStorage.setItem("pagNumMensure", JSON.stringify(pageNum));
  window.sessionStorage.setItem("pagSizeMensure", JSON.stringify(pageSize));
  return (
    <div className="py-5 bg-white">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="px-5">
        <h2 className="font-bold text-[25px]">{lang ? "Единицы измерения" : "O'lchov birliklari"}</h2>
        <span className="text-slate-400">{totalElement} {lang ? "единицы измерения" : "o'lchov birliklar"}</span>
      </div>
      <div className="px-5">
        <MeasureTable setRefResh={setRefResh} refResh={refResh} unitsList={unitsList} />
      </div>
      <div className="flex justify-center py-5">
        <Pagination
          showQuickJumper
          defaultCurrent={pageNum}
          defaultPageSize={pageSize}
          total={totalElement || unitsList.length > 0 ? totalElement : 1}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default Measure;

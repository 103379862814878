import React from 'react'
import { Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import { Context } from '../../Context/Context';
function RoleSearch({ setRoleId, setLoading, setPageNum }) {
  const { lang } = useContext(Context)
  const LargeClearIcon = (props) => (
    <CloseCircleOutlined {...props} style={{ fontSize: '24px' }} />
  );
  const onChange = (value) => {
    if (setPageNum) {
      setPageNum(1)
    }
    setRoleId(value);
    setLoading(true)
  };
  const roleList = [
    {
      value: 1,
      label: "Administrator"
    },
    {
      value: 2,
      label: "Kassir"
    },
    {
      value: 3,
      label: "Buxgalter"
    },
    {
      value: 4,
      label: "Xamkor"
    },
    {
      value: 5,
      label: "Tashkilot"
    },
  ]

  return (
    <Select
      className='w-full sm:w-[350px]'
      style={{ 'inlineSize': `100%` }}
      showSearch
      allowClear
      size="large"
      placeholder={lang ? "Выберите позицию" : "Lavozim tanlang"}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={roleList}
    />
  )
}

export default RoleSearch
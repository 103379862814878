import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useState } from "react";
import LoadingFull from "../../components/LoadingFull/LoadingFull";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
function BranchsTable({ setRefResh, loading, refResh, branchResList }) {
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const navigate = useNavigate();
  const { lang, role } = useContext(Context)
  // Btn Clicks start
  const [fullLoading, setFullLoading] = useState(false);

  const handleMoreBntClick = (evt) => {
    setFullLoading(true);
    setTimeout(() => {
      setFullLoading(false);
      navigate(`more/${evt.target.id}`);
    }, [300]);
  };
  // Btn Clicks end

  const clickedActive = (evt) => {
    if (role == "partner") {
      if (branchResList.find(item => item.id == evt.target.id)?.active) {
        axios
          .put(
            `${env}cabinet/v2/branch/change-status?id=${Number(evt.target.id)}`,
            {
              params: {
                id: Number(evt.target.id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setRefResh(!refResh)
            toast.success(lang ? "Измененный" : "O'zgartirildi");
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murojaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murojaat qiling")
    }
    else {
      axios
        .put(
          `${env}cabinet/v2/branch/change-status?id=${Number(evt.target.id)}`,
          {
            params: {
              id: Number(evt.target.id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setRefResh(!refResh)
          toast.success(lang ? "Измененный" : "O'zgartirildi");
        })
    }
  }
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="text-slate-400">
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">ID</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "ИНН" : "STIR"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center  p-2.5">{lang ? "ОРГАНИЗАЦИЯ" : "TASHKILOT"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] uppercase text-center p-2.5">{lang ? "названия филиала" : "FILIAL NOMI"}  </th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "АДРЕС" : "MANZIl"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5 w-[10%]">{lang ? "ВРЕМЯ СОЗДАНИЯ" : "YARATILGAN VAQT"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5 w-[10%]">{lang ? "СТАТУС" : "STATUS"}</th>
            <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "ПОДРОБНО" : "BATAFSIL"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading}
                  width={70}
                  height={70}
                  alt="Loading Icon"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {branchResList.length > 0 ?
              branchResList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA]">
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.inn}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.legalName}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    {item.title}
                  </td>
                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-ellipsis max-w-[150px] xl:max-w-[350px] whitespace-nowrap overflow-hidden text-center p-2.5">{item.address}</td>

                  <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    {item.createdAt.split("T")[0] || item.createdAt.split("T")[0]}
                  </td>
                  <td className="border-t-[1px]  text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.active ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.active ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td id={item.id} className=" border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 relative border-slate-center">
                    <button
                      onClick={handleMoreBntClick}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[30px] h-[30px] bg-[#C2E0FF]"
                    >
                      <img id={item.id} src={Edit} width={20} height={20} alt="Edit" />
                    </button>
                  </td>
                </tr>
              )) :
              <tr>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
              </tr>
            }
          </tbody>
        )}
      </table>
      {fullLoading ? <LoadingFull /> : ""}
    </div>
  );
}

export default BranchsTable;

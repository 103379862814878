import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UpdateBtn } from "../../components/NewBtn/NewBtn"
import { useContext } from "react";
import { Context } from "../../Context/Context";
import toast, { Toaster } from "react-hot-toast";
function OtganBranchMore() {
  const { lang, role } = useContext(Context)
  const { id } = useParams();
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [refResh, setRefResh] = useState(false)
  const [moreData, setMoreData] = useState({});
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/branches/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMoreData(res.data);
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refResh]);
  window.sessionStorage.setItem("organEditBranch", JSON.stringify(moreData));

  const clickedActive = (evt) => {
    if (role == "partner") {
      if (moreData.active) {
        axios
          .put(
            `${env}cabinet/v2/branch/change-status?id=${Number(id)}`,
            {
              params: {
                id: Number(id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setRefResh(!refResh)
            toast.success(lang ? "Измененный" : "O'zgartirildi");
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murajaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Нет возможности изменить" : "O'zgartirish imkoniyati mavjud emas")
    }
    else {
      axios
        .put(
          `${env}cabinet/v2/branch/change-status?id=${Number(id)}`,
          {
            params: {
              id: Number(id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setRefResh(!refResh)
          toast.success(lang ? "Измененный" : "O'zgartirildi");
        })
    }
  }
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className=" bg-white rounded-lg mb-5">
        <div className="px-5 flex border-b-[1px] border-slate-300 items-center justify-between pb-4">
          <div className="flex items-center space-x-8">
            <h2 className="font-bold text-[20px]">{moreData.legalName}</h2>
          </div>
          <div className="flex items-end space-x-3">
            <div
              id={moreData.id}
              onClick={clickedActive}
              className="flex cursor-pointer justify-center items-center"
            >
              <span className="text-[15px] text-slate-500">{moreData.active ? lang ? "Активный" : "Faol" : lang ? "Не активен" : "Faol emas"}</span>
              <ul
                id={moreData.id}
                className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${moreData.active ? "bg-blue-500 " : "bg-slate-300"
                  } rounded-[50px]`}
              >
                <li
                  id={moreData.id}
                  className={`absolute duration-200 ${moreData.active ? "translate-x-4" : ""
                    } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                ></li>
              </ul>
            </div>
            {role != "company" &&
              <UpdateBtn onClick={() => navigate(`/filist/edit/${id}`)} title={lang ? "Редактироват" : 'Tahrirlash'} />
            }
          </div>
        </div>
      </div>
      <div className="px-5 flex flex-col md:flex-row items-start justify-between">
        <ul className="w-full md:w-[49%] border-[1px] p-5 rounded-lg flex flex-col space-y-4">
          <li>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Название филиала" : "Filial nomi"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.title}</h2>
          </li>
          <li>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Полное имя: (Официальное название)" : "Toliq nomlanishi: (Yuridik nomi)"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.legalName}</h2>
          </li>
          <li>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "ИНН номер" : "INN raqami"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.inn}</h2>
          </li>
          <li>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Тип организации" : "Tashkilot turi"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData?.typeName?.oz}</h2>
          </li>
          <li>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Полное имя директора" : "Rahbarning to'liq ismi"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.director}</h2>
          </li>
          <li>
            <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Телефон директора" : "Rahbarning telefon raqami"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.phoneNumber}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Бухгалтер" : "Buxgalter"}</span>
            <h2 className=" text-[16px] sm:text-[18px]">{moreData.accountant}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Телефон бухгалтера" : "Buxgalter telfon raqami"}</span>
            <h2 className=" text-[16px] sm:text-[18px]">{moreData.phoneNumber2}</h2>
          </li>
        </ul>
        <ul className="w-full mt-3 md:mt-0 md:w-[49%] border-[1px] p-5 rounded-lg flex flex-col space-y-4">
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Юридический адрес" : "Yuridik manzil"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.address}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Банк" : "Bank"}</span>
            <h2 className=" text-[16px] sm:text-[18px]">{moreData.bank}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Провинция" : "Viloyat"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData?.region?.oz}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Провинция/Город" : "Viloyat/Shahar"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData?.district?.oz}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Сделано" : "Kim tomonidan yaratilgan"}</span>
            <h2 className="text-[16px] sm:text-[18px]">{moreData.createdBy}</h2>
          </li>
          <li>
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Обновлено" : "Kim tomonidan o'zgartirilgan"}</span>
            <h2 className=" text-[16px] sm:text-[18px]">{moreData.updatedBy}</h2>
          </li>
          <li className="flex flex-col">
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
            <span className="text-[16px] sm:text-[18px]">{moreData?.createdAt?.split("T")[0]} - {moreData?.createdAt?.split("T")[1].split(".")[0]}</span>
          </li>
          <li className="flex flex-col">
            <span className="text-slate-500 sm:text-[16px]">{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
            <span className="text-[16px] sm:text-[18px]">{moreData?.updatedAt?.split("T")[0]} - {moreData?.updatedAt?.split("T")[1].split(".")[0]}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default OtganBranchMore;

import React from "react";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function AddProductsTable({ getMxikList }) {
  const { lang } = useContext(Context)
  return (
    <table className="w-full mt-5">
      <thead>
        <tr className="text-slate-400">
          <th className="text-center uppercase font-normal p-2.5">ID</th>
          <th className="text-center uppercase font-normal p-2.5">{lang ? "Мхик" : "Mxik"}</th>
        </tr>
      </thead>
      <tbody>
        {getMxikList.length > 0 &&
          getMxikList.map((item) => (
            <tr key={item.id} className="even:bg-[#FAFAFA]">
              <td className="border-t-[1px] border-slate-300 text-center p-2">{item.id}</td>
              <td className="border-t-[1px] border-slate-300 text-center p-2">{item.mxik}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default AddProductsTable;

import React from "react";

const SearchInput = ({
  type,
  pls,
  onChange,
  handleRef,
  onClick,
  width,
  blurInput,
  defaultValeu,
  readOnly,
}) => {
  return (
    <input
      defaultValue={defaultValeu}
      onBlur={blurInput}
      readOnly={readOnly ? true : false}
      ref={handleRef}
      onChange={onChange}
      onClick={onClick}
      className={`py-2 pr-2 focus:shadow-sm focus:shadow-blue-300 search-input border-[1.7px] pl-[38px] rounded-lg  ${width} outline-none`}
      type={type}
      placeholder={pls}
    />
  );
};

export default SearchInput;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Buttons from '../../components/Buttons/Buttons';
import { Input, Select, Space } from 'antd';
import JoditEditor from "jodit-react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import Checkbox2 from "../../components/Checkbox/Checkbox"
import { useContext } from 'react';
import { Context } from "../../Context/Context"

function NotifGetAdd() {
    const { lang } = useContext(Context)
    const env = process.env.REACT_APP_API;
    const token = JSON.parse(window.localStorage.getItem("token"));
    const dateFormat = "YYYY-MM-DD";
    const date = new Date();
    const navigate = useNavigate()
    const { id } = useParams()
    // Get Input search start
    const [typeId, setTypeId] = useState(2)
    const getInputSearch = (value) => {
        setTypeId(value);
    };
    // Get Input search end



    // Company list end

    // Jodit start
    const [contentOz, setContentOz] = useState(window.sessionStorage.getItem("contentOz") || "");
    const [contentUz, setContentUz] = useState(window.sessionStorage.getItem("contentUz") || "");
    const [contentRu, setContentRu] = useState(window.sessionStorage.getItem("contentRu") || "");
    const editor = useRef(null);

    // Jodit End

    // Date Picer start
    const [devTimem, setDevTime] = useState(
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
    );

    const handleChangeDatePiccer = (evt) => {
        setDevTime(
            evt.$y +
            "-" +
            (evt.$M + 1).toString().padStart(2, "0") +
            "-" +
            evt.$D.toString().padStart(2, "0")
        );
    };
    // Date Picer end

    //  Active Lang start
    const [activeLang, setActiveLang] = useState('uzb')
    //  Active Lang End

    // company change start
    const [company, setCompany] = useState(false)
    const [usersChangeList, setUsersChangeList] = useState(false)
    const [kkmChanges, setKkmChanges] = useState(false)
    const companyChange = (e) => {
        setCompany(!company)
        setUsersChangeList(false)
        setKkmChanges(false)
    };
    const [companyId, setCompanyId] = useState([])
    const companyListChange = (evt, id,) => {
        setCompanyId(id.map(item => item.id));
        setUserId([])
        setKkmId([])
    };
    const [companyList, setCompanyList] = useState([])
    useEffect(() => {
        axios
            .get(`${env}cabinet/v2/companies`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setCompanyList(res.data)
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    navigate("/")
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    window.location.reload();
                }
            });
    }, [])
    // company change end

    // User change start
    let filterRequest1 = {
        pageNumber: 1,
        pageSize: 10000,
        sortingField: "id",
        sortDirection: "ASC",
    };
    const [userId, setUserId] = useState([])
    const userChange = (evt, id) => {
        setUserId(id.map(item => item.id));
        setCompanyId([])
        setKkmId([])
    };
    const usersChanges = (e) => {
        setUsersChangeList(!usersChangeList)
        setCompany(false)
        setKkmChanges(false)
    };
    const [usersName, setUsersName] = useState([])
    useEffect(() => {
        axios
            .post(`${env}cabinet/v2/user`, filterRequest1, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setUsersName(res.data.listOfItems)
            })
    }, [])
    // User change end

    // Kassa start
    const [kkmList, setKkmList] = useState([])
    useEffect(() => {
        axios
            .post(`${env}cabinet/v2/checkout/getFilter`, filterRequest1, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setKkmList(res.data.listOfItems)
            })
    }, [])
    const [kkmId, setKkmId] = useState([])
    const handleChangekkm = (evt, id) => {
        setKkmId(id.map(item => item.id));
        setCompanyId([])
        setUserId([])
    }

    const kkmChangeList = () => {
        setKkmChanges(!kkmChanges)
        setCompany(false)
        setUsersChangeList(false)
    }
    // Kassa end

    // get Notic start
    const [seenNotif, setSeenNotif] = useState(false)
    const seenNotifClick = () => {
        setSeenNotif(!seenNotif)
    }
    // get Notic end

    const { Option } = Select;
    // Barnch end

    // Title change start
    const [titleOzVal, setTitleOzVal] = useState(window.sessionStorage.getItem("titleOz") || "")
    const OzbekChangeTitle = (evt) => {
        setTitleOzVal(evt.target.value);
    }

    const [titleUzVal, setTitleUzVal] = useState(window.sessionStorage.getItem("titleUz") || "")
    const UzbekChangeTitle = (evt) => {
        setTitleUzVal(evt.target.value);
    }
    const [titleRusVal, setTitleRusVal] = useState(window.sessionStorage.getItem("titleRus") || "")
    const RuschaChangeTitle = (evt) => {
        setTitleRusVal(evt.target.value);
    }
    // Title change end

    // Send request start
    const createNotification = () => {
        if (id) {
            const data = {
                titleUz: titleUzVal,
                titleOz: titleOzVal,
                titleRu: titleRusVal,
                date: devTimem,
                contentUz: contentUz,
                contentOz: contentOz,
                contentRu: contentRu,
                with_alert: seenNotif,
                id: id - 0
            }
            axios
                .put(`${env}cabinet/v2/notification/edit`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    toast.success(res.data)
                    setTitleOzVal("")
                    setTitleUzVal("")
                    setTitleRusVal("")
                    setContentOz("")
                    setContentUz("")
                    setContentRu("")

                    setTimeout(() => {
                        navigate(-1)

                    }, 800)
                }).catch(err => {
                    toast.error("Xatolik bor !")
                })
        }
        else {
            const data = {
                titleUz: titleUzVal,
                titleOz: titleOzVal,
                titleRu: titleRusVal,
                date: devTimem,
                contentUz: contentUz,
                contentOz: contentOz,
                contentRu: contentRu,
                type: typeId,
                withAlert: seenNotif,
                companies: companyId,
                devices: kkmId,
                users: userId
            }
            axios
                .post(`${env}cabinet/v2/notification/create`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    toast.success(res.data)
                    setTitleOzVal("")
                    setTitleUzVal("")
                    setTitleRusVal("")
                    setContentOz("")
                    setContentUz("")
                    setContentRu("")

                    setTimeout(() => {
                        navigate(-1)

                    }, 800)
                }).catch(err => {
                    toast.error("Xatolik bor !")
                })
        }
    }
    // Send request end

    // Update Part start
    const getOnce = id ? JSON.parse(window.localStorage.getItem("notifGetOne")) || {} : ""
    useEffect(() => {
        if (id) {
            setTitleOzVal(getOnce.titleOz)
            setTitleUzVal(getOnce.titleUz)
            setTitleRusVal(getOnce.titleRu)
            setContentOz(getOnce.contentOz)
            setContentUz(getOnce.contentUz)
            setContentRu(getOnce.contentRu)
            setSeenNotif(getOnce.withAlert)
            setDevTime(getOnce.createdAt.split("T")[0])
        }
        else {
            window.localStorage.removeItem("notifGetOne")
            setTitleOzVal("")
            setTitleUzVal("")
            setTitleRusVal("")
            setContentOz("")
            setContentUz("")
            setContentRu("")
            setSeenNotif("")
            setDevTime("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // Update Part end
    window.sessionStorage.setItem("titleOz", titleOzVal)
    window.sessionStorage.setItem("titleUz", titleUzVal)
    window.sessionStorage.setItem("titleRus", titleRusVal)

    window.sessionStorage.setItem("contentOz", contentOz)
    window.sessionStorage.setItem("contentUz", contentUz)
    window.sessionStorage.setItem("contentRu", contentRu)
    return (
        <div className=''>
            <Toaster position="top-center" reverseOrder={false} />
            <div className='px-5 py-5 bg-white flex items-center justify-between'>
                <div className='flex items-center space-x-8'>
                    <h2 className="font-bold text-[25px]">{lang ? "Отправить новое уведомление" : "Yangi bildirishnoma yuborish"}</h2>
                </div>
                <Buttons clicked={createNotification} btnType={'button'} bg={'blue'} title={id ? lang ? "Изменить сообщение" : "Xabarni o'zgartirish" : lang ? "Отправить сообщение" : "Xabarni yuborish"} />
            </div>
            <div className='flex mt-5 px-5 justify-between'>
                <div className='w-[63%] bg-white  p-5 rounded-lg'>
                    <div className='flex items-center justify-end space-x-4'>
                        <span className='text-[16px] font-semibold'>{lang ? "ЯЗЫК:" : "TIL:"}</span>
                        <div className='flex items-center space-x-3'>
                            <button className={`${activeLang === 'uzb' ? "text-blue-800  rounded-md bg-[#E1F0FF]" : ""} p-1`} onClick={() => setActiveLang('uzb')}>O'zbekcha</button>
                            <button className={`${activeLang === 'ru' ? "text-blue-800 rounded-md bg-[#E1F0FF]" : ""} p-1 `} onClick={() => setActiveLang('ru')}>Русский</button>
                            <button className={`${activeLang === 'kr' ? "text-blue-800 rounded-md bg-[#E1F0FF]" : ""} p-1 `} onClick={() => setActiveLang('kr')}>Krilcha</button>
                        </div>
                    </div>
                    <div className='flex justify-between'>
                        {id ? "" :
                            <div className='w-[49%] '>
                                <span className='text-[15px] mb-1 block'>{lang ? "Тип уведомления" : "Bildirishnoma turi"}</span>
                                <Space className='w-full' wrap>
                                    <Select
                                        value={typeId}
                                        size='large'
                                        className='w-full'
                                        defaultValue="Barchasi"
                                        style={{
                                            "inlineSize": '300%',
                                        }}
                                        onChange={getInputSearch}
                                        options={[
                                            {
                                                value: 1,
                                                label: 'Yangilik',
                                            },
                                            {
                                                value: 2,
                                                label: 'Xabar',
                                            }
                                        ]}
                                    />
                                </Space>
                            </div>
                        }

                        {activeLang === 'uzb' &&
                            <div className='w-[49%] '>
                                <span className='text-[15px] mb-1 block'>Mavzu kiriting</span>
                                <Input defaultValue={id ? getOnce.titleOz : titleOzVal} onChange={OzbekChangeTitle} size='large' placeholder="Mavzu" />
                            </div>
                        }
                        {activeLang === 'ru' &&
                            <div className='w-[49%] '>
                                <span className='text-[15px] mb-1 block'>Введите тему </span>
                                <Input defaultValue={id ? getOnce.titleRu : titleUzVal} onChange={UzbekChangeTitle} size='large' placeholder="Тема" />
                            </div>
                        }
                        {activeLang === 'kr' &&
                            <div className='w-[49%] '>
                                <span className='text-[15px] mb-1 block'>Мавзу киритинг</span>
                                <Input defaultValue={id ? getOnce.titleUz : titleRusVal} onChange={RuschaChangeTitle} size='large' placeholder="Мавзу" />
                            </div>
                        }
                    </div>
                    {id ? "" :
                        <div>
                            <div className='mt-5'>
                                <Checkbox2 handleClick={companyChange} setOpen={setCompany} open={company}>{lang ? "Выбор организации" : "Tashkilot tanlash"}</Checkbox2>
                                {company && <Select
                                    className='mt-2 notificationSelect'
                                    size='large'
                                    mode="multiple"
                                    style={{
                                        "inlineSize": '100%',
                                    }}
                                    placeholder={lang ? "Выбор организации" : "Tashkilot tanlash"}
                                    onChange={companyListChange}
                                    optionLabelProp="label"
                                >
                                    {companyList.length > 0 && companyList.map(item => (
                                        <Option id={item.id} key={item.id} value={item.title} label={item.title}>
                                            <Space>
                                                <span role="img" aria-label={item.title}>
                                                    KN :
                                                </span>
                                                {item?.title}
                                            </Space>
                                        </Option>

                                    ))}
                                </Select>}

                            </div>
                            <div className='mt-5'>
                                <Checkbox2 handleClick={usersChanges} setOpen={setUsersChangeList} open={usersChangeList}>{lang ? "Выбор пользователя" : "Foydalanuvchi tanlash"}</Checkbox2>
                                {usersChangeList && <Select
                                    className='mt-2 notificationSelect'
                                    size='large'
                                    mode="multiple"
                                    style={{
                                        "inlineSize": '100%',
                                    }}
                                    placeholder={lang ? "Выберите пользователя" : "Foydalanuvchi tanlang"}
                                    onChange={userChange}
                                    optionLabelProp="label"
                                >
                                    {usersName.length > 0 && usersName.map((item, index) => (
                                        <Option key={index} id={item.id} value={item.id} label={`${item.firstName}${item.lastName}`}>
                                            <Space>
                                                <span role="img" aria-label={item.firstName}>
                                                    FN :
                                                </span>
                                                {item.firstName}{item.lastName}
                                            </Space>
                                        </Option>
                                    ))}
                                </Select>}

                            </div>
                            <div className='mt-5'>
                                <Checkbox2 handleClick={kkmChangeList} setOpen={setKkmChanges} open={kkmChanges}>{lang ? "Выбор кассы" : "Kassa tanlash"}</Checkbox2>
                                {kkmChanges && <Select
                                    className='mt-2 notificationSelect'
                                    size='large'
                                    mode="multiple"
                                    style={{
                                        "inlineSize": '100%',
                                    }}
                                    placeholder={lang ? "Выбор кассы" : "Kassa tanlash"}
                                    onChange={handleChangekkm}
                                    optionLabelProp="label"
                                >
                                    {kkmList.length > 0 && kkmList.map((item, index) => (
                                        <Option key={index} id={item.id} value={item.id} label={item.title}>
                                            <Space>
                                                <span role="img" aria-label={item.title}>
                                                    KN :
                                                </span>
                                                {item.title}
                                            </Space>
                                        </Option>
                                    ))}
                                </Select>}

                            </div>
                        </div>}
                    {activeLang === 'uzb' &&
                        <div className='mt-5'>
                            <span className='text-[15px] mb-1 block'>{lang ? "Введите сообщение" : "Habar kkiriting"}</span>
                            <JoditEditor
                                className="w-full"
                                ref={editor}
                                value={contentOz}
                                onChange={(newCOntent) => setContentOz(newCOntent)}
                            />
                        </div>
                    }
                    {activeLang === 'ru' &&
                        <div className='mt-5'>
                            <span className='text-[15px] mb-1 block'>Введите сообщение</span>
                            <JoditEditor
                                className="w-full"
                                ref={editor}
                                value={contentUz}
                                onChange={(newCOntent) => setContentUz(newCOntent)}
                            />
                        </div>
                    }
                    {activeLang === 'kr' &&
                        <div className='mt-5'>
                            <span className='text-[15px] mb-1 block'>Ҳабар ккиритинг</span>
                            <JoditEditor
                                className="w-full"
                                ref={editor}
                                value={contentRu}
                                onChange={(newCOntent) => setContentRu(newCOntent)}
                            />
                        </div>
                    }
                </div>
                <div className='w-[35%] bg-white rounded-lg'>
                    <h2 className='py-5 border-b-2 px-5'>{lang ? "Время доставки" : "Jo'natish vaqti"}</h2>
                    <div className='px-5'>
                        <DatePicker
                            size='large'
                            className="w-full mt-5 border-slate-400"
                            onChange={handleChangeDatePiccer}
                            defaultValue={dayjs(
                                `${date.getFullYear()}-${(date.getMonth() + 1)
                                    .toString()
                                    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`,
                                dateFormat
                            )}
                            format={dateFormat}
                        />
                    </div>
                    <div className='text-right px-5 mt-5'>
                        <Checkbox2 handleClick={seenNotifClick} open={seenNotif} setOpen={setSeenNotif}>{lang ? "Показать уведомление" : "Bildirishnoma chiqarish"}</Checkbox2>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default NotifGetAdd